import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';


@Component({
  selector: 'app-more-actions-button',
  templateUrl: './more-actions-button.component.html',
  styleUrls: ['./more-actions-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule
  ]
})
export class MoreActionsButtonComponent {
  @Input() isOpened = false;
  @Input() disabled = false;

  constructor(public element: ElementRef<HTMLElement>) {}
}
