import { Injectable } from '@angular/core';
import { EMPTY_UID } from '@app/core/constants';
import { KpiGroup } from '@app/core/models/kpi.model';
import { ClientKpisRepository } from '@app/repositories/fishbone/client-questions/client-kpis/client-kpis.repository';
import { Observable } from 'rxjs';

@Injectable()
export class KpiService {
  private readonly blockCid = 'KpiService';

  constructor(private clientKpisRepository: ClientKpisRepository) {}

  getKpiGroups(
    clientUid: string,
    industryUid?: string,
    businessFunctionUids?: string[],
    blockCid?: string
  ): Observable<KpiGroup[]> {
    const queryParams = {
      industryUid,
      businessFunctionUids,
    };

    this.filterEmptyFileds(queryParams);

    return this.clientKpisRepository.getKpiGroups(
      clientUid,
      queryParams,
      blockCid || this.blockCid
    );
  }


  private filterEmptyFileds(queryParams: {
    industryUid: string;
    businessFunctionUids: string[];
  }) {
    Object.keys(queryParams).filter((key) => {
      if (queryParams[key] === EMPTY_UID) {
        delete queryParams[key];
      }

      if (Array.isArray(queryParams[key])) {
        queryParams[key] = queryParams[key].filter(
          (element) => element !== EMPTY_UID
        );
      }
    });
  }
}
