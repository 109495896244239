import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlLegacySelectDirective } from './directives/fnl-legacy-select.directive';
import { FnlLegacySelectGroupOptionDirective } from './directives/fnl-legacy-select-group-option.directive';
import { FnlLegacySelectOptionDirective } from './directives/fnl-legacy-select-option.directive';
import { FnlSelectGroupOptionDirective } from './directives/fnl-select-group-option.directive';
import { FnlSelectOptionDirective } from './directives/fnl-select-option.directive';
import { FnlSelectDirective } from './directives/fnl-select.directive';


@NgModule({
  declarations: [
    FnlLegacySelectDirective,
    FnlSelectDirective,
    FnlLegacySelectGroupOptionDirective,
    FnlSelectGroupOptionDirective,
    FnlLegacySelectOptionDirective,
    FnlSelectOptionDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FnlLegacySelectDirective,
    FnlSelectDirective,
    FnlLegacySelectGroupOptionDirective,
    FnlSelectGroupOptionDirective,
    FnlLegacySelectOptionDirective,
    FnlSelectOptionDirective,
  ],
})
export class FnlSelectModule {}
