import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fnl-paper',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FnlPaperComponent {
  @Input() titleText: string;
  @Input() minHeight: number;
  @Input() customPadding = '16px 24px 15px';
}
