import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWithBackoff } from '../operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  static readonly retryHeaderName = 'App-maxRetry';

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const maxRetry = Number(
      request.headers.get(RetryInterceptor.retryHeaderName)
    );
    request = request.clone({
      headers: request.headers.delete(RetryInterceptor.retryHeaderName),
    });
    return next.handle(request).pipe(retryWithBackoff(maxRetry));
  }
}
