<div
  class="filter-name"
  [class.filter-name_filled]="isFilled"
  [style.--fin-filter-left]="paddingLeft"
  [style.--fin-filter-right]="paddingRight"
>
  <mat-icon class="filter-name__icon" svgIcon="search"></mat-icon>
  <input
    class="filter-name__input"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (input)="onFilter()"
    (blur)="onBlur()"
  />
  <fnl-icon-button
    *ngIf="isFilled"
    class="filter-name__clear"
    [isRippleSmall]="true"
    (click)="onClear()"
  >
    <mat-icon class="filter-name__clear-icon" svgIcon="close"></mat-icon>
  </fnl-icon-button>
</div>
