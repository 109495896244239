import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { delay, filter, map, switchMap, take } from 'rxjs/operators';

import { LegacyCompany } from '../models/company.model';
import { CompanyService } from '../services/company.service';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class LegacyGuard  {
  constructor(
    private _router: Router,
    private _authGuard: AuthGuard,
    private _companyService: CompanyService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._authGuard.canActivate(route, state).pipe(
      switchMap((isActive: boolean) =>
        isActive
          ? this._companyService
              .getCompanyByLegacyId(route.params?.legacyCompanyId)
              .pipe(
                map((company?: LegacyCompany) => {
                  if (company != null) {
                    const subscription: Subscription = this._router.events
                      .pipe(
                        filter((ev) => ev instanceof NavigationCancel),
                        take(1),
                        delay(1)
                      )
                      .subscribe(() => {
                        this._router.navigate(
                          ['account-discovery', company.name, company.uid],
                          {
                            replaceUrl: true,
                            state: { redirect: state.url },
                          }
                        );

                        subscription.unsubscribe();
                      });
                  }

                  return !company;
                })
              )
          : of(false)
      )
    );
  }
}
