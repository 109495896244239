import { mixinDisabled, mixinInitialized } from '@angular/material/core';
import { MAX_INT32_VALUE } from '@app/common/constants/data-types';

export class PageEvent {
  pageIndex: number;
  previousPageIndex?: number;
  pageSize: number;
  length: number;
}

class MatPaginatorBase {}
// eslint-disable-next-line @typescript-eslint/naming-convention
export const _MatPaginatorBase: typeof MatPaginatorBase = mixinDisabled(
  mixinInitialized(MatPaginatorBase)
);

export const DEFAULT_PAGE_SIZE = 25;
export const MAX_PAGE_SIZE = MAX_INT32_VALUE;
