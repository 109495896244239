import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';

import { BaseList } from '../models/common.model';
import {
  UserNotification,
  FullUserNotificationApi,
  FullUserNotification,
  NotificationCreate
} from '../models/notification.model';
import { User } from '../models/user.model';
import { timezoneCorrection } from '../utils/date.util';

import { ApiService, BaseCreateResponse, BaseErrorResponse } from './api.service';
import { UserService } from './user.service';

export type ListPage = {
  size: number;
  index: number;
};
export type ListSorter = {
  property: string;
  direction: SortDirection;
};

export type SortDirectionApi = 'Ascending' | 'Descending' | '';

export type ListSorterApi = {
  property: string;
  direction: SortDirectionApi;
};

export type NotificationListFilter = {
  enabled?: boolean;
  displayOn?: string;
  contentLike?: string;
  status?: string;
};

@Injectable({
  providedIn: 'root',
})
export class UserNotificationsService {
  static blockCid = 'UserNotificationsService';

  constructor(private _apiService: ApiService, private _userService: UserService) {}

  getUserNotifications(userUid: string, blockCid?: string): Observable<BaseList<UserNotification>> {
    return this._apiService
      .get<BaseList<UserNotification>>(
        `common/users/${userUid}/notifications`,
        blockCid || UserNotificationsService.blockCid,
        -1
      )
      .pipe(
        map((list) => ({
          ...list,
          items: list.items.map((item) => ({
            ...item,
            lastUpdatedDate:
              item.lastUpdatedDate != null
                ? timezoneCorrection(item.lastUpdatedDate as string)
                : null,
          })),
        })),
        catchError(() =>
          of({
            items: [] as UserNotification[],
            totalCount: 0,
          } as BaseList<UserNotification>)
        )
      );
  }

  getAllNotifications(
    page: ListPage,
    sort?: ListSorter[],
    filter?: NotificationListFilter,
    blockCid = UserNotificationsService.blockCid
  ): Observable<BaseList<FullUserNotification>> {
    const sortApi: ListSorterApi[] = sort?.map((sorter) => ({
      property: sorter.property,
      direction: sorter.direction === 'asc' ? 'Ascending' : 'Descending',
    }));
    return this._apiService
      .post<BaseList<FullUserNotificationApi>>(
        'common/notifications/query',
        {
          page,
          sort: sortApi,
          filter,
        },
        blockCid,
        -1
      )
      .pipe(
        map(
          (list) =>
            ({
              ...list,
              items: list.items.reduce((result, item) => {
                const { visibilityClients, ...itemWithoutUnits } = item;
                const resultItem = {
                  ...itemWithoutUnits,
                  clients: item.visibilityClients.map((unit) => unit.name).join(', '),
                } as FullUserNotification;

                return [...result, resultItem];
              }, []),
            } as BaseList<FullUserNotification>)
        ),
      );
  }

  markAsViewed(uid: string, blockCid?: string): Observable<BaseCreateResponse> {
    return this._userService.getCurrentUser().pipe(
      first(),
      switchMap((user: User) =>
        this._apiService.put<BaseCreateResponse>(
          `common/users/${user.uid}/notifications/${uid}/viewed`,
          {
            viewed: true,
          },
          blockCid || UserNotificationsService.blockCid,
          -1
        )
      ),
      map(() => ({ entityUid: uid } as BaseCreateResponse)),
      catchError((error: HttpErrorResponse) =>
        of({
          entityUid: null,
          statusText: (error.error as BaseErrorResponse).detail,
        })
      )
    );
  }

  removeNotification(uid: string, blockCid?: string): Observable<boolean> {
    return this._apiService.delete(`common/notifications/${uid}`, blockCid || UserNotificationsService.blockCid).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  getNotification(uid: string, blockCid?: string): Observable<FullUserNotificationApi> {
    return this._apiService
      .get<FullUserNotificationApi>(`common/notifications/${uid}`, blockCid || UserNotificationsService.blockCid)
      .pipe(
        first(),
      );
  }

  createNotification(
    form: NotificationCreate,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    return this._apiService
      .post<BaseCreateResponse>(
        'common/notifications',
        {
          ...form,
          visibilityClients: form.visibilityClients || [],
        }, 
        blockCid || UserNotificationsService.blockCid
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  updateNotification(
    form: NotificationCreate,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    return this._apiService
      .put<BaseCreateResponse>(
        `common/notifications/${form.uid}`,
        {
          ...form,
          visibilityClients: form.visibilityClients || [],
        }, 
        blockCid || UserNotificationsService.blockCid
      )
      .pipe(
        map(()=> ({uid: form.uid})),
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }
}
