import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UUID } from '@app/core/models/common.model';
import {
  ClientUserManagementApiService
} from '@app/api/identity/client-user-management/client-user-management-api.service';
import {
  ClientAdministratorsResponse
} from '@app/repositories/identity/client-user-management/models/client-administrators.model';

@Injectable({
  providedIn: 'root'
})
export class ClientUserManagementRepository {
  blockCid = 'ClientUserManagementRepository';

  constructor(
    private clientUserManagementApiService: ClientUserManagementApiService,
  ) {}

  getClientAdministrators(clientUid: UUID, blockCid: UUID = this.blockCid): Observable<ClientAdministratorsResponse> {
    return this.clientUserManagementApiService.getClientAdministrators(clientUid, blockCid);
  }
}
