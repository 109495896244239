/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { ClientFeatureCode } from '@app/core/models/client.model';
import { User } from '@app/core/models/user.model';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { UserSettingsService } from '@app/core/services/user-settings.service';
import { UserService } from '@app/core/services/user.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { FooterPortalType, LayoutService } from '../layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @HostBinding('class.global-wrapper') isWrapper = true;

  readonly defaultHeaderTitle = 'View Classic ClientIQ';
  readonly headerTitleButton = {
    IBM: 'View Classic CBV',
  };
  readonly goToBackLink = `${environment.apiGateway}/identity/Legacy/SSO/v1TitlePage`;

  linkBackToLegacyTitle$: Observable<string>;
  showLegacyLink$: Observable<boolean>;

  portal$: Observable<FooterPortalType>;
  currentYear: number;

  constructor(
    private _userService: UserService,
    private _userSettingsService: UserSettingsService,
    private _layoutService: LayoutService,
    private _amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.showLegacyLink$ = combineLatest([
      this._userService
        .getCurrentUser()
        .pipe(map((user: User) => user.legacyId != null)),
      this._userSettingsService.isFeatureEnabled(
        ClientFeatureCode.ClientIQ1Link
      ),
    ]).pipe(map(([enabled1, enabled2]) => enabled1 && enabled2));
    this.linkBackToLegacyTitle$ = this._userService
      .getCurrentUser()
      .pipe(
        map(
          (user: User) =>
            this.headerTitleButton[user.clientCode] || this.defaultHeaderTitle
        )
      );

    this.currentYear = new Date().getFullYear();
    this.portal$ = this._layoutService.getFooterPortal();
  }

  amplitudeTracking(nameEvent: string): void {
    this._amplitudeService.amplitudeEvent(nameEvent);
  }
}
