import { Directive, Input } from '@angular/core';
import { MatOption } from '@angular/material/core';

@Directive({
  selector: 'mat-option[fnlSelectGroupOption]'
})
export class FnlSelectGroupOptionDirective {

  /**
   * List of values to be selected by this option.
   */
  @Input() valuesToSelect: unknown[];

  constructor(
    public host: MatOption,
  ) {}
}
