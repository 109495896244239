import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BaseItem } from '@app/core/models/common.model';
import { MetricsTrend, MetricsUnit } from '@app/core/models/metric.model';
import { formatMetric } from '@app/core/utils/base';
import { tuiDefaultProp, tuiPure } from '@taiga-ui/cdk';
import * as d3 from 'd3';
import { Dictionary, sortBy } from 'lodash';
import { CommonModule } from '@angular/common';
import { FnlInfoModule } from 'fnl-ui';

type ArcData = {
  fill: string;
  value: number;
};

type DotData = DashboardCompany & {
  angle: number;
  level: number;
};

export interface DashboardMeasures extends BaseItem {
  firstQuartile?: number;
  fourthQuartile?: number;
  median: number;
  company?: DashboardCompany;
  peers: DashboardCompany[];
}

export interface DashboardMetric extends DashboardMeasures {
  /**
   * The description of the metric.
   */
  description?: string;
  /**
   * The trend shows in which direction the scale is marked: from a small value to a large one, or vice versa.
   */
  trend: MetricsTrend;
  /**
   * The unit of measurement of the metric.
   */
  unit: MetricsUnit;
  /**
   * Empty state flag.
   */
  placeholder?: boolean;
}

export interface DashboardCompany extends BaseItem {
  color: string;
  value?: number;
}

@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FnlInfoModule,
  ]
})
export class DashboardChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') chartRef: ElementRef<SVGSVGElement>;
  @ViewChild('tooltip') tooltipRef: ElementRef<HTMLElement>;
  @Input() metric: DashboardMetric;

  @Input()
  @tuiDefaultProp()
  width?: number = 300;

  chartClassName = 'chart-svg';

  // private readonly _width = 300;
  private readonly _height = 140;
  private readonly _radius = 105;
  private readonly _innerRadius = (this._radius * 3) / 7;
  private readonly _levelSize = 12;
  private readonly _topFix = 4;
  private readonly _bottomAngleFix =
    this._levelSize / (this._innerRadius + this._topFix + this._levelSize * 2);
  private readonly _criticalDifference =
    this._levelSize / (this._innerRadius + this._topFix + this._levelSize * 2);
  private readonly _arcsData: ArcData[] = [
    {
      fill: 'rgba(52, 102, 138, 0.1)',
      value: 45,
    },
    {
      fill: 'rgba(52, 102, 138, 0.16)',
      value: 90,
    },
    {
      fill: 'rgba(52, 102, 138, 0.3)',
      value: 45,
    },
  ];
  private readonly _lineData: [number, number][] = [
    [
      -Math.cos(-0.25 * Math.PI) * this._innerRadius,
      Math.sin(-0.25 * Math.PI) * this._innerRadius,
    ],
    [
      -Math.cos(-0.25 * Math.PI) * this._radius,
      Math.sin(-0.25 * Math.PI) * this._radius,
    ],
    [-Infinity, -Infinity],
    [0, -this._innerRadius],
    [0, -this._radius],
    [-Infinity, -Infinity],
    [
      Math.cos(0.25 * Math.PI) * this._innerRadius,
      -Math.sin(0.25 * Math.PI) * this._innerRadius,
    ],
    [
      Math.cos(0.25 * Math.PI) * this._radius,
      -Math.sin(0.25 * Math.PI) * this._radius,
    ],
  ];

  private _svg: d3.Selection<SVGElement, unknown, null, undefined>;
  private _scene: d3.Selection<SVGElement, unknown, null, undefined>;
  private _dataLayer: d3.Selection<SVGElement, unknown, null, undefined>;
  private _dots: DotData[];

  @HostBinding('class.placeholder')
  get placeholder(): boolean {
    return !!this.metric.placeholder;
  }

  get chartClasses(): Dictionary<boolean> {
    return { [this.chartClassName]: true };
  }

  get trendMultiplier(): 1 | -1 {
    return this._getTrendMultiplier(this.metric?.trend);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.metric != null &&
      !changes.metric.firstChange &&
      changes.metric.currentValue !== changes.metric.previousValue
    ) {
      this._reinitializeData(changes.metric.currentValue);
    }
  }

  ngAfterViewInit(): void {
    this._svg = d3.select(this.chartRef.nativeElement);

    this._initializeDimensions();
    this._initScene();
    this._initDataLayer();

    if (this.metric != null) {
      this._reinitializeData(this.metric);
    }
  }

  private _initializeDimensions(): void {
    this._svg.attr(
      'viewBox',
      `${-this.width / 2} ${-this._height + 3} ${this.width} ${this._height}`
    );
  }

  private _initDataLayer(): void {
    this._dataLayer = this._svg
      .append('g')
      .classed(`${this.chartClassName}__data-layer`, true);
  }

  private _clearDataLayer(): void {
    this._dataLayer.selectChildren().remove();
  }

  //#region Dots and Data
  private _reinitializeData(metric: DashboardMetric): void {
    this._clearDataLayer();
    this._dots = this._initDots(metric);
    this._drawDots(this._dots);
    this._drawMedianText(metric);
    if (this._dots?.length > 0 && metric.company?.value != null) {
      this._drawPointer(this._dots[0]);
    }
  }

  private _initDots(metric: DashboardMetric): DotData[] {
    const { median, firstQuartile, forthQuartile, min, max } =
      this._prepareIndustryParts(metric);

    const dots: DotData[] = [];
    if (metric.company?.value != null) {
      dots.push({
        ...metric.company,
        angle: this._computeAngle(
          this.trendMultiplier,
          metric.company.value,
          median,
          firstQuartile,
          forthQuartile,
          max,
          min
        ),
        level: 2,
      });
    }
    metric.peers.forEach((peer) => {
      if (peer.value != null) {
        dots.push({
          ...peer,
          angle: this._computeAngle(
            this.trendMultiplier,
            peer.value,
            median,
            firstQuartile,
            forthQuartile,
            max,
            min
          ),
          level: 2,
        });
      }
    });

    return this._fixLevelConflicts(dots);
  }

  // eslint-disable-next-line complexity
  private _fixLevelConflicts(dots: DotData[]): DotData[] {
    const newDots = dots.slice(0);
    const sortedByAngleDotsIndexes = sortBy(
      newDots.map((_, i) => i),
      (value) => newDots[value].angle
    );
    const conflictsToCheck: Dictionary<boolean> = {};
    let checkRange: [number, number];
    for (let i = sortedByAngleDotsIndexes.length, levelConflicts = []; i--; ) {
      if (conflictsToCheck[sortedByAngleDotsIndexes[i]]) {
        continue;
      }
      checkRange = [
        newDots[sortedByAngleDotsIndexes[i]].angle - this._criticalDifference,
        newDots[sortedByAngleDotsIndexes[i]].angle + this._criticalDifference,
      ];

      for (let j = sortedByAngleDotsIndexes.length; j--; ) {
        if (i === j || conflictsToCheck[sortedByAngleDotsIndexes[j]]) {
          continue;
        }

        conflictsToCheck[sortedByAngleDotsIndexes[j]] =
          newDots[sortedByAngleDotsIndexes[i]].level ===
            newDots[sortedByAngleDotsIndexes[j]].level &&
          checkRange[0] <= newDots[sortedByAngleDotsIndexes[j]].angle &&
          checkRange[1] >= newDots[sortedByAngleDotsIndexes[j]].angle;
        if (conflictsToCheck[sortedByAngleDotsIndexes[j]]) {
          levelConflicts.push(sortedByAngleDotsIndexes[j]);
          if (
            newDots[sortedByAngleDotsIndexes[j]].angle >
            newDots[sortedByAngleDotsIndexes[i]].angle
          ) {
            checkRange[1] = Math.max(
              newDots[sortedByAngleDotsIndexes[j]].angle +
                this._criticalDifference,
              checkRange[1]
            );
          } else {
            checkRange[0] = Math.min(
              newDots[sortedByAngleDotsIndexes[j]].angle -
                this._criticalDifference,
              checkRange[0]
            );
          }
        }
      }

      if (levelConflicts.length > 0) {
        levelConflicts.push(sortedByAngleDotsIndexes[i]);

        const startIndex = levelConflicts.length % 2;
        const startBonus = startIndex === 0 ? 0.5 : 0;
        for (
          let k = startIndex, kOdd: boolean;
          k < levelConflicts.length;
          k++
        ) {
          kOdd = k % 2 === 1;
          newDots[levelConflicts[k]] = {
            ...newDots[levelConflicts[k]],
            level:
              newDots[levelConflicts[k]].level +
              (kOdd ? -1 : 1) *
                (startBonus + Math[startIndex === 1 ? 'ceil' : 'floor'](k / 2)),
          };
        }
        levelConflicts = [];
      }
    }

    return newDots;
  }

  private _computeAngle(
    trendMultiplier: 1 | -1,
    value: number,
    median: number,
    firstQuartile: number,
    forthQuartile: number,
    max: number,
    min: number
  ): number {
    const angle =
      trendMultiplier * value > trendMultiplier * median
        ? trendMultiplier * value < trendMultiplier * firstQuartile
          ? Math.PI *
            0.25 *
            (Math.abs(value - median) / Math.abs(median - firstQuartile))
          : Math.PI *
            (0.25 +
              0.25 *
                (Math.abs(value - firstQuartile) /
                  Math.abs(max - firstQuartile)))
        : trendMultiplier * value > trendMultiplier * forthQuartile
        ? Math.PI *
          -0.25 *
          (Math.abs(value - median) / Math.abs(median - forthQuartile))
        : Math.PI *
          -(
            0.25 +
            0.25 *
              (Math.abs(value - forthQuartile) / Math.abs(min - forthQuartile))
          );

    return 0.5 * Math.PI - Math.abs(angle) > this._bottomAngleFix
      ? angle
      : Math.sign(angle) * (0.5 * Math.PI - this._bottomAngleFix);
  }

  private _drawDots(dots: DotData[]): void {
    this._dataLayer
      .selectAll('circle')
      .data(dots)
      .join('circle')
      .attr('cx', (d) => this._getXCircleCoordinates(d))
      .attr('cy', (d) => this._getYCircleCoordinates(d))
      .style(
        'transform-origin',
        (d) =>
          `${this._getXCircleCoordinates(d)}px ${this._getYCircleCoordinates(
            d
          )}px`
      )
      .attr('r', 6)
      .attr('stroke-width', 2)
      .attr('stroke', '#fff')
      .attr('fill', (d) => d.color)
      .on('mouseover', (event: Event, dot) => {
        const point = this.chartRef.nativeElement.createSVGPoint();
        [point.x, point.y] = d3.pointer(event);
        const matrix = this.chartRef.nativeElement.getScreenCTM()
        const position = point.matrixTransform(matrix);

        const d3Tip = d3
          .select(this.tooltipRef.nativeElement)
          .style('display', 'flex')
          .style('left', `${position.x + 20}px`)
          .style('top', `${position.y + 3}px`)
          .html(
            `<span>${dot.name}</span><b>${formatMetric(
              dot.value,
              this.metric.unit
            )}`
          );
        if (this.tooltipRef.nativeElement.offsetWidth > 250) {
          d3Tip.classed('dashboard-chart__tooltip-multi', true);
        }

        const tooltipBounding =
          this.tooltipRef.nativeElement.getBoundingClientRect();

        if (window.innerWidth < tooltipBounding.right) {
          d3Tip
            .style('left', `${position.x - 60 - tooltipBounding.right + window.innerWidth}px`)
            .style('top', `${position.y - 30}px`);
        }
      })
      .on('mouseout', () => {
        d3.select(this.tooltipRef.nativeElement)
          .style('display', 'none')
          .classed('dashboard-chart__tooltip-multi', false);
      });
  }

  private _getXCircleCoordinates(data: DotData): number {
    return (
      Math.sin(data.angle) *
      (this._innerRadius + this._topFix + data.level * this._levelSize)
    );
  }
  private _getYCircleCoordinates(data: DotData): number {
    return (
      -Math.cos(data.angle) *
      (this._innerRadius + this._topFix + data.level * this._levelSize)
    );
  }

  private _drawMedianText(metric: DashboardMetric): void {
    this._dataLayer
      .append('text')
      .attr('x', -71)
      .attr('y', -137)
      .attr('dy', '1.23em')
      .attr('font-family', '"Roboto", sans-serif')
      .attr('font-size', '13px')
      .attr('textLength', '143')
      .classed(`${this.chartClassName}__text-group`, true)
      .text(
        `Industry Median: ${
          metric.median != null ? formatMetric(metric.median, metric.unit) : '-'
        }`
      );
  }

  private _drawPointer(companyDot: DotData): void {
    const angle = (companyDot.angle * 180) / Math.PI;
    const path = d3.path();
    path.moveTo(0, 0);
    path.quadraticCurveTo(-2, 1, -3, -10);
    path.quadraticCurveTo(-3, -25, -1, -40);
    path.arc(0, -40, 1, -Math.PI, 0);
    path.quadraticCurveTo(3, -25, 3, -10);
    path.quadraticCurveTo(2, 1, 0, 0);
    path.closePath();
    this._dataLayer
      .append('path')
      .attr('fill', '#34668a')
      .style('transform', `rotate(${angle}deg)`)
      .attr('d', path.toString());
  }
  //#endregion

  //#region Industry preparation
  private _prepareIndustryParts(metric: DashboardMetric): {
    median: number;
    firstQuartile: number;
    forthQuartile: number;
    max: number;
    min: number;
  } {
    const dots = sortBy(
      [
        metric.company?.value,
        ...metric.peers.map((item) => item.value)
      ].filter(item => !!item),
      (item) => (item || 0) * this.trendMultiplier
    );
    const trendMultiplier = this.trendMultiplier;

    const median = this._prepareMedian(
      trendMultiplier,
      dots,
      metric.median,
      metric.firstQuartile,
      metric.fourthQuartile
    );
    const firstQuartile = this._prepareFirstQuartile(
      trendMultiplier,
      dots,
      median,
      metric.firstQuartile,
      metric.fourthQuartile
    );
    const forthQuartile = this._prepareForthQuartile(
      trendMultiplier,
      dots,
      median,
      metric.firstQuartile,
      metric.fourthQuartile
    );
    const initialStep = Math.max(
      Math.abs(median - forthQuartile),
      Math.abs(firstQuartile - median)
    );
    const max = firstQuartile + trendMultiplier * initialStep;
    const min = forthQuartile - trendMultiplier * initialStep;
    return {
      median,
      firstQuartile,
      forthQuartile,
      max:
        max * trendMultiplier > dots[dots.length - 1] * trendMultiplier
          ? max
          : dots[dots.length - 1],
      min: min * trendMultiplier < dots[0] * trendMultiplier ? min : dots[0],
    };
  }
  private _prepareMedian(
    trendMultiplier: 1 | -1,
    dots: number[],
    median?: number,
    firstQuartile?: number,
    forthQuartile?: number
  ): number {
    if (median != null) {
      return median;
    }

    if (firstQuartile != null && forthQuartile != null) {
      return (firstQuartile + forthQuartile) / 2;
    }

    if (firstQuartile == null && forthQuartile != null) {
      const afterForthQuartile = dots.filter(
        (item) =>
          trendMultiplier * (item || 0) > trendMultiplier * forthQuartile
      );

      if (afterForthQuartile.length !== 0) {
        return (
          (afterForthQuartile.reduce(
            (result, item) => result + (item || 0),
            0
          ) +
            forthQuartile) /
            afterForthQuartile.length +
          1
        );
      } else {
        return forthQuartile * (1 + 0.5 * trendMultiplier);
      }
    } else if (firstQuartile != null && forthQuartile == null) {
      const beforeFirstQuartile = dots.filter(
        (item) =>
          trendMultiplier * (item || 0) < trendMultiplier * firstQuartile
      );

      if (beforeFirstQuartile.length !== 0) {
        return (
          (beforeFirstQuartile.reduce(
            (result, item) => result + (item || 0),
            0
          ) +
            firstQuartile) /
            beforeFirstQuartile.length +
          1
        );
      } else {
        return firstQuartile * (1 - 0.5 * trendMultiplier);
      }
    } else {
      return (
        dots.reduce((result, item) => result + (item || 0), 0) / dots.length
      );
    }
  }
  private _prepareFirstQuartile(
    trendMultiplier: 1 | -1,
    dots: number[],
    median: number,
    firstQuartile?: number,
    forthQuartile?: number
  ): number {
    if (firstQuartile != null) {
      return firstQuartile;
    }

    if (forthQuartile != null) {
      return Math.abs(median - forthQuartile) * trendMultiplier + median;
    } else {
      const afterMedian = dots.filter(
        (item) => trendMultiplier * (item || 0) > trendMultiplier * median
      );

      if (afterMedian.length !== 0) {
        return (
          afterMedian.reduce((result, item) => result + item || 0, 0) /
          afterMedian.length
        );
      } else {
        return median * (1 + 0.5 * trendMultiplier);
      }
    }
  }
  private _prepareForthQuartile(
    trendMultiplier: 1 | -1,
    dots: number[],
    median: number,
    firstQuartile?: number,
    forthQuartile?: number
  ): number {
    if (forthQuartile != null) {
      return forthQuartile;
    }

    if (firstQuartile != null) {
      return median - Math.abs(median - firstQuartile) * trendMultiplier;
    } else {
      const beforeMedian = dots.filter(
        (item) => trendMultiplier * (item || 0) < trendMultiplier * median
      );

      if (beforeMedian.length !== 0) {
        return (
          beforeMedian.reduce((result, item) => result + item || 0, 0) /
          beforeMedian.length
        );
      } else {
        return median * (1 - 0.5 * trendMultiplier);
      }
    }
  }
  //#endregion

  //#region Scene
  private _initScene(): void {
    this._scene = this._svg
      .append('g')
      .classed(`${this.chartClassName}__scene`, true);

    this._initializeSceneArcs(this._scene);
    this._initializeSceneLines(this._scene);
    this._initializeSceneText(this._scene);
  }

  private _initializeSceneArcs(
    scene: d3.Selection<SVGElement, unknown, null, undefined>
  ): void {
    const arc = d3
      .arc<any, d3.PieArcDatum<ArcData>>()
      .innerRadius(this._innerRadius)
      .outerRadius(this._radius);
    const pie = d3
      .pie<any, ArcData>()
      .sort(null)
      .value((d) => d.value)
      .startAngle(-0.5 * Math.PI)
      .endAngle(0.5 * Math.PI)(this._arcsData);
    scene
      .selectAll('path')
      .data(pie)
      .join('path')
      .attr('fill', (d) => d.data.fill)
      .attr('d', arc);
  }

  private _initializeSceneLines(
    scene: d3.Selection<SVGElement, unknown, null, undefined>
  ): void {
    const line = d3.line().defined((d) => Number.isFinite(d[0]))(
      this._lineData
    );

    scene
      .append('path')
      .attr('d', line)
      .attr('stroke', 'rgba(52, 102, 138, 0.6)');

    const bottomLine = d3.line()([
      [-this.width / 2, 0],
      [this.width / 2, 0],
    ]);

    scene.append('path').attr('d', bottomLine).attr('stroke', '#e0e0e0');
  }

  private _initializeSceneText(
    scene: d3.Selection<SVGElement, unknown, null, undefined>
  ): void {
    const textContent = scene
      .append('g')
      .classed(`${this.chartClassName}__text`, true);

    const firstQuarterText = textContent
      .append('text')
      .attr('x', 88)
      .attr('y', -97)
      .classed(`${this.chartClassName}__text-group`, true);

    firstQuarterText
      .append('tspan')
      .text('1')
      .attr('dx', 10)
      .attr('dy', '1.25em')
      .attr('font-family', '"Roboto", sans-serif')
      .attr('font-weight', 500)
      .attr('font-size', '14px');
    firstQuarterText
      .append('tspan')
      .text('st')
      .attr('dx', 3)
      .attr('dy', -3)
      .attr('font-family', '"Roboto", sans-serif')
      .attr('font-size', '12px');
    firstQuarterText
      .append('tspan')
      .text('Quartile')
      .attr('x', 88)
      .attr('dy', '1.16em')
      .attr('font-family', '"Roboto", sans-serif')
      .attr('font-size', '12px');

    const forthQuarterText = textContent
      .append('text')
      .attr('x', -130)
      .attr('y', -97)
      .classed(`${this.chartClassName}__text-group`, true);

    forthQuarterText
      .append('tspan')
      .text('4')
      .attr('dx', 12)
      .attr('dy', '1.25em')
      .attr('font-family', '"Roboto", sans-serif')
      .attr('font-weight', 500)
      .attr('font-size', '14px');
    forthQuarterText
      .append('tspan')
      .text('th')
      .attr('dx', 3)
      .attr('dy', -3)
      .attr('font-family', '"Roboto", sans-serif')
      .attr('font-size', '12px');
    forthQuarterText
      .append('tspan')
      .text('Quartile')
      .attr('x', -130)
      .attr('dy', '1.16em')
      .attr('font-family', '"Roboto", sans-serif')
      .attr('font-size', '12px');
  }
  //#endregion

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @tuiPure
  private _getTrendMultiplier(trend: MetricsTrend): 1 | -1 {
    return trend === MetricsTrend.Positive ? 1 : -1;
  }
}
