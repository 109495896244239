<mat-form-field
  class="mat-form-field-no-validation"
  appearance="outline"
  color="accent"
  [class.limited-input__invalid]="invalid"
>
  <input
    type="text"
    digitOnly
    inputmode="numeric"
    [decimal]="decimalPlaces !== 0"
    [allowNegatives]="allowNegatives"
    autocomplete="off"
    matInput
    [pattern]="pattern"
    [step]="step"
    [min]="min"
    [max]="max"
    (input)="onInputChange()"
    [(ngModel)]="value"
    (keypress)="onKeyPress($event)"
    (blur)="onBlur(); inputBlur.emit($event)"
    (focus)="onTouch()"
    title=""
  />
  <span *ngIf="suffix" matSuffix>
    {{ suffix }}
  </span>
</mat-form-field>
