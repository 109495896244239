import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
  standalone: true,
})
export class EmptyPipe implements PipeTransform {
  transform(value: any, placeholder: string): any {
    return value != null ? value : placeholder;
  }
}
