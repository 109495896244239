import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';


/**
 * @deprecated
 * Use fnl-icon-button from FnlButtonsModule of fnl-ui instead
 */
@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatRippleModule,
    CommonModule
  ]
})
export class IconButtonComponent implements OnInit {
  @Input() disabled = false;

  @Input() isRippleSmall = false;
  @Input() rippleWidth = 34;
  @Input() rippleHeight = 34;

  ngOnInit() {
    if (this.isRippleSmall) {
      this.rippleWidth = 28;
      this.rippleHeight = 28;
    }
  }
}
