<button
  type="button"
  matRipple
  [matRippleUnbounded]="true"
  [matRippleCentered]="true"
  [matRippleDisabled]="disabled"
  [disabled]="disabled"
  color="primary"
  class="icon-button"
  [ngClass]="{ 'icon-button_disabled': disabled }"
  [class.small-indent]="isRippleSmall"
  tabindex="-1"
>
  <ng-content></ng-content>
  <div
    class="mat-ripple-element mat-ripple-element_hover"
    [ngStyle]="{ width: rippleWidth + 'px', height: rippleHeight + 'px' }"
  ></div>
</button>
