import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlInfoComponent } from './info.component';
import { FnlInfoButtonComponent } from './info-button/info-button.component';
import { FnlInfoPopoverComponent } from './info-popover/info-popover.component';
import { MatIconModule } from '@angular/material/icon';
import { FnlButtonModule } from '../button/button.module';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';


@NgModule({
  declarations: [
    FnlInfoComponent,
    FnlInfoButtonComponent,
    FnlInfoPopoverComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FnlButtonModule,
    MatLegacyTooltipModule
  ],
  exports: [
    FnlInfoComponent
  ],
})
export class FnlInfoModule {}
