import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type FooterPortalType = Portal<any>;

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _footerPortal = new BehaviorSubject<FooterPortalType>(null);

  getFooterPortal(): Observable<FooterPortalType> {
    return this._footerPortal.asObservable();
  }
  setFooterPortal(portal: FooterPortalType): void {
    this._footerPortal.next(portal);
  }
}
