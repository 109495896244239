import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-icon-text-button',
  templateUrl: './icon-text-button.component.html',
  styleUrls: ['./icon-text-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
  ]
})
export class IconTextButtonComponent {
  @Input() svgIcon?: string;
  @Input() icon?: string;
  @Input() color?: 'warn' | 'accent' | 'bright';
  @Input() iconPosition?: 'right';

  get classes(): { [key: string]: boolean } {
    return {
      [`icon-text-button_${this.color}`]: this.color != null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'icon-text-button_right': this.iconPosition === 'right',
    };
  }

  get isWarnColor(): boolean {
    return this.color === 'warn';
  }

  get isAccentColor(): boolean {
    return this.color === 'accent';
  }

  get isRightIcon(): boolean {
    return this.iconPosition === 'right';
  }
}
