import { ImageFolder } from '../image-options.model';

export const FNL_HEADER_OPPORTUNITIES: ImageFolder = {
  subFolder: '/header-opportunities',
  imagesList: [
    {
      name: 'analyze-industry',
      size: '0 0 164 66 ',
    },
    {
      name: 'check-ranking',
      size: '0 0 141 53',
    },
    {
      name: 'get-acquainted',
      size: '0 0 107 81',
    },
    {
      name: 'learn-financials',
      size: '0 0 79 79',
    },
    {
      name: 'pursuits',
      size: '0 0 120 58',
    },
    {
      name: 'analyze-trends',
      size: '0 0 159 81',
    },
  ],
};
