import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-help-content',
  templateUrl: './help-content.component.html',
  styleUrls: ['./help-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpContentComponent {
  isResponseSuccess = false;

  handleResponseSuccess(value?: boolean) {
    this.isResponseSuccess = value || false;
  }
}
