import { Pipe, PipeTransform } from '@angular/core';

import { Currency } from '@app/core/models/currency.model';

@Pipe({
  name: 'filterCurrencies',
  standalone: true,
})
export class FilterCurrenciesPipe implements PipeTransform {
  transform(currencies: Currency[], filter: string): Currency[] {
    const normalizedFilter = filter.trim().toLowerCase();

    if (!normalizedFilter) {
      return currencies;
    }

    return currencies.filter(currency => {
      const normalizedCode = currency.code.toLowerCase();
      const normalizedName = currency.name.toLowerCase();
      const isMatchToCode = normalizedCode.includes(normalizedFilter);
      const isMatchToDescription = normalizedName.includes(normalizedFilter);
      return isMatchToCode || isMatchToDescription;
    });
  }
}
