import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlTreeDiagramComponent } from './tree-diagram.component';
import { FnlInfoModule } from '../info/info.module';


@NgModule({
  declarations: [
    FnlTreeDiagramComponent,
  ],
  imports: [
    CommonModule,
    FnlInfoModule,
  ],
  exports: [
    FnlTreeDiagramComponent
  ]
})
export class FnlTreeDiagramModule {}
