import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
} from '@angular/core';

import { PopoverRef } from './popover-ref';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('popover', [
      state('void, exit', style({ opacity: 0 })),
      transition(
        '* => enter',
        animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1 }))
      ),
      transition(
        '* => void, * => exit',
        animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 }))
      ),
    ]),
  ],
})
export class PopoverComponent<T, D = any> implements OnInit {
  @HostBinding('class.popover') isPopover = true;
  @HostBinding('@popover') popoverAnimationState: 'void' | 'enter' | 'exit' =
    'enter';

  animationStateChanged = new EventEmitter<AnimationEvent>();
  content: ComponentType<T>;

  constructor(
    private _popoverRef: PopoverRef<T, D>,
    private _element: ElementRef<HTMLElement>
  ) {}

  @HostListener('document:mousedown', ['$event']) clickedOutside(event) {
    const isClickOutsidePopover = !this._element.nativeElement.contains(
      event.target
    );

    if (isClickOutsidePopover) {
      this.closePopover();
    }
  }
  @HostListener('document:keydown.escape') pressedEscape() {
    this.closePopover();
  }

  @HostListener('@popover.start', ['$event'])
  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  @HostListener('@popover.done', ['$event'])
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  ngOnInit() {
    this.content = this._popoverRef.content;
  }

  startExitAnimation(): void {
    this.popoverAnimationState = 'exit';
  }

  closePopover() {
    this._popoverRef.close();
  }
}
