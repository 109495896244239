import { ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { PopoverRef, PopoverService } from '../popover';
import { FnlInfoPopoverComponent } from './info-popover/info-popover.component';

@Component({
  selector: 'fnl-info',
  templateUrl: './info.component.html',
  styleUrls: [ './info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FnlInfoComponent implements OnDestroy {
  @Input() isColorLight = false;
  @Input() hasContent = false;
  @Input() width = 16;
  @Input() height = 16;
  @Input() align: 'left' | 'right' | 'auto' = 'auto';
  @ViewChild('placeholderContent') placeholderContentRef: TemplateRef<any>;
  @ViewChild('content') contentRef: TemplateRef<any>;

  private _popoverRef: PopoverRef<FnlInfoPopoverComponent>;

  constructor(private _popoverService: PopoverService) {}

  private get _leftPopoverPosition(): ConnectedPosition[] {
    return [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: this.height + 4,
        offsetX: -4,
        panelClass: 'popover__top-start',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -1 * (this.height + 4),
        offsetX: -4,
        panelClass: 'popover__bottom-start',
      },
    ];
  }

  private get _rightPopoverPosition(): ConnectedPosition[] {
    return [
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: this.height + 4,
        offsetX: 4,
        panelClass: 'popover__top-end',
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetY: -1 * (this.height + 4),
        offsetX: 4,
        panelClass: 'popover__bottom-end',
      },
    ];
  }

  ngOnDestroy(): void {
    if (this._popoverRef != null) {
      this._popoverRef.close();
    }
  }

  isLeftAligned(element: HTMLElement): boolean {
    if (this.align === 'auto') {
      return window.innerWidth - element.getBoundingClientRect().right > 420;
    } else {
      return this.align === 'left';
    }
  }

  openInfoPopover(element: HTMLElement) {
    const isLeftAligned = this.isLeftAligned(element);

    this._popoverRef = this._popoverService.open(
      FnlInfoPopoverComponent,
      element,
      {
        positions: isLeftAligned
          ? this._leftPopoverPosition
          : this._rightPopoverPosition,
        data: {
          template: this.hasContent
            ? this.contentRef
            : this.placeholderContentRef,
          isLeftAligned,
          width: this.width,
          height: this.height,
        },
      }
    );

    this._popoverRef.afterClosed$.subscribe(() => {
      this._popoverRef = null;
    });
  }
}
