export enum EnvironmentTypes {
  /** Default environment for local development */
  Default = 'default',
  Local = 'local',
  /** Development environment for dev stand */
  Dev = 'dev',
  Lab = 'lab',
  Uat = 'uat',
  /** Development environment for production */
  Prod = 'prod',
}
