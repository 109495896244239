import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from '@app/core/models/common.model';
import { ApiService } from '@app/core/services/api.service';
import { isNil, omitBy } from 'lodash';
import { Observable } from 'rxjs';

export interface KpiApi {
  uid: UUID;
  name: string;
  description: string;
  industryUids: UUID[];
}

interface PresentationKpisApi {
  items: PresentationKpiGroupApi[];
}

export interface PresentationKpiGroupApi {
  uid: UUID;
  absoluteValueDisplayText: string;
  relativeValueDisplayText: string;
  kpis: PresentationKpiApi[];
}

export interface PresentationKpiApi {
  uid: UUID;
  childItems: PresentationKpiApi[];
  name: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientKpisApiService {

  private readonly blockCid = 'ClientKpisApiService';

  constructor(
    private _apiService: ApiService
  ) {
  }

  read(
    clientUid: UUID,
    uid: UUID,
    blockCid: string = this.blockCid
  ): Observable<KpiApi> {
    return this._apiService.get<KpiApi>(
      `fishbone/clients/${ clientUid }/kpis/${ uid }`,
      blockCid,
      -1
    );
  }

  listPresentationKpis(
    clientUid: UUID,
    queryParams,
    blockCid?: string
  ): Observable<PresentationKpisApi> {
    return this._apiService.get<PresentationKpisApi>(
      `fishbone/clients/${ clientUid }/kpis/presentation`,
      {
        params: new HttpParams({
          fromObject: omitBy(queryParams, isNil),
        }),
      },
      blockCid || this.blockCid,
      -1
    );
  }
}
