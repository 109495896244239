import { Injectable } from '@angular/core';
import { HistoryItem } from '@app/core/models/history.model';
import { cache } from '@app/core/operators';
import { ApiService } from '@app/core/services/api.service';
import { UserService } from '@app/core/services/user.service';
import { isNil, omitBy, sortBy } from 'lodash';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  static readonly blockCid = 'HistoryService';

  private readonly favoritesUrl = 'industryresearch/FavoriteSearch';
  private readonly recentlyUrl = 'industryresearch/RecentView';
  private favoritesUpdate = new BehaviorSubject<void>(null);
  private favoritesUpdate$ = this.favoritesUpdate.asObservable();
  private favorites$: Observable<HistoryItem[]>;
  private recentlyUpdate = new BehaviorSubject<void>(null);
  private recentlyUpdate$ = this.recentlyUpdate.asObservable();
  private recently$: Observable<HistoryItem[]>;

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    this.userService.getActiveStatus().subscribe((isActive: boolean) => {
      if (!isActive) {
        this.favorites$ = null;
        this.recently$ = null;
      }
    });
  }

  getFavorites(blockCid?: string): Observable<HistoryItem[]> {
    if (!this.favorites$) {
      this.favorites$ = this.favoritesUpdate$.pipe(
        switchMap(() =>
          this.apiService
            .get<HistoryItem[]>(
              this.favoritesUrl,
              blockCid || HistoryService.blockCid
            )
            .pipe(catchError(() => of([]) as Observable<HistoryItem[]>))
        ),
        map((items) =>
          sortBy(items, (item) => {
            const industry = item.subIndustry || item.industry;
            const industryName = industry && industry.name;
            const sic = item.standardIndustrialClassification;
            const sicName = sic && `${sic.name} (SIC: ${sic.code})`;
            const companyName = item.company && item.company.name;
            const regionName = item.region && item.region.name;
            return companyName
              ? companyName
              : `${industryName || sicName}: ${regionName}`;
          })
        ),
        cache()
      );
    }

    return this.favorites$;
  }

  async addFavoriteIndustry(
    industryUid: string,
    subIndustryUid: string,
    sicUid: string,
    regionUid: string
  ): Promise<void> {
    await this.addFavorite(
      null,
      false,
      industryUid,
      subIndustryUid,
      sicUid,
      regionUid
    );
  }

  async addFavoriteCompany(
    companyUid: string,
    isPrivateCompany: boolean
  ): Promise<void> {
    await this.addFavorite(companyUid, isPrivateCompany);
  }

  async addFavorite(
    companyUid?: string,
    isPrivateCompany?: boolean,
    industryUid?: string,
    subIndustryUid?: string,
    sicUid?: string,
    regionUid?: string
  ): Promise<void> {
    const requestBody = omitBy(
      {
        industryUid,
        subIndustryUid,
        standardIndustrialClassificationUid: sicUid,
        regionUid,
        companyUid,
        isPrivateCompany,
      },
      isNil
    );
    await firstValueFrom(this.apiService.post(this.favoritesUrl, requestBody));

    this.forceUpdateFavorites();
  }

  async removeFavorite(favoriteUid: string): Promise<void> {
    await firstValueFrom(
      this.apiService.delete(`${this.favoritesUrl}/${favoriteUid}`)
    );

    this.forceUpdateFavorites();
  }

  forceUpdateFavorites() {
    this.favoritesUpdate.next();
  }

  getRecently(blockCid?: string): Observable<HistoryItem[]> {
    if (!this.recently$) {
      this.recently$ = this.recentlyUpdate$.pipe(
        switchMap(() =>
          this.apiService
            .get<HistoryItem[]>(
              this.recentlyUrl,
              blockCid || HistoryService.blockCid
            )
            .pipe(catchError(() => of([]) as Observable<HistoryItem[]>))
        ),
        // get only the 10 latest elements for recently
        map((items) => items.slice(0, 10)),
        cache()
      );
    }

    return this.recently$;
  }

  async addRecentlyIndustry(
    industryUid: string,
    subIndustryUid: string,
    sicUid: string,
    regionUid: string
  ): Promise<void> {
    await this.addRecently(
      null,
      false,
      industryUid,
      subIndustryUid,
      sicUid,
      regionUid
    );
  }

  async addRecentlyCompany(
    companyUid: string,
    isPrivateCompany: boolean
  ): Promise<void> {
    await this.addRecently(companyUid, isPrivateCompany);
  }

  async addRecently(
    companyUid?: string,
    isPrivateCompany?: boolean,
    industryUid?: string,
    subIndustryUid?: string,
    sicUid?: string,
    regionUid?: string
  ): Promise<void> {
    const requestBody = omitBy(
      {
        industryUid,
        subIndustryUid,
        standardIndustrialClassificationUid: sicUid,
        regionUid,
        companyUid,
        isPrivateCompany,
      },
      isNil
    );
    await firstValueFrom(
      this.apiService.post<HistoryItem[]>(this.recentlyUrl, requestBody)
    );

    this.forceUpdateRecently();
  }

  forceUpdateRecently() {
    this.recentlyUpdate.next();
  }
}
