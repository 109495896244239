<div
  class="info-popover"
  [class.info-popover_left]="isLeftAligned"
  [class.info-popover_right]="!isLeftAligned"
>
  <div class="info-popover__close">
    <fnl-info-button
      class="info-popover__handle"
      (click)="closePopover()"
      [width]="width"
      [height]="height"
    ></fnl-info-button>
  </div>
  <div class="info-popover__content">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</div>
