import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  OnDestroy,
  EventEmitter,
  Output,
  ElementRef,
} from '@angular/core';
import { PopoverRef, PopoverService } from '../popover';
import { PriorityInfoButtonComponent } from './priority-info-button/priority-info-button.component';
import { PriorityInfoPopoverComponent } from './priority-info-popover/priority-info-popover.component';

@Component({
  selector: 'app-priority-info',
  templateUrl: './priority-info.component.html',
  styleUrls: ['./priority-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityInfoComponent implements OnDestroy {
  @ViewChild('button') buttonRef: PriorityInfoButtonComponent;

  private _popoverRef: PopoverRef<PriorityInfoPopoverComponent>;

  constructor(
    private _elRef: ElementRef,
    private _popoverService: PopoverService
  ) {}

  private get _popoverPositions(): ConnectedPosition[] {
    const height =
      this.buttonRef.element.nativeElement.getBoundingClientRect().height;
    return [
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: height - 16,
        panelClass: 'priority-info-popover__top-end',
      },
    ];
  }

  ngOnDestroy(): void {
    if (this._popoverRef != null) {
      this._popoverRef.close();
      this._popoverRef = null;
    }
  }

  openPopover(): void {
    if (this._popoverRef != null) {
      return;
    }

    this._popoverRef = this._popoverService.open(
      PriorityInfoPopoverComponent,
      this._elRef.nativeElement,
      {
        positions: this._popoverPositions,
      }
    );
    this._popoverRef.afterClosed$.subscribe(() => {
      this._popoverRef = null;
    });
  }
}
