import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { CookieServiceSetOptions } from '../interfaces/cookie-service.interface';
import { CookieStorage } from '../interfaces/cookie-storage.interface';

@Injectable()
export class BaseCookieStorage implements CookieStorage {
  constructor(private _cookieService: CookieService) {}

  get length(): number {
    return Object.keys(this._cookieService.getAll()).length;
  }

  clear(): void {
    this._cookieService.deleteAll();
  }

  getItem(key: string): string | null {
    const item = this._cookieService.get(key);

    return item != null ? item : null;
  }

  key(index: number): string | null {
    const keys = Object.keys(this._cookieService.getAll());

    return index >= 0 && index < keys.length ? keys[index] : null;
  }

  removeItem(key: string): void {
    this._cookieService.delete(key);
  }

  setItem(
    key: string,
    data: string,
    options: Partial<CookieServiceSetOptions> = { path: '/' }
  ): void {
    this._cookieService.set(key, data, options);
  }
}
