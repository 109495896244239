import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { FnlButtonModule } from 'fnl-ui';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: [ './filter-input.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FilterInputComponent),
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    FnlButtonModule,
  ]
})
export class FilterInputComponent implements ControlValueAccessor {
  @Input() value = '';
  @Input() placeholder = '';
  @Input() paddingLeft = '12px';
  @Input() paddingRight = '12px';
  @Output() filter = new EventEmitter<string>();

  get isFilled(): boolean {
    return !!this.trimmedValue;
  }

  get trimmedValue(): string | null {
    return this.value?.trim() || null;
  }

  onBlur() {
    if (this.value) {
      this.value = this.value.trim();
    }
    this.onTouched();
  }

  onFilter() {
    this.onChange(this.trimmedValue)
    this.filter.emit(this.trimmedValue);
  }

  onClear() {
    this.value = null;
    this.onChange(null)
    this.filter.emit(null);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: string) => void = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => {
  };
}
