import { Injectable } from '@angular/core';
import { CurrencyApiService, RateTypeApi } from '@app/api/common/currency/currency-api.service';
import { map, Observable } from 'rxjs';
import { UUID } from '@app/core/models/common.model';

export enum RateType {
  Current = 'Current',
  LastTwelveMonth = 'LastTwelveMonth',
}

interface ExchangeRateItem {
  toCurrencyUid: UUID;
  rate: number;
}

export interface ExchangeRatesResponse {
  fromCurrencyUid: UUID;
  rateType: RateType;
  items: ExchangeRateItem[];
}

@Injectable({
  providedIn: 'root'
})
export class CurrencyRepository {
  private readonly blockCid = 'CurrencyRepository';

  constructor(
    private currencyApiService: CurrencyApiService,
  ) { }

  getExchangeRates(
    sourceCurrencyUid: UUID,
    rateType: RateType = RateType.LastTwelveMonth,
    blockCid?: string
  ): Observable<ExchangeRatesResponse> {
    return this.currencyApiService.getExchangeRates(
      sourceCurrencyUid,
      convertRateTypeToApi(rateType),
      blockCid,
    )
      .pipe(
        map(rates => {
          return {
            fromCurrencyUid: rates.fromCurrencyUid,
            rateType: convertRateTypeFromApi(rates.rateType),
            items: rates.items,
          };
        }),
      );
  }
}

function convertRateTypeToApi(rateType: RateType): RateTypeApi {
  return RateTypeApi[rateType];
}

function convertRateTypeFromApi(rateType: RateTypeApi): RateType {
  return RateType[rateType];
}
