import { Directive, Input } from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';

/**
 * @deprecated
 * Use {@link FnlSelectGroupOptionDirective} instead.
 */
@Directive({
  selector: 'mat-option[fnlLegacySelectGroupOption]'
})
export class FnlLegacySelectGroupOptionDirective {

  /**
   * List of values to be selected by this option.
   */
  @Input() valuesToSelect: unknown[];

  constructor(
    public host: MatOption,
  ) {}
}
