import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Currency } from '@app/core/models/currency.model';
import { CurrencyService } from '@app/core/services/currency.service';
import { FinAbstractControlComponent } from '@app/core/utils/abstract-control.component';
import { PopoverRef, PopoverService } from '@app/shared/popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tuiDefaultProp } from '@taiga-ui/cdk';

import { CurrencyButtonComponent } from './currency-button/currency-button.component';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { CurrencyPopoverComponent } from './currency-popover/currency-popover.component';
import { CommonModule } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CurrencyComponent),
    },
  ],
  imports: [
    CommonModule,
    CurrencyButtonComponent,
    CurrencyInputComponent
  ]
})
export class CurrencyComponent
  extends FinAbstractControlComponent<Currency>
  implements OnInit, OnDestroy
{
  @Input() currency: Currency;
  @Input()
  @tuiDefaultProp()
  inputLike: 'none' | 'base' | 'form' = 'none';
  @Output() update = new EventEmitter<Currency>();
  @Input()
  @tuiDefaultProp()
  placeholder = '';

  @ViewChild('button') buttonRef:
    | CurrencyInputComponent
    | CurrencyButtonComponent;

  allCurrencies: Currency[];
  disabled = false;

  private _popoverRef: PopoverRef<CurrencyPopoverComponent>;

  constructor(
    private _elRef: ElementRef,
    private _popoverService: PopoverService,
    private _currencyService: CurrencyService,
    injector: Injector,
    cdr: ChangeDetectorRef
  ) {
    super(injector, cdr);
  }

  get isAllDataReceived(): boolean {
    return !!(this.displayCurrency?.name && this.allCurrencies);
  }

  get displayCurrency(): Currency {
    if (!this.currency) {
      return;
    }
    return this.currency.name
      ? this.currency
      : this.allCurrencies?.find(
          (currency) => currency.uid === this.currency.uid
        );
  }

  private get _popoverPositions(): ConnectedPosition[] {
    const height =
      this.buttonRef.element.nativeElement.getBoundingClientRect().height;
    return [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: height,
        panelClass: 'currency-popover__top-start',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -height,
        panelClass: 'currency-popover__bottom-start',
      },
    ];
  }

  ngOnInit() {
    super.ngOnInit();

    this._currencyService
      .getAllCurrencies()
      .pipe(untilDestroyed(this))
      .subscribe((allCurrencies) => {
        this.allCurrencies = allCurrencies;

        // TODO: remove when the selected currency will be passed as prop everywhere
        if (!this.currency) {
          this.currency = this.allCurrencies.find(
            (currency) => currency.code === 'USD'
          );
          this._onChange(this.currency);
        }

        this._cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this._popoverRef?.close();
  }

  openCurrencyPopover() {
    if (this._popoverRef != null) {
      return;
    }
    this._popoverRef = this._popoverService.open(
      CurrencyPopoverComponent,
      this._elRef.nativeElement,
      {
        positions: this._popoverPositions,
        data: {
          allCurrencies: this.allCurrencies,
          currency: this.displayCurrency,
          inputLike: this.inputLike,
          placeholder: this.placeholder,
          width: this._elRef.nativeElement.getBoundingClientRect().width,
        },
      }
    );
    this._popoverRef.afterClosed$.subscribe((currency?: Currency) => {
      this._popoverRef = null;
      if (currency) {
        this.currency = currency;
        this.update.emit(currency);
        this._onChange(this.currency);
        this._cdr.detectChanges();
      }

      this.onTouch();
    });
  }

  writeValue(selected: Currency): void {
    this.currency = selected;
    this._cdr.markForCheck();
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onTouch(): void {
    if (!this._touched || !this._formControl.touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
