import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY_UID } from '@app/core/constants';
import { BaseGroupItem, BaseItem, UUID } from '@app/core/models/common.model';
import { Company, CompanyProfile } from '@app/core/models/company.model';
import { Currency } from '@app/core/models/currency.model';
import {
  FullIndustryDataSet,
  Industry,
  IndustryCode,
} from '@app/core/models/industry.model';
import { Peer, PeerData } from '@app/core/models/peer.model';
import { User } from '@app/core/models/user.model';
import {
  ActivityTrackerService,
  TrackingCategory,
  TrackingSubCategory,
} from '@app/api/tracking/activity-tracker.service';
import { ApiService, BaseCreateResponse } from '@app/core/services/api.service';
import { BusinessFunctionService } from '@app/core/services/business-function.service';
import { CompanyService } from '@app/core/services/company.service';
import { CurrencyService } from '@app/core/services/currency.service';
import { IndustryService } from '@app/core/services/industry.service';
import { NotificationsService } from '@app/core/services/notifications.service';
import { PeerService } from '@app/core/services/peer.service';
import { SolutionsService } from '@app/core/services/solutions.service';
import { UserSettingsService } from '@app/core/services/user-settings.service';
import { UserService } from '@app/core/services/user.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary, isNil, omitBy } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { PrivateCompanyAmplitudeService } from './private-company-amplitude.service';

import { PrivateCompanyDataService } from './private-company-data.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PrivateCompanyService implements OnDestroy {
  static readonly blockCid = 'PrivateCompany';
  static readonly filtersCid = `${ PrivateCompanyService.blockCid }_Filters`;
  static readonly peersCid = `${ PrivateCompanyService.blockCid }_Peers`;

  private _businessFunctions$: Observable<BaseItem[]>;
  private _recommendedPeers$: Observable<Peer[]>;

  private _currencyFilter = new BehaviorSubject<Currency>(null);
  private _targetRevenueFilter = new BehaviorSubject<number>(null);
  private _functionFilter = new BehaviorSubject<string>(null);
  private _peerFilter = new BehaviorSubject<Peer[]>(null);
  private _selectedSolution = new BehaviorSubject<string[]>([]);

  private _moduleFields: Dictionary<unknown> = {};
  private _prevSavedPeers: string[] = null;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _userService: UserService,
    private _userSettingsService: UserSettingsService,
    private _currencyService: CurrencyService,
    private _businessFunctionService: BusinessFunctionService,
    private _solutionsService: SolutionsService,
    private _notificationsService: NotificationsService,
    private _activityTrackerService: ActivityTrackerService,
    private _industryService: IndustryService,
    private _companyService: CompanyService,
    private _peerService: PeerService,
    private _privateCompanyDataService: PrivateCompanyDataService,
    private _privateCompanyAmplitudeService: PrivateCompanyAmplitudeService
  ) {
  }

  ngOnDestroy(): void {
    this._businessFunctions$ = null;

    this.resetGlobalFilters();
  }

  saveModuleField(key: string, value: any): void {
    this._moduleFields[key] = value;
  }

  getModuleFields() {
    return this._moduleFields;
  }

  clearModuleFields(): void {
    this._moduleFields = {};
  }

  //#region Filters data
  getBusinessFunctions(): Observable<BaseItem[]> {
    if (this._businessFunctions$ == null) {
      this._businessFunctions$ = this._privateCompanyDataService
        .getIndustry()
        .pipe(
          distinctUntilChanged((a, b) => a?.uid === b?.uid),
          switchMap((industry: Industry) =>
            this._businessFunctionService.getIndustryBusinessFunctions(
              industry.uid,
              true,
              PrivateCompanyService.filtersCid
            )
          ),
          tap((items) => {
            const selectedFunction = this._functionFilter.getValue();

            if (
              selectedFunction != null &&
              items.find((item) => item.uid === selectedFunction) == null
            ) {
              this._functionFilter.next(null);
            }
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
          untilDestroyed(this, 'ngOnDestroy')
        );
    }

    return this._businessFunctions$;
  }

  getRecommendedPeers(): Observable<Peer[]> {
    if (this._recommendedPeers$ == null) {
      this._recommendedPeers$ = combineLatest([
        this._privateCompanyDataService.getNotEmptyCompany(),
        this._privateCompanyDataService.getRevenue(),
      ]).pipe(
        debounceTime(50),
        filter(([ _, revenue ]) => revenue != null),
        distinctUntilChanged(
          (a, b) => a[0].uid === b[0].uid && a[1].uid === b[1].uid
        ),
        switchMap(([ selectedCompany, revenue ]) =>
          this._peerService
            .getCompanyPeers(
              selectedCompany.uid,
              false,
              revenue?.uid,
              PrivateCompanyService.peersCid
            )
            .pipe(
              map((data: PeerData) => {
                if (data.savedPeers?.length > 0) {
                  const saved = data.savedPeers.map((peer: Peer) => peer.uid);
                  const defaultRecommenced = data.recommendedPeers?.slice(0, 4);
                  if (
                    !defaultRecommenced.every((peer: Peer) =>
                      saved.includes(peer.uid)
                    ) ||
                    defaultRecommenced.length !== saved.length
                  ) {
                    this._peerFilter.next(data.savedPeers);
                  }
                  this._prevSavedPeers = saved;
                }

                return data.recommendedPeers;
              })
            )
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
        untilDestroyed(this, 'ngOnDestroy')
      );
    }

    return this._recommendedPeers$;
  }

  getSolutionOptions(businessFunctionObservable: Observable<UUID>): Observable<BaseGroupItem[]> {
    return combineLatest([
      this._privateCompanyDataService.getIndustry(),
      this._privateCompanyDataService.getSubIndustry(),
      this._userService.getCurrentUser(),
      businessFunctionObservable,
    ]).pipe(
      debounceTime(50),
      distinctUntilChanged(
        (a, b) =>
          a[0].uid === b[0]?.uid &&
          a[1]?.uid === b[1]?.uid &&
          a[2].clientUid === b[2].clientUid &&
          a[3] === b[3]
      ),
      switchMap(([ industry, subIndustry, user, businessFunctionUid ]) => {
        return this._solutionsService
          .getClientSolutionsByIndustries(
            user.clientUid,
            [ industry.uid ],
            [ subIndustry?.uid ],
            businessFunctionUid,
            PrivateCompanyService.filtersCid
          )
      }),
    );
  }

  getSolutionOptionsWithEmptyOption(businessFunctionObservable: Observable<UUID>): Observable<BaseGroupItem[]> {
    return combineLatest([
      this.getSolutionOptions(businessFunctionObservable),
      this._userSettingsService.getSolutionTerminology()
    ])
    .pipe(
      map(([solutionList, solutionTerm]) => {
        const allSolution = [
          {
            uid: EMPTY_UID,
            name: `No ${ solutionTerm }`,
          },
        ];

        return solutionList[0]?.uid === EMPTY_UID
        ? [
          {
            ...solutionList[0],
            items: allSolution.concat(solutionList[0].items),
          },
        ].concat(solutionList.slice(1))
        : [
          {
            uid: EMPTY_UID,
            name: '',
            items: allSolution,
          },
        ].concat(solutionList)
      })
    )
  }

  getSolutions(businessFunctionObservable: Observable<UUID>): Observable<BaseGroupItem[]> {
    return this.updateSelectedSolutions(
      this.getSolutionOptionsWithEmptyOption(businessFunctionObservable)
    )
  }

  getSolutionsOptionsAndUpdateSelections(businessFunctionObservable: Observable<UUID>): Observable<BaseGroupItem[]> {
    return this.updateSelectedSolutions(
      this.getSolutionOptions(businessFunctionObservable)
    )
  }

  updateSelectedSolutions(solutionList: Observable<BaseGroupItem[]>): Observable<BaseGroupItem[]> {
    return solutionList
    .pipe(
      tap((solutions) => {
        const selectedSolution = this._selectedSolution.getValue();
        const flatSolutionList = solutions
        .reduce(
          (result: BaseItem[], item: BaseGroupItem) =>
            result.concat(item.items),
          []
        )
        .map(solution => solution.uid);
        const filteredList = selectedSolution?.filter(solutionUid => flatSolutionList?.includes(solutionUid))

        this._selectedSolution.next(filteredList);
      })
    );
  }

  getCurrencyFilterRaw(): Observable<Currency> {
    return this._currencyFilter.asObservable();
  }

  getCurrencyFilter(): Observable<Currency> {
    return this.getCurrencyFilterRaw().pipe(
      switchMap((currency) =>
        currency ? of(currency) : this.getDefaultCurrency()
      )
    );
  }

  async setCurrencyFilter(currency: Currency) {
    const defaultCurrency = await firstValueFrom(this.getDefaultCurrency());

    this._currencyFilter.next(
      currency?.uid !== defaultCurrency?.uid ? currency : null
    );
  }

  getTargetRevenueFilterRaw(): Observable<number> {
    return this._targetRevenueFilter.asObservable();
  }

  getTargetRevenueFilter(): Observable<number> {
    return this.getTargetRevenueFilterRaw().pipe(
      switchMap((revenue) =>
        revenue ? of(revenue) : this.getDefaultPrivateRevenue()
      )
    );
  }

  async setTargetRevenueFilter(
    estimatedRevenue: number,
    force = false,
    skipSave = false
  ): Promise<void> {
    const defaultRevenue = await firstValueFrom(
      this.getDefaultPrivateRevenue()
    );
    const getValue =
      estimatedRevenue !== defaultRevenue
        ? estimatedRevenue || defaultRevenue
        : null;
    if (getValue !== this._targetRevenueFilter.getValue() || force) {
      this._targetRevenueFilter.next(
        getValue === defaultRevenue ? null : getValue
      );

      const clearing = !!this._targetRevenueFilter.getValue();

      this._privateCompanyAmplitudeService.triggerAmplitude(
        clearing
          ? 'Estimated Revenue change'
          : 'Estimated Revenue - Reset to Default Click',
        {
          value: getValue,
        }
      );

      if (!skipSave) {
        await firstValueFrom(
          combineLatest([
            this._privateCompanyDataService.getNotEmptyCompany(),
            this._userService.getCurrentUser(),
          ]).pipe(
            switchMap(([ company, user ]: [ Company, User ]) =>
              this._apiService
                .put<BaseCreateResponse>(
                  `company/users/${ user.uid }/private-company/${ company.uid }/estimated-revenue`,
                  { estimatedRevenue: getValue || defaultRevenue }
                )
                .pipe(catchError(() => of(null)))
            )
          )
        );
      }
    }
  }

  getDefaultPrivateRevenue(): Observable<number> {
    return this._privateCompanyDataService
      .getNotEmptyCompany()
      .pipe(map((company) => company.revenue));
  }

  getCanRevenueClear(): Observable<boolean> {
    return combineLatest([
      this.getTargetRevenueFilter(),
      this.getDefaultPrivateRevenue(),
    ]).pipe(
      map(
        ([ revenue, defaultRevenue ]) =>
          revenue && defaultRevenue && revenue !== defaultRevenue
      )
    );
  }

  getPrivateCompanyProfile(): Observable<CompanyProfile> {
    return combineLatest([
      this._privateCompanyDataService.getNotEmptyCompany(),
      this._userService.getCurrentUser(),
    ]).pipe(
      switchMap(([ company, user ]: [ Company, User ]) =>
        this._companyService.getPrivateCompanyProfile(
          company.uid,
          user.uid,
          PrivateCompanyService.blockCid
        )
      )
    );
  }

  getFunctionFilterRaw(): Observable<string> {
    return this._functionFilter.asObservable();
  }

  getFunctionFilter(): Observable<string> {
    return this.getFunctionFilterRaw().pipe(
      map((businessFunction) =>
        businessFunction ? businessFunction : EMPTY_UID
      )
    );
  }

  async setFunctionFilter(businessFunction: string): Promise<void> {
    this._functionFilter.next(
      businessFunction !== EMPTY_UID ? businessFunction : null
    );

    const bFName = await firstValueFrom(
      this.getBusinessFunctions().pipe(
        map(
          (items) => items.find((item) => item.uid === businessFunction)?.name
        )
      )
    );

    this._privateCompanyAmplitudeService.triggerAmplitude(
      'Business Function filter change',
      { value: bFName }
    );
  }

  getPeerFilterRaw(): Observable<Peer[]> {
    return this._peerFilter.asObservable();
  }

  getPeerFilter(): Observable<Peer[]> {
    return this.getPeerFilterRaw().pipe(
      switchMap((peers) =>
        peers != null
          ? of(peers)
          : this.getRecommendedPeers().pipe(
            map((data: Peer[]) => data?.slice(0, 4))
          )
      )
    );
  }

  async setPeerFilter(peers?: Peer[]): Promise<void> {
    let defaultPeers = await firstValueFrom(this.getRecommendedPeers());
    defaultPeers = defaultPeers?.slice(0, 4);

    const defaultPeersObject = defaultPeers.reduce((result, peer) => {
      result[peer.uid] = true;
      return result;
    }, {});

    const isDefaultSelected =
      defaultPeers.length === peers?.length &&
      peers?.every((peer) => !!defaultPeersObject[peer.uid]);
    this._peerFilter.next(!isDefaultSelected ? peers : null);

    const company = await firstValueFrom(
      this._privateCompanyDataService.getNotEmptyCompany()
    );

    this._privateCompanyAmplitudeService.triggerAmplitude(
      peers?.length > 0
        ? 'Peer filter change'
        : 'Peer filter - Reset to Default Click',
      {
        values: (peers?.length > 0 ? peers : defaultPeers).map(
          (value) => value.name
        ),
      }
    );

    const newSavedPeers =
      peers != null ? peers.map((peer: Peer) => peer.uid) : [];
    if (
      this._prevSavedPeers == null ||
      !newSavedPeers.every((item) => this._prevSavedPeers.includes(item)) ||
      newSavedPeers.length !== this._prevSavedPeers?.length
    ) {
      this._peerService.savePeersForCompany(company.uid, newSavedPeers, false);
      this._prevSavedPeers = newSavedPeers;
    }
  }

  getSelectedSolutionRaw(): Observable<string> {
    return this._selectedSolution.asObservable()
    .pipe(
      map((data) => (data?.length > 0 ? data[0] : null)),
    );
  }
  
  getSelectedSolutionsRaw(): Observable<string[]> {
    return this._selectedSolution.asObservable();
  }

  getSelectedSolution(): Observable<string> {
    return this._selectedSolution
    .pipe(
      map((data) => (data?.length > 0 ? data[0] : EMPTY_UID))
    );
  }

  getSelectedSolutions(): Observable<string[]> {
    return this._selectedSolution;
  }

  async setSolution(value: string, pageParameter: string): Promise<void> {
    this._selectedSolution.next(value !== EMPTY_UID ? [value] : []);

      if (value !== EMPTY_UID) {
        const company = await firstValueFrom(
          this._privateCompanyDataService.getCompany()
        );

        this._activityTrackerService.registerClick({
          category: TrackingCategory.Solution,
          subCategory: TrackingSubCategory.PrivateCompany,
          parameter1: value,
          parameter2: company.uid,
          parameter3: pageParameter,
        });
      }

      this._privateCompanyAmplitudeService.triggerAmplitude(
        'Solution filter change',
        { value: value || 'All Solutions' }
      );
  }

  async setSolutions(value: string[], pageParameter: string): Promise<void> {
    this._selectedSolution.next(value);
  }

  resetGlobalFilters(saveReset = false) {
    this._currencyFilter.next(null);
    this._functionFilter.next(null);
    this._selectedSolution.next([]);
    if (saveReset) {
      this.setTargetRevenueFilter(null);
      this.setPeerFilter(null);
    } else {
      this._targetRevenueFilter.next(null);
      this._peerFilter.next(null);
    }
  }

  //#endregion

  //#region Pursuit and Value modeler
  async createPursuit(blockCid?: string): Promise<void> {
    const [ company, customizedIndustryData ] = await firstValueFrom(
      combineLatest([
        this._privateCompanyDataService.getNotEmptyCompany(),
        this._privateCompanyDataService.getBasedIndustryDataRaw(),
      ])
    );
    const result: BaseCreateResponse = await firstValueFrom(
      this._apiService
        .post<BaseCreateResponse>(
          'fishbone/myPursuit/private-company-pursuit',
          omitBy(
            {
              privateCompanyUid: company.uid,
              industryRevenueGroupUid: customizedIndustryData?.revenue.uid,
              pursuit: {},
            },
            isNil
          ),
          blockCid || PrivateCompanyService.blockCid
        )
        .pipe(
          tap(() =>
            this._notificationsService.removeNotification('create-pursuit')
          ),
          catchError(() => of(null))
        )
    );

    if (result != null) {
      this._activityTrackerService.registerCreate({
        category: TrackingCategory.Pursuit,
        subCategory: TrackingSubCategory.PrivateCompany,
        parameter1: result.entityUid,
        parameter2: company.uid,
      });
      await this._router.navigate([ 'industry-pursuit', result.entityUid ]);
    } else {
      this._notificationsService.removeNotification(
        NotificationsService.generalError
      );
      this._notificationsService.addError({
        id: 'create-pursuit',
        message:
        // eslint-disable-next-line @typescript-eslint/quotes
          "Sorry, the pursuit can't be created for some reason. Please try again later.",
        autoRemovable: true,
      });
    }
  }

  //#endregion

  downloadSolutionAttachment(solutionLink: string, newTab = false): void {
    this._apiService.downloadByLink(
      `${ environment.apiGateway }${ solutionLink }` || 'not-found',
      'solution-attachment',
      newTab
    );
  }

  private getDefaultCurrency(): Observable<Currency> {
    return combineLatest([
      this._privateCompanyDataService.getNotEmptyCompany(),
      this._currencyService.getAllCurrencies(),
      this._currencyService.getDefaultCurrency(),
    ]).pipe(
      map(([ company, currencies, defaultCurrency ]) => {
        const currencyId = company.currencyUid;
        const companyCurrency = currencies.find(
          (currency) => currency.uid === currencyId
        );
        return companyCurrency || defaultCurrency;
      })
    );
  }
}
