import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {User, UserRole} from '@app/core/models/user.model';
import {UserService} from '@app/core/services/user.service';

import {AuthGuard} from './auth.guard';

/**
 * @abstract
 */
export class AnyRoleGuard  {
  constructor(
    private _router: Router,
    private _authGuard: AuthGuard,
    private _userService: UserService,
    private _expectedRoles: UserRole[]
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._authGuard.canActivate(route, state)
      .pipe(
        switchMap((isActive: boolean) => isActive ? this._currentUserHasAnyExpectedRole() : of(false))
      );
  }

  private _currentUserHasAnyExpectedRole(): Observable<boolean | UrlTree> {
    return this._userService.getCurrentUser().pipe(
      map((user: User) => this._haveIntersection(user.roleNames, this._expectedRoles) || this._router.createUrlTree(['not-found']))
    );
  }

  private _haveIntersection(actual: UserRole[], expected: UserRole[]): boolean {
    return actual.filter(it => expected.includes(it)).length > 0
  }

}
