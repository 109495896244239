import { Dictionary } from 'lodash';
import { BaseItem } from './common.model';

export enum MetricChartRowType {
  Company,
  Quartile,
  Peer,
  Median,
}

export class MetricChartRow {
  metricName: string;
  metricType: MetricChartRowType;
  metricColor?: string;
  values: Dictionary<MetricChartItem>;
}

export class MetricChartItem {
  value: number;
  isMin?: boolean;
  isMax?: boolean;
  tooltip?: string;
}

export enum MetricChartView {
  Years,
  TwelveMonth,
}

export function asMetricChartView(value: string): MetricChartView {
  if (value === '1') {
    return MetricChartView.TwelveMonth;
  }
  return MetricChartView.Years;
}

export const CHART_VIEWS_LIST: BaseItem[] = [
  {uid: MetricChartView.Years.toString(), name: 'Annual Data'},
  {uid: MetricChartView.TwelveMonth.toString(), name: 'Latest 12 Months'},
];

export class MetricChartColumn {
  uid: string;
  year: number | string;
  month?: string;
  name?: string;
  tooltip?: string;
}
