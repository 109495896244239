import { Injectable } from '@angular/core';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '../../core/services/api.service';
import { UserService } from '../../core/services/user.service';

export enum TrackingEvent {
  Click = 0,
  Page = 1,
  Create = 2,
  Player = 3,
  Complete = 4,
}

export enum TrackingCategory {
  Company = 0,
  Industry = 1,
  Report = 2,
  Presentation = 3,
  Pursuit = 4,
  ValueModeler = 5,
  PageRedirect = 6,
  Canvas = 7,
  PrivateCompany = 8,
  FinancialAnalysis = 9,
  PowerOfOne = 10,
  Solution = 11,
  TrainingVideo = 12,
  UserJourney = 13,
  Prospecting = 14
}

export enum TrackingSubCategory {
  Company = 0,
  Industry = 1,
  PrivateCompany = 2,
  PercentWatched = 3,
}

export interface ActivityEvent {
  type?: TrackingEvent;
  category: TrackingCategory;
  dateTime?: string;
  subCategory?: TrackingSubCategory;
  parameter1?: string;
  parameter2?: string;
  parameter3?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ActivityTrackerService {
  registerClick: (clickEvent: ActivityEvent) => void;
  registerPage: (pageEvent: ActivityEvent) => void;
  registerCreate: (createEvent: ActivityEvent) => void;
  registerPlayer: (playerEvent: ActivityEvent) => void;

  registerComplete: (playerEvent: ActivityEvent) => void;

  private readonly _bufferTime = 2000;
  private readonly _localStorageKey = '_activityEvents';
  private _eventRunner: ReturnType<typeof setTimeout> = null;
  private _eventsQue: ActivityEvent[] = [];

  constructor(
    private _apiService: ApiService,
    private _userService: UserService
  ) {
    this._push = this._push.bind(this);

    this.registerClick = this.registerSpecificType.bind(
      this,
      TrackingEvent.Click
    );
    this.registerPage = this.registerSpecificType.bind(
      this,
      TrackingEvent.Page
    );
    this.registerCreate = this.registerSpecificType.bind(
      this,
      TrackingEvent.Create
    );
    this.registerPlayer = this.registerSpecificType.bind(
      this,
      TrackingEvent.Player
    );
    this.registerComplete = this.registerSpecificType.bind(
      this,
      TrackingEvent.Complete
    );
  }

  register(event: ActivityEvent): void {
    this._eventsQue = this._eventsQue.concat({
      ...event,
      dateTime: new Date().toISOString(),
    });

    if (this._eventRunner == null) {
      this._eventRunner = setTimeout(this._push, this._bufferTime);
    }
  }

  clear(): void {
    this._eventsQue = [];

    if (this._eventRunner != null) {
      clearTimeout(this._eventRunner);

      this._eventRunner = null;
    }
  }

  private registerSpecificType(
    type: TrackingEvent,
    clickEvent: ActivityEvent
  ): void {
    this.register({
      type,
      ...clickEvent,
    });
  }

  private async _push(): Promise<void> {
    if (this._eventsQue.length === 0) {
      return;
    }

    const isUserActive = await firstValueFrom(this._userService.isUserActive());
    this._eventRunner = null;

    if (!isUserActive) {
      return;
    }
    const eventsToSend = this._eventsQue;
    this.clear();

    const result = await firstValueFrom(
      this._apiService
        .post(
          'tracking/userActivity',
          {
            userActions: eventsToSend,
          },
          '',
          null,
          true
        )
        .pipe(
          map((data) => !!data),
          catchError(() => of(false))
        )
    );

    if (!result) {
      this._eventsQue = eventsToSend;
    }
  }
}
