import { Directive, Input } from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';

/**
 * @deprecated
 * Use {@link FnlSelectOptionDirective} instead.
 */
@Directive({
  selector: 'mat-option[fnlLegacySelectOption]'
})
export class FnlLegacySelectOptionDirective {

  @Input() groupValue: unknown;

  constructor(
    public host: MatOption,
  ) {}
}
