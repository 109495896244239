import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SidePanelListService } from '../side-panel-list.service';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelComponent implements OnInit {
  @ContentChild(TemplateRef) contentRef: TemplateRef<any>;

  @Input()
  panel: string;
  @Input()
  title: string;
  @Input()
  icon: string;

  isSidePanelOpened$: Observable<boolean>;

  constructor(private sidePanelListService: SidePanelListService) {}

  async togglePanel(): Promise<void> {
    this.sidePanelListService.toggleOpenedPanel(this.panel);
  }

  ngOnInit() {
    this.isSidePanelOpened$ = this.sidePanelListService
      .getOpenedPanel()
      .pipe(map((panel) => panel === this.panel));
  }
}
