import { PresentationKpiApi, PresentationKpiGroupApi } from '@app/api/fishbone/client-kpis/client-kpis-api.service';
import { Kpi, KpiGroup } from '@app/core/models/kpi.model';

export function convertKpiGroupListFromApiToClient(kpiGroups: PresentationKpiGroupApi[]): KpiGroup[] {
  return kpiGroups.map(item => ({
      metricUid: item.uid,
      metricAbsoluteValueDisplayText: item.absoluteValueDisplayText,
      metricRelativeValueDisplayText: item.relativeValueDisplayText,
      kpis: kpiHierarchy(item.kpis),
    })
  );
}

function kpiHierarchy(items: PresentationKpiApi[], parent: Kpi = null, level: number = 1): Kpi[] {
  return items.map(it => {
    const node: Kpi = {
      uid: it.uid,
      name: it.name,
      description: it.description,
      level,
      parent,
      childItems: []
    };
    node.childItems = kpiHierarchy(it.childItems, node, level + 1)
    return node;
  })
}
