import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseItem } from '@app/core/models/common.model';
import { HistoryTheme } from '@app/core/models/history.model';
import { trackByUid } from '@app/core/utils/base';

@Component({
  selector: 'app-history-custom-companies-view',
  templateUrl: './history-custom-companies-view.component.html',
  styleUrls: ['./history-custom-companies-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryCustomCompaniesViewComponent {
  @Input() items: BaseItem[];
  trackByUid = trackByUid;

  getRoute(item: BaseItem): string[] {
      return ['/account-discovery', item.name, item.uid];
  }
}
