import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@app/core/models/company.model';
import { MetricChartView } from '@app/core/models/metric-chart.model';
import {
  CashFlowPeriod,
  CFVGBlock,
  CFVGTextData,
  CompanyMetric,
  CompanyMetricGroup,
  CompanyMetricInsights,
  CompanyMetricPeriodDetails,
  ExtendedIndustryMetric,
  ExtendedIndustryMetricGroup,
  FetchedCompanyDataPeriod,
  FetchedCompanyMetricData,
  FetchedIndustryMetricData,
  IndustryMetric,
  IndustryMetricDriver,
  IndustryMetricGroup,
  IndustryMetricTrendInfo,
  IndustryMetricYearDetails,
  IndustryWhatsInIt,
  MetricFull,
  MetricInsights,
  MetricsTrend,
  MetricsType,
  PeerTrend,
  Priority,
} from '@app/core/models/metric.model';
import { Peer } from '@app/core/models/peer.model';
import { format, set } from 'date-fns';
import { Dictionary, flatMap, isNil, omitBy, reduce, sortBy } from 'lodash';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { INCOME_GROWTH_UIDS } from '../constants';

import { BaseGroupItem, BaseItem } from '../models/common.model';
import { ApiService } from './api.service';
import { timezoneCorrection } from '../utils/date.util';
import { NotificationsService } from '@app/core/services/notifications.service';
import { environment } from '../../../environments/environment';
import { EnvironmentTypes } from '../../../environments/environment-types';

@Injectable({
  providedIn: 'root',
})
export class MetricService {
  static readonly blockCid = 'MetricService';

  constructor(
    private apiService: ApiService,
    private notificationsService: NotificationsService
  ) {
  }

  getMetricsByCategory(
    categoryUid: string,
    havingKPI?: boolean,
    blockCid?: string
  ): Observable<BaseItem[]> {
    return this.apiService
      .get<{
        items: {
          uid: string;
          absoluteValueDisplayText: string;
          relativeValueDisplayText: string;
        }[];
      }>(
        'industryResearch/Metric/metric-options',
        {
          params: new HttpParams({
            fromObject: omitBy({ categoryUid, havingKPI }, isNil),
          }),
        },
        blockCid
      )
      .pipe(
        map((data) =>
          data.items.map((item) => ({
            uid: item.uid,
            name:
              item.absoluteValueDisplayText || item.relativeValueDisplayText,
          }))
        ),
        catchError(() => of([]))
      );
  }

  getMetrics(
    forCompany = false,
    industryUid: string,
    type: MetricsType,
    businessFunctionUid?: string,
    blokCId?: string
  ): Observable<Array<IndustryMetricGroup>> {
    return this.apiService.get<IndustryMetricGroup[]>(
      forCompany
        ? 'industryResearch/metric/company-metrics'
        : 'industryResearch/metric',
      {
        params: new HttpParams({
          fromObject: omitBy(
            {
              industryUid,
              businessFunctionUid,
              metricUsing: type.toString(),
            },
            isNil
          ),
        }),
      },
      blokCId
    );
  }

  getMetricGroups(
    industryUid: string,
    type: MetricsType,
    businessFunctionUid?: string,
    checkChildren = false,
    forCompany = false,
    blokCId?: string,
    skipCatch = false
  ): Observable<IndustryMetricGroup[]> {
    return this.getMetrics(
      forCompany,
      industryUid,
      type,
      businessFunctionUid,
      blokCId
    ).pipe(
      map((metricGroups: IndustryMetricGroup[]) =>
        sortBy(metricGroups, 'order').map(
          (metricGroup: IndustryMetricGroup) => ({
            ...metricGroup,
            metrics: sortBy(
              checkChildren
                ? metricGroup.metrics.map((metric: IndustryMetric) => {
                  if (metric.childItems?.length > 0) {
                    return {
                      ...metric,
                      childItems: sortBy(metric.childItems || [], 'order'),
                    };
                  }

                  return metric;
                })
                : metricGroup.metrics,
              'order'
            ),
          })
        )
      ),
      catchError((error: HttpErrorResponse) =>
        skipCatch
          ? throwError(error)
          : (of([]) as Observable<IndustryMetricGroup[]>)
      )
    );
  }

  getExpandedMetrics(
    industryUid: string,
    type: MetricsType,
    businessFunctionUid?: string,
    checkChildren = false,
    forCompany = false,
    blokCId?: string,
    skipCatch?: boolean
  ): Observable<IndustryMetric[]> {
    return this.getMetricGroups(
      industryUid,
      type,
      businessFunctionUid,
      checkChildren,
      forCompany,
      blokCId,
      skipCatch
    ).pipe(
      map((metricGroups: IndustryMetricGroup[]) =>
        flatMap(metricGroups, (metricGroup: IndustryMetricGroup) => {
          let children = [];
          const metrics = metricGroup.metrics.map((metric: IndustryMetric) => {
            const order = metric.order + metricGroup.order * 100;
            if (checkChildren && metric.childItems?.length > 0) {
              children = children.concat(
                metric.childItems.map((childMetric: IndustryMetric) => ({
                  ...childMetric,
                  order: order + (childMetric.order + 1) / 100,
                }))
              );
            }

            return {
              ...metric,
              childItems: [],
              order,
            };
          });

          return checkChildren
            ? sortBy(metrics.concat(children), 'order')
            : metrics;
        })
      )
    );
  }

  getGeneralTrendInfo(
    industryUid: string,
    subIndustryUid?: string,
    blokCId?: string
  ): Observable<Dictionary<IndustryMetricTrendInfo>> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          industryUid,
          subIndustryUid,
        },
        isNil
      ),
    });

    return this.apiService
      .get<IndustryMetricTrendInfo[]>(
        'industryResearch/metric/metric-trends-info',
        {
          params,
        },
        blokCId
      )
      .pipe(
        map((trendsInfo: IndustryMetricTrendInfo[]) =>
          trendsInfo.reduce(
            (
              result: Dictionary<IndustryMetricTrendInfo>,
              info: IndustryMetricTrendInfo
            ) => {
              result[info.uid] = info;

              return result;
            },
            {} as Dictionary<IndustryMetricTrendInfo>
          )
        ),
        catchError(
          () => of({}) as Observable<Dictionary<IndustryMetricTrendInfo>>
        )
      );
  }

  getCompanyTrendInfo(
    companyUid: string,
    financialAnalysisType: MetricChartView,
    industryRevenueGroupUid?: string,
    blockCId?: string,
    SkipContribution: boolean = false
  ): Observable<Dictionary<IndustryMetricTrendInfo>> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          companyUid,
          financialAnalysisType,
          industryRevenueGroupUid,
          SkipContribution,
        },
        isNil
      ),
    });

    return this.apiService
      .get<IndustryMetricTrendInfo[]>(
        'company/metric/growth-and-profitability-trends-info',
        {
          params,
        },
        blockCId
      )
      .pipe(
        map((trendsInfo: IndustryMetricTrendInfo[]) =>
          trendsInfo.reduce(
            (
              result: Dictionary<IndustryMetricTrendInfo>,
              info: IndustryMetricTrendInfo
            ) => {
              result[info.uid] = info;

              return result;
            },
            {} as Dictionary<IndustryMetricTrendInfo>
          )
        ),
        catchError(
          () => of({}) as Observable<Dictionary<IndustryMetricTrendInfo>>
        )
      );
  }

  getIndustryTrendInfo(
    revenueGroupUid: string,
    skipContribution: boolean,
    blokCId?: string
  ): Observable<Dictionary<IndustryMetricTrendInfo>> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          revenueGroupUid,
          skipContribution,
        },
        isNil
      ),
    });

    return this.apiService
      .get<IndustryMetricTrendInfo[]>(
        'industryResearch/metric/growth-and-profitability-trends-info',
        {
          params,
        },
        blokCId
      )
      .pipe(
        map((trendsInfo: IndustryMetricTrendInfo[]) =>
          trendsInfo.reduce(
            (
              result: Dictionary<IndustryMetricTrendInfo>,
              info: IndustryMetricTrendInfo
            ) => {
              result[info.uid] = info;

              return result;
            },
            {} as Dictionary<IndustryMetricTrendInfo>
          )
        ),
        catchError(
          () => of({}) as Observable<Dictionary<IndustryMetricTrendInfo>>
        )
      );
  }

  getIndustryTrendInfoNew(
    industryRevenueGroupUid: string,
    metricsUid: string[],
    blokCId?: string
  ): Observable<Dictionary<string[]>> {
    return this.apiService
      .get<{ items: { uid: string; industryTrends: string[] }[] }>(
        'industryResearch/metric/metric-growth-profit-info',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                industryRevenueGroupUid,
                ...metricsUid.reduce(
                  (result: Dictionary<string>, item: string, index: number) => {
                    result[`metricUid[${ index }]`] = item;
                    return result;
                  },
                  {}
                ),
              },
              isNil
            ),
          }),
        },
        blokCId
      )
      .pipe(
        map((trendsInfo) =>
          trendsInfo.items.reduce((result: Dictionary<string[]>, info) => {
            result[info.uid] = info.industryTrends;

            return result;
          }, {})
        ),
        catchError(() => of({}))
      );
  }

  getCompanyMetricsPerformance(
    metricsUid: string[],
    companiesUid: string[],
    blokCId?: string
  ): Observable<Dictionary<PeerTrend[][]>> {
    return this.apiService
      .get<Dictionary<{ relativeOrder: number; peerTrends: PeerTrend[] }[]>>(
        'company/Metric/metric-performance',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                ...metricsUid.reduce(
                  (result: Dictionary<string>, item: string, index: number) => {
                    result[`metricUid[${ index }]`] = item;
                    return result;
                  },
                  {}
                ),
                ...companiesUid.reduce(
                  (result: Dictionary<string>, item: string, index: number) => {
                    result[`companyUid[${ index }]`] = item;
                    return result;
                  },
                  {}
                ),
              },
              isNil
            ),
          }),
        },
        blokCId
      )
      .pipe(
        map((data) =>
          reduce(
            data,
            (result, metricData, metricUid) => {
              result[metricUid] = sortBy(metricData, 'relativeOrder')
                .reverse()
                .map((item) =>
                  item.peerTrends.map((subItem) => ({
                    ...subItem,
                    fiscalPeriodEnd:
                      subItem.fiscalPeriodEnd &&
                      timezoneCorrection(subItem.fiscalPeriodEnd as string),
                  }))
                );
              return result;
            },
            {}
          )
        ),
        catchError(() => of({}))
      );
  }

  getIndustryMetricsDrivers(
    industryUid: string,
    subIndustryUid?: string,
    blokCId?: string
  ): Observable<Dictionary<IndustryMetricDriver[]>> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          industryUid,
          subIndustryUid,
        },
        isNil
      ),
    });

    return this.apiService
      .get<IndustryMetricDriver[]>(
        'industryResearch/metric/metric-drivers',
        {
          params,
        },
        blokCId
      )
      .pipe(
        map((driversInfo: IndustryMetricDriver[]) =>
          driversInfo.reduce(
            (
              result: Dictionary<IndustryMetricDriver[]>,
              driver: IndustryMetricDriver
            ) => {
              if (result[driver.uid]) {
                result[driver.uid].push(driver);
              } else {
                result[driver.uid] = [ driver ];
              }

              return result;
            },
            {} as Dictionary<IndustryMetricDriver[]>
          )
        ),
        catchError(
          () => of({}) as Observable<Dictionary<IndustryMetricDriver[]>>
        )
      );
  }

  getWhatsInIt(
    industryUid: string,
    subIndustryUid?: string,
    blockCid?: string
  ): Observable<Dictionary<IndustryWhatsInIt>> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          industryUid,
          subIndustryUid,
        },
        isNil
      ),
    });

    return this.apiService
      .get<IndustryWhatsInIt[]>(
        'industryResearch/metric/metric-whats-in-it-info',
        {
          params,
        },
        blockCid
      )
      .pipe(
        map((whatsInIts: IndustryWhatsInIt[]) =>
          whatsInIts.reduce(
            (
              result: Dictionary<IndustryWhatsInIt>,
              whatsInIt: IndustryWhatsInIt
            ) => {
              result[whatsInIt.uid] = whatsInIt;

              return result;
            },
            {} as Dictionary<IndustryWhatsInIt>
          )
        ),
        catchError(() => of(null))
      );
  }

  getIsFishboneEnabled(
    industryUid: string,
    blockCid?: string
  ): Observable<string[]> {
    return this.apiService
      .get<string[]>(
        'fishbone/financialAnalysis/fishbone-metrics',
        {
          params: new HttpParams({
            fromObject: {
              industryUid,
            },
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  getFilledMetricGroups(
    industryUid: string,
    type: MetricsType,
    forCompany: boolean,
    subIndustryUid?: string,
    businessFunctionUid?: string,
    checkChildren = false,
    blokCId?: string,
    skipCatch = false
  ): Observable<IndustryMetricGroup[]> {
    return combineLatest([
      this.getMetricGroups(
        industryUid,
        type,
        businessFunctionUid,
        checkChildren,
        forCompany,
        blokCId,
        skipCatch
      ),
      this.getGeneralTrendInfo(industryUid, subIndustryUid, blokCId),
      this.getIndustryMetricsDrivers(industryUid, subIndustryUid, blokCId),
      this.getWhatsInIt(industryUid, subIndustryUid, blokCId),
    ]).pipe(
      map(
        ([ groups, trendsInfo, drivers, whatsInIts ]: [
          IndustryMetricGroup[],
          Dictionary<IndustryMetricTrendInfo>,
          Dictionary<IndustryMetricDriver[]>,
          Dictionary<IndustryWhatsInIt>
        ]) =>
          groups.map((group: IndustryMetricGroup) => ({
            ...group,
            metrics: group.metrics.map((metric: IndustryMetric) => ({
              ...metric,
              childItems:
                checkChildren && metric.childItems?.length > 0
                  ? metric.childItems.map((childMetric: IndustryMetric) => ({
                    ...childMetric,
                    trendText: trendsInfo[childMetric.uid]?.text,
                    driverData: drivers[childMetric.uid],
                    whatsInIt: whatsInIts[childMetric.uid],
                  }))
                  : [],
              trendText: trendsInfo[metric.uid]?.text,
              driverData: drivers[metric.uid],
              whatsInIt: whatsInIts[metric.uid],
            })),
          }))
      )
    );
  }

  getFilledCompanyMetricGroups(
    industryUid: string,
    type: MetricsType,
    companyUid: string,
    financialAnalysisType: MetricChartView,
    subIndustryUid?: string,
    businessFunctionUid?: string,
    industryRevenueGroupUid?: string,
    checkChildren = false,
    blokCId?: string,
    skipCatch = false
  ): Observable<IndustryMetricGroup[]> {
    return combineLatest([
      this.getFilledMetricGroups(
        industryUid,
        type,
        true,
        subIndustryUid,
        businessFunctionUid,
        checkChildren,
        blokCId,
        skipCatch
      ),
      this.getCompanyTrendInfo(
        companyUid,
        financialAnalysisType,
        industryRevenueGroupUid,
        blokCId
      ),
      this.getIsFishboneEnabled(industryUid, blokCId),
    ]).pipe(
      map(
        ([ groups, trendsInfo, metricsWithFish ]: [
          IndustryMetricGroup[],
          Dictionary<IndustryMetricTrendInfo>,
          string[]
        ]) =>
          groups.map((group: IndustryMetricGroup) => ({
            ...group,
            metrics: group.metrics.map((metric: IndustryMetric) => ({
              ...metric,
              childItems:
                checkChildren && metric.childItems?.length > 0
                  ? metric.childItems.map((childMetric: IndustryMetric) => ({
                    ...childMetric,
                    trendText:
                      trendsInfo[childMetric.uid]?.trends ||
                      childMetric.trendText,
                    fishEnabled: false,
                  }))
                  : [],
              trendText: trendsInfo[metric.uid]?.trends || metric.trendText,
              fishEnabled: metricsWithFish.includes(metric.uid),
            })),
          }))
      )
    );
  }

  getFilledIndustryMetricGroups(
    industryUid: string,
    type: MetricsType,
    revenueGroupUid: string,
    subIndustryUid?: string,
    businessFunctionUid?: string,
    checkChildren = false,
    blokCId?: string,
    skipCatch = false
  ): Observable<IndustryMetricGroup[]> {
    return combineLatest([
      this.getFilledMetricGroups(
        industryUid,
        type,
        false,
        subIndustryUid,
        businessFunctionUid,
        checkChildren,
        blokCId,
        skipCatch
      ),
      this.getIndustryTrendInfo(revenueGroupUid, false, blokCId),
    ]).pipe(
      map(
        ([ groups, trendsInfo ]: [
          IndustryMetricGroup[],
          Dictionary<IndustryMetricTrendInfo>
        ]) =>
          groups.map((group: IndustryMetricGroup) => ({
            ...group,
            metrics: group.metrics.map((metric: IndustryMetric) => ({
              ...metric,
              childItems:
                checkChildren && metric.childItems?.length > 0
                  ? metric.childItems.map((childMetric: IndustryMetric) => ({
                    ...childMetric,
                    trendText:
                      trendsInfo[childMetric.uid]?.trends ||
                      childMetric.trendText,
                  }))
                  : [],
              trendText: trendsInfo[metric.uid]?.trends || metric.trendText,
            })),
          }))
      )
    );
  }

  getIndustryMetricsData(
    industryRevenueUid: string,
    metricsUIds: string[],
    targetRevenue: number,
    blokCId?: string
  ): Observable<
    Dictionary<MetricFull<IndustryMetricYearDetails[], MetricInsights>>
  > {
    const httpParams = new HttpParams({
      fromObject: {
        metricsUids: metricsUIds,
        industryRevenueUid,
        targetRevenue: targetRevenue?.toString(),
      },
    });

    return this.apiService
      .get<Dictionary<FetchedIndustryMetricData>>(
        'industryResearch/Metric/metrics-data',
        {
          params: httpParams,
        },
        blokCId
      )
      .pipe(
        map((metricDictionary) => {
          const dataKeyPairs = reduce(
            metricDictionary,
            (
              resultArray: [ string, FetchedIndustryMetricData ][],
              data: FetchedIndustryMetricData,
              key: string
            ) => {
              resultArray.push([ key, data ]);
              return resultArray;
            },
            []
          );

          const metricResult =
            this._getEmptyMetricsDictionary<
              MetricFull<IndustryMetricYearDetails[], MetricInsights>
            >(metricsUIds);

          dataKeyPairs.forEach(
            (keyPair: [ string, FetchedIndustryMetricData ]) => {
              const key = keyPair[0];
              const metricData = keyPair[1];

              metricResult[key].details = metricData.data.reverse();
              const notNullYears = metricResult[key].details.filter(
                (data) => data.year != null
              );

              metricResult[key].insights = {
                average: {
                  start: notNullYears[0].year,
                  end: notNullYears[notNullYears.length - 1].year,
                  value: metricData.insights.averageMedian,
                },
                median: {
                  year: metricData.insights.median.year,
                  value: metricData.insights.median.median,
                },
                trend: metricData.insights.trend,
              } as MetricInsights;

              metricResult[key].powerOfOne =
                metricData.powerOfOne != null
                  ? {
                    powerOfOne: metricData.powerOfOne,
                    isTop: metricData.isTopPowerOfOne,
                  }
                  : null;

              metricResult[key].priority = metricData.priority;
              metricResult[key].priorityClass = this._calculatePriority(
                key,
                metricData.priority
              );
            }
          );

          return metricResult;
        }),
        catchError(() =>
          of(
            this._getEmptyMetricsDictionary<
              MetricFull<IndustryMetricYearDetails[], MetricInsights>
            >(metricsUIds)
          )
        )
      );
  }

  getFullIndustryMetricGroups(
    metricGroups: IndustryMetricGroup[],
    industryRevenueUid: string,
    targetRevenue: number,
    checkChildren = false,
    blokCId?: string
  ): Observable<ExtendedIndustryMetricGroup[]> {
    return this.getIndustryMetricsData(
      industryRevenueUid,
      flatMap(metricGroups, (metricGroup: IndustryMetricGroup) =>
        checkChildren
          ? metricGroup.metrics.concat(
            flatMap(
              metricGroup.metrics,
              (metric: IndustryMetric) => metric.childItems || []
            )
          )
          : metricGroup.metrics
      ).map((metric: IndustryMetric) => metric.uid),
      targetRevenue,
      blokCId
    ).pipe(
      map(
        (
          metricsData: Dictionary<
            MetricFull<IndustryMetricYearDetails[], MetricInsights>
          >
        ) =>
          metricGroups.map((metricGroup: IndustryMetricGroup) => ({
            ...metricGroup,
            metrics: (
              metricGroup.metrics.map((metric: IndustryMetric) => ({
                ...metric,
                ...metricsData[metric.uid],
                childItems:
                  checkChildren && metric.childItems?.length > 0
                    ? metric.childItems.map(
                      (childMetric: IndustryMetric) =>
                        ({
                          ...childMetric,
                          ...metricsData[childMetric.uid],
                        } as ExtendedIndustryMetric)
                    )
                    : [],
              })) as ExtendedIndustryMetric[]
            ).filter((metric) => Object.keys(metric.details).length !== 0),
          })) as ExtendedIndustryMetricGroup[]
      )
    );
  }

  /**
   * @deprecated
   *
   * Use MetricRepository.getCompanyMetricData instead
   */
  getCompanyMetricData(
    company: Company,
    metricsUids: string[],
    peers: Peer[],
    financialAnalysisType: MetricChartView,
    currencyUid: string,
    industryRevenueGroupUid?: string,
    blokCUid?: string
  ): Observable<
    Dictionary<MetricFull<CompanyMetricPeriodDetails[], CompanyMetricInsights>>
  > {
    const peersMappedNames = peers.reduce((mapped, peer) => {
      mapped[peer.uid] = [ peer.name, peer.ticker ];

      return mapped;
    }, {});

    peersMappedNames[company?.uid] = [
      company?.name,
      company?.ticker || company.shortName,
    ];

    return this.apiService
      .get<Dictionary<FetchedCompanyMetricData>>(
        'company/Metric/metrics-data',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                financialAnalysisType: financialAnalysisType.toString(),
                companyUid: company?.uid,
                peersUids: peers.map((peer) => peer.uid),
                currencyUid,
                metricsUids,
                industryRevenueGroupUid,
              },
              isNil
            ),
          }),
        },
        blokCUid
      )
      .pipe(
        map((metricData: Dictionary<FetchedCompanyMetricData>) => {
          const dataKeyPairs = reduce(
            metricData,
            (
              resultArray: [ string, FetchedCompanyMetricData ][],
              data: FetchedCompanyMetricData,
              key: string
            ) => {
              resultArray.push([ key, data ]);
              return resultArray;
            },
            []
          );

          const metricResult = this._getEmptyMetricsDictionary<
            MetricFull<CompanyMetricPeriodDetails[], CompanyMetricInsights>
          >(metricsUids, true);

          dataKeyPairs.forEach(
            (keyPair: [ string, FetchedCompanyMetricData ]) => {
              const key = keyPair[0];
              const fetchedMetricData = keyPair[1];

              metricResult[key].details = fetchedMetricData.data
                .map((periodData: FetchedCompanyDataPeriod) => ({
                  uid: periodData.fiscalPeriodEnd,
                  period:
                    periodData.fiscalPeriodEnd &&
                    timezoneCorrection(periodData.fiscalPeriodEnd),
                  value: periodData.value,
                  industryTrend: periodData.industryTrend,
                  peerTrends: sortBy(
                    periodData.peerTrends.map((peerTrend) => ({
                      ...peerTrend,
                      name: peersMappedNames[peerTrend.companyUid][0],
                      ticker: peersMappedNames[peerTrend.companyUid][1],
                      fiscalPeriodEnd: peerTrend.fiscalPeriodEnd
                        ? timezoneCorrection(
                          peerTrend.fiscalPeriodEnd as string
                        )
                        : null,
                    })),
                    'name'
                  ),
                  year: periodData.year !== 0 ? periodData.year : null,
                }))
                .reverse();

              metricResult[key].powerOfOne =
                fetchedMetricData.powerOfOne != null
                  ? {
                    powerOfOne: fetchedMetricData.powerOfOne,
                    isTop: fetchedMetricData.isTopPowerOfOne,
                  }
                  : null;

              const isYear = financialAnalysisType === MetricChartView.Years;
              const notNullPeriods = metricResult[key].details.filter((data) =>
                isYear ? data.year != null : data.period != null
              );
              const lastPeriod = isYear
                ? notNullPeriods[notNullPeriods.length - 1]?.year != null
                  ? set(new Date(), {
                    year: notNullPeriods[notNullPeriods.length - 1].year,
                  })
                  : null
                : notNullPeriods[notNullPeriods.length - 1]?.period;
              metricResult[key].insights = {
                average: {
                  start: isYear
                    ? notNullPeriods[0]?.year != null
                      ? set(new Date(), { year: notNullPeriods[0].year })
                      : null
                    : notNullPeriods[0]?.period,
                  end: lastPeriod,
                  value: fetchedMetricData.insights.averageMedian,
                },
                median: {
                  period: lastPeriod,
                  value: fetchedMetricData.insights.median.median,
                  industryValue:
                  fetchedMetricData.insights.median.industryMedian,
                },
                trend: fetchedMetricData.insights.trend,
                peer: {
                  period: lastPeriod,
                  name:
                    peersMappedNames[
                      fetchedMetricData.insights.bestPeer?.companyUid
                      ] != null
                      ? peersMappedNames[
                        fetchedMetricData.insights.bestPeer?.companyUid
                        ][0]
                      : null,
                },
              } as CompanyMetricInsights;

              metricResult[key].priority = fetchedMetricData.priority;
              metricResult[key].priorityClass = this._calculatePriority(
                key,
                fetchedMetricData.priority
              );

              metricResult[key].cashFlow =
                fetchedMetricData.cashFlowValueOfGap != null
                  ? {
                    industry:
                    fetchedMetricData.cashFlowValueOfGap
                      .cashFlowValueOfGapIndustryValue,
                    bestPeer:
                    fetchedMetricData.cashFlowValueOfGap
                      .cashFlowValueOfGapOfBestPeerValue,
                    company:
                    fetchedMetricData.cashFlowValueOfGap
                      .cashFlowValueOfGapOfCompanyValue,
                    bestPeerData: {
                      uid: fetchedMetricData.cashFlowValueOfGap
                        .companyUidOfBestPeerCashFlowValueOfGap,
                      name:
                        fetchedMetricData.cashFlowValueOfGap
                          .companyUidOfBestPeerCashFlowValueOfGap != null
                          ? peersMappedNames[
                            fetchedMetricData.cashFlowValueOfGap
                              .companyUidOfBestPeerCashFlowValueOfGap
                            ][0]
                          : null,
                      ticker:
                        fetchedMetricData.cashFlowValueOfGap
                          .companyUidOfBestPeerCashFlowValueOfGap != null
                          ? peersMappedNames[
                            fetchedMetricData.cashFlowValueOfGap
                              .companyUidOfBestPeerCashFlowValueOfGap
                            ][1]
                          : null,
                    },
                    period: timezoneCorrection(
                      fetchedMetricData.cashFlowValueOfGap
                        .fiscalPeriodEndOfCompanyCashFlowValueOfGap
                    ),
                    periodType:
                    fetchedMetricData.cashFlowValueOfGap
                      .periodTypeOfIndustryCashFlowValueOfGap,
                    year: fetchedMetricData.cashFlowValueOfGap
                      .yearOfCompanyCashFlowValueOfGap,
                  }
                  : null;
            }
          );

          return metricResult;
        }),
        catchError((err) => {
          const environmentsToHideErr = [ EnvironmentTypes.Prod ];
          if (environmentsToHideErr.includes(environment.type) && err.status === 400) {
            this.notificationsService.clearNotifications();
          }
          return of(
            this._getEmptyMetricsDictionary<
              MetricFull<CompanyMetricPeriodDetails[], CompanyMetricInsights>
            >(metricsUids, true)
          );
        })
      );
  }

  getFullCompanyMetricGroups(
    metricGroups: IndustryMetricGroup[],
    company: Company,
    peers: Peer[],
    view: MetricChartView,
    currencyUid: string,
    industryRevenueGroupUid?: string,
    checkChildren = false,
    blokCId?: string
  ): Observable<CompanyMetricGroup[]> {
    return this.getCompanyMetricData(
      company,
      flatMap(metricGroups, (metricGroup: IndustryMetricGroup) =>
        checkChildren
          ? metricGroup.metrics.concat(
            flatMap(
              metricGroup.metrics,
              (metric: IndustryMetric) => metric.childItems || []
            )
          )
          : metricGroup.metrics
      ).map((metric: IndustryMetric) => metric.uid),
      peers,
      view,
      currencyUid,
      industryRevenueGroupUid,
      blokCId
    ).pipe(
      map(
        (
          metricsData: Dictionary<
            MetricFull<CompanyMetricPeriodDetails[], CompanyMetricInsights>
          >
        ) =>
          metricGroups.map((metricGroup: IndustryMetricGroup) => ({
            ...metricGroup,
            metrics: (
              metricGroup.metrics.map((metric: IndustryMetric) => ({
                ...metric,
                ...metricsData[metric.uid],
                childItems:
                  checkChildren && metric.childItems?.length > 0
                    ? metric.childItems.map(
                      (childMetric: IndustryMetric) =>
                        ({
                          ...childMetric,
                          ...metricsData[childMetric.uid],
                        } as CompanyMetric)
                    )
                    : [],
              })) as CompanyMetric[]
            ).filter((metric) => metric.details.length !== 0),
          })) as CompanyMetricGroup[]
      )
    );
  }

  getMetricsByIndustries(
    industryUids: string[],
    blockCid?: string
  ): Observable<BaseGroupItem[]> {
    return this.apiService
      .get<{
        items: (BaseItem & {
          metrics: {
            uid: string;
            absoluteValueDisplayText?: string;
            relativeValueDisplayText?: string;
          }[];
        })[];
      }>(
        'industryResearch/metric/metric-options-for-custom-kpis',
        {
          params: new HttpParams({
            fromObject: {
              ...industryUids.reduce(
                (result: Dictionary<string>, item: string, index: number) => {
                  result[`industryUid[${ index }]`] = item;
                  return result;
                },
                {}
              ),
            },
          }),
        },
        blockCid
      )
      .pipe(
        map((data) =>
          data.items.map((item) => ({
            uid: item.uid,
            name: item.name,
            items: item.metrics.map((metric) => ({
              uid: metric.uid,
              name:
                metric.absoluteValueDisplayText ||
                metric.relativeValueDisplayText,
            })),
          }))
        ),
        catchError(() => of([]))
      );
  }

  getCashflowPeriodText(periodType: CashFlowPeriod): string {
    switch (periodType) {
      case CashFlowPeriod.FirstQuartile:
        return '1st Quartile';
      case CashFlowPeriod.Median:
        return 'Median';
      case CashFlowPeriod.FourthQuartile:
        return '4th Quartile';
      default:
        return 'period';
    }
  }

  generateCFGText(
    companyData: Company,
    metricData: CompanyMetric,
    peersData: Peer[],
    industryName: string,
    period: MetricChartView = MetricChartView.Years
  ): CFVGTextData {
    let company: CFVGBlock[];
    let industry: CFVGBlock[];
    let peers: CFVGBlock[];

    const startTemplate = this._getCFVGStart(metricData);
    const emptyTemplate: CFVGBlock[] = [
      {
        text: 'Latest ',
      },
      {
        text: companyData.name,
      },
      {
        text: ' performance is the best',
      },
    ];

    if (metricData.cashFlow?.company != null) {
      if (metricData.cashFlow.company !== 0) {
        company = startTemplate.concat([
          {
            text:
              period === MetricChartView.Years
                ? metricData.cashFlow.year?.toString()
                : format(metricData.cashFlow.period, 'MMM-yyyy'),
            strong: true,
          },
        ]);
      } else {
        company = emptyTemplate.slice();
      }
    }

    if (metricData.cashFlow?.industry != null) {
      if (metricData.cashFlow.industry !== 0) {
        industry = startTemplate.concat([
          {
            text: this.getCashflowPeriodText(metricData.cashFlow.periodType),
            strong: true,
          },
        ]);
      } else {
        industry = emptyTemplate.concat([
          {
            text: ' vs ',
          },
          {
            text: industryName,
            strong: true,
          },
        ]);
      }
    }

    if (metricData.cashFlow?.bestPeer != null) {
      if (metricData.cashFlow.bestPeer !== 0) {
        peers = startTemplate.concat([
          {
            text: metricData.cashFlow.bestPeerData.name,
            strong: true,
          },
        ]);
      } else {
        peers = emptyTemplate.concat([
          {
            text: ' vs ',
          },
          {
            text: peersData.map((peer: Peer) => peer.name).join(', '),
            strong: true,
          },
        ]);
      }
    }

    return {
      company,
      industry,
      peers,
    };
  }

  getMetricNamesForIndustry(
    industryUid: string,
    nameLike: string,
    blockId?: string,
    pageSize: number = 1000,
    pageNumber: number = 0
  ): Observable<Array<BaseItem>> {
    return this.apiService
      .get<BaseItem[]>(
        `industryresearch/Metric/relative-name-autocomplete`,
        {
          params: new HttpParams({
            fromObject: {
              industryUid,
              nameLike,
              pageSize,
              pageNumber,
            },
          }),
        },
        blockId
      )
      .pipe(catchError(() => of(null)));
  }

  private _getCFVGStart(metric: CompanyMetric): CFVGBlock[] {
    const metricTrend =
      metric.trend === MetricsTrend.Positive ? 'increase' : 'reduction';
    const metricName =
      metric.absoluteValueDisplayText || metric.relativeValueDisplayText;
    switch (metric.uid) {
      // Days in Inventory
      case 'f6a69034-6cb1-4309-bf15-8a577d00459f':
      case 'e7768f1c-465c-4252-a29a-28af3b1712a3':
        return [
          {
            text: 'One-time ',
          },
          {
            text: metricTrend,
          },
          {
            text: ' to investment in Inventories by improving to the best performance ',
          },
        ];
      // Fixed Asset Utilization, Net Patient Revenue/Fixed Assets
      case '09ec4e49-5549-45a2-b450-cd7a9fae754a':
      case '8b6900df-3e0b-4496-82ef-d499bc925220':
        return [
          {
            text: 'Annual reduction to investment in Fixed Assets by improving to the best performance ',
          },
        ];
      // Days Sales Outstanding
      case '6b4d7721-e091-4964-bb6c-6dbf8dc892f0':
      case 'ec324c58-6526-4ec0-8621-321aaca5515e':
        return [
          {
            text: 'One-time ',
          },
          {
            text: metricTrend,
          },
          {
            text: ' to investment in Accounts Receivable by improving to the best performance ',
          },
        ];
      // Days Purchases Outstanding
      case '7a98ed3f-3f7c-4f2e-9213-ad831ef91245':
      case '25b09ab2-921c-45d5-93b9-7736d56191aa':
        return [
          {
            text: 'One-time ',
          },
          {
            text: metricTrend,
          },
          {
            text: ' to Accounts Payable by improving to the best performance ',
          },
        ];
      //  Operating Income Margin
      case '39d0bc91-721b-4758-9db8-879f64f69174':
      case 'e77e1316-7dc8-46be-8652-47cfb934fc39':
        return [
          {
            text: 'Annual increase to Operating Income by improving to the best performance ',
          },
        ];
      // Pretax Profit Margin metric
      case '229c18f0-0c0a-4edd-920d-72da48433659':
      case 'db95d05b-2154-4187-8dfb-57d4d91e8e5e':
        return [
          {
            text: 'Annual increase to Pretax Profit by improving to the best performance ',
          },
        ];
      // Efficiency Ratio (Cost to Income) metric
      case 'a59054dd-6616-47c5-b2cd-bbcc5cc212ca':
        return [
          {
            text: 'Annual reduction to Total Non-Interest Expense by improving to the best performance ',
          },
        ];
      default:
        return [
          {
            text: 'Annual ',
          },
          {
            text: metricTrend,
          },
          {
            text: ' to ',
          },
          {
            text: metricName,
          },
          {
            text: ' by improving to the best performance ',
          },
        ];
    }
  }

  private _calculatePriority(
    uid: string,
    priorityFormRange?: number
  ): Priority {
    return priorityFormRange != null
      ? priorityFormRange <= 0.15
        ? Priority.Low
        : priorityFormRange >= 0.25
          ? Priority.Hight
          : Priority.Medium
      : INCOME_GROWTH_UIDS.includes(uid)
        ? Priority.NotAvailable
        : null;
  }

  private _getEmptyMetricsDictionary<T>(
    metricsUIds?: string[],
    arrayDetails = false
  ): Dictionary<T> {
    if (metricsUIds == null) {
      return {};
    }

    return metricsUIds.reduce((prevValue: Dictionary<T>, metricUId: string) => {
      prevValue[metricUId] = {
        details: arrayDetails ? [] : {},
        insights: null,
        priority: null,
      } as any;

      return prevValue;
    }, {});
  }
}
