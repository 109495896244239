import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlCarouselComponent } from './carousel.component';
import { MatIconModule } from '@angular/material/icon';
import { FnlCarouselItemDirective } from './carousel.directive';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';


@NgModule({
  declarations: [
    FnlCarouselComponent,
    FnlCarouselItemDirective,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    FnlCarouselComponent,
    FnlCarouselItemDirective,
  ],
})
export class FnlCarouselModule {}
