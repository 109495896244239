import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { PopoverRef } from '@app/shared/popover';

@Component({
  selector: 'app-size-options-popover',
  templateUrl: './size-options-popover.component.html',
  styleUrls: ['./size-options-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizeOptionsPopoverComponent {
  sizeOptions: number[];
  selectedSize: number;

  constructor(private popoverRef: PopoverRef<SizeOptionsPopoverComponent>) {
    this.sizeOptions = this.popoverRef.data.sizeOptions || [];
    this.selectedSize = this.popoverRef.data.selectedSize;
  }

  isDisabled(): boolean {
    return this.sizeOptions.length <= 1;
  }

  isSelected(size: number) {
    return this.selectedSize === size;
  }

  changeSize(size: number) {
    this.popoverRef.close(size);
  }
}
