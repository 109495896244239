import { Injectable } from '@angular/core';
import { catchError, combineLatest, distinctUntilChanged, map, merge, Observable, of, Subject, switchMap, take, } from 'rxjs';
import { CompanyPresentationBackData, CompanyPresentationData, } from '@app/modules/common/presentation/presentation.model';
import { CompanyComponentDataService } from '@app/modules/company/services/company-component-data.service';
import { CompanyComponentService } from '@app/modules/company/services/company-component.service';
import { Industry, IndustryCategory, IndustryCode } from '@app/core/models/industry.model';
import { BaseGroupItem, BaseItem, UUID } from '@app/core/models/common.model';
import { AllSegments, AllSegmentsItems } from '@app/core/models/segment.model';
import { EMPTY_UID, NOT_AVAILABLE } from '@app/core/constants';
import { Peer } from '@app/core/models/peer.model';
import { StrategiesService } from '@app/core/services/strategies.service';
import { isEqual, sortBy } from 'lodash';
import { cache } from '@app/core/operators';
import { PresentationService } from '@app/modules/common/presentation/presentation.service';
import { CompanyPresentationDataService } from '@app/core/services/company-presentation-data.service';
import { ActivityTrackerService, TrackingCategory, } from '@app/api/tracking/activity-tracker.service';
import { CompanyComponentAmplitudeService } from './company-component-amplitude.service';
import { MetricService } from '@app/core/services/metric.service';
import { IndustryMetric, MetricsType } from '@app/core/models/metric.model';
import { UserService } from '@app/core/services/user.service';
import { KpiGroup } from '@app/core/models/kpi.model';
import { User } from '@app/core/models/user.model';
import { PowerOfOneService } from '@app/core/services/power-of-one.service';
import { po1MetricsAsKpiGroups } from '@app/core/models/powerOfOne.model';

@Injectable()
export class CompanyPresentationService {
  private _industryGoals$: Observable<BaseItem[]>;
  private _businessFunctionFromForm = new Subject<string>();
  private _solutionFromForm = new Subject<string>();
  private _selectedRankingMetrics$: Observable<IndustryMetric[]>;

  private readonly _notAvailableBaseItem: BaseItem = {
    uid: NOT_AVAILABLE,
    name: 'Not Available',
  };

  constructor(
    private _companyDataService: CompanyComponentDataService,
    private _companyComponentService: CompanyComponentService,
    private _strategiesService: StrategiesService,
    private _companyPresentationDataService: CompanyPresentationDataService,
    private _activityTrackerService: ActivityTrackerService,
    private _companyComponentAmplitudeService: CompanyComponentAmplitudeService,
    private _metricService: MetricService,
    private _userService: UserService,
    private _powerOfOneService: PowerOfOneService,
  ) {
  }

  setBusinessFunction(value?: string): void {
    this._businessFunctionFromForm.next(value);
  }

  getBusinessFunction(): Observable<string> {
    return merge(
      this._companyComponentService.getFunctionFilter(),
      this._businessFunctionFromForm
    );
  }

  setSolution(value?: string): void {
    this._solutionFromForm.next(value);
  }

  getSolution(): Observable<string> {
    return merge(
      this._companyComponentService.getSelectedSolution(),
      this._solutionFromForm
    );
  }

  getIndustryGoals(): Observable<BaseItem[]> {
    if (this._industryGoals$ == null) {
      this._industryGoals$ = combineLatest([
        this.getCompanyIndustry(),
        this.getCompanySubIndustry(),
        this.getBusinessFunction(),
      ]).pipe(
        switchMap(
          ([ industry, subindustry, businessFunction ]: [
            Industry,
            Industry,
            string
          ]) =>
            this._strategiesService.getStrategiesByGoal(
              industry.uid,
              subindustry?.uid,
              businessFunction !== EMPTY_UID ? businessFunction : null,
              PresentationService.blockCid
            )
        ),
        map((items) =>
          sortBy(
            items.map((item) => ({
              uid: item.uid,
              name: item.name,
            })),
            (item) => item.name.toLowerCase()
          )
        ),
        cache()
      );
    }

    return this._industryGoals$;
  }

  getCompanyCategory(): Observable<IndustryCategory> {
    return this._companyDataService.getCompanyCategory();
  }

  getCompanyIndustry(): Observable<Industry> {
    return this._companyDataService.getCompanyIndustry();
  }

  getCompanySubIndustry(): Observable<Industry> {
    return this._companyDataService.getCompanySubIndustry();
  }

  getBusinessFunctions(): Observable<BaseItem[]> {
    return this._companyComponentService.getBusinessFunctions();
  }

  getSolutions(): Observable<BaseGroupItem[]> {
    return this._companyComponentService.getSolutionOptionsWithEmptyOption(this.getBusinessFunction());
  }

  getSegments(): Observable<AllSegmentsItems> {
    return this._companyComponentService.getSegments().pipe(
      map((data: AllSegments) => {
        if (data) {
          return {
            business: data.businessSegments?.length
              ? this._companyComponentService.getEmptySegments(
                'Business',
                data.businessSegments
              )
              : [ this._notAvailableBaseItem ],
            geographic: data.geographicSegments?.length
              ? this._companyComponentService.getEmptySegments(
                'Geographic',
                data.geographicSegments
              )
              : [ this._notAvailableBaseItem ],
          };
        } else {
          return this._getNotAvailableSegments();
        }
      })
    );
  }

  getRecommendedPeers(): Observable<Peer[]> {
    return this._companyComponentService.getRecommendedPeers();
  }

  getParentPeer(): Observable<Peer> {
    return this._companyDataService.getSelectedNotEmptyCompany().pipe(
      map((company) => ({
        uid: company.uid,
        name: company.name,
      }))
    );
  }

  getEntityName(): Observable<string> {
    return this._companyDataService
      .getSelectedNotEmptyCompany()
      .pipe(map((data) => data.name));
  }

  getCompanyPresentationData(): Observable<CompanyPresentationData> {
    return this._getCompanyPresentationData();
  }

  sendPresentationData(data: CompanyPresentationData): Observable<boolean> {
    const backData: CompanyPresentationBackData = {
      industryRevenueGroupUid: data.industryRevenueGroupUid,
      peerUids: data.peers.map((peer: Peer): string => peer.uid),
      currencyUid: data.currency.uid,
      goalUids: data.goalUids,
    };
    if (data.geoSegmentUid && data.geoSegmentUid !== EMPTY_UID) {
      backData.geographicalSegmentUid = data.geoSegmentUid;
    }
    if (data.businessSegmentUid && data.businessSegmentUid !== EMPTY_UID) {
      backData.businessSegmentUid = data.businessSegmentUid;
    }
    if (data.solutionUid && data.solutionUid !== EMPTY_UID) {
      backData.solutionUid = data.solutionUid;
    }
    if (data.businessFunctionUid && data.businessFunctionUid !== EMPTY_UID) {
      backData.businessFunctionUid = data.businessFunctionUid;
    }
    if (data.metricUids && data.metricUids.length) {
      backData.metricUids = data.metricUids.map(
        (item: IndustryMetric) => item.uid
      );
    }
    if (data.kpiUids) {
      const distinctUids = [ ...new Set(data.kpiUids.map(item => item.uid)) ]
      backData.kpis = distinctUids.map(uid => ({uid}));
    }

    this.trackingExport(true, data.companyUid);

    return this._companyPresentationDataService.runPresentationGeneration(
      backData,
      data.companyUid
    );
  }

  async trackingExport(generate: boolean, companyUid?: string): Promise<void> {
    this._companyComponentAmplitudeService.triggerAmplitude(
      generate
        ? 'Company Insights - Reports - Export to Presentation - Generate'
        : 'Company Insights - Reports - Export to Presentation - Cancel Click'
    );
    if (generate) {
      this._activityTrackerService.registerClick({
        category: TrackingCategory.Presentation,
        parameter1: companyUid,
      });
    }
  }

  getSelectedRankingMetrics(): Observable<IndustryMetric[]> {
    if (!this._selectedRankingMetrics$) {
      this._selectedRankingMetrics$ = combineLatest([
        this.getCompanyIndustry(),
        this.getBusinessFunction(),
      ]).pipe(
        switchMap(([ parentIndustry, businessFunction ]) =>
          this._metricService
            .getExpandedMetrics(
              parentIndustry.uid,
              MetricsType.Trends,
              businessFunction !== EMPTY_UID ? businessFunction : null,
              true,
              true
            )
            .pipe(catchError(() => of([]) as Observable<IndustryMetric[]>))
        ),
        cache()
      );
    }

    return this._selectedRankingMetrics$;
  }

  getSelectedRecommendedMetrics(): Observable<IndustryMetric[]> {
    return this.getSelectedRankingMetrics().pipe(
      map((metrics) =>
        metrics.filter((metric) => metric.isKey && metric.order % 1 === 0)
      )
    );
  }

  getSelectedOtherMetrics(): Observable<IndustryMetric[]> {
    return this.getSelectedRankingMetrics().pipe(
      map((metrics) =>
        metrics.filter((metric) => !metric.isKey || metric.order % 1 !== 0)
      )
    );
  }

  getKpiGroups(): Observable<KpiGroup[]> {
    return combineLatest([
      this._userService.getCurrentUser(),
      this.getCompanyIndustry(),
      this.getBusinessFunction(),
      this.getSolution()
    ]).pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      switchMap(([ user, industry, businessFunctionUid, solutionUid ]: [ User, Industry, UUID, UUID ]) => {
          return this._powerOfOneService
            .getMetricsWithKPIAndOverrides(
              user.uid,
              {industryUid: industry.uid},
              businessFunctionUid === EMPTY_UID ? null : businessFunctionUid,
              solutionUid === EMPTY_UID ? null : [solutionUid]
            )
            .pipe(map(po1MetricsAsKpiGroups))
        }
      )
    )
  }

  clearData(): void {
    this._industryGoals$ = null;
    this._selectedRankingMetrics$ = null;
  }

  private _getCompanyPresentationData(): Observable<CompanyPresentationData> {
    return combineLatest([
      this._companyDataService.getSelectedNotEmptyCompany(),
      this._companyDataService.getIndustryData(),
      this._companyComponentService.getPeerFilter(),
      this._companyComponentService.getSelectedSolution(),
      this._companyComponentService.getCurrencyFilter(),
      this._companyComponentService.getSelectedBusinessSegment(),
      this._companyComponentService.getSelectedGeographicSegment(),
      this._companyComponentService.getFunctionFilter(),
      this.getIndustryGoals().pipe(take(1)),
      this.getSelectedRankingMetrics().pipe(take(1)),
    ]).pipe(
      map(
        (data) =>
          ({
            peers: data[2],
            companyUid: data[0].uid,
            industryRevenueGroupUid: data[1].revenue.uid,
            solutionUid: data[0].industry.category.code === IndustryCode.Healthcare ? EMPTY_UID : data[3],
            currency: data[4],
            businessSegmentUid: data[5],
            geoSegmentUid: data[6],
            businessFunctionUid: data[7],
            goalUids: data[8].map((item) => item.uid),
            metricUids: data[9]
          } as CompanyPresentationData)
        )
      );
  }

  private _getNotAvailableSegments(): AllSegmentsItems {
    return {
      business: [ this._notAvailableBaseItem ],
      geographic: [ this._notAvailableBaseItem ],
    };
  }
}
