<div class="side-panel" [class.open]="isSidePanelOpened$ | async" cdkTrapFocus>
  <button class="side-panel__sleeve" type="button" (click)="togglePanel()">
    <mat-icon class="side-panel__icon-open" [svgIcon]="icon"></mat-icon>
    <span i18n class="side-panel__sleeve-name">{{ title }}</span>
  </button>
  <app-icon-button class="side-panel__close" (click)="togglePanel()">
    <mat-icon
      class="side-panel__icon-close"
      svgIcon="close"
    ></mat-icon>
  </app-icon-button>

  <ng-container *ngIf="isSidePanelOpened$ | async">
    <ng-container *ngTemplateOutlet="contentRef"></ng-container>
  </ng-container>
</div>
