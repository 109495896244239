import { Injectable } from '@angular/core';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { ApiService } from '@app/core/services/api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FAQArticle, MessageInfo } from './side-panel-list.model';
import { UserService } from '@app/core/services/user.service';

/* eslint-disable max-len */
@Injectable()
export class SidePanelListService {
  private readonly faqFish: FAQArticle[] = [
    {
      id: 'x_1',
      heading: 'Accounts FAQ',
      clarifications: [
        {
          id: 'x_1_1',
          question: 'How many companies are in the {system_name} database?',
          answer:
            '<p>The database includes full financials for approximately 70,000 publicly traded companies in over 120 countries globally. We include revenue estimates for thousands of private companies as well.</p>',
        },
        {
          id: 'x_1_2',
          question: 'What are the sources of data? ',
          answer:
            '<p>LSEG Data & Analytics is the primary source for the publicly traded company data, but other regulatory sources are used in the Americas such as the FDIC, Federal Reserve, Office of the Superintendent of Financial Institutions in Canada (OFSI), National Credit Union Association (NCUA) and Centers for Medicare and Medicaid Services (CMS) for U.S. Hospital financial data.</p>' +
            '<p>D&B Hoovers is the source for Private Company revenue estimates.</p>' +
            '<p>APQC (American Productivity & Quality Center) is the source for KPI benchmarks found in the Power of One and elsewhere in {system_name}.</p>',
        },
        {
          id: 'x_1_3',
          question: 'How often is the data updated?',
          answer:
            '<p>Our data provider sends us weekly data updates, and new data typically appears in {system_name} by 12:00 AM ET every Tuesday.</p>',
        },
        {
          id: 'x_1_4',
          question: 'Why can’t I find the company I’m looking for?',
          answer:
            '<p>Check the Company’s investor relations website to ensure you are searching for the correct company name. For publicly traded entities (traded on a stock exchange), {system_name} uses the exact name of the Company that is listed in their regulatory filings. For example, Restoration Hardware is reported in {system_name} under RH, which is the official name reported in their SEC Form 10-K.</p>' +
            '<p>If a company is private, toggle on the “Search Private companies” option in {system_name}. In some cases, you will need to search under the parent company name if applicable.</p>',
        },
        {
          id: 'x_1_5',
          question: 'Why don’t I see the latest annual results for my company?',
          answer:
            '<p>Public Companies typically publish their audited financials within 60 days after their fiscal year-end (filed via SEC Form 10-K for U.S. companies). The timing can vary by size of company, and location, and companies outside the U.S. can have different guidelines for reporting.</p>' +
            '<p>Then, it will typically take 1 to 3 weeks after a company publishes its audited financial report (Annual Report, Interim Report, Quarterly Report) for the company’s financials to be available in the {system_name} database. The audited report is necessary as preliminary reports often do not disclose full financials, which are necessary to maintain consistent period to period comparisons.</p>',
        },
        {
          id: 'x_1_6',
          question: 'Can {system_name} help with Private companies?',
          answer:
            '<p>Yes. {system_name} includes information on tens of thousands of private companies. Estimated revenue size and the industry in which they compete provides the ability to review industry trends and potential cash flow benefits you can demonstrate for your buyer. The financial data displayed will represent the median values for the respective industry as a whole (not actual company ratios). The Power of One is useful, as this scales the potential improvement values, to the estimated revenue amounts for that private company.</p>' +
            '<p>If you have specific financial data for a private company, you can create a “Custom Company” in {system_name}, allowing you to further model and create financial insights.</p>',
        },
        {
          id: 'x_1_7',
          question: 'Can I create a custom company?',
          answer:
            '<p>Yes. <strong>Steps to create and upload financial data for a Custom/Private company to FinListics {system_name}</strong>:</p>' +
            '<ul>' +
            '<li>In {system_name}, go to top right of main menu and select drop down menu beside your name</li>' +
            '<li>Select <strong>Custom Companies</strong></li>' +
            '<li>Select <strong>Add New Company</strong></li>' +
            '<li>There are info buttons  for various entry points to guide you</li>' +
            '<li>Using the target company’s financial reports, enter the data</li>' +
            '<li>Upon completion of entering the data into the spreadsheet, click SAVE.</li>' +
            '<li>You can SAVE at any time, and return to edit as needed</li>' +
            '<li>The new Custom Company should now be available by searching in the Main Menu &gt; Accounts</li>' +
            '</ul>' +
            '<p>NOTE: Custom Companies are only available for viewing to the person who created the company.</p>'
        },
        {
          id: 'x_1_8',
          question: 'My customer is a publicly traded U.S health insurance company, why can’t I find it in {system_name}?',
          answer:
            '<p>Our database does not include all managed care companies. This is due to our data provider treating some of them as industrial companies, while our model includes them as insurance. In this case, you can consider a manual input option. Under the Custom Companies menu (under your name/drop down menu upper right of the {system_name} screen), you can upload key financial details for the company, using the appropriate matching industry template. You can then select them as a peer to compare with other publicly traded competitors for that industry.</p>',
        }
      ],
    },
    {
      id: 'x_2',
      heading: 'Industries FAQ',
      clarifications: [
        {
          id: 'x_2_1',
          question: 'I need to run industry research for a specific SIC code, but I only know the name, not the SIC code, how do I find it?',
          answer:
            '<p>Under Industries, change the dropdown next to the search box to SIC and start typing the name of the industry or a word in the industry name into the search box; all relevant SIC codes and industry names will populate in the dropdown.</p>',
        },
        {
          id: 'x_2_2',
          question: 'I’m looking for a specific SIC code, but I don’t see it in the list – how can I find it?',
          answer:
            '<p>If you don’t see a certain SIC code in {system_name}, it’s likely because we don’t have enough companies to create a statistically relevant sample size for the industry aggregates. For example, the Truck & Bus Bodies industry is SIC 3713. In this case, you can look for the first three digits of the SIC followed by an “X” (SIC 371X – Motor Vehicles & Equipment) – this will give you aggregated industry data at a higher level but will still be meaningful.</p>',
        },
        {
          id: 'x_2_3',
          question: 'Where does the qualitative research on the Industry Trends tab come from (external factors, internal factors, market disrupters, risk factors)?',
          answer:
            '<p>FinListics’ Research & Analysis team examines sources around the world including company annual reports, investor presentations, earnings calls, industry association reports, market analysis reports, and government filings. They aggregate all their research into the qualitative data you see in {system_name}.</p>',
        },
      ],
    },
    {
      id: 'x_3',
      heading: 'Data Reporting FAQ',
      clarifications: [
        {
          id: 'x_3_1',
          question: 'Why does the data not match the Company’s Annual Report?',
          answer:
            '<p>Our primary source of financial data is LSEG Data & Analytics. The source documents used to gather Annual results are the SEC Form 10-Ks (for companies in US); and outside the US, sources include company-reported Annual Reports, Form 20F, Registration Documents, etc.</p>' +
            '<p>It is possible that differences will arise, due to the nature of accounting methodologies, accrual or impairment estimates, or how companies handle special items like acquisition costs or discontinued operations.</p>' +
            '<p>However, our data provider minimizes the variation as much as possible, by extracting the company-reported data according to established global templates that are specific to industry groups, taking into consideration the variety of accounting conventions to facilitate comparisons between companies. Note: the bottom line, or “Net Income,” will be the same for both. </p>',
        },
        {
          id: 'x_3_2',
          question: 'Why does {system_name} show revenue decline year over year, when the company annual report shows a revenue increase? (or vice versa)',
          answer:
            '<p>Companies are allowed to restate prior year financials, following certain standard accounting rules. Common reasons to restate prior year revenues include discontinued operations/mergers/acquisitions, etc. However, {system_name} does not restate prior year results, as the changes are important to know as a seller. To research a significant percentage change for a company, you can review the Annual Reports and/or Google, to search for keywords such as:</p>' +
            '<ul>' +
            '<li>Discontinued operations</li>' +
            '<li>Acquisitions</li>' +
            '<li>Mergers</li>' +
            '<li>Divestitures</li>' +
            '<li>Restated financials/Restate</li>' +
            '<li>Accounting methodology change/Account classification change</li>' +
            '</ul>',
        },
        {
          id: 'x_3_3',
          question: 'Why can’t I compare certain companies? For example, Mastercard with American Express?',
          answer:
            '<p>Companies such as Visa, Mastercard and Western Union are treated as goods and services companies, and have an income statement with expenses like cost of services and SG&A. American Express is a banking institution and has a very different income statement structure, with expenses like provision for credit losses and non-interest expenses. In our database, you cannot compare a goods & services company with a bank. This is true for other non-compatible comparisons – like a bank versus an insurance company.</p>',
        },
        {
          id: 'x_3_4',
          question: 'My customer is in the hotel industry and revenues from annual reports do not match revenues in {system_name}, why?',
          answer:
            '<p>For Hotels, where there is an element of cost reimbursement revenue, revenues and expenses will not fully match the income statements. Our data provider reports revenues excluding cost reimbursement revenue and accordingly expenses exclude reimbursed expenses. These represent amounts received from franchises for costs incurred on their behalf. It cancels out. So, it’s neither revenue nor costs. This method is applied by our data provider for peers as well.</p>',
        },
        {
          id: 'x_3_5',
          question: 'My customer is a bank that also sells insurance, why doesn’t the cost to income (efficiency) ratio in the annual report match the {system_name} cost to income ratio?',
          answer:
            '<p>For banks that also sell insurance, this difference is due to how our data provider reports results from insurance activity. Many banks report the net result from insurance activities under total revenues, but our data provider reports them separately, i.e., insurance premiums as revenues and insurance expenses as operating expenses. This causes variability in ratios. This is done to standardize the way insurance activity is reported across all banks, so that comparisons with peers are accurate.</p>',
        },
        {
          id: 'x_3_6',
          question: 'Why does Cost of Goods Sold (COGS) not match the Company 10-K report?',
          answer:
            '<p>Our data provider LSEG Data & Analytics removes Depreciation & Amortization from COGS, and reports this as a separate line item. Some companies include depreciation & amortization in their COGS number while others do not. LSEG Data & Analytics standardizes its data collection to exclude depreciation & amortization in COGS. There may be other minor adjustments as well, but this is typically the biggest one.</p>',
        },
        {
          id: 'x_3_7',
          question: 'Why is Days in Inventory (DII) not the same number, as when I use the Company raw data?',
          answer:
            '<p>The calculation used for Days in Inventory is:</p>' +
            '<blockquote>' +
            'DII= Inventory / Cost Per Day<br/>' +
            'Cost Per Day = Cost of Goods Sold (COGS) / 365 Days<br/>' +
            '</blockquote>' +
            '<p>COGS reported in {system_name}, removes Depreciation & Amortization, reported as a separate line item, which can create a variation in the calculation.</p>',
        },
        {
          id: 'x_3_8',
          question: 'What is the difference between Latest Twelve Months (LTM) and Annual reporting when looking at Financial Analysis?',
          answer:
            '<p>LTM data is based on a company’s quarterly or interim financial report. Companies do not always report the same level of detail in the quarterly financial results, as compared to the Annual results. In those cases, it’s possible the LTM view will be presented at a higher level. For example, R&D expenses are typically only available in Annual Report notes. In the quarterly report they are not available, therefore those expenses are typically rolled into the SG&A expense line instead.</p>',
        },
        {
          id: 'x_3_9',
          question: 'Why is the Latest Twelve Months (LTM) data not current?',
          answer:
            '<p>There are certain regions which require only semiannual audited results to be reported, not quarterly. For those companies, there is a lag to report the first and third quarter results.</p>',
        },
        {
          id: 'x_3_10',
          question: 'Why can’t I find a Peer company in ‘Search for Peers’?',
          answer:
            '<p>There are a few reasons why certain companies do not appear in the Peer search.</p>' +
            '<p>First, make sure the Peer company is a Publicly traded company within our database. Second, the Peer company must be in the same category as the main company you are comparing against. Because of differences in Financial Metrics used, companies are assigned to one of these categories for comparison purposes.</p>' +
            '<ul>' +
            '<li>Banking/Financial Markets</li>' +
            '<li>Health Care (Includes U.S. Hospitals only)</li>' +
            '<li>Insurance</li>' +
            '<li>Industrial (Aerospace & Defense, Automotive, Chemicals, Consumer Products, Health Care Providers, Hi-Tech & Electronics, Hospitality & Leisure, Information Technology, Life Sciences, Manufacturing, Media, Mining, Oil & Gas, Retail, Services, Transportation, Utilities, Wholesale Distribution)</li>' +
            '</ul>' +
            '<p>For example, Banks report Non-Interest Income and Net interest income, which are different financial metrics when compared to any other groups.</p>',
        },
        {
          id: 'x_3_11',
          question: 'Do you have actual company-reported IT spend data?',
          answer:
            '<p>Our tool does not include the actual IT spend, as this is typically not reported by companies. We do have industry benchmark data collected via surveys by APQC (America Productivity & Quality Center). We apply these benchmarks to calculate the 1% improvement in the Power of One tab. So, you can get the estimated spend from this page.</p>' +
            '<p>To see the actual industry benchmark values used to calculate the Power of One, click on the calculator icon to the right of the KPI.</p>',
        },
        {
          id: 'x_3_12',
          question: 'What data do you show for a Private company analysis?',
          answer:
            '<p>For private companies, we use the related industry performance trends as a proxy, not actual company data. However, you will find the estimated revenue for the company, which is provided by D&B Hoovers. This will help with calculating the 1% improvement for KPIs. Note that we don’t have access to the actual full financials of any private company, as they typically do not publish them.</p>',
        },
        {
          id: 'x_3_13',
          question: 'Where does data for U.S. Hospitals come from?',
          answer:
            '<p>We source U.S. Hospitals data in {system_name} differently, it comes from the federal agency Centers for Medicare & Medicaid Services - CMS, not from LSEG Data & Analytics. Data updates and reporting from CMS each year are on a longer timeframe than typical publicly traded companies.</p>' +
            '<p>Hospitals often have many subsidiary names, some with financials and some listed as private. To identify the parent company, look for the words ‘HC system’ next to the hospital name.</p>',
        },
      ],
    },
    {
      id: 'x_4',
      heading: 'General FAQ',
      clarifications: [
        {
          id: 'x_4_1',
          question: 'What is the Power of One?',
          answer:
            '<p>The Power of One tab in {system_name} estimates a +1% improvement potential for certain KPIs, based on benchmark values applied to actual revenues for a company. Benchmark values are based on industry-specific surveys for each KPI, to estimate median values. Select the calculator icon to the right of the KPI, to see benchmark values. You can customize the benchmark value if you have more insights directly from your client. You can also change the % range of improvement, if you feel your solution has a different impact.</p>' +
            '<p>The Power of One is intended to be used as a conversation starter, not a promise of actual results. The values are estimates and indicative for the customer’s industry. They are not the actual value for an individual company. Showing the estimated “how much” equips sellers to position their solutions in terms of the decision maker’s bottom line.</p>',
        },
        {
          id: 'x_4_2',
          question: 'What are the benchmark values used in Power of One?',
          answer:
            '<p>The operational KPI values used to calculate the Power of One, are based on survey results from thousands of companies around the world. The values are collected and then aggregated into specific industries like Automotive, Consumer Products, or Banking. Surveys are conducted by APQC (American Productivity & Quality Center).</p>',
        },
        {
          id: 'x_4_3',
          question: 'What is Value Modeler?',
          answer:
            '<p>Similar to the Power of One, Value Modeler helps you answer the question a client often asks early in the sales process, “What are your solution’s potential financial benefits?”</p>' +
            '<p>The Value Modeler helps you:</p>' +
            '<ul>' +
            '<li>Qualify and disqualify opportunities</li>' +
            '<li>Develop insights into which executive within the client’s company would likely sponsor your solution – higher level executives may not sponsor an initiative unless it potentially delivers a minimum level of cash flow</li>' +
            '<li>Provide the client with greater motivation for going forward</li>' +
            '<li>Show the client the cost of delay</li>' +
            '</ul>' +
            '<p>The calculations are high-level estimates, and are not a promise of actual results.</p>',
        },
        {
          id: 'x_4_4',
          question: 'The PDF Reports will not download',
          answer:
            '<p>It’s possible you have pop-up blockers in place preventing you from viewing or downloading these reports. You should disable the blockers, and retest. Reports will be available to you, wherever you find downloaded reports on your computer. For example, the Downloads folder for Microsoft products.</p>',
        },
        {
          id: 'x_4_5',
          question: 'Is {system_name} customizable?',
          answer:
            '<p>Yes, in {system_name}, you can customize various features, such as type of currency, Annual vs. Latest 12 Months financial data, peer comparisons, or geography. In the Power of One tab, you can alter the range of improvement your solution can potentially impact, and adjust the benchmark values if you have greater insights that directly correlate with your buyer’s business.</p>' +
            '<p>Customized Solution to Value Maps can be created by your company ({system_name} Admin access only) to show your solutions’ potential impact and the value of improvement to operational KPIs.</p>' +
            '<p>Additionally, you can create a Custom Company. Note that, Custom Companies will only be visible to the person who entered it.</p>' +
            '<p>You can also explore the Dashboard menu, to find various customizable options including Journeys. Some of the options here include:</p>' +
            '<ul>' +
            '<li>Research prospects</li>' +
            '<li>Get the Meeting</li>' +
            '<li>Prep for a Meeting</li>' +
            '</ul>',
        },
      ],
    },
  ];
  private openedPanel = new BehaviorSubject<string>(null);

  constructor(
    private _api: ApiService,
    private _amplitudeService: AmplitudeService,
    private _users: UserService,
  ) {
  }

  getOpenedPanel(): Observable<string> {
    return this.openedPanel.asObservable();
  }

  setOpenedPanel(newPanel: string): void {
    this.openedPanel.next(newPanel);
  }

  toggleOpenedPanel(panel: string): void {
    const currentPanel = this.openedPanel.getValue();

    if (currentPanel !== panel) {
      this.openedPanel.next(panel);
      if (panel === 'faq') {
        this._amplitudeService.amplitudeEvent('Help Click');
      }
    } else {
      this.openedPanel.next(null);
    }
  }

  getFAQContent(): Observable<FAQArticle[]> {
    return this._users.getCurrentUser()
      .pipe(
        map(user => user.clientCode === 'IBM'),
        map(isIbm => isIbm ? 'CBV' : 'ClientIQ'),
        map(systemName => this.faqInClientTerminology(systemName))
      );
  }

  sendMessage(data: MessageInfo): Observable<boolean> {
    return this._api
      .post<boolean>('common/feedback', data, 'HELP_request', true)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  private faqInClientTerminology(systemName: string): FAQArticle[] {
    return this.faqFish.map(article => ({
      id: article.id,
      heading: this.replaceTermPlaceholders(article.heading, systemName),
      description: this.replaceTermPlaceholders(article.description, systemName),
      image: article.image,
      clarifications: article.clarifications.map(item => ({
        id: item.id,
        question: this.replaceTermPlaceholders(item.question, systemName),
        answer: this.replaceTermPlaceholders(item.answer, systemName),
      }))
    }));
  }

  private replaceTermPlaceholders(text: string, systemName: string): string {
    return text
      ?.replace(/\{system_name}/g, systemName);
  }
}
