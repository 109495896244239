<ng-container *tuiLet="{
  isVisible: visible | async,
  isAdminUser: adminUser | async
} as display">
  <ng-container *ngIf="display.isVisible || display.isVisible === false && display.isAdminUser">
    <button class="non-button button-element"
            [class.admin-mode]="display.isVisible === false"
            (click)="openLearnMore($event)"
            type="button">
      <mat-icon class="fnl-text-blue learn-more-icon"
                svgIcon="video"></mat-icon>
      <span class="fnl-text-blue learn-more-text"
            style="white-space: nowrap">LEARN MORE</span>
    </button>
  </ng-container>
</ng-container>
