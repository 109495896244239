<h6 class="dashboard-chart__head">
  {{ metric.name
  }}<fnl-info
    *ngIf="!metric.placeholder && metric.description"
    [hasContent]="true"
    class="dashboard-chart__info"
  >
    <div class="dashboard-chart__info-content">
      {{ metric.description }}
    </div></fnl-info
  >
</h6>
<svg #chart class="dashboard-chart__container" [ngClass]="chartClasses"></svg>
<div #tooltip class="dashboard-chart__tooltip"></div>
