import { Injectable } from '@angular/core';
import { catchError, combineLatest, distinctUntilChanged, map, merge, Observable, of, Subject, switchMap, take, } from 'rxjs';
import {
  PrivateCompanyPresentationBackData,
  PrivateCompanyPresentationData,
} from '@app/modules/common/presentation/presentation.model';
import { Industry, IndustryCategory, IndustryCode } from '@app/core/models/industry.model';
import { BaseGroupItem, BaseItem, UUID } from '@app/core/models/common.model';
import { EMPTY_UID } from '@app/core/constants';
import { Peer } from '@app/core/models/peer.model';
import { StrategiesService } from '@app/core/services/strategies.service';
import { isEqual, sortBy } from 'lodash';
import { cache } from '@app/core/operators';
import { PresentationService } from '@app/modules/common/presentation/presentation.service';
import { CompanyPresentationDataService } from '@app/core/services/company-presentation-data.service';
import { MetricService } from '@app/core/services/metric.service';
import { IndustryMetric, MetricsType } from '@app/core/models/metric.model';
import { UserService } from '@app/core/services/user.service';
import { KpiGroup } from '@app/core/models/kpi.model';
import { User } from '@app/core/models/user.model';
import { PrivateCompanyService } from './private-company.service';
import { PrivateCompanyDataService } from './private-company-data.service';
import { po1MetricsAsKpiGroups } from '@app/core/models/powerOfOne.model';
import { PowerOfOneService } from '@app/core/services/power-of-one.service';

@Injectable()
export class PrivateCompanyPresentationService {
  private _industryGoals$: Observable<BaseItem[]>;
  private _businessFunctionFromForm = new Subject<string>();
  private _solutionFromForm = new Subject<string>();
  private _selectedRankingMetrics$: Observable<IndustryMetric[]>;

  constructor(
    private _privateCompanyDataService: PrivateCompanyDataService,
    private _privateCompanyService: PrivateCompanyService,
    private _companyPresentationDataService: CompanyPresentationDataService,
    private _strategiesService: StrategiesService,
    private _metricService: MetricService,
    private _userService: UserService,
    private _powerOfOneService: PowerOfOneService,
  ) {
  }

  setBusinessFunction(value?: string): void {
    this._businessFunctionFromForm.next(value);
  }

  getBusinessFunction(): Observable<string> {
    return merge(
      this._privateCompanyService.getFunctionFilter(),
      this._businessFunctionFromForm
    );
  }

  setSolution(value?: string): void {
    this._solutionFromForm.next(value);
  }

  getSolution(): Observable<string> {
    return merge(
      this._privateCompanyService.getSelectedSolution(),
      this._solutionFromForm
    );
  }

  getIndustryGoals(): Observable<BaseItem[]> {
    if (this._industryGoals$ == null) {
      this._industryGoals$ = combineLatest([
        this.getCompanyIndustry(),
        this.getCompanySubIndustry(),
        this.getBusinessFunction(),
      ]).pipe(
        switchMap(
          ([ industry, subindustry, businessFunction ]: [
            Industry,
            Industry,
            string
          ]) =>
            this._strategiesService.getStrategiesByGoal(
              industry.uid,
              subindustry?.uid,
              businessFunction !== EMPTY_UID ? businessFunction : null,
              PresentationService.blockCid
            )
        ),
        map((items) =>
          sortBy(
            items.map((item) => ({
              uid: item.uid,
              name: item.name,
            })),
            (item) => item.name.toLowerCase()
          )
        ),
        cache()
      );
    }

    return this._industryGoals$;
  }

  getCompanyCategory(): Observable<IndustryCategory> {
    return this._privateCompanyDataService.getCategory();
  }

  getCompanyIndustry(): Observable<Industry> {
    return this._privateCompanyDataService.getIndustry();
  }

  getCompanySubIndustry(): Observable<Industry> {
    return this._privateCompanyDataService.getSubIndustry();
  }

  getPrivateCompanyRevenue(): Observable<number> {
    return this._privateCompanyService.getTargetRevenueFilter();
  }

  getBusinessFunctions(): Observable<BaseItem[]> {
    return this._privateCompanyService.getBusinessFunctions();
  }

  getSolutions(): Observable<BaseGroupItem[]> {
    return this._privateCompanyService.getSolutionOptionsWithEmptyOption(this.getBusinessFunction());
  }

  getRecommendedPeers(): Observable<Peer[]> {
    return this._privateCompanyService.getRecommendedPeers();
  }

  getParentPeer(): Observable<Peer> {
    return this._privateCompanyDataService.getNotEmptyCompany().pipe(
      map((company) => ({
        uid: company.uid,
        name: company.name,
      }))
    );
  }

  getEntityName(): Observable<string> {
    return this._privateCompanyDataService
      .getNotEmptyCompany()
      .pipe(map((data) => data.name));
  }

  getCompanyPresentationData(): Observable<PrivateCompanyPresentationData> {
    return this._getCompanyPresentationData();
  }

  // eslint-disable-next-line complexity
  sendPresentationData(data: PrivateCompanyPresentationData): Observable<boolean> {
    const backData: PrivateCompanyPresentationBackData = {
      industryRevenueGroupUid: data.industryRevenueGroupUid,
      peerUids: data.peers.map((peer: Peer): string => peer.uid),
      goalUids: data.goalUids,
      estimatedRevenue: data.estimatedRevenue,
    };
    if (data.solutionUid && data.solutionUid !== EMPTY_UID) {
      backData.solutionUid = data.solutionUid;
    }
    if (data.businessFunctionUid && data.businessFunctionUid !== EMPTY_UID) {
      backData.businessFunctionUid = data.businessFunctionUid;
    }
    if (data.metricUids && data.metricUids.length) {
      backData.metricUids = data.metricUids.map(
        (item: IndustryMetric) => item.uid
      );
    }
    if (data.kpiUids) {
      const distinctUids = [ ...new Set(data.kpiUids.map(item => item.uid)) ]
      backData.kpis = distinctUids.map(uid => ({uid}));
    }

    return this._companyPresentationDataService.runPrivateCompaniesPresentationGeneration(
      backData,
      data.companyUid
    );
  }

  getSelectedRankingMetrics(): Observable<IndustryMetric[]> {
    if (!this._selectedRankingMetrics$) {
      this._selectedRankingMetrics$ = combineLatest([
        this.getCompanyIndustry(),
        this.getBusinessFunction(),
      ]).pipe(
        switchMap(([ parentIndustry, businessFunction ]) =>
          this._metricService
            .getExpandedMetrics(
              parentIndustry.uid,
              MetricsType.Trends,
              businessFunction !== EMPTY_UID ? businessFunction : null,
              true,
              true
            )
            .pipe(catchError(() => of([]) as Observable<IndustryMetric[]>))
        ),
        cache()
      );
    }

    return this._selectedRankingMetrics$;
  }

  getSelectedRecommendedMetrics(): Observable<IndustryMetric[]> {
    return this.getSelectedRankingMetrics().pipe(
      map((metrics) =>
        metrics.filter((metric) => metric.isKey && metric.order % 1 === 0)
      )
    );
  }

  getSelectedOtherMetrics(): Observable<IndustryMetric[]> {
    return this.getSelectedRankingMetrics().pipe(
      map((metrics) =>
        metrics.filter((metric) => !metric.isKey || metric.order % 1 !== 0)
      )
    );
  }

  getKpiGroups(): Observable<KpiGroup[]> {
    return combineLatest([
      this._userService.getCurrentUser(),
      this.getCompanyIndustry(),
      this.getBusinessFunction(),
      this.getSolution()
    ]).pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      switchMap(([ user, industry, businessFunctionUid, solutionUid ]: [ User, Industry, UUID, UUID ]) => {
          return this._powerOfOneService
            .getMetricsWithKPIAndOverrides(
              user.uid,
              {industryUid: industry.uid},
              businessFunctionUid === EMPTY_UID ? null : businessFunctionUid,
              solutionUid === EMPTY_UID ? null : [solutionUid]
            )
            .pipe(map(po1MetricsAsKpiGroups))
        }
      )
    )
  }

  clearData(): void {
    this._industryGoals$ = null;
    this._selectedRankingMetrics$ = null;
  }

  private _getCompanyPresentationData(): Observable<PrivateCompanyPresentationData> {
    return combineLatest([
      this._privateCompanyDataService.getNotEmptyCompany(),
      this._privateCompanyDataService.getRevenue(),
      this._privateCompanyService.getPeerFilter(),
      this._privateCompanyService.getSelectedSolution(),
      this._privateCompanyService.getFunctionFilter(),
      this._privateCompanyService.getDefaultPrivateRevenue(),
      this.getPrivateCompanyRevenue(),
      this.getIndustryGoals().pipe(take(1)),
      this.getSelectedRankingMetrics().pipe(take(1))
    ]).pipe(
      map(
        (data) =>
          ({
            peers: data[2],
            companyUid: data[0].uid,
            industryRevenueGroupUid: data[1].uid,
            solutionUid: data[0].industry.category.code === IndustryCode.Healthcare ? EMPTY_UID : data[3],
            businessFunctionUid: data[4],
            defaultRevenue: data[5],
            estimatedRevenue: data[6],
            goalUids: data[7].map((item) => item.uid),
            metricUids: data[8]
          })
      )
    );
  }
}
