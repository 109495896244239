import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import { cache } from '@app/core/operators';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private actualRoute$: Observable<ActivatedRoute>;
  private actualParams$: Observable<Params>;

  constructor(private router: Router) {}

  getActualRoute(): Observable<ActivatedRoute> {
    if (!this.actualRoute$) {
      this.actualRoute$ = this.router.events.pipe(
        startWith(this.router),
        filter(
          event => event instanceof NavigationEnd || event instanceof Router
        ),
        map(() => {
          let route = this.router.routerState.root;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        cache()
      );
    }

    return this.actualRoute$;
  }

  getActualParams(): Observable<Params> {
    if (!this.actualParams$) {
      this.actualParams$ = this.getActualRoute().pipe(
        switchMap(route => route.params),
        cache()
      );
    }

    return this.actualParams$;
  }
}
