import { Inject, Injectable } from '@angular/core';

import { CookieStorage } from '../interfaces/cookie-storage.interface';
import { MemoryStorage } from '../interfaces/memory-storage.interface';
import { SessionStorage } from '../interfaces/session-storage.interface';
import { COOKIE_IN_INCOGNITO } from '../storage.tokens';
import { storageAvailable } from '../utils/storage.util';

/**
 * Browser session storage
 */
@Injectable()
export class BaseSessionStorage implements SessionStorage {
  /**
   * Storage
   */
  private readonly storage: Storage;

  constructor(
    @Inject(COOKIE_IN_INCOGNITO) private _cookieInIncognito: boolean,
    private _cookieStorage: CookieStorage,
    private _memoryStorage: MemoryStorage
  ) {
    if (storageAvailable('sessionStorage')) {
      this.storage = window.sessionStorage;
    } else if (this._cookieInIncognito) {
      this.storage = this._cookieStorage;
    } else {
      this.storage = this._memoryStorage;
    }
  }

  get length(): number {
    return this.storage.length;
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }
}
