import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Currency } from '@app/core/models/currency.model';
import { tuiDefaultProp } from '@taiga-ui/cdk';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class CurrencyInputComponent {
  @Input()
  @tuiDefaultProp()
  isOpened = false;
  @Input() currency: Currency;
  @Input()
  @tuiDefaultProp()
  formView = false;
  @Input() placeholder?: string;

  constructor(public element: ElementRef<HTMLElement>) {}
}
