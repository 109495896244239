import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent implements OnInit {
  @Input() color: 'primary' | 'secondary' = 'primary';
  @HostBinding('class') hostClass = '';

  ngOnInit() {
    this.hostClass = this.color;
  }
}
