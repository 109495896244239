export enum LockState {
  Lock,
  Unlock,

  Cancel,
}
export interface BlockedItem {
  lockCId: string;
  lockUrl: string;
  lockState: LockState;
}

export enum SpinnerTypes {
  Default = 'default',
  Square = 'square',
}