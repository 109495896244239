import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  HistoryFilterType,
  HistoryTheme,
} from '@app/core/models/history.model';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { PopoverRef } from '@app/shared/popover';

@Component({
  selector: 'app-list-popover',
  templateUrl: './list-popover.component.html',
  styleUrls: ['./list-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPopoverComponent {
  @ViewChild('listFiller') listFiller: ElementRef;
  fillerWidth = 0;
  filter: HistoryFilterType = HistoryFilterType.All;
  isFavorites: boolean;
  isRecently: boolean;
  historyTheme = HistoryTheme;

  constructor(
    private popoverRef: PopoverRef<ListPopoverComponent>,
    private _amplitudeService: AmplitudeService
  ) {
    this.isFavorites = !!this.popoverRef.data.isFavorites;
    this.isRecently = !!this.popoverRef.data.isRecently;
  }

  closePopover(): void {
    this.popoverRef.close();
  }

  setFilter(filter: HistoryFilterType) {
    this.filter = filter;

    this._amplitudeService.amplitudeEvent('Favorites View', {
      scope:
        filter === HistoryFilterType.All
          ? 'All Favorites'
          : filter === HistoryFilterType.Industry
          ? 'Industry'
          : 'Company',
    });

    // it is needed to prevent popover from bouncing
    this.lockPopoverWidth();
  }

  private lockPopoverWidth(): void {
    const fillerElement = this.listFiller && this.listFiller.nativeElement;
    const fillerWidth = fillerElement && fillerElement.clientWidth;

    if (fillerWidth && fillerWidth > this.fillerWidth) {
      this.fillerWidth = fillerWidth;
    }
  }
}
