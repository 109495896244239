<button
  type="button"
  tabindex="-1"
  class="learn-basics-button"
  [class.learn-basics-button_opened]="isOpened"
  [class.learn-basics-button_active]="isActive"
  (click)="triggerAmplitude()"
>
  Learning
</button>
