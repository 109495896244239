import { Injectable } from '@angular/core';

import { first, map, Observable } from 'rxjs';
import { CurrencyRepository, ExchangeRatesResponse } from '@app/repositories/common/currency/currency.repository';
import { USD_CURRENCY_UID } from '@app/core/constants';
import { ExchangeRates } from '@app/common/models/exchange-rates.model';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRatesResolver  {

  constructor(
    private currencyRepository: CurrencyRepository,
  ) {}

  resolve(): Observable<ExchangeRates> {

    return this.currencyRepository.getExchangeRates(USD_CURRENCY_UID)
      .pipe(
        first(),
        map((response: ExchangeRatesResponse) => {
          return response.items.reduce((acc, item) => {
            acc[item.toCurrencyUid] = item.rate;
            return acc;
          }, {});
        }),
      );
  }
}
