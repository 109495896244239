import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { Dictionary } from 'lodash';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  private _instance = amplitude.getInstance();

  constructor() {
    this._instance.init(environment.amplitudeKey);
  }

  //todo <T>
  amplitudeEvent(typeEvent: string, data?: Dictionary<unknown>): void {
    this._instance.logEvent(typeEvent, {
      iframe: window.self !== window.top,
      ...data,
    });
  }

  amplitudeUserLogin(email: string): void {
    this._instance.setUserId(email);
  }
}
