import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { catchError, first, map, Observable, of, switchMap } from 'rxjs';

import { HistoryService } from '@app/core/services/history.service';
import {
  HistoryFilterType,
  HistoryItem,
  HistoryTheme,
} from '@app/core/models/history.model';
import { BaseItem } from '@app/core/models/common.model';
import { UserService } from '@app/core/services/user.service';
import { CustomCompanyService } from '@app/core/services/custom-company.service';
import { User } from '@app/core/models/user.model';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent implements OnInit {
  @Input() isFavorites: boolean;
  @Input() isCustomCompanies = false;
  @Input() filter = HistoryFilterType.All;
  @Input() theme = HistoryTheme.Default;
  @Output() navigate = new EventEmitter<void>();

  readonly blockCid = 'HistoryComponent_block';

  list$: Observable<HistoryItem[]>;
  customCompanies$: Observable<BaseItem[]>;

  constructor(
    private historyService: HistoryService,
    private userService: UserService,
    private customCompanyService: CustomCompanyService) {}

  navigateToItem() {
    this.navigate.emit();
  }

  async removeItem(item: HistoryItem) {
    await this.historyService.removeFavorite(item.uid);
  }

  ngOnInit(): void {
    this.list$ = (
      this.isFavorites
        ? this.historyService.getFavorites(this.blockCid)
        : this.historyService.getRecently(this.blockCid)
    ).pipe(
      map((items) =>
        items.filter(
          (item) =>
            item.company != null ||
            item.industry != null ||
            item.subIndustry != null ||
            item.standardIndustrialClassification != null
        )
      )
    );

    this.customCompanies$ = this.getCustomCompanies(this.blockCid)
  }

  private getCustomCompanies(blockCid?: string): Observable<BaseItem[]> {
    return this.userService
      .currentUser$
      .pipe(
        first(),
        switchMap((user: User) =>
          this.customCompanyService.getAddedCompanies(
            user?.uid,
            {
              size: 10,
              index: 0
            },
            [{
              property: 'lastUpdated',
              direction: 'Descending '
            }],
            {
              published: true
            },
            blockCid
          )
        ))
        .pipe(
          map(
            (list) =>
              
              list.items.map((item) => (
                {
                  name: item.name,
                  uid: item.uid
                } 
              ))
        ),
          catchError(() =>
            of([] as BaseItem[])
        ))
  }
}
