import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fnl-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FnlInfoButtonComponent {
  @Input() isColorLight = false;
  @Input() width: number;
  @Input() height: number;

  get iconStyle(): { width: string; height: string } {
    return {
      width: `${this.width || 16}px`,
      height: `${this.height || 16}px`,
    };
  }
}
