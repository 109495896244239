import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlWizardNavigationComponent } from './wizard-navigation.component';
import { FnlWizardStepComponent } from './wizard-step/wizard-step.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FnlWizardNavigationComponent, FnlWizardStepComponent],
  imports: [CommonModule, MatIconModule],
  exports: [FnlWizardNavigationComponent, FnlWizardStepComponent],
})
export class FnlWizardNavigationModule {}
