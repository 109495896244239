import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { Observable, Subscription } from 'rxjs';
import { delay, filter, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  private _isRunning = false;

  constructor(private _router: Router, private _userService: UserService) {}

  get isRunning(): boolean {
    return this._isRunning;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    this._isRunning = true;
    let subscription: Subscription;

    return this._userService.isUserActive().pipe(
      tap((isActive) => {
        this._isRunning = false;

        if (!isActive) {
          subscription = this._router.events
            .pipe(
              filter((ev) => ev instanceof NavigationCancel),
              take(1),
              delay(1)
            )
            .subscribe(() => {
              if (environment.loginRedirect) {
                window.location.replace(
                  `${
                    environment.loginRedirect
                  }?backURL=${window.encodeURIComponent(
                    `${window.location.origin}${state.url}`
                  )}`
                );
              } else {
                this._router.navigateByUrl('/account/sign-in', {
                  replaceUrl: true,
                  state: { redirect: state.url },
                });
              }

              subscription.unsubscribe();
            });
        }

        // this solution commented due angular issue https://github.com/angular/angular/issues/27148
        // return (
        //   isActive ||
        //   this.router.createUrlTree(['account', 'sign-in'], {
        //     replaceUrl: true,
        //     state: { redirect: state.url },
        //   })
        // );
      })
    );
  }
  canLoad(): Observable<boolean> {
    return this._userService.isUserActive();
  }
}
