import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-priority-info-button',
  templateUrl: './priority-info-button.component.html',
  styleUrls: ['./priority-info-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityInfoButtonComponent {
  constructor(public element: ElementRef<HTMLElement>) {}
}
