import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { Currency } from '@app/core/models/currency.model';

@Component({
  selector: 'app-currency-button',
  templateUrl: './currency-button.component.html',
  styleUrls: ['./currency-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class CurrencyButtonComponent {
  @Input() isOpened = false;
  @Input() currency: Currency;
  @Input() placeholder: string;

  constructor(public element: ElementRef<HTMLElement>) {}
}
