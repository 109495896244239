import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  Injector,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  template: '',
})
export abstract class FinAbstractControlComponent<ValueType>
  implements ControlValueAccessor, OnInit, DoCheck
{
  protected _value: ValueType;
  protected _touched = false;
  protected _formControl: FormControl<ValueType>;
  protected _form?: FormGroupDirective;

  constructor(
    protected _injector: Injector,
    protected _cdr: ChangeDetectorRef
  ) {}

  ngDoCheck(): void {
    const newTouched =
      (this._formControl != null && this._formControl.touched) ||
      (this._form != null && this._form.submitted);
    if (newTouched !== this._touched) {
      this._touched = newTouched;
      this._cdr.detectChanges();
    }
  }
  ngOnInit(): void {
    try {
      const ngControl = this._injector.get(NgControl);
      if (ngControl instanceof FormControlName) {
        this._form = this._injector.get(FormGroupDirective);
        this._formControl = this._form.getControl(ngControl);
      } else if (ngControl != null) {
        this._formControl = (ngControl as FormControlDirective)
          .form as FormControl;
      }
    } catch (error) {
      this._formControl = null;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onChange(a: ValueType) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onTouched() {}

  abstract writeValue(obj: any): void;
  abstract setDisabledState?(isDisabled: boolean): void;
}
