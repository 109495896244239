import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AnonymGuard } from '@app/core/guards/anonym.guard';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { UserService } from '@app/core/services/user.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly _anonymUrls: string[] = [
    '/account/sign-in',
    '/account/sign-up',
    '/account/failure',
    '/account/register',
    '/payment-required',
    '/integrations/salesforce/company-mapping',
    '/public'
  ];
  private readonly _paymentUrl: string = '/payment-required';

  constructor(
    private _router: Router,
    private _userService: UserService,
    private _authGuard: AuthGuard,
    private _anonymGuard: AnonymGuard
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 401:
            if (
              this._anonymUrls.find((url: string) => {
                const currentUrl = this._router.url;
                return currentUrl === '/' || currentUrl.includes(url);
              }) == null &&
              !this._authGuard.isRunning &&
              !this._anonymGuard.isRunning
            ) {
              if (environment.loginRedirect) {
                window.location.replace(
                  `${
                    environment.loginRedirect
                  }?backURL=${window.encodeURIComponent(window.location.href)}`
                );
              } else {
                this._router.navigateByUrl('/account/sign-in', {
                  state: { redirect: this._router.url },
                });
              }
            }

            this._userService.changeActive(false);
            break;

          case 402:
            this._router.navigateByUrl(this._paymentUrl);
            break;
        }

        return throwError(err);
      })
    );
  }
}
