import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil, omitBy } from 'lodash';
import { sortBy } from 'lodash/fp';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EMPTY_UID } from '../constants';
import { BaseItem } from '../models/common.model';
import {
  AllSegments,
  AllSegmentsItems,
  AllSegmentsPairs,
  ColorizedSegmentItem,
  Segment,
  SegmentItem,
  SegmentsCategories,
} from '../models/segment.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  private readonly _segmentColors = [
    'chart-blue-light',
    'chart-green',
    'chart-blue',
    'chart-orange',
    'chart-text-green',
    'chart-grey',
    'chart-red',
    'chart-green-dark',
    'chart-yellow',
    'chart-blue1',
    'chart-green-light',
    'chart-violet',
    'chart-blue3',
    'chart-red-light',
    'chart-blue-dark',
    'chart-purple',
    'chart-blue2',
    'chart-orange-dark',
    'chart-violet-dark',
    'chart-brown',
  ];

  constructor(private _apiService: ApiService) {}

  getCompanySegments(): Observable<SegmentsCategories> {
    return of({
      defaultSegmentName: { uid: '0', name: 'All Segments' },
      businessSegments: [
        { uid: '1', name: 'GMNA' },
        { uid: '21', name: 'GMI' },
        { uid: '3', name: 'GM Financial' },
        { uid: '4', name: 'Corporate & Other' },
        { uid: '5', name: 'GM Cruise' },
        { uid: '6', name: 'Eliminations' },
      ],
      geographicSegments: [
        { uid: '7', name: 'United States' },
        { uid: '8', name: 'Other Foreign' },
      ],
    } as SegmentsCategories);
  }

  getDefaultCompanySegment(): Observable<Segment> {
    return this.getCompanySegments().pipe(
      map(({ defaultSegmentName }) => defaultSegmentName)
    );
  }

  getAllCompaniesSegments(
    companyUid: string,
    currencyUid?: string,
    blockCid?: string
  ): Observable<AllSegments> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          companyUid,
          currencyUid,
        },
        isNil
      ),
    });

    return this._apiService
      .get<AllSegments>(
        'company/statistic/segments-data',
        {
          params,
        },
        blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  getAllSegmentsPairs(
    companyUid: string,
    currencyUid?: string,
    blockCid?: string
  ): Observable<AllSegmentsPairs> {
    return this.getAllCompaniesSegments(companyUid, currencyUid, blockCid).pipe(
      map(
        (segments: AllSegments) =>
          ({
            business: segments != null ? segments.businessSegments : [],
            geographic: segments != null ? segments.geographicSegments : [],
          } as AllSegmentsPairs)
      )
    );
  }

  getAllSegmentsItems(
    companyUid: string,
    currencyUid?: string,
    blockCid?: string
  ): Observable<AllSegmentsItems> {
    return this.getAllSegmentsPairs(companyUid, currencyUid, blockCid).pipe(
      map(this.addEmptySegments)
    );
  }

  getColoredSegments(
    companyUid: string,
    blockCid?: string
  ): Observable<{
    businessSegments: ColorizedSegmentItem[];
    geographicSegments: ColorizedSegmentItem[];
  }> {
    return this.getAllCompaniesSegments(companyUid, null, blockCid).pipe(
      map((data: AllSegments) =>
        data
          ? {
              businessSegments: this.colorizeSegmentItems(
                data.businessSegments
              ),
              geographicSegments: this.colorizeSegmentItems(
                data.geographicSegments
              ),
            }
          : {
              businessSegments: [],
              geographicSegments: [],
            }
      )
    );
  }

  addEmptySegments(data: AllSegmentsPairs): AllSegmentsItems {
    return {
      business: this.createEmptySegments('Business', data.business),
      geographic: this.createEmptySegments('Geographic', data.geographic),
    };
  }

  createEmptySegments(
    segmentName: string,
    items: Array<SegmentItem>
  ): Array<BaseItem> {
    return [
      {
        uid: EMPTY_UID,
        name: `All ${segmentName} Segments`,
      },
    ].concat(
      sortBy(
        (item: BaseItem) => item.name.toLowerCase(),
        items.map((item: SegmentItem) => ({
          uid: item.segmentUid,
          name: item.segmentName,
        }))
      )
    );
  }

  colorizeSegmentItems(items: SegmentItem[]): ColorizedSegmentItem[] {
    const results = sortBy(
      (item: SegmentItem) => item.revenueContribution || 0,
      items
    )
      .reverse()
      .map(
        (item: SegmentItem, index: number) =>
          ({
            uid: item.segmentUid,
            segmentName: item.segmentName,
            shareOfTotalRevenue:
              item.revenueContribution != null
                ? item.revenueContribution * 100
                : null,
            profitMargin:
              item.profitMargin != null ? item.profitMargin * 100 : null,
            color: this._segmentColors[index],
          } as ColorizedSegmentItem)
      );
    return results.some(
      (value: ColorizedSegmentItem) =>
        value.profitMargin > 0 || value.shareOfTotalRevenue > 0
    )
      ? results
      : [];
  }
}
