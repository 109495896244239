import { FormControl } from '@angular/forms';

/**
 * Stringified UUID.
 *
 * @see [RFC 4112](https://tools.ietf.org/html/rfc4122)
 * @pattern [0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}
 * @example "52907745-7672-470e-a803-a2f8feb52944"
 */
export type UUID = string;

export enum LayoutType {
  Anonym,
  Base,
  Special,
  /** Layout for the public part of the application */
  Public,
}

export interface BaseItem {
  uid: string;
  name: string;
}

export function byNameAscending(a: { name: string }, b: { name: string }): number {
  return a.name.localeCompare(b.name);
}

export interface BaseGroupItem<T = BaseItem> extends BaseItem {
  items: T[];
}

export type EditorType = 'add' | 'edit';

export interface Attachment {
  uid: string;
  fileName?: string;
  temporary?: boolean;
  uploadDate?: string;
  uploader?: string;
  contentType?: string;
}

export type FileIcons =
  | 'file-pdf'
  | 'file-ppt'
  | 'exclefile'
  | 'description'
  | 'file-csv';

export interface CustomAndVisible {
  custom?: boolean;
  visible?: boolean;
}

export interface Link {
  uri: string;
  text?: string;
}

export enum PeriodView {
  Millions,
  Thousands,
}

export const PERIODS_LIST: BaseItem[] = [
  {
    uid: PeriodView.Millions.toString(),
    name: 'Millions',
  },
  {
    uid: PeriodView.Thousands.toString(),
    name: 'Thousands',
  },
];

export type AdministrationTokenKeys = 'system' | 'company';
export type MainEntityTokenKeys = 'company' | 'industry' | 'private-company';

export interface BaseList<T> {
  items: T[];
  totalCount?: number;
}

export interface BaseValue<T = number> {
  uid: string;
  value: T;
}

export type FormTypeModifier<T> = {
  [Property in keyof T]: FormControl<T[Property]>;
};

export enum FeatureStates {
  ValueModeler = 'Value modeler',
}

export type WordForms = {
  singular: string,
  plural: string
}