import { AfterViewInit, ContentChildren, Directive, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormControlDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FnlSelectGroupOptionDirective } from './fnl-select-group-option.directive';
import { FnlSelectOptionDirective } from './fnl-select-option.directive';

const SELECT_ALL_GROUP_VALUE = 'selectAll';

@UntilDestroy()
@Directive({
  selector: '[formControl][fnlSelect]'
})
export class FnlSelectDirective implements OnInit, AfterViewInit {

  @ContentChildren(FnlSelectGroupOptionDirective) selectGroupOptionDirectives: FnlSelectGroupOptionDirective[];
  @ContentChildren(FnlSelectOptionDirective) selectOptionDirectives: FnlSelectOptionDirective[];

  /**
   * The name of the property to be used as id in case when the option value is an object.
   */
  @Input() bind: string;

  formControlDirective: FormControlDirective;
  formControl: FormControl;

  constructor() {

    try {
      this.formControlDirective = inject(FormControlDirective);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('FnlSelectDirective: formControlDirective is required. Please add it to the host element. Example: <mat-select fnlLegacySelect [formControl]="control">');
    }
  }

  ngOnInit(): void {
    this.formControl = this.formControlDirective.form;
  }

  ngAfterViewInit(): void {
    this.selectGroupOptionDirectives?.forEach((selectGroupOptionDirective) => {
      const selectGroupOption = selectGroupOptionDirective?.host;

      if (selectGroupOption) {
        if (!selectGroupOption.value && this.selectGroupOptionDirectives.length > 1) {
          // eslint-disable-next-line no-console
          console.error('FnlSelectDirective: selectAllOptionDirective must have a value. Example: <mat-option fnlLegacySelectGroupOption [value]="selectAll">');
        }

        if (!selectGroupOption.value && this.selectGroupOptionDirectives.length === 1) {
          selectGroupOption.value = SELECT_ALL_GROUP_VALUE;
        }

        selectGroupOption.onSelectionChange
          .pipe(
            untilDestroyed(this),
            filter(change => change.isUserInput),
            tap((change) => {
              if (change.source.selected) {
                const alreadySelectedValues = this.formControl.value || [];
                const valueToAdd = this.bind
                  ? selectGroupOptionDirective.valuesToSelect
                    .filter(v => !alreadySelectedValues.some(selected => selected[this.bind] === v[this.bind]))
                  : selectGroupOptionDirective.valuesToSelect;

                this.formControl.setValue([
                  selectGroupOption.value,
                  ...valueToAdd,
                  ...alreadySelectedValues,
                ]);
              } else {
                const valueToSet = this.formControl.value
                  .filter((v) => v !== selectGroupOption.value && !selectGroupOptionDirective.valuesToSelect.includes(v));

                this.formControl.setValue(valueToSet);
              }
            }),
          )
          .subscribe();
      }

    });

    const directivesGroups = this.selectOptionDirectives.reduce((acc, directive) => {

      if (!directive.groupValue && this.selectGroupOptionDirectives.length > 1) {
        // eslint-disable-next-line no-console
        console.error('FnlSelectDirective: selectOptionDirective must have a groupValue. Example: <mat-option fnlLegacySelectOption [groupValue]="group.value">');
      }

      if (!directive.groupValue && this.selectGroupOptionDirectives.length === 1) {
        directive.groupValue = SELECT_ALL_GROUP_VALUE;
      }

      const groupKey = String(directive.groupValue);
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(directive);
      return acc;

    }, {} as Record<string, FnlSelectOptionDirective[]>);

    this.selectOptionDirectives?.forEach((selectOptionDirective) => {
      selectOptionDirective.host.onSelectionChange
        .pipe(
          untilDestroyed(this),
          filter(change => change.isUserInput),
          tap((change) => {
            const groupDirective = this.selectGroupOptionDirectives
              .find(gd => gd.host.value === selectOptionDirective.groupValue);
            const host = groupDirective?.host;

            const allElementsSelectedInGroup = directivesGroups[String(selectOptionDirective.groupValue)].every(d => d.host.selected);

            if (change.source.selected && allElementsSelectedInGroup) {
              host.select();
            } else if (!change.source.selected && !allElementsSelectedInGroup) {
              host.deselect();
            }
          }),
        )
        .subscribe();
    });
  }
}
