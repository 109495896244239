import {
  ChangeDetectionStrategy,
  Component,
  ElementRef, Input,
  OnDestroy,
} from '@angular/core';
import { PopoverRef, PopoverService } from '@app/shared/popover';
import { LearnBasicsPopoverComponent } from './learn-basics-popover/learn-basics-popover.component';

@Component({
  selector: 'app-learn-basics',
  templateUrl: './learn-basics.component.html',
  styleUrls: ['./learn-basics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnBasicsComponent implements OnDestroy {
  @Input() isActive = false;
  private _popoverRef: PopoverRef<LearnBasicsPopoverComponent>;

  constructor(
    private elRef: ElementRef,
    private _popoverService: PopoverService
  ) {}

  ngOnDestroy(): void {
    if (this._popoverRef != null) {
      this._popoverRef.close();
    }
  }

  openPopover(): void {
    if (this._popoverRef != null) {
      return;
    }

    this._popoverRef = this._popoverService.open(
      LearnBasicsPopoverComponent,
      this.elRef.nativeElement,
      {
        positions: [
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top',
            offsetX: -16,
            offsetY: this.elRef.nativeElement.getBoundingClientRect().height + 12,
          },
        ],
      }
    );

    this._popoverRef.afterClosed$.subscribe(() => {
      this._popoverRef = null;
    });
  }
}
