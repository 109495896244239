<button
  *ngIf="isBackToTopShown"
  i18n-aria-label
  type="button"
  class="back-to-top__button"
  aria-label="Back to top of page"
  (click)="scrollTop()"
  @appearInOut
>
  <mat-icon class="back-to-top__icon" svgIcon="back-to-top"></mat-icon>
</button>
