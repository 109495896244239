<div *ngIf="display" class="cookies-message" @notificationAnimation>
  <p class="cookies-message__text">
    This site uses cookies to offer you the best experience. By continuing to
    use our site you consent to the use of cookies.
  </p>
  <button
    class="cookies-message__button"
    mat-stroked-button
    (click)="closeMessage()"
  >
    OK
  </button>
</div>
