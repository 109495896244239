import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { FaqArticleComponent, FaqContentComponent } from './faq-content';
import {
  HelpContentComponent,
  HelpContentFormComponent,
  HelpContentSuccessComponent,
} from './help-content';
import { SidePanelListComponent } from './side-panel-list.component';
import { SidePanelListService } from './side-panel-list.service';
import { SidePanelComponent } from './side-panel/side-panel.component';

@NgModule({
  declarations: [
    SidePanelComponent,
    SidePanelListComponent,
    FaqContentComponent,
    HelpContentComponent,
    HelpContentFormComponent,
    HelpContentSuccessComponent,
    FaqArticleComponent,
  ],
  imports: [SharedModule],
  exports: [SidePanelListComponent],
  providers: [SidePanelListService],
})
export class SidePanelModule {}
