import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  ViewChild,
} from '@angular/core';
import { BaseItem } from '@app/core/models/common.model';
import { trackByUid } from '@app/core/utils/base';
import { PopoverRef } from '@app/shared/popover';
import { MoreActionsButtonComponent } from '../more-actions-button/more-actions-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-more-actions-popover',
  templateUrl: './more-actions-popover.component.html',
  styleUrls: ['./more-actions-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MoreActionsButtonComponent
  ]
})
export class MoreActionsPopoverComponent {
  @ViewChild('handle', { read: ElementRef }) handleRef: ElementRef;
  @ViewChild('content') contentRef: ElementRef<HTMLElement>;
  @HostBinding('class.more-actions-popover') popover = true;
  actions: BaseItem[];

  trackByUid = trackByUid;

  constructor(private _popoverRef: PopoverRef<MoreActionsPopoverComponent>) {
    this.actions = this._popoverRef.data.actions as BaseItem[];
  }

  closePopover() {
    this._popoverRef.close();
  }

  callAction(action: BaseItem) {
    this._popoverRef.close(action);
  }
}
