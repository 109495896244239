import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BaseItem } from '@app/core/models/common.model';
import { tuiDefaultProp } from '@taiga-ui/cdk';

@Component({
  selector: 'app-base-selector-button',
  templateUrl: './base-selector-button.component.html',
  styleUrls: ['./base-selector-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class BaseSelectorButtonComponent {
  @Input()
  @tuiDefaultProp()
  isOpened = false;
  @Input() placeholder: string;
  @Input() selected?: BaseItem;
  @Input()
  @tuiDefaultProp()
  disabled = false;
  @Input()
  @tuiDefaultProp()
  focusable = false;

  @ViewChild('button')
  private buttonRef: ElementRef<HTMLElement>;

  constructor(public element: ElementRef<HTMLElement>) {}

  focus(): void {
    this.buttonRef.nativeElement.focus();
  }
  blur(): void {
    this.buttonRef.nativeElement.blur();
  }
}
