<app-side-panel [title]="'HELP'" panel="faq" icon="info_outline">
  <ng-template>
    <div class="faq-content" *tuiLet="articles$ | async as articles">
      <h3 class="faq-content__heading">HELP</h3>
      <div class="faq-content__articles">
        <app-faq-article
          *ngFor="let article of articles; index as i; trackBy: trackArticle"
          [data]="article"
          [index]="i + 1"
        ></app-faq-article>
      </div>
      <app-help-content class="faq-content__ask"></app-help-content>
    </div>
  </ng-template>
</app-side-panel>
