import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import {
  NotificationContainerExtended,
  NotificationsService,
  NotificationType,
} from '@app/core/services/notifications.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { compareAsc } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-notifications-queue',
  templateUrl: './notifications-queue.component.html',
  styleUrls: ['./notifications-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('appearNotification', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('200ms', style({ opacity: 0, transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class NotificationsQueueComponent implements OnInit {
  @HostBinding('class.notifications-queue') isErrorQueue = true;
  @HostBinding('class.notifications-queue_top') isTop = false;

  orderedNotifications$: Observable<NotificationContainerExtended[]>;

  constructor(private _notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.orderedNotifications$ = this._notificationsService
      .getNotificationsList()
      .pipe(
        map((errors: NotificationContainerExtended[]) =>
          errors.sort(
            (
              a: NotificationContainerExtended,
              b: NotificationContainerExtended
            ) => compareAsc(a.createDate, b.createDate)
          )
        )
      );

    this._notificationsService
      .getNotificationsList()
      .pipe(
        map(
          (errors: NotificationContainerExtended[]) =>
            errors.find((item) => item.top) != null
        ),
        untilDestroyed(this)
      )
      .subscribe((hasTop) => {
        this.isTop = hasTop;
      });
  }

  trackById(index: number, error: any): string {
    return error.id;
  }

  removeOne(notification: NotificationContainerExtended): void {
    this._notificationsService.removeNotification(notification.id);
  }

  isError(notificationType: NotificationType): boolean {
    return notificationType === NotificationType.Error;
  }
  isSuccess(notificationType: NotificationType): boolean {
    return notificationType === NotificationType.Success;
  }
  isWarning(notificationType: NotificationType): boolean {
    return notificationType === NotificationType.Warning;
  }
  getIcon(notificationType: NotificationType): string {
    switch (notificationType) {
      case NotificationType.Error:
        return 'warn';
      case NotificationType.Success:
        return 'check-circle';
      case NotificationType.Warning:
        return 'info_outline';
    }
  }
}
