<div *ngIf="notification" class="notice-bar-wrapper" [ngClass]="getTypeClass(notification.type)">
  <div class="notice-bar text-body1">
    <p class="notice-bar-message" [title]="notification.content">
      {{ notification.content }}
    </p>
    <a
      *ngIf="notification.buttonEnabled"
      rel="noreferrer noopener"
      [href]="notification.buttonLink"
      target="_blank"
      mat-stroked-button
      color
      class="notice-bar-button"
      [attr.data-testid]="'notification-button'"
    >
      {{ notification.buttonText }}
    </a>
  </div>

  <app-icon-button class="notice-bar-close">
    <mat-icon
      class="notice-bar-close-icon"
      svgIcon="close"
      (click)="closeNoticeBar()"
      [attr.data-testid]="'close-button'"
    ></mat-icon>
  </app-icon-button>
</div>
