<ng-template #placeholderContent>
  <div class="info-placeholder">
    <p class="info-placeholder__paragraph">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </p>
    <p class="info-placeholder__paragraph">
      Aenean in quam est. In fringilla mauris id dolor fringilla commodo
    </p>
    <div class="info-placeholder__formula">
      <div class="info-placeholder__formula-numerator">
        (Quisque rhoncus egestas - Sed eu fermentum neque)
      </div>
      <div class="info-placeholder__formula-denumerator">
        Quisque rhoncus egestas
      </div>
    </div>
    <div class="info-placeholder__footer">
      <a class="info-placeholder__footer-link">
        <mat-icon
          class="info-placeholder__footer-link-icon"
          svgIcon="video"
        ></mat-icon>
        <span>Maecenas vel sapien mattis!</span>
      </a>
    </div>
  </div>
</ng-template>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<fnl-icon-button
    #tooltip="matTooltip"
    matTooltipClass="mat-tooltip-info"
    class="insight__title__container-icon"
    matTooltip="info"
    [isRippleSmall]="true"
>
  <div class="info-popover"
       #infoContainer>
    <fnl-info-button
      [width]="width"
      [height]="height"
      class="info-popover__button"
      (click)="tooltip.hide(); openInfoPopover(infoContainer)"
      [isColorLight]="isColorLight"
    ></fnl-info-button>
  </div>
</fnl-icon-button>
