import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { User } from '@app/core/models/user.model';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { UserService } from '@app/core/services/user.service';
import { PopoverRef } from '@app/shared/popover';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfilePopoverComponent } from '../../profile-menu';

@Component({
  selector: 'app-learn-basics-popover',
  templateUrl: './learn-basics-popover.component.html',
  styleUrls: ['./learn-basics-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnBasicsPopoverComponent implements OnInit, AfterViewInit {
  @ViewChild('handle', { read: ElementRef })
  handleRef: ElementRef;
  @ViewChild('content') contentRef: ElementRef<HTMLElement>;

  readonly defaultAllegoLink = environment.allego;
  readonly clientAllegoDictionary = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    IBM: 'https://finlistics.gitbook.io/client-business-value/',
  };

  isFullWidth = false;
  containerWidth = 0;

  allegoLink$: Observable<string>;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _popoverRef: PopoverRef<ProfilePopoverComponent>,
    private _amplitudeService: AmplitudeService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.allegoLink$ = this._userService
      .getCurrentUser()
      .pipe(
        map(
          (user: User) =>
            this.clientAllegoDictionary[user.clientCode] ||
            this.defaultAllegoLink
        )
      );
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const handleWidth =
        this.handleRef.nativeElement.getBoundingClientRect().width;
      const listWidth =
        this.contentRef.nativeElement.getBoundingClientRect().width;
      this.isFullWidth = handleWidth >= listWidth;
      this.containerWidth = this.isFullWidth
        ? handleWidth
        : handleWidth >= listWidth - 8
        ? handleWidth + 8
        : 0;
      this._cdr.markForCheck();
    });
  }

  closePopover(): void {
    this._popoverRef.close();
  }

  amplitudeEventTracking(nameEvent: string): void {
    this._amplitudeService.amplitudeEvent(nameEvent);
    this.closePopover();
  }
}
