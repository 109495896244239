import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  PortalInjector,
} from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { PopoverConfig } from './popover-config';
import { PopoverRef } from './popover-ref';
import { FnlPopoverComponent } from './popover.component';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  private readonly _positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'top',
    },
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'bottom',
    },
  ];

  constructor(private _overlay: Overlay, private _injector: Injector) {}

  open<T, D = any>(
    componentRef: ComponentType<T>,
    element: HTMLElement,
    config: PopoverConfig = {}
  ): PopoverRef<T, D> {
    const overlayRef = this.createOverlay(element, config);
    const popoverRef = new PopoverRef(overlayRef, componentRef, config.data);

    const injector = this.createInjector(popoverRef);
    const popoverPortal = new ComponentPortal(
      FnlPopoverComponent,
      null,
      injector
    );
    const popoverComponentRef = overlayRef.attach(popoverPortal);
    popoverRef.setComponentInstance(popoverComponentRef.instance);

    return popoverRef;
  }

  private createOverlay(
    element: HTMLElement,
    config: PopoverConfig
  ): OverlayRef {
    const strategy = this._overlay
      .position()
      .flexibleConnectedTo(element)
      .withPositions(config.positions || this._positions)
      .withPush(false);
    const overlayConfig = new OverlayConfig({
      positionStrategy: strategy,
      width: config.width,
      height: config.height,
      hasBackdrop: false,
      scrollStrategy: config.scroll || this._overlay.scrollStrategies.close(),
    });
    return this._overlay.create(overlayConfig);
  }

  private createInjector<T, D = any>(
    panelRef: PopoverRef<T, D>
  ): PortalInjector {
    const injectionTokens = new WeakMap([[PopoverRef, panelRef]]);

    return new PortalInjector(this._injector, injectionTokens);
  }
}
