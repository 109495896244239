import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { PopoverComponent } from './popover.component';

export class PopoverRef<T, D = any> {
  private _componentInstance: PopoverComponent<any>;
  private _beforeClosed = new Subject<any>();
  private _afterClosed = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly beforeClosed$ = this._beforeClosed.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly afterClosed$ = this._afterClosed.asObservable();

  private _closeSubscription: Subscription;
  private _closeData: any;

  constructor(
    public overlayRef: OverlayRef,
    public content: ComponentType<T>,
    public data: D
  ) {}

  beforeClose(data: any) {
    this._beforeClosed.next(data);
  }

  close(data?: any): void {
    this._closeData = data;
    this._componentInstance.startExitAnimation();
  }

  setComponentInstance(instance: PopoverComponent<any>): void {
    if (this._closeSubscription != null) {
      this._closeSubscription.unsubscribe();
    }
    this._componentInstance = instance;

    this._closeSubscription = this._componentInstance.animationStateChanged
      .pipe(
        filter(
          (event: AnimationEvent) =>
            event.phaseName === 'done' &&
            (event.toState === 'exit' || event.toState === 'void')
        ),
        take(1)
      )
      .subscribe(() => {
        this.overlayRef.dispose();
        this._afterClosed.next(this._closeData);
        this._beforeClosed.complete();
        this._afterClosed.complete();
      });
  }
}
