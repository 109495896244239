import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { isArray } from 'lodash';
import { BlockedItem, LockState, SpinnerTypes } from './blocker.model';
import { FnlUiBlockerService } from './blocker.service';

/**
 * Component for disabling interface elements
 */
@Component({
  selector: 'fnl-ui-blocker',
  templateUrl: './fnl-ui-blocker.component.html',
  styleUrls: ['./fnl-ui-blocker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FnlUiBlockerComponent implements OnChanges, OnDestroy {
  @Input() cId?: string | string[];
  @Input() blocking?: boolean;
  @Input() postpone = true;
  @Input() postponeTime = 10;
  @Input() spinnerStyle?: string = SpinnerTypes.Default;
  @Input() spinnerText?: string;

  @HostBinding('class.blocked-ui_small')
  @Input()
  smallLock = false;

  @ViewChild('topFocus') topFocus: ElementRef<HTMLDivElement>;
  @ViewChild('blocker') blocker: ElementRef<HTMLDivElement>;
  @ViewChild('helper', { static: true }) helper: ElementRef<HTMLSpanElement>;

  spinnerTypes = SpinnerTypes;

  private focused: HTMLElement;
  private serviceBlockedCount = 0;
  private subscription: Subscription;
  private postponedQueue: BlockedItem[] = [];
  private queueRunnerTimer: ReturnType<typeof setTimeout>;

  constructor(
    private blockerService: FnlUiBlockerService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription = this.blockerService.chanel$.subscribe(
      (value: BlockedItem) => {
        if (this.postpone) {
          this.postponedQueue.push(value);

          if (this.queueRunnerTimer) {
            clearTimeout(this.queueRunnerTimer);
          }
          this.queueRunnerTimer = setTimeout(
            () => this.queueUpdateBlockRunner(),
            this.postponeTime
          );
        } else {
          this.updateBlock(value);
        }
      }
    );
  }

  @HostBinding('attr.aria-busy')
  @HostBinding('class.blocked-ui')
  get isBlocking(): boolean {
    return this.blocking || this.serviceBlockedCount !== 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.blocking &&
      changes.blocking.currentValue !== changes.blocking.previousValue
    ) {
      this.focusOut(this.isBlocking);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  tabbedUpTop(event: Event): void {
    if (this.blocking) {
      this.blocker.nativeElement.focus();
    }
  }

  tabbedDownTop(event: Event): void {
    if (this.blocking) {
      event.preventDefault();
      this.blocker.nativeElement.focus();
    }
  }

  tabbedUpBottom(event: Event): void {
    if (this.blocking) {
      this.topFocus.nativeElement.focus();
    }
  }

  tabbedDownBottom(event: Event): void {
    if (this.blocking) {
      event.preventDefault();
      this.topFocus.nativeElement.focus();
    }
  }

  /**
   * Get the current active element safely.
   * Ref: https://gist.github.com/Alex1990/046a6553dc83e22dd6f4
   */
  private safeActiveElement(doc?: HTMLDocument): Element {
    doc = doc || document;
    let activeElement: Element;

    // Support: IE 9 only
    // IE9 throws an "Unspecified error" accessing document.activeElement from an <iframe>
    // Support: IE 9 - 11 only
    // IE may return null instead of an element
    // Interestingly, this only seems to occur when NOT in an iframe
    // Support: IE 11 only
    // IE11 returns a seemingly empty object in some cases when accessing
    // document.activeElement from an <iframe>
    try {
      activeElement = doc.activeElement;
      if (!activeElement || !activeElement.nodeName) {
        activeElement = doc.body;
      }
    } catch (error) {
      activeElement = doc.body;
    }

    return activeElement;
  }

  private checkIfElementInViewport(element: Element) {
    const bounding = element.getBoundingClientRect();

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  private focusOut(isBlocked: boolean) {
    if (isBlocked) {
      if (
        this.helper.nativeElement &&
        this.helper.nativeElement.parentNode &&
        this.helper.nativeElement.parentNode.contains &&
        this.helper.nativeElement.parentNode.contains(this.safeActiveElement())
      ) {
        this.focused = this.safeActiveElement() as HTMLElement;
        if (this.focused && this.focused !== document.body) {
          setTimeout(
            () =>
              this.focused &&
              typeof this.focused.blur === 'function' &&
              this.focused.blur()
          );
        }
      }
    } else {
      const ae = this.safeActiveElement();

      if (
        this.focused &&
        (!ae || ae === document.body || ae === this.topFocus.nativeElement)
      ) {
        if (
          typeof this.focused.focus === 'function' &&
          this.checkIfElementInViewport(this.focused)
        ) {
          this.focused.focus();
        }
        this.focused = null;
      }
    }
  }

  private updateBlock(value: BlockedItem): void {
    const cIdsToSearch = isArray(this.cId) ? this.cId : [this.cId];

    if (cIdsToSearch.includes(value.lockCId)) {
      const prevBlocked = this.isBlocking;
      this.serviceBlockedCount += value.lockState === LockState.Lock ? 1 : -1;
      if (this.serviceBlockedCount < 0) {
        this.serviceBlockedCount = 0;
      }
      const newBlocked = this.isBlocking;

      this.cdr.markForCheck();

      if (newBlocked !== prevBlocked) {
        this.focusOut(newBlocked);
      }
    }
  }

  private queueUpdateBlockRunner(): void {
    const cIdsToSearch = isArray(this.cId) ? this.cId : [this.cId];
    let shiftedValue: BlockedItem = null;
    let runCheckOut = false;
    let currentCheckOut = false;
    const prevBlocked = this.isBlocking;

    while (this.postponedQueue.length !== 0) {
      shiftedValue = this.postponedQueue.shift();

      currentCheckOut = cIdsToSearch.includes(shiftedValue.lockCId);

      if (currentCheckOut) {
        runCheckOut = true;
        this.serviceBlockedCount +=
          shiftedValue.lockState === LockState.Lock ? 1 : -1;
        if (this.serviceBlockedCount < 0) {
          this.serviceBlockedCount = 0;
        }
      }
    }

    const newBlocked = this.isBlocking;

    if (runCheckOut) {
      this.cdr.markForCheck();

      if (newBlocked !== prevBlocked) {
        this.focusOut(newBlocked);
      }
    }
  }
}
