import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { Currency } from '@app/core/models/currency.model';
import { trackByUid } from '@app/core/utils/base';

@Component({
  selector: 'app-currency-list',
  templateUrl: './currency-list.component.html',
  styleUrls: ['./currency-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class CurrencyListComponent {
  @Input() list: Currency[];
  @Output() update = new EventEmitter<Currency>();
  trackByUid = trackByUid;

  constructor(private domSanitizer: DomSanitizer) {}

  flagSrc(currency: Currency): SafeStyle {
    const flagCode = currency.code.slice(0, 2).toLowerCase();
    const src = `url(/assets/images/flags/${flagCode}.svg)`;
    return this.domSanitizer.bypassSecurityTrustStyle(src);
  }

  onClick(currency: Currency) {
    this.update.emit(currency);
  }
}
