import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '@app/core/services/user.service';
import { User, UserRole } from '@app/core/models/user.model';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomePageSelectorGuard  {

  constructor(
    private _router: Router,
    private _userService: UserService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._userService.getCurrentUser()
    .pipe(
      map(user => {
          if (!user) {
            return this._redirectToLogin();
          }

          return this._router.createUrlTree(['dashboard'])
        }
      )
    );
  }

  private _redirectToLogin(): boolean | UrlTree {
    if (environment.loginRedirect) {
      window.location.replace(
        `${environment.loginRedirect}?backURL=${window.encodeURIComponent(window.location.href)}`
      );
      return false;
    } else {
      this._router.createUrlTree(['account', 'sign-in'], {
        queryParams: {redirect: this._router.url},
      });
    }
  }

}
