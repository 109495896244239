<div class="not-found__container">
  <div class="not-found__placeholder">
    <h1 class="not-found__head">404</h1>
    <h3 class="not-found__subhead">Oops, page not found</h3>
  </div>
  <div class="not-found__message">
    The page you are looking for no longer exists.<br />
    Perhaps you can return back to the site's home page and see if you can find
    what you are looking for.
  </div>
</div>
