/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Currency } from '@app/core/models/currency.model';
import { PopoverRef } from '@app/shared/popover';
import { isBoolean, xorBy } from 'lodash';
import { POPULAR_CURRENCY_CODES } from '@app/common/constants/popular-currencies';
import { CurrencyInputComponent } from '../currency-input/currency-input.component';
import { FilterCurrenciesPipe } from './filter-currencies.pipe';
import { CurrencyListComponent } from '../currency-list/currency-list.component';
import { FieldSearchComponent } from '@app/shared/field-search/field-search.component';
import { CurrencyButtonComponent } from '../currency-button/currency-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-currency-popover',
  templateUrl: './currency-popover.component.html',
  styleUrls: [ './currency-popover.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CurrencyInputComponent,
    FilterCurrenciesPipe,
    CurrencyListComponent,
    FieldSearchComponent,
    CurrencyButtonComponent,
  ]
})
export class CurrencyPopoverComponent implements AfterViewInit {
  @ViewChild('handle', { read: ElementRef }) handleRef: ElementRef;
  @ViewChild('content') contentRef: ElementRef<HTMLElement>;

  favoriteCurrencies: Currency[];
  nonFavoriteCurrencies: Currency[];

  currency: Currency;
  filter = '';
  inputLike: 'none' | 'base' | 'form';
  placeholder: string;
  isFullWidth = false;
  width: number;
  containerWidth = 0;

  private readonly _allowedPopularCurrencies = this.convertCurrenciesToObject(POPULAR_CURRENCY_CODES);

  private _allCurrencies: Currency[];

  constructor(
    private _popoverRef: PopoverRef<CurrencyPopoverComponent>,
    private _cdr: ChangeDetectorRef
  ) {
    this._allCurrencies = this._popoverRef.data.allCurrencies;
    this.favoriteCurrencies = this._getPopularCurrencies(this._allCurrencies);
    this.nonFavoriteCurrencies = xorBy(
      this._allCurrencies,
      this.favoriteCurrencies
    );

    this.currency = this._popoverRef.data.currency;
    this.inputLike = this._popoverRef.data.inputLike;
    this.placeholder = this._popoverRef.data.placeholder;
    this.width = this._popoverRef.data.width;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const handleElement = this.handleRef.nativeElement;
      const listElement = this.contentRef.nativeElement;
      const handleWidth = handleElement.getBoundingClientRect().width;
      const listWidth = listElement.getBoundingClientRect().width;
      this.isFullWidth = handleWidth >= listWidth;
      this.containerWidth = this.isFullWidth
        ? handleWidth
        : handleWidth >= listWidth - 12
          ? handleWidth + 12
          : 0;
      this._cdr.markForCheck();
    });
  }

  closePopover(): void {
    this._popoverRef.close();
  }

  updateSelectedCurrency(currency: Currency): void {
    this._popoverRef.close(currency);
  }

  updateFilter(filter: string): void {
    this.filter = filter;
  }

  private _getPopularCurrencies(currencies: Currency[]): Currency[] {
    const popularNumber = Object.keys(this._allowedPopularCurrencies).length;
    const popular = new Array<Currency>(popularNumber);
    currencies.forEach((currency) => {
      const normalizedCode = currency.code.toUpperCase();
      const currencyIndex = this._allowedPopularCurrencies[normalizedCode];

      if (currencyIndex !== undefined) {
        popular[currencyIndex] = currency;
      }
    });
    return popular;
  }

  private convertCurrenciesToObject(currencies: string[]): Record<string, number> {
    const convertedObject = {};

    for (let i = 0; i < currencies.length; i++) {
      convertedObject[currencies[i]] = i;
    }

    return convertedObject;
  }
}
