import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';

import { UUID } from '@app/core/models/common.model';
import {
  ClientAdministratorsResponseApi
} from '@app/api/identity/client-user-management/models/client-administrators-api.model';


@Injectable({
  providedIn: 'root'
})
export class ClientUserManagementApiService {

  constructor(
    private apiService: ApiService,
  ) { }

  getClientAdministrators(clientUid: UUID, blockCid: UUID): Observable<ClientAdministratorsResponseApi> {
    return this.apiService.get<ClientAdministratorsResponseApi>(`identity/clients/${ clientUid }/administrators`, blockCid);
  }
}
