<app-priority-info-button
  #handle
  class="priority-info-popover__handle"
  (click)="closePopover()"
></app-priority-info-button>
<div #content class="priority-info-popover__content">
  <div class="priority-info-popover__text">
    Priority score is based on two factors - a performance score based on trend,
    peers and industry performance gap values and a Power of One score, which is
    the relative weight of the Power of One values for each metric. For example,
    a metric that has high performance gap and a high Power of One value will
    result in high priority. Note that revenue growth is always a high priority.
  </div>
</div>
