<div class="size-options-popover">
  <div class="size-options-popover__items">
    <div
      *ngFor="let size of sizeOptions"
      class="size-options-popover__item"
      [ngClass]="{ 'size-options-popover__item_selected': isSelected(size) }"
      (click)="changeSize(size)"
    >
      <div
        *ngIf="isSelected(size)"
        i18n
        class="size-options-popover__item-label"
      >
        Rows per page:
      </div>

      <div class="size-options-popover__item-value">
        {{ size }}
      </div>

      <mat-icon
        *ngIf="isSelected(size)"
        svgIcon="paginate_down"
        class="size-options-popover__item-arrow"
      ></mat-icon>
    </div>
  </div>
</div>
