import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlockerService } from '@app/core/services/blocker.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

@Injectable()
export class CancelLockInterceptor implements HttpInterceptor {
  constructor(private _blockerService: BlockerService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;

    this._blockerService.approveUrlLock(request.url);

    return next.handle(request).pipe(
      tap((response: HttpEvent<any>) => {
        lastResponse = response;
      }),
      catchError((err: HttpErrorResponse) => {
        error = err;
        if (err) {
          this._blockerService.removeLockByUrl(request.url);
        }

        return throwError(err);
      }),
      finalize(() => {
        if (lastResponse.type === HttpEventType.Sent && !error) {
          this._blockerService.removeLockByUrl(request.url);
        }
      })
    );
  }
}
