import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, map, firstValueFrom, switchMap, first, of } from 'rxjs';

import { LayoutType } from './core/models/common.model';
import { UserService } from '@app/core/services/user.service';
import { CompanyPresentationDataService } from '@app/core/services/company-presentation-data.service';
import { User } from '@app/core/models/user.model';
import { UserNotification } from './core/models/notification.model';
import { UserNotificationsService } from './core/services/user-notifications.service';
import { FnlIconsRegistry } from 'fnl-ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  isSpinnerActive$: Observable<boolean>;
  notificationsData$: Observable<UserNotification[]>;

  layout: LayoutType = LayoutType.Anonym;

  constructor(
    private _fnlIconsRegistry: FnlIconsRegistry,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _gtmService: GoogleTagManagerService,
    private _cdr: ChangeDetectorRef,
    private _userService: UserService,
    private _companyPresentationDataService: CompanyPresentationDataService,
    private _userNotificationsService: UserNotificationsService
  ) {}

  get isBaseLayout(): boolean {
    return this.layout === LayoutType.Base;
  }

  get isAnonymLayout(): boolean {
    return this.layout === LayoutType.Anonym;
  }

  get isSpecialLayout(): boolean {
    return this.layout === LayoutType.Special;
  }

  get isPublicLayout(): boolean {
    return this.layout === LayoutType.Public;
  }

  get isFinlisticsAdmin(): Observable<boolean> {
    return this._userService.isUserAdmin();
  }

  ngOnInit() {
    this._fnlIconsRegistry.initCustomIcons();
    this._gtmService.addGtmToDom();

    this._userService.getCurrentUser().subscribe((user: User) => {
      this._companyPresentationDataService.setCurrentUser(user?.uid);
      this._companyPresentationDataService.checkFilesToDownload();
    });

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._gtmService.pushTag({
          event: 'page',
          pageUrl: event.url,
        });

        this.layout =
          this._activatedRoute.firstChild.snapshot.data.layout != null
            ? this._activatedRoute.firstChild.snapshot.data.layout
            : LayoutType.Base;

        this._cdr.markForCheck();
      }
    });

    this.notificationsData$ = this._userService.currentUser$
    .pipe(
      first(),
      switchMap((user: User) =>
      this._userNotificationsService.getUserNotifications(user.uid)
      .pipe(
        map((data) => data.items),
        first(),
      ),
    ));
  }

  async sentViewedNotice(notificationData: UserNotification): Promise<void> {
    const result = await firstValueFrom(this._userNotificationsService.markAsViewed(notificationData.uid));
  }

  onEditTopic(code): void {
    this._router.navigateByUrl(
      `/system-management/learn-more/${code}`
    )
  }
}
