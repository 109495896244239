import {
  Directive,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';

type ButtonType = 'small' | 'medium' | 'large' | '' | undefined;

const DEFAULT_TYPE: ButtonType = 'medium';

@Directive({
  selector: 'button[fnlButton], a[fnlButton]',
  standalone: true,
})
export class FnlButtonDirective implements OnInit, OnChanges {

  @Input() fnlButton: ButtonType = DEFAULT_TYPE;
  @Input() size: number;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private renderer2: Renderer2,
  ) {
  }

  ngOnInit(): void {
    const el = this.viewContainerRef.element.nativeElement;
    this.renderer2.addClass(el, 'fnl-mdc-button');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('fnlButton' in changes) {
      if (changes.fnlButton.currentValue === changes.fnlButton.previousValue) {
        return;
      }

      const clsToRemove = this.getElementCssClass(changes.fnlButton.previousValue);
      const clsToAdd = this.getElementCssClass(changes.fnlButton.currentValue);
      const el = this.viewContainerRef.element.nativeElement;

      if (clsToRemove) {
        this.renderer2.removeClass(el, clsToRemove);
      }
      if (clsToAdd) {
        this.renderer2.addClass(el, clsToAdd);
      }
    }

    if ('size' in changes) {
      if (changes.size.currentValue === changes.size.previousValue) {
        return;
      }

      this.setCustomSize();
    }
  }

  private getElementCssClass(elementType: ButtonType): string {
    if (!elementType) {
      elementType = DEFAULT_TYPE;
    }

    return `fnl-mdc-button--${ elementType }`;
  }

  private setCustomSize(): void {
    const buttonPadding = this.size / 4;
    const iconSize = this.size / 2;

    const buttonEl = this.viewContainerRef.element.nativeElement;
    const iconEl = buttonEl.querySelector('mat-icon');

    this.renderer2.setStyle(
      buttonEl,
      '--mdc-icon-button-state-layer-size',
      `${ this.size }px`,
      RendererStyleFlags2.DashCase
    );
    this.renderer2.setStyle(
      buttonEl,
      'padding', `${ buttonPadding }px`
    );
    this.renderer2.setStyle(
      iconEl,
      'height', `${ iconSize }px`
    );
    this.renderer2.setStyle(
      iconEl,
      'width', `${ iconSize }px`
    );
    this.renderer2.setStyle(
      iconEl,
      '--mdc-icon-button-icon-size', `${ iconSize }px`,
      RendererStyleFlags2.DashCase
    );
  }
}
