import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BaseItem } from '@app/core/models/common.model';
import { MetricsUnit } from '@app/core/models/metric.model';
import { formatMetric } from '@app/core/utils/base';
import { tuiDefaultProp } from '@taiga-ui/cdk';
import * as d3 from 'd3';
import { Dictionary } from 'lodash';
import { NgClass, PercentPipe } from '@angular/common';
import { EmptyPipe } from '@app/common/pipes/empty.pipe';

export interface TrendMeasures extends BaseItem {
  values: TrendDMetricData[];
  average?: number;
}
export interface TrendMetric extends TrendMeasures {
  unit: MetricsUnit;
  placeholder?: boolean;
}

export interface TrendDMetricData extends BaseItem {
  date?: string;
  xCord: number;
  yCord?: number;
}

@Component({
  selector: 'app-trend-chart',
  templateUrl: './trend-chart.component.html',
  styleUrls: [ './trend-chart.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    PercentPipe,
    EmptyPipe
  ]
})
export class TrendChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') chartRef: ElementRef<SVGSVGElement>;
  @ViewChild('tooltip') tooltipRef: ElementRef<HTMLElement>;

  @Input() data: TrendMetric;

  @Input()
  @tuiDefaultProp()
  width?: number = 332;

  chartClassName = 'trend-svg';

  private readonly _height = 56;
  private readonly _dotFix = 3;
  private readonly _textSpace = 22;
  private readonly _color = '#aac638';
  private readonly _noDataColor = '#abacac';

  private _svg: d3.Selection<SVGElement, unknown, null, undefined>;
  private _dataLayer: d3.Selection<SVGElement, unknown, null, undefined>;

  private _x: number[];
  private _y: number[];
  private _d: boolean[];
  private _i: number[];
  private _xDomain: [number, number];
  private _yDomain: [number, number];
  private _yMinEmpty: number;
  private _xScale: d3.ScaleLinear<number, number, never>;
  private _yScale: d3.ScaleLinear<number, number, never>;

  @HostBinding('class.placeholder')
  get placeholder(): boolean {
    return !!this.data.placeholder;
  }

  get chartClasses(): Dictionary<boolean> {
    return { [this.chartClassName]: true };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.data != null &&
      !changes.data.firstChange &&
      changes.data.currentValue !== changes.data.previousValue
    ) {
      this._reinitializeData(changes.data.currentValue);
    }
  }

  ngAfterViewInit(): void {
    this._svg = d3.select(this.chartRef.nativeElement);

    this._initializeDimensions();
    this._initDataLayer();

    if (this.data != null) {
      this._reinitializeData(this.data);
    }
  }

  private _initializeDimensions(): void {
    this._svg.attr('viewBox', `0 0 ${this.width} ${this._height}`);
  }

  private _initDataLayer(): void {
    this._dataLayer = this._svg
      .append('g')
      .classed(`${this.chartClassName}__data-layer`, true);
  }

  private _clearDataLayer(): void {
    this._dataLayer.selectChildren().remove();
  }

  //#region Area Chart creation
  private _reinitializeData(data: TrendMetric): void {
    this._clearDataLayer();

    this._initDataAndScales(data);

    this._drawAxis(data);
    this._drawArea();
    this._drawLines();
    this._drawDots(data);
  }

  private _initDataAndScales(data: TrendMetric): void {
    this._x = d3.map(data.values, (d) => d.xCord);
    this._y = d3.map(data.values, (d) => d.yCord);
    this._d = d3.map(data.values, (d) => d.yCord != null && !isNaN(d.yCord));
    this._i = d3.range(this._x.length);
    this._xDomain = [0, d3.max(this._x)];
    this._yDomain = d3.extent(this._y);
    this._yMinEmpty = this._yDomain[0];
    if (this._yDomain[0] === this._yDomain[1]) {
      this._yDomain =
        this._yDomain[0] != null
          ? this._yDomain[0] > 0
            ? [0, this._yDomain[1]]
            : [this._yDomain[0], 0]
          : [0, 0];
      this._yMinEmpty = 0;
    }

    this._xScale = d3.scaleLinear(this._xDomain, [
      this._dotFix,
      this.width - this._dotFix,
    ]);
    this._yScale = d3.scaleLinear(this._yDomain, [
      this._height - this._dotFix - this._textSpace,
      this._dotFix,
    ]);
  }

  private _drawAxis(data: TrendMetric): void {
    const xAxisGenerator = d3
      .axisBottom(this._xScale)
      .ticks(this._i.length)
      .tickSize(8)
      .tickFormat((_, index) => {
        let dataArray;
        if(data.values[index].date) {
          dataArray = (data?.values[index].date.split('-'))
        } else {
          dataArray = null
        }

        if (dataArray?.length === 1) {
          return dataArray[0];
        }

        const shortMonthName = dataArray?.length > 0 ? dataArray[0].slice(0,3) : null;
        return shortMonthName !== null ? `${shortMonthName}-${dataArray[1]}` : null;
      });
    const ticks = this._dataLayer
      .append('g')
      .classed(`${this.chartClassName}__x-axis`, true)
      .attr(
        'transform',
        `translate(0, ${this._height - this._textSpace - this._dotFix})`
      )
      .call(xAxisGenerator);

    // Fix first and last tick out of view position
    ticks.selectAll('text').attr('x', (_, i, a) => {

      const shift = (a[i] as SVGGElement).getBBox()?.width / 2;

      if (i === 0) {
        return shift - this._dotFix
      } else if (i === 1) {
        return shift / 2 - this._dotFix
      } else if (i === data.values.length - 2) {
        return -shift / 2 + this._dotFix
      } else if (i === data.values.length - 1) {
        return -shift + this._dotFix
      } else {
        return 0;
      }
    });
  }

  private _drawArea(): void {
    const area = d3
      .area<number>()
      .curve(d3.curveLinear)
      .x((i) => this._xScale(this._x[i]))
      .y0(this._yScale(this._yDomain[0]))
      .y1((i) => this._yScale(this._y[i]));

    this._dataLayer
      .append('path')
      .attr('fill', this._color)
      .style('opacity', '0.3')
      .attr('d', area(this._i.filter((i) => this._d[i])));
  }

  private _drawLines(): void {
    const emptyLines = this._getEmptyLines(
      this._x,
      this._y,
      this._d,
      this._yMinEmpty
    );
    for (let index = emptyLines.length; index--; ) {
      this._dataLayer
        .append('path')
        .attr('stroke', this._noDataColor)
        .attr('fill', 'none')
        .attr(
          'd',
          d3
            .line()
            .x((d) => this._xScale(d[0]))
            .y((d) => this._yScale(d[1]))(emptyLines[index])
        );
    }

    const line = d3
      .line<number>()
      .x((i) => this._xScale(this._x[i]))
      .y((i) => this._yScale(this._y[i]));

    this._dataLayer
      .append('path')
      .attr('stroke', this._color)
      .attr('fill', 'none')
      .attr('d', line(this._i.filter((i) => this._d[i])));
  }

  private _drawDots(data: TrendMetric): void {
    this._dataLayer
      .selectAll('circle')
      .data(this._i)
      .join('circle')
      .attr('cx', (i) => this._xScale(this._x[i]))
      .attr('cy', (i) =>
        this._d[i]
          ? this._yScale(this._y[i])
          : this._yScale(
              this._getYEmptyDot(this._y, this._d, i, this._yMinEmpty)
            )
      )
      .style('transform-origin', (i) =>
        this._d[i]
          ? `${this._xScale(this._x[i])}px ${this._yScale(this._y[i])}px`
          : null
      )
      .attr('r', 2.5)
      .attr('fill', (i) => (this._d[i] ? this._color : this._noDataColor))
      .classed(`no-scale`, (i) => !this._d[i])
      .on('mouseover', (event: Event, i: number) => {
        if (!this._d[i]) {
          return;
        }

        const point = this.chartRef.nativeElement.createSVGPoint();
        [point.x, point.y] = d3.pointer(event);
        const matrix = this.chartRef.nativeElement.getScreenCTM()
        const position = point.matrixTransform(matrix);

        const d3Tip = d3
          .select(this.tooltipRef.nativeElement)
          .style('display', 'flex')
          .style('left', `${position.x + 20}px`)
          .style('top', `${position.y}px`)
          .html(
            `<span>${data.values[i].name}</span><b>${formatMetric(
              this._y[i],
              this.data.unit
            )}`
          );
        if (this.tooltipRef.nativeElement.offsetWidth > 250) {
          d3Tip.classed('trend-chart__tooltip-multi', true);
        }

        const tooltipBounding =
          this.tooltipRef.nativeElement.getBoundingClientRect();

        if (window.innerWidth < tooltipBounding.right) {
          d3Tip
            .style('left', `${position.x - 60 - tooltipBounding.right + window.innerWidth}px`)
            .style('top', `${position.y - 30}px`);
        }
      })
      .on('mouseout', () => {
        d3.select(this.tooltipRef.nativeElement)
          .style('display', 'none')
          .classed('trend-chart__tooltip-multi', false);
      });
  }
  //#endregion

  private _getEmptyLines(
    x: number[],
    y: number[],
    d: boolean[],
    yMin: number
  ): [number, number][][] {
    let prevCords: [number, number];
    const result: [number, number][][] = [];
    let lastLine: [number, number][];

    for (let index = x.length; index--; ) {
      if (d[index] && lastLine != null && prevCords == null) {
        lastLine = lastLine.concat([[x[index], yMin]]);

        if (lastLine.length + index === x.length) {
          result.push(lastLine);
        }
        lastLine = null;
      }

      if (!d[index]) {
        if (lastLine == null) {
          lastLine = prevCords == null ? [] : [[prevCords[0], yMin]];
        }

        lastLine.push([x[index], yMin]);
      }

      prevCords = d[index] ? [x[index], y[index]] : null;
    }

    if (lastLine != null) {
      result.push(lastLine);
    }

    return result;
  }

  private _getYEmptyDot(
    y: number[],
    d: boolean[],
    index: number,
    yMin: number
  ): number {
    let leftIndex: number;
    for (let i = index; i--; ) {
      if (d[i]) {
        leftIndex = i;
        break;
      }
    }
    let rightIndex: number;
    for (let i = index + 1; i < d.length; i++) {
      if (d[i]) {
        rightIndex = i;
        break;
      }
    }

    if (leftIndex == null || rightIndex == null) {
      return yMin;
    } else {
      return (
        ((y[rightIndex] - y[leftIndex]) / (rightIndex - leftIndex)) *
          (index - leftIndex) +
        y[leftIndex]
      );
    }
  }
}
