import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-size-options-button',
  templateUrl: './size-options-button.component.html',
  styleUrls: [ './size-options-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    NgIf,
  ]
})
export class SizeOptionsButtonComponent {
  @Input() disabled = false;
  @Input() isOpened = false;
  @Input() value: number;
  @Output() toggle = new EventEmitter();

  onClick() {
    if (this.disabled) {
      return;
    }

    this.toggle.emit();
  }
}
