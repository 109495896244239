import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EMPTY_UID } from '../constants';
import { BaseItem } from '../models/common.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessFunctionService {
  static readonly blockCid = 'BusinessFunctionService';
  private readonly _defaultFunction: BaseItem = {
    uid: EMPTY_UID,
    name: 'All Business Functions',
  };

  constructor(private _apiService: ApiService) {
    this.isDefaultBusinessFunction = this.isDefaultBusinessFunction.bind(this);
  }

  getIndustryBusinessFunctionsByMetrics(
    industryUid: string,
    withAll = false,
    metricsUids: string[] = [],
    blockCid = BusinessFunctionService.blockCid
  ): Observable<BaseItem[]> {
    return this.getBusinessFunctionsByIndustries(
      [industryUid],
      metricsUids,
      blockCid
    ).pipe(
      map((items) => (withAll ? [this._defaultFunction].concat(items) : items))
    );
  }

  /**
   * @deprecated Use {@link getIndustryBusinessFunctionsByMetrics} instead
   */
  getIndustryBusinessFunctions(
    industryUid: string,
    withAll = false,
    blockCid = BusinessFunctionService.blockCid
  ): Observable<BaseItem[]> {
    return this.getIndustryBusinessFunctionsByMetrics(
      industryUid,
      withAll,
      [],
      blockCid
    );
  }

  getDefaultBusinessFunction(): BaseItem {
    return this._defaultFunction;
  }

  getBusinessFunctionsByIndustries(
    industryUids: string[],
    metricUids: string[],
    blockCid?: string
  ): Observable<BaseItem[]> {
    return this._apiService
      .get<{
        items: BaseItem[];
      }>(
        'industryResearch/businessFunction/business-function-options',
        {
          params: new HttpParams({
            fromObject: {
              ...industryUids.reduce(
                (result: Dictionary<string>, item: string, index: number) => {
                  result[`industryUid[${index}]`] = item;
                  return result;
                },
                {}
              ),
              ...metricUids.reduce(
                (result: Dictionary<string>, item: string, index: number) => {
                  result[`metricUid[${index}]`] = item;
                  return result;
                },
                {}
              ),
            },
          }),
        },
        blockCid
      )
      .pipe(
        map((data) => data.items),
        catchError(() => of([]))
      );
  }

  isDefaultBusinessFunction(businessFunction?: BaseItem): boolean {
    return businessFunction?.uid === this._defaultFunction.uid;
  }
}
