import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlImagePreviewComponent } from './fnl-image-preview/fnl-image-preview.component';
import { FnlButtonDirective } from '../../directives/fnl-button.directive';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FnlImagePreviewDialogComponent } from './fnl-image-preview-dialog/fnl-image-preview-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    FnlImagePreviewComponent,
    FnlImagePreviewDialogComponent
  ],
  imports: [
    CommonModule,
    FnlButtonDirective,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule
  ],
  exports: [
    FnlImagePreviewComponent
  ],
})
export class FnlImagePreviewModule {
}
