import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlLearnMoreComponent } from './learn-more.component';
import { MatIconModule } from '@angular/material/icon';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
  declarations: [FnlLearnMoreComponent],
  imports: [CommonModule, MatIconModule, OverlayModule],
  exports: [FnlLearnMoreComponent],
})
export class FnlLearnMoreModule {}
