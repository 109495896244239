import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  OnInit,
  Input,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { IconButtonComponent } from '../buttons/icon-button/icon-button.component';
import { MatLegacyTooltipModule as MatTooltipModule,
} from '@angular/material/legacy-tooltip';


@Component({
  selector: 'app-field-search',
  templateUrl: './field-search.component.html',
  styleUrls: ['./field-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    CommonModule,
    IconButtonComponent,
    MatTooltipModule
  ]
})
export class FieldSearchComponent implements OnInit {
  @Input() defaultValue = '';
  @Input() isDark = false;
  @Input() placeholder = 'Search';
  @Output() search = new EventEmitter<string>();
  value = '';

  get isEmpty(): boolean {
    return !this.value.trim();
  }

  submit() {
    this.search.emit(this.value.trim());
  }

  trimOnBlur() {
    if (this.value) {
      this.value = this.value.trim();
    }
  }

  clearValue() {
    this.value = '';
    this.submit();
  }

  ngOnInit() {
    this.value = this.defaultValue;
  }
}
