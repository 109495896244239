import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CookieStorage } from '@app/core/utils/storage/interfaces/cookie-storage.interface';

@Component({
  selector: 'app-cookies-message',
  templateUrl: './cookies-message.component.html',
  styleUrls: ['./cookies-message.component.scss'],
  animations: [
    trigger('notificationAnimation', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate(400, style({ opacity: 1 }))]),
      transition(':leave', [animate(400, style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesMessageComponent implements OnInit {
  display: boolean;

  constructor(private cookieStorage: CookieStorage) {}

  ngOnInit(): void {
    this.display = this.cookieStorage.getItem('notification') ? false : true;
  }

  closeMessage(): void {
    const twoYearsFromNow = new Date();
    twoYearsFromNow.setFullYear(twoYearsFromNow.getFullYear() + 2);
    this.cookieStorage.setItem('notification', 'informed', {
      expires: twoYearsFromNow,
      path: '/',
      secure: true,
      sameSite: 'None',
    });
    this.display = !this.display;
  }
}
