<div
  class="history-view history-view_theme_default"
>
  <div
    *ngFor="let item of items; trackBy: trackByUid"
    class="history-view__item"
  >
    <a
      class="history-view__item-name"
      [routerLink]="getRoute(item)"
    >
      <ng-container>
        {{ item.name }}
      </ng-container>
    </a>
  </div>
  <ng-container *ngIf="items?.length === 0">
    <app-error-no-data
      class="history-view__caption"
      errorText="You have no recently viewed items for now. Once you start using the search bar, all recently viewed items will be displayed here."
      i18n-errorText
      [compact]="true"
    ></app-error-no-data>
  </ng-container>
</div>
