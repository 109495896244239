<button
  #button
  type="button"
  class="base-selector-input"
  [class.base-selector-input_opened]="isOpened"
  [class.base-selector-input_inverted]="inverted"
  [class.base-selector-input_form]="formView"
  [class.base-selector-input_disabled]="disabled"
  [class.base-selector-input_invalid]="invalid"
  [disabled]="disabled"
  [attr.tabIndex]="focusable ? 0 : -1"
>
  <span *ngIf="placeholder" class="base-selector-input__label"
    >{{ placeholder }}{{ selected != null ? ':' : '' }}</span
  >
  <div class="base-selector-input__value">{{ selected?.name || '' }}</div>
  <mat-icon
    class="base-selector-input__arrow"
    svgIcon="navigate_before"
  ></mat-icon>
</button>
