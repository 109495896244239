import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { User } from '@app/core/models/user.model';

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileButtonComponent {
  @Input() isOpened = false;
  @Input() user: User;

  constructor(public elRef: ElementRef) {}

  get initials(): string {
    return this.user.name
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word && word[0])
      .join('');
  }
}
