<mat-form-field
  class="limited-input__mat-field mat-form-field-no-validation"
  appearance="outline"
  [class.limited-input__invalid]="invalid"
  color="accent"
>
  <span *ngIf="prefix" matPrefix>
    {{ prefix }}
  </span>
  <input
    type="text"
    autocomplete="off"
    [placeholder]="placeholder"
    [attr.min]="min"
    [attr.max]="max"
    matInput
    [(ngModel)]="valueInput"
    digitOnly
    [decimal]="decimal !== 0"
    inputmode="numeric"
    [pattern]="pattern"
    [attr.step]="step"
    [disabled]="disabled"
    (blur)="onBlur()"
    (focus)="onTouch()"
    (keydown.enter)="onBlur()"
    (keyup.arrowup)="updateToFixed(1)"
    (keyup.arrowdown)="updateToFixed(-1)"
    (keyup.shift.arrowup)="updateToFixed(0.1)"
    (keyup.shift.arrowdown)="updateToFixed(-0.1)"
    (keyup.control.arrowup)="updateToFixed(0.5)"
    (keyup.control.arrowdown)="updateToFixed(-0.5)"
    (keyup.alt.arrowup)="updateToFixed(10)"
    (keyup.alt.arrowdown)="updateToFixed(-10)"
    title=""
  />
  <button
    *ngIf="showClear"
    tabindex="-1"
    type="button"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    [matTooltip]="clearTooltip"
    (mousedown)="onClear($event)"
    (click)="$event.stopPropagation()"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <span *ngIf="suffix" matSuffix>
    {{ suffix }}
  </span>
</mat-form-field>
