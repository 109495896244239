<app-more-actions-button
  #handle
  class="more-actions-popover__handle"
  [isOpened]="true"
  (click)="closePopover()"
></app-more-actions-button>
<div #content class="more-actions-popover__content">
  <div class="more-actions-popover__list more-actions-list">
    <div
      *ngFor="let item of actions; trackBy: trackByUid"
      class="more-actions-list__item"
      (click)="callAction(item)"
    >
      {{ item.name }}
    </div>
  </div>
</div>
