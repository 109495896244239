import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { FooterComponent } from './footer/footer.component';
import { HeaderModule } from './header/header.module';
import { SidePanelModule } from './side-panel/side-panel.module';

import { VersionManagerComponent } from './version-manager/version-manager.component';
import { CookiesMessageComponent } from './cookies-message/cookies-message.component';
import { HotjarComponent } from './hotjar/hotjar.component';

@NgModule({
  imports: [SharedModule, RouterModule],
  exports: [
    HeaderModule,
    SidePanelModule,
    FooterComponent,
    VersionManagerComponent,
    CookiesMessageComponent,
    HotjarComponent,
  ],
  declarations: [
    FooterComponent,
    VersionManagerComponent,
    CookiesMessageComponent,
    HotjarComponent,
  ],
})
export class LayoutModule {}
