<div class="list-popover" [ngClass]="{ 'list-popover_favorites': isFavorites }">
  <app-list-button
    class="list-popover__handle"
    [isOpened]="true"
    [isFavorites]="isFavorites"
    [isRecently]="isRecently"
    (click)="closePopover()"
  ></app-list-button>
  <div class="list-popover__content">
    <div
      class="list-popover__filler"
      #listFiller
      [style.width]="fillerWidth ? fillerWidth + 'px' : null"
    ></div>
    <app-list-filters
      [isFavorites]="isFavorites"
      [isRecently]="isRecently"
      [filter]="filter"
      (update)="setFilter($event)"
    ></app-list-filters>
    <app-history
      class="list-popover__list"
      [isFavorites]="isFavorites"
      [filter]="filter"
      [theme]="historyTheme.Header"
      (navigate)="closePopover()"
    ></app-history>
  </div>
</div>
