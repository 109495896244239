import { IndustryCode } from '@app/core/models/industry.model';
import { MetricChartView } from '@app/core/models/metric-chart.model';

export function generateRevenueName(industryCode: IndustryCode): string {
  return industryCode !== IndustryCode.Bank
    ? 'Revenue Growth %'
    : 'Total Income Growth %';
}

export function generateRevenueDescription(industryCode: IndustryCode): string {
  switch (industryCode) {
    case (IndustryCode.Bank):
      return 'Revenue Growth is typically called Total Income Growth in this industry. Total Income Growth measures the year-over-year percentage change in Total Income, which is a sum of Net Interest Income and Non-Interest Income. It is calculated as follows: (Total Income this period – Total Income last period) / Total Income last period.';
    case (IndustryCode.Healthcare):
      return 'Net Patient Revenue Growth measures the year-over-year percentage change in Net Patient Revenue. Net Patient Revenue is defined as Inpatient Revenue + Outpatient Revenue less contractual allowances and discounts on patients\' accounts. Net Patient Revenue Growth is calculated as (Net Patient Revenue this period - Net Patient Revenue last period) / Net Patient Revenue last period.';
    case (IndustryCode.Industrial):
      return 'Revenue growth measures the year-over-year percentage change in operating revenue. It is calculated as follows: (Revenue this period - Revenue last period) / Revenue last period.';
    case (IndustryCode.Insurance):
      return 'Total Revenue is the sum of Premiums Earned and Non-Premium Revenue. Total Revenue Growth measures the year-over-year percentage change in Total Revenue.';

  }
}

export function generateRevenueTrendName(industryCode: IndustryCode, view: MetricChartView): string {
  const metricName = industryCode !== IndustryCode.Bank
    ? 'Revenue'
    : 'Total Income';
  return generateTrendName(view, metricName);
}

export function generateIncomeName(): string {
  return 'Profitability %';
}

export function generateIncomeDescription(industryCode: IndustryCode): string {
  switch (industryCode) {
    case (IndustryCode.Bank):
      return 'Pretax Profit Margin is a primary measure of profitability for banks & financial institutions. Pretax Profit Margin is calculated as follows: Pretax Profits / Total Income (Revenue).';
    case (IndustryCode.Healthcare):
      return 'Operating Income Margin measures the profitability of a company\'s ongoing operations and excludes non-operating income and expense. It is calculated as follows: (Revenue - Total Operating Expenses) / Revenue.';
    case (IndustryCode.Industrial):
      return 'Operating Income Margin measures the profitability of a company\'s ongoing operations and excludes non-operating income and expense. It is calculated as follows: (Revenue - Total Operating Expenses) / Revenue.';
    case (IndustryCode.Insurance):
      return 'Pretax Profit Margin is a one measure of profitability for an insurance company. Pretax Profit Margin is calculated as follows: Pre-Tax Income / Total Revenues.';

  }
}

export function generateIncomeTrendName(view: MetricChartView): string {
  return generateTrendName(view, 'Profitability');
}

function generateTrendName(view: MetricChartView, metricName: string): string {
  const starterString = view === MetricChartView.Years ? '5-Year ' : 'Last 5 LTM Periods ';
  return starterString + metricName + ' Trend';
}
