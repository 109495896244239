import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { UUID } from '@app/core/models/common.model';
import { Observable } from 'rxjs';


export enum RateTypeApi {
  Current = 'Current',
  LastTwelveMonth = 'LastTwelveMonth',
}

interface ExchangeRateItemApi {
  toCurrencyUid: UUID;
  rate: number;
}


interface ExchangeRatesResponseApi {
  fromCurrencyUid: UUID;
  rateType: RateTypeApi;
  items: ExchangeRateItemApi[];
}


@Injectable({
  providedIn: 'root'
})
export class CurrencyApiService {

  constructor(private apiService: ApiService) {}

  getExchangeRates(
    sourceCurrencyUid: UUID,
    rateType: RateTypeApi,
    blockCid?: string
  ): Observable<ExchangeRatesResponseApi> {
    return this.apiService.get<ExchangeRatesResponseApi>(
      `common/Currency/${ sourceCurrencyUid }/exchange-rates?rateType=${ rateType }`,
      blockCid,
    );
  }
}
