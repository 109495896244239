<button
  type="button"
  class="currency-input"
  [class.currency-input_opened]="isOpened"
  [class.currency-input_form]="formView"
  tabindex="0"
>
  <span *ngIf="placeholder" class="currency-input__label"
    >{{ placeholder }}:</span
  >
  <div class="currency-input__value">{{ currency?.code }}</div>
  <mat-icon class="currency-input__arrow" svgIcon="navigate_before"></mat-icon>
</button>
