import { Peer } from './peer.model';
import { InsightTrend } from './insights.model';
import { BaseItem, BaseValue } from './common.model';

export enum MetricsTrend {
  Positive,
  Negative,
}

export enum MetricsUnit {
  Percent,
  Currency,
  Days,
  Number,
}

export enum MetricGoalType {
  None,
  Increase,
  Decrease,
  Improve,
  Optimize,
}

export enum MetricsType {
  RankingTable = 1,
  Trends = 2,
}

export enum Priority {
  Low,
  Medium,
  Hight,
  NotAvailable,
}

export enum CashFlowPeriod {
  Current,
  FirstQuartile,
  Median,
  FourthQuartile,
}

export enum MetricsVisibility {
  KeyOnly,
  All,
}
export const METRICS_VISIBILITY_LIST: BaseItem[] = [
  { uid: MetricsVisibility.KeyOnly.toString(), name: 'Key Metrics' },
  { uid: MetricsVisibility.All.toString(), name: 'All Metrics' },
];

export interface UnitsContainer {
  absoluteValueUnit?: MetricsUnit;
  relativeValueUnit?: MetricsUnit;
}

export interface IndustryMetricGroup extends BaseItem {
  order: number;
  metrics: IndustryMetric[];
}

export interface IndustryMetric {
  uid: string;
  columnName: string;
  description?: string;
  absoluteValueDisplayText?: string;
  relativeValueDisplayText?: string;
  units: UnitsContainer;
  trend: MetricsTrend;
  order: number;
  isKey?: boolean;
  trendText?: string | string[];
  driverData?: IndustryMetricDriver[];
  whatsInIt?: IndustryWhatsInIt;
  childItems?: IndustryMetric[];
  fishEnabled?: boolean;
}

export interface IndustryMetricInfo {
  powerOfOne: number;
  isTop: boolean;
}

export interface IndustryMetricTrendInfo {
  uid: string;
  text?: string;
  trends?: string[];
}

export interface IndustryMetricDriver {
  uid: string;
  shortName: string;
  descriptionText: string;
}

export interface IndustryWhatsInIt {
  uid: string;
  definitionText: string;
  goalText: string;
  kpiTextItems?: string[];
}

export interface MetricFull<TDetails, TInsights> {
  details?: TDetails;
  insights?: TInsights;
  powerOfOne?: IndustryMetricInfo;
  priority?: number;
  priorityClass?: Priority;
  cashFlow?: CashFlow;
}

export interface IndustryMetricDetails {
  [year: string]: IndustryMetricYearDetails;
}

export interface MetricInsights {
  average: {
    start: number;
    end: number;
    value: number;
  };
  median: {
    year: number;
    value: number;
  };
  trend: InsightTrend;
}

export interface IndustryMetricYearDetails {
  firstQuartile: number;
  fourthQuartile: number;
  median: number;
  year?: number;
}

export interface ExtendedIndustryMetricGroup extends IndustryMetricGroup {
  metrics: ExtendedIndustryMetric[];
}

export interface ExtendedIndustryMetric
  extends IndustryMetric,
    MetricFull<IndustryMetricYearDetails[], MetricInsights> {
  childItems?: ExtendedIndustryMetric[];
}

export interface FetchedIndustryMetricData {
  data: FetchedMetricDataByYear[];
  insights: FetchedMetricInsights;
  isTopPowerOfOne: boolean;
  powerOfOne?: number;
  priority?: number;
}

export interface FetchedMetricInsights {
  averageMedian: number;
  median: {
    year: number;
    median: number;
  };
  trend: InsightTrend;
}

export interface FetchedMetricDataByYear extends IndustryMetricYearDetails {
  year: number;
}

export interface FetchedCompanyMetricData {
  uid: string;
  cashFlowValueOfGap?: FetchedCashFlow;
  data: FetchedCompanyDataPeriod[];
  insights: FetchedCompanyInsight;
  powerOfOne?: number;
  isTopPowerOfOne: boolean;
  priority?: number;
}

export interface FetchedCompanyInsight {
  averageMedian?: number;
  median: {
    fiscalPeriodEnd: string;
    industryMedian: number;
    median: number;
  };
  bestPeer?: PeerTrend;
  trend?: InsightTrend;
}

export interface FetchedCompanyDataPeriod {
  fiscalPeriodEnd?: string;
  industryTrend: IndustryMetricYearDetails;
  peerTrends: PeerTrend[];
  value: number;
  year: number;
}

export interface FetchedCashFlow {
  cashFlowValueOfGapIndustryValue?: number;
  cashFlowValueOfGapOfBestPeerValue?: number;
  cashFlowValueOfGapOfCompanyValue?: number;
  companyUidOfBestPeerCashFlowValueOfGap: string;
  fiscalPeriodEndOfCompanyCashFlowValueOfGap: string;
  periodTypeOfIndustryCashFlowValueOfGap: CashFlowPeriod;
  uid: string;
  yearOfCompanyCashFlowValueOfGap?: number;
}

export interface PeerTrend {
  companyUid: string;
  fiscalPeriodEnd?: string | Date;
  value?: number;
  name?: string;
  ticker?: string;
}

export interface CompanyMetricPeriodDetails extends BaseValue {
  period?: Date;
  industryTrend?: IndustryMetricYearDetails;
  peerTrends: PeerTrend[];
  year?: number;
}

export interface CompanyMetricGroup extends IndustryMetricGroup {
  metrics: CompanyMetric[];
}

export interface CompanyMetric
  extends IndustryMetric,
    MetricFull<CompanyMetricPeriodDetails[], CompanyMetricInsights> {
  childItems?: CompanyMetric[];
}

export interface CompanyMetricInsights {
  average: {
    start: Date;
    end: Date;
    value: number;
  };
  median: {
    period: Date;
    value: number;
    industryValue: number;
  };
  trend: InsightTrend;
  peer: {
    period: Date;
    name: string;
  };
}

export interface CashFlow {
  industry?: number;
  bestPeer?: number;
  company?: number;
  bestPeerData: Peer;
  period: Date;
  periodType: CashFlowPeriod;
  year: number;
}

export interface CFVGTextData {
  company?: CFVGBlock[];
  industry?: CFVGBlock[];
  peers?: CFVGBlock[];
}
export interface CFVGBlock {
  text: string;
  strong?: boolean;
}
