import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FnlTreeDiagramData } from './models/tree-diagram.model';


const EMPTY_DATA_ELEMENT: FnlTreeDiagramData = {
  name: '',
  children: [],
};

const EMPTY_DATA: FnlTreeDiagramData = {
  ...EMPTY_DATA_ELEMENT,
  children: new Array(3).fill({
    ...EMPTY_DATA_ELEMENT,
    children: new Array(2).fill(EMPTY_DATA_ELEMENT),
  }),
};


@Component({
  selector: 'fnl-tree-diagram',
  templateUrl: './tree-diagram.component.html',
  styleUrls: [ './tree-diagram.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FnlTreeDiagramComponent implements OnChanges {

  @Input() data: FnlTreeDiagramData = EMPTY_DATA;
  @Input() diagramName = 'Entity';

  get isDataEmpty(): boolean {
    return JSON.stringify(this.data) === JSON.stringify(EMPTY_DATA);
  }

  get dataItems(): FnlTreeDiagramData[] {
    return this.data?.children ?? [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      if (!changes.data.currentValue?.name) {
        this.data = EMPTY_DATA;
      }
    }
  }
}
