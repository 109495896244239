import {
  formatCurrency,
  formatNumber,
  formatPercent,
  getCurrencySymbol,
} from '@angular/common';
import { IndustryMetric, MetricsUnit } from '@app/core/models/metric.model';
import { isBoolean, isString } from 'lodash';

import { LOCALE } from '../constants';
import { Attachment, FileIcons, PeriodView } from '../models/common.model';
import { RevenueRange } from '../models/revenue.model';

export function trackByUid(index: number, item: any) {
  return item.uid;
}

export function trackByKey(index: number, item: any) {
  return item.key;
}

export function sortAscDefault(
  items: any[],
  field: string,
  defaultValue?: string
) {
  return items.slice(0).sort((i1, i2) => {
    const value1 = i1[field];
    const value2 = i2[field];

    if (
      (value1 < value2 || value1 === defaultValue) &&
      value2 !== defaultValue
    ) {
      return -1;
    }

    if (value1 > value2) {
      return 1;
    }

    return 0;
  });
}

export interface FormatterOptions {
  currencyCode?: string;
  currency?: string;
  pattern?: string;
}

export function formatMetric(
  value: number,
  typeOrOption?: MetricsUnit | FormatterOptions | string | boolean,
  option?: FormatterOptions | string | boolean
): string {
  let transformed: string;

  const type = (typeOrOption as any) in MetricsUnit ? typeOrOption : undefined;
  option = !((typeOrOption as any) in MetricsUnit)
    ? (typeOrOption as FormatterOptions | string | boolean)
    : option;

  if (value != null) {
    switch (type) {
      case MetricsUnit.Percent:
        const percentPattern = isString(option) ? option : '1.1-1';
        transformed = formatPercent(value, LOCALE, percentPattern);
        break;
      case MetricsUnit.Currency:
        option = option || {};
        const currencyCode = isString((option as FormatterOptions).currencyCode)
          ? (option as FormatterOptions).currencyCode
          : 'USD';
        const currency = isString((option as FormatterOptions).currency)
          ? (option as FormatterOptions).currency
          : getCurrencySymbol(currencyCode, 'narrow', LOCALE);
        const currencyPattern = isString((option as FormatterOptions).pattern)
          ? option
          : '1.2-2';

        transformed = formatCurrency(
          value,
          LOCALE,
          currency,
          currencyCode,
          currencyPattern as string
        );
        break;
      case MetricsUnit.Days:
        const isShort = isBoolean(option) ? option : true;
        transformed = `${formatNumber(value, LOCALE, '1.0-0')} ${
          isShort ? 'd' : `day${value !== 1 ? 's' : ''}`
        }`;
        break;
      default:
        const numberPattern = isString(option) ? option : '1.2-2';
        transformed = formatNumber(value, LOCALE, numberPattern);
    }
  }

  return transformed;
}

export function getRevenueText(revenue: RevenueRange): string {
  if (!revenue) {
    return 'Not Selected'
  }

  const isMaxValue = revenue.maxValue % 9 === 0;
  const isMinValue = revenue.minValue === 0;

  const isMinBillion = revenue.minValue / 1000 >= 1;
  const isMaxBillion = revenue.maxValue / 1000 >= 1;
  const minUnit = isMinBillion ? 'Bil' : 'Mil';
  const maxUnit = isMaxBillion ? 'Bil' : 'Mil';
  const formattedMinValue = formatNumber(
    revenue.minValue / (isMinBillion ? 1000 : 1),
    LOCALE,
    '1.0-6'
  );
  const formattedMaxValue = formatNumber(
    revenue.maxValue / (isMaxBillion ? 1000 : 1),
    LOCALE,
    '1.0-6'
  );

  if (isMaxValue && isMinValue) {
    return 'All';
  } else if (!isMaxValue) {
    return `$${formattedMinValue}${
      isMinValue ? '' : ` (${minUnit})`
    } - $${formattedMaxValue} (${maxUnit})`;
  } else {
    return `$${formattedMinValue}${isMinValue ? '' : ` (${minUnit})`} or more`;
  }
}

export enum TreeParts {
  Emptiness = 0,
  Parent = 1,
  Child = 2,
  LastChild = 3,
}

export function generateTreeLevelList(
  prevLevelsList: number[] = [],
  level = 0,
  isLast = false
): number[] {
  const newLevelList = [...prevLevelsList];

  if (newLevelList.length < level) {
    newLevelList.push(TreeParts.Parent);
    newLevelList[prevLevelsList.length - 1] = isLast
      ? TreeParts.LastChild
      : TreeParts.Child;
    if (newLevelList[prevLevelsList.length - 2] != null) {
      newLevelList[prevLevelsList.length - 2] =
        newLevelList[prevLevelsList.length - 2] === TreeParts.Child
          ? TreeParts.Parent
          : newLevelList[prevLevelsList.length - 2] === TreeParts.LastChild
          ? TreeParts.Emptiness
          : newLevelList[prevLevelsList.length - 2];
    }
  } else {
    newLevelList[newLevelList.length - 1] = isLast
      ? TreeParts.LastChild
      : TreeParts.Child;
    if (
      newLevelList[newLevelList.length - 1] !==
        prevLevelsList[newLevelList.length - 1] &&
      level > 1
    ) {
      newLevelList[prevLevelsList.length - 2] =
        newLevelList[prevLevelsList.length - 2] === TreeParts.Child
          ? TreeParts.Parent
          : newLevelList[prevLevelsList.length - 2] === TreeParts.LastChild
          ? TreeParts.Emptiness
          : newLevelList[prevLevelsList.length - 2];
    }
  }
  return newLevelList;
}

export function fixNumber(value: number, fixValue = 1): number {
  return Number(value.toFixed(fixValue));
}

export function getAttachmentsIcon(contentType?: string): FileIcons {
  switch (contentType) {
    case 'application/pdf':
      return 'file-pdf';
    case 'text/csv':
      return 'file-csv';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return 'exclefile';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.oasis.opendocument.presentation':
      return 'file-ppt';
    default:
      return 'description';
  }
}

export function formatPeriod(value: number, periodType: PeriodView): number {
  if (value == null) {
    return value;
  }

  return periodType === PeriodView.Millions ? value : value * 1000;
}

export function getRelativeMetricName(metric: IndustryMetric): string {
  return metric.relativeValueDisplayText || metric.absoluteValueDisplayText;
}
