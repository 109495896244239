import { BaseItem } from '@app/core/models/common.model';
import { TrackingSubCategory } from '@app/api/tracking/activity-tracker.service';

export enum UsageType {
  Total,
  Report,
  Account,
  Solution,
  Video = 6,
}

export enum UsageColumnType {
  Text,
  Email,
  Number,
  Date,
  Account,
  Percent,
}

export enum ReportStatus {
  Ready,
  Failed,
  RequestReceived,
  RequestReceiveFailed,
  ReportPosted,
  ReportPostFailed,
  ReportCreated,
  ReportHasNoData,
  LinkExpired,
}

export class ReportStatusUtils {
  /**
   * Indicates whether report status means that generation has terminated unsuccessfully.
   *
   * @param value status to check
   */
  static isFailure(value: ReportStatus): boolean {
    return value === ReportStatus.Failed
      || value === ReportStatus.RequestReceiveFailed
      || value === ReportStatus.ReportPostFailed
      || value === ReportStatus.ReportHasNoData
      || value === ReportStatus.LinkExpired;
  }

  /**
   * Indicates that report is in status that may lead to successful completion of generation.
   *
   * @param value status to check
   */
  static isCompletable(value: ReportStatus): boolean {
    return value === ReportStatus.Ready ||
      value === ReportStatus.ReportCreated ||
      value === ReportStatus.ReportPosted ||
      value === ReportStatus.RequestReceived;
  }

  /**
   * Indicates whether report status means that generation has terminated successfully.
   *
   * @param value status to check
   */
  static isReady(value: ReportStatus): boolean {
    return value === ReportStatus.Ready;
  }
}

export interface UsagePeriod {
  start?: Date;
  end?: Date;
}

export interface UsageColumn extends BaseItem {
  type: UsageColumnType;
}

export interface BaseAnalysisItem {
  clientName: string;
  userName: string;
  userEmail: string;
}

export interface BaseTotalAnalysisItem {
  industryAnalysis: number;
  industryPursuits: number;
  industryValueModeler: number;
  industryReports: number;
  companyAnalysis: number;
  companyPursuits: number;
  companyValueModeler: number;
  companyReports: number;
  companyPresentations: number;
  googleCanvas?: number;
}

export interface TotalAnalysisItem
  extends BaseAnalysisItem,
    BaseTotalAnalysisItem {
  total: number;
}

export interface BaseReportAnalysisItem {
  reportPresentationAnalysis: number;
}

export interface ReportAnalysisItem
  extends BaseAnalysisItem,
    BaseReportAnalysisItem {
  companyOrIndustry: string;
  accountType: TrackingSubCategory;
  reportGroupPresentation: string;
  reportPresentationName: string;
  reportPresentationAnalysis: number;
  firstAccess: string | Date;
  lastAccess: string | Date;
}

export interface BaseAccountAnalysisItem {
  accountAnalysis: number;
  pursuits: number;
  valueModelers: number;
  reports: number;
  companyPresentations: number;
  googleCanvas?: number;
}

export interface AccountAnalysisItem
  extends BaseAnalysisItem,
    BaseAccountAnalysisItem {
  accountName: string;
  accountType: TrackingSubCategory;
  firstAccess: string | Date;
  lastAccess: string | Date;
  total: number;
}

export interface BaseSolutionAnalysisItem {
  countSolutionSelections: number;
}

export interface SolutionAnalysisItem
  extends BaseAnalysisItem,
    BaseSolutionAnalysisItem {
  accountName: string;
  accountType: TrackingSubCategory;
  page: string;
  solutionAreaName: string;
  solutionName: string;
  total: number;
}

export interface VideoWatchedAnalysisItem extends BaseAnalysisItem {
  videoName: string;
  firstTimeWatched: string | Date;
  lastTimeWatched: string | Date;
  percentWatched: number;
}

export interface ReportData {
  messageId: string;
  linkToFile?: string;
  totalPages?: number;
  status: ReportStatus;
  expireOn?: string;
}

export interface StorageReportData {
  messageId: string;
  expireDate: string | Date;
}
