<!-- This need to correct work cdkOverlayOrigin with trigger -->
<div cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayPanelClass]="contentWrapperClass"
  [cdkConnectedOverlayOpen]="isOpen$ | async"
  (detach)="closeSidebar()"
>
  <div class="learn-more-wrap" @appearSidebar>
    <div class="learn-more-wrap-container">
      <div class="learn-more-wrap-container-header">
        <mat-icon
          class="learn-more-icon"
          svgIcon="close"
          (click)="closeSidebar()"
        ></mat-icon>
        <button
          *ngIf="canEdit"
          class="learn-more-edit"
          (click)="editLearnMoreTopic()"
        >Edit
        </button>
      </div>

      <div class="learn-more-wrap-container-content" [innerHtml]="(learnMoreObject$ | async)?.content"></div>
    </div>
  </div>
</ng-template>
