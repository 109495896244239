<app-ui-blocker [cId]="blockCid">
  <app-history-view
    *ngIf="!isCustomCompanies"
    [isFavorites]="isFavorites"
    [theme]="theme"
    [items]="list$ | async | historyFilter: filter"
    (navigate)="navigateToItem()"
    (remove)="removeItem($event)"
  ></app-history-view>
  
  <app-history-custom-companies-view
    *ngIf="isCustomCompanies"
    [items]="customCompanies$ | async"
  >
  </app-history-custom-companies-view>
</app-ui-blocker>
