import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { first, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { BaseErrorResponse } from '@app/core/services/api.service';
import { NotificationsService } from '@app/core/services/notifications.service';
import { UserService } from '@app/core/services/user.service';
import { LearnMoreRepository } from '@app/repositories/common/learn-more/learn-more.repository';
import { FnlLearnMoreService } from 'fnl-ui';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TuiLetModule } from '@taiga-ui/cdk';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';

@Component({
  selector: 'app-learn-more-link',
  templateUrl: './learn-more-link.component.html',
  styleUrls: [ './learn-more-link.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ CommonModule, MatIconModule, TuiLetModule ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LearnMoreLinkComponent {

  @Input() topic?: string;
  @Output() opened = new EventEmitter<string>();

  constructor(
    private userService: UserService,
    private learnMoreRepository: LearnMoreRepository,
    private notificationsService: NotificationsService,
    private learnMoreService: FnlLearnMoreService,
    private amplitudeService: AmplitudeService
  ) {
  }

  get visible(): Observable<boolean | null> {
    return this.userService.getCurrentUser()
      .pipe(
        map(user => user.clientUid),
        switchMap(clientUid => this.topic
          ? this.learnMoreRepository.isTopicVisible(clientUid, this.topic)
          : of(null)
        )
      )
  }

  get adminUser(): Observable<boolean> {
    return this.userService.isUserAdmin();
  }

  openLearnMore($event: MouseEvent) {
    $event.stopPropagation();

    return this.userService.getCurrentUser()
      .pipe(
        first(),
        switchMap((user) => this.learnMoreRepository.getLearnMoreTopic(user.clientUid, this.topic)),
        catchError((error: BaseErrorResponse) => {
          this.notificationsService.removeNotification(
            NotificationsService.generalError
          );
          this.notificationsService.addError({
            id: 'learn-more-service',
            message: error.detail ?? 'Unexpected error',
            removable: true,
          });
          return of(null);
        }),
        filter((learnMoreObj) => !!learnMoreObj),
        tap(learnMoreObj => this.learnMoreService.open(learnMoreObj)),
        tap(() => this.opened.emit(this.topic)),
        tap(learnMoreObj => this.amplitudeService.amplitudeEvent('Learn More - View', {
          code: learnMoreObj.code,
          clientUid: learnMoreObj.clientUid
        })),
      )
      .subscribe();
  }
}
