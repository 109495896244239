import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-list-button',
  templateUrl: './list-button.component.html',
  styleUrls: ['./list-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListButtonComponent {
  @HostBinding('class.list-button_opened')
  @HostBinding('class.list-button')
  isListButton = true;
  @HostBinding('attr.tabIndex') tabIndex = '0';

  @Input()
  isOpened = false;
  @Input() isFavorites = false;
  @Input() isRecently = false;

  hovered = false;

  get isHighlighted(): boolean {
    return this.isOpened || this.hovered;
  }

  @HostListener('mouseenter')
  @HostListener('focus')
  onMouseEnter() {
    this.hovered = true;
  }

  @HostListener('mouseleave')
  @HostListener('focusout')
  onMouseLeave() {
    this.hovered = false;
  }
}
