import { untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Company } from '@app/core/models/company.model';
import {
  FullIndustryDataSet,
  Industry, IndustryCategory,
  IndustryCode,
  IndustryDataSet,
} from '@app/core/models/industry.model';
import { Region } from '@app/core/models/region.model';
import { RevenueRange } from '@app/core/models/revenue.model';
import { RevenueService } from '@app/core/services/revenue.service';
import { RoutingService } from '@app/core/services/routing.service';
import {
  BehaviorSubject,
  merge,
  Observable,
  of,
  switchMap,
  shareReplay,
  combineLatest,
  firstValueFrom,
} from 'rxjs';
import { Params } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { CompanyService } from '@app/core/services/company.service';
import { RegionService } from '@app/core/services/region.service';
import { cache } from '@app/core/operators';
import { IndustryService } from '@app/core/services/industry.service';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { getRevenueText } from '@app/core/utils/base';

@Injectable()
export class PrivateCompanyDataService implements OnDestroy {
  static readonly blockCid = 'PrivateCompany';
  static readonly filtersCid = `${PrivateCompanyDataService.blockCid}_Filters`;

  private _company$: Observable<Company>;
  private _baseCompanyRegion$: Observable<Region>;
  private _baseCompanyRevenue$: Observable<RevenueRange>;

  private _basedOnIndustry = new BehaviorSubject<IndustryDataSet>(null);

  constructor(
    private _routingService: RoutingService,
    private _revenueService: RevenueService,
    private _userService: UserService,
    private _companyService: CompanyService,
    private _regionService: RegionService,
    private _industryService: IndustryService,
    private _amplitudeService: AmplitudeService,
  ) {}

  ngOnDestroy(): void {
    this._company$ = null;
  }

  //#region Company data
  getCompany(): Observable<Company> {
    if (this._company$ == null) {
      this._company$ = this._routingService.getActualParams().pipe(
        distinctUntilChanged(
          (a: Params, b: Params) => a.uid === b.uid && a.name === b.name
        ),
        switchMap((params: Params) => {
          const companyId = params.uid;

          return !companyId
            ? of(null)
            : merge(
                of(null),
                this._userService
                  .getCurrentUser()
                  .pipe(
                    switchMap((user) =>
                      this._companyService.getUserPrivateCompany(
                        user.uid,
                        companyId,
                        false,
                        false,
                        PrivateCompanyDataService.blockCid
                      )
                    )
                  )
                  .pipe(
                    map((company) => {
                      const revenueRounded = Math.round(company.revenue);
                      return {
                        ...company,
                        revenue:
                          revenueRounded >= 1
                            ? revenueRounded < 999999
                              ? revenueRounded
                              : 999999
                            : 1,
                      };
                    })
                  )
              );
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        untilDestroyed(this, 'ngOnDestroy')
      );
    }

    return this._company$;
  }
  getNotEmptyCompany(): Observable<Company> {
    return this.getCompany().pipe(filter((company) => company != null));
  }

  getBasedOnCompanyRegion(): Observable<Region> {
    if (this._baseCompanyRegion$ == null) {
      this._baseCompanyRegion$ = this.getNotEmptyCompany().pipe(
        distinctUntilChanged((a, b) => a.uid === b.uid),
        switchMap((company: Company) => this._getRegionForCompany(company)),
        shareReplay({ bufferSize: 1, refCount: true }),
        untilDestroyed(this, 'ngOnDestroy')
      );
    }

    return this._baseCompanyRegion$;
  }

  getBasedOnCompanyRevenue(): Observable<RevenueRange> {
    if (this._baseCompanyRevenue$ == null) {
      this._baseCompanyRevenue$ = combineLatest([
        this.getNotEmptyCompany(),
        this.getBasedOnCompanyRegion(),
      ]).pipe(
        distinctUntilChanged(
          (a, b) => a[0].uid === b[0].uid && a[1]?.uid === b[1]?.uid
        ),
        switchMap(([ company, region ]: [ Company, Region ]) => {
          return region != null && company.industryRevenueGroupUid != null
            ? this._revenueService
              .getRevenueRanges(
                region.uid,
                company.sic == null && company.subIndustry == null
                  ? company.industry.uid
                  : null,
                company.sic == null ? company.subIndustry?.uid : null,
                company.sic?.uid,
                PrivateCompanyDataService.filtersCid
              )
              .pipe(
                map((ranges: RevenueRange[]) =>
                  ranges.find(
                    (item) => item.uid === company.industryRevenueGroupUid
                  )
                )
              )
            : of(null)
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        untilDestroyed(this, 'ngOnDestroy')
      );
    }

    return this._baseCompanyRevenue$;
  }

  getCategory(): Observable<IndustryCategory> {
    return this.getIndustry().pipe(
      map(industry => industry.category),
    )
  }

  getIndustry(): Observable<Industry> {
    return combineLatest([
      this.getNotEmptyCompany(),
      this._basedOnIndustry,
    ]).pipe(
      map(
        ([company, industryDataSelection]: [Company, IndustryDataSet]) =>
          industryDataSelection?.industry || company.industry
      )
    );
  }
  getSubIndustry(): Observable<Industry> {
    return combineLatest([
      this.getNotEmptyCompany(),
      this._basedOnIndustry,
    ]).pipe(
      map(([company, industryDataSelection]: [Company, IndustryDataSet]) =>
        industryDataSelection
          ? industryDataSelection.subIndustry
          : company.subIndustry
      )
    );
  }
  getRegion(): Observable<Region> {
    return combineLatest([
      this.getNotEmptyCompany(),
      this._basedOnIndustry,
      this._regionService.getRegions(),
    ]).pipe(
      map(
        ([company, industryDataSelection, regions]: [
          Company,
          IndustryDataSet,
          Region[]
        ]) =>
          industryDataSelection?.region ||
          regions?.find((item: Region) => item.uid === company.regionUid)
      )
    );
  }
  getRevenue(): Observable<RevenueRange> {
    return combineLatest([
      this.getBasedOnCompanyRevenue(),
      this._basedOnIndustry,
    ]).pipe(
      map(([revenue, industryDataSelection]: [RevenueRange, IndustryDataSet]) =>
        industryDataSelection ? industryDataSelection.revenue : revenue
      )
    );
  }

  getCompanyIndustryData(): Observable<FullIndustryDataSet> {
    return this.getNotEmptyCompany().pipe(
      filter((company: Company) => company != null),
      switchMap((company: Company) =>
        combineLatest([
          this.getBasedOnCompanyRegion(),
          this.getBasedOnCompanyRevenue(),
        ]).pipe(
          filter(([region]: [Region, RevenueRange]) => region != null),
          map(
            ([region, revenue]: [Region, RevenueRange]) =>
              ({
                industry: company.industry,
                subIndustry: company.subIndustry,
                sic: company.sic,
                revenueLabel:
                  company.industry.category.code !== IndustryCode.Bank
                    ? 'Revenue'
                    : 'Assets',
                revenue,
                region,
                industryUrl: this._industryService.getIndustryUrl(
                  company.sic?.uid ||
                    company.subIndustry?.uid ||
                    company.industry.uid,
                  region.uid
                ),
                industryName: company.sic
                  ? `${company.sic.code} - ${company.sic.name}`
                  : company.subIndustry?.name || company.industry.name,
              } as FullIndustryDataSet)
          )
        )
      ),
      cache()
    );
  }
  getBasedOnIndustryData(): Observable<FullIndustryDataSet> {
    return combineLatest([
      this.getCompanyIndustryData(),
      this._basedOnIndustry,
    ]).pipe(
      map(
        ([companyIndustryData, industryDataSelection]: [
          FullIndustryDataSet,
          IndustryDataSet
        ]) => {
          return industryDataSelection == null
            ? companyIndustryData
            : {
              ...companyIndustryData,
              ...industryDataSelection,
              industryUrl: this._industryService.getIndustryUrl(
                industryDataSelection.sic?.uid ||
                industryDataSelection.subIndustry?.uid ||
                industryDataSelection.industry.uid,
                industryDataSelection.region.uid
              ),
              industryName: industryDataSelection.sic
                ? `${ industryDataSelection.sic.code } - ${ industryDataSelection.sic.name }`
                : industryDataSelection.subIndustry?.name ||
                industryDataSelection.industry.name,
              sic: industryDataSelection.sic ?? null
            }
        }
      ),
      cache()
    );
  }

  async setBasedOnIndustryData(
    dataSet?: IndustryDataSet,
    skipSave = false
  ): Promise<void> {
    if (dataSet == null) {
      dataSet = await firstValueFrom(
        this.getNotEmptyCompany().pipe(
          switchMap((company) =>
            this._companyService.getPrivateCompany(
              company.uid,
              false,
              true,
              PrivateCompanyDataService.blockCid
            )
          ),
          switchMap((privateCompany) =>
            this._getRegionForCompany(privateCompany).pipe(
              switchMap((region) =>
                this._revenueService
                  .getRevenueRanges(
                    region.uid,
                    null,
                    privateCompany.subIndustry.uid,
                    null,
                    PrivateCompanyDataService.blockCid
                  )
                  .pipe(
                    map((ranges: RevenueRange[]) => ({
                      industry: privateCompany.industry,
                      subIndustry: privateCompany.subIndustry,
                      revenue: ranges[0],
                      region,
                    }))
                  )
              )
            )
          )
        )
      );
    }

    this._basedOnIndustry.next(dataSet);

    if (dataSet) {
      const company = await firstValueFrom(this.getNotEmptyCompany());

      this._amplitudeService.amplitudeEvent('Source Industry Data change', {
        target: 'Private Company',
        name: company.name,
        value: dataSet?.sic !== null ? 'Industry' : 'SIC',
        sourceValue: dataSet?.subIndustry?.name || dataSet.industry.name,
        region: dataSet.region.name,
        revenue: getRevenueText(dataSet.revenue),
      });
    }
    if (!skipSave) {
      firstValueFrom(
        combineLatest([
          this._userService.getCurrentUser(),
          this.getNotEmptyCompany(),
        ]).pipe(
          switchMap(([user, company]) =>
            this._companyService.setUserPrivateCompanyData(
              user.uid,
              company.uid,
              dataSet?.revenue.uid
            )
          )
        )
      );
    }
  }
  getBasedIndustryDataRaw(): Observable<IndustryDataSet> {
    return this._basedOnIndustry.asObservable();
  }
  //#endregion

  private _getRegionForCompany(company: Company): Observable<Region> {
    return this._regionService
      .getRegionsBySelection({
        industryUid:
          company.subIndustry == null ? company.industry.uid : undefined,
        subIndustryUid:
          company.sic == null ? company.subIndustry?.uid : undefined,
        standardIndustrialClassificationUid: company.sic?.uid,
      })
      .pipe(
        map((regions: Region[]) => {
          let region = regions?.find(
            (item: Region) => item.uid === company.regionUid
          );

          if (region == null) {
            region = regions?.find(
              (item: Region) =>
                item.uid ===
                (company.subIndustry || company.industry).defaultRegionUid
            );
          }

          return region;
        })
      );
  }
}
