import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter, first, map, switchMap, tap } from 'rxjs';
import { FnlLearnMoreService } from './learn-more.service';
import { FnlLearnMoreItem } from './models/fnl-learn-more.model';
import { NavigationEnd, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'fnl-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('appearSidebar', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('200ms', style({ opacity: 0, transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class FnlLearnMoreComponent implements OnInit {
  @Input() canEdit = false;
  @Input() contentWrapperClass: string[] = [];
  @Output() editTopic: EventEmitter<string> = new EventEmitter<string>();

  isOpen$: Observable<boolean> = this.learnMoreService.isOpen$;
  learnMoreObject$: Observable<FnlLearnMoreItem>;

  constructor(
    private learnMoreService: FnlLearnMoreService,
    private sanitize: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getLearnMoreObject();
    this.subsbscribeOnRouteChange();
  }

  closeSidebar(): void {
    this.learnMoreService.close();
  }

  editLearnMoreTopic(): void {
    this.learnMoreObject$
      .pipe(
        first(),
        untilDestroyed(this),
        tap((data) => this.editTopic.next(data.code))
      )
      .subscribe();

    this.closeSidebar();
  }

  private getLearnMoreObject(): void {
    /**
     * Add sanitize template for prevent bugs and save protection
     */
    this.learnMoreObject$ = this.learnMoreService.learnMoreObject$.pipe(
      map((learnMOreObject: FnlLearnMoreItem) => {
        const sanitizedHtml = this.sanitize.bypassSecurityTrustHtml(
          learnMOreObject?.content
        );

        return {
          ...learnMOreObject,
          content: sanitizedHtml,
        } as FnlLearnMoreItem;
      })
    );
  }

  private subsbscribeOnRouteChange(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap(() =>
          this.isOpen$.pipe(
            first(),
            tap((isOpen) => {
              if (isOpen) {
                this.closeSidebar();
              }
            })
          )
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
