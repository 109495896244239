import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddedCompaniesData,
  AddedCompaniesFilter,
  MetricsForCustomCompanies,
  NewAddedCompany,
} from '@app/core/models/company.model';
import {
  ApiService,
  BaseCreateResponse,
  BaseErrorResponse,
} from '@app/core/services/api.service';
import { Dictionary, isNil, omitBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ListPage } from './client.service';
import { BaseList } from '../models/common.model';
import { CACHE_EXPIRE_DISTANCE } from '../constants';
import { timezoneCorrection } from '../utils/date.util';
export type CustomSorter = {
  property: string;
  direction: SortCustomDirection;
};

export type SortCustomDirection = 'Ascending' | 'Descending ' | '';

@Injectable({
  providedIn: 'root',
})
export class CustomCompanyService {
  private _cache: Dictionary<{ data: NewAddedCompany; time: Date }> = {};

  constructor(private apiService: ApiService) {}

  getAddedCompanies(
    userUid: string,
    page: ListPage,
    sort?: CustomSorter[],
    filter?: AddedCompaniesFilter,
    blockCid?: string
  ): Observable<BaseList<AddedCompaniesData>> {
    return this.apiService
      .post<BaseList<AddedCompaniesData>>(
        `company/users/${userUid}/custom-companies/query`,
        omitBy(
          {
            page,
            sort,
            filter,
          },
          isNil
        ),
        blockCid,
        -1
      )
      .pipe(
        map(
          (list) =>
            ({
              ...list,
              items: list.items.map((item) => ({
                ...item,
                dateLastUpdated:
                  item.dateLastUpdated != null
                    ? timezoneCorrection(item.dateLastUpdated as string)
                    : null,
              })),
            } as BaseList<AddedCompaniesData>)
        ),
        catchError(() =>
          of({
            items: [] as AddedCompaniesData[],
            totalCount: 0,
          } as BaseList<AddedCompaniesData>)
        )
      );
  }

  removeAddedCompany(
    userUid: string,
    uid: string,
    blockCid?: string
  ): Observable<boolean> {
    return this.apiService
      .delete<{ uid: string }>(
        `company/users/${userUid}/custom-companies/${uid}`,
        blockCid
      )
      .pipe(
        map((data) => data.uid != null),
        catchError(() => of(false))
      );
  }

  getCustomCompany(
    userUid: string,
    companyUid: string,
    force = false,
    useCache = false,
    blockId?: string
  ): Observable<NewAddedCompany> {
    const cacheUid = this._getCacheUid(userUid, companyUid);
    if (
      this._cache[cacheUid] != null &&
      (Date.now() - this._cache[cacheUid].time.getTime() <
        CACHE_EXPIRE_DISTANCE ||
        useCache) &&
      !force
    ) {
      return of(this._cache[cacheUid].data);
    }
    return this.apiService
      .get<NewAddedCompany>(
        `company/users/${userUid}/custom-companies/${companyUid}`,
        blockId
      )
      .pipe(
        tap((customCompany: NewAddedCompany) => {
          this._cache[cacheUid] = {
            data: customCompany,
            time: new Date(),
          };
        }),
        catchError(() => of(null))
      );
  }

  getCustomCompanyMetrics(
    categoryUid: string,
    blockId?: string
  ): Observable<MetricsForCustomCompanies[]> {
    return this.apiService
      .get<{ items: MetricsForCustomCompanies[] }>(
        `company/custom-companies/metrics?categoryUid=${categoryUid}`,
        blockId
      )
      .pipe(
        map((items) => items.items),
        catchError(() => of(null))
      );
  }

  createCustomCompany(
    userUid: string,
    companyData: NewAddedCompany,
    blockId?: string
  ): Observable<BaseCreateResponse> {
    return this.apiService
      .post<BaseCreateResponse>(
        `company/users/${userUid}/custom-companies`,
        companyData,
        blockId
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  updateCustomCompany(
    userUid: string,
    companyUid: string,
    companyData: NewAddedCompany,
    blockId?: string
  ): Observable<BaseCreateResponse> {
    this._cache[this._getCacheUid(userUid, companyUid)] = null;

    return this.apiService
      .put<BaseCreateResponse>(
        `company/users/${userUid}/custom-companies/${companyUid}`,
        companyData,
        blockId
      )
      .pipe(
        map(() => ({ entityUid: userUid } as BaseCreateResponse)),
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  private _getCacheUid(uid: string, secondUid: string): string {
    return `${secondUid}_${uid}`;
  }
}
