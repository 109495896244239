import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import {
  delay,
  mergeMap,
  retryWhen,
  share,
  throttleTime,
} from 'rxjs/operators';

export function cache() {
  return <T>(source: Observable<T>) => {
    const rSubject = new ReplaySubject<T>(1);
    return source.pipe(
      share({
        connector: () => rSubject,
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: true,
      })
    );
  };
}

export function smooth() {
  return <T>(source: Observable<T>) =>
    source.pipe(
      throttleTime(50, undefined, {
        leading: false,
        trailing: true,
      })
    );
}

const DEFAULT_MAX_RETRIES = -1;
const DEFAULT_BACKOFF = 1000;
const DEFAULT_RETRY_DELAY = 2000;
export function retryWithBackoff(
  maxRetry = DEFAULT_MAX_RETRIES,
  delayMs = DEFAULT_RETRY_DELAY,
  backoffMs = DEFAULT_BACKOFF
) {
  let retries = 0;
  return <T>(source: Observable<T>) =>
    source.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          mergeMap((error) => {
            maxRetry =
              maxRetry >= 0
                ? maxRetry
                : error.status === 429
                ? 2
                : error.status >= 500
                ? 3
                : 0;
            if (retries < maxRetry) {
              const backoffTime = backoffMs + retries * delayMs;
              retries++;
              return of(error).pipe(delay(backoffTime));
            }
            return throwError(error);
          })
        )
      )
    );
}
