import { Injectable } from '@angular/core';
import { BlockedItem, LockState } from '@app/core/models/blocker.model';
import { Dictionary } from 'lodash';
import { Observable, Subject } from 'rxjs';

/**
 * @deprecated
 *
 * Use FnlUiBlockerService with fnl-ui-blocker component from fnl-ui library instead.
 */
@Injectable()
export class BlockerService {
  private _blockerChanel$: Subject<BlockedItem> = new Subject();
  private _blockedCIdsByUrl: Dictionary<string[]> = {};
  private _preparedCids: Dictionary<string> = {};

  get chanel$(): Observable<BlockedItem> {
    return this._blockerChanel$.asObservable();
  }

  prepareUrlLock(lockCId: string, lockUrl: string): void {
    this._preparedCids[lockUrl] = lockCId;
  }
  approveUrlLock(lockUrl: string): void {
    if (this._preparedCids[lockUrl] != null) {
      this.addLock(this._preparedCids[lockUrl], lockUrl);
      this._preparedCids[lockUrl] = null;
    }
  }

  addLock(lockCId: string, lockUrl?: string): void {
    lockUrl = this.getLockUrl(lockUrl);
    if (this._blockedCIdsByUrl[lockUrl] != null) {
      this._blockedCIdsByUrl[lockUrl].push(lockCId);
    } else {
      this._blockedCIdsByUrl[lockUrl] = [lockCId];
    }

    this._blockerChanel$.next({
      lockCId,
      lockUrl,
      lockState: LockState.Lock,
    });
  }

  removeLock(
    lockCId: string,
    lockUrl?: string,
    fullRemove: boolean = true
  ): void {
    lockUrl = this.getLockUrl(lockUrl);
    if (
      fullRemove &&
      this._blockedCIdsByUrl[lockUrl] != null &&
      this._blockedCIdsByUrl[lockUrl].length !== 0
    ) {
      this._blockedCIdsByUrl[lockUrl] = this._blockedCIdsByUrl[lockUrl].filter(
        (cId: string) => cId !== lockCId
      );

      if (this._blockedCIdsByUrl[lockUrl].length === 0) {
        this._blockedCIdsByUrl[lockUrl] = null;
      }
    }

    this._blockerChanel$.next({
      lockCId,
      lockUrl,
      lockState: LockState.Unlock,
    });
  }

  removeLockByUrl(lockUrl: string): void {
    lockUrl = this.getLockUrl(lockUrl);
    if (
      this._blockedCIdsByUrl[lockUrl] != null &&
      this._blockedCIdsByUrl[lockUrl].length !== 0
    ) {
      this.removeLock(this._blockedCIdsByUrl[lockUrl].pop(), lockUrl, false);

      if (this._blockedCIdsByUrl[lockUrl].length === 0) {
        this._blockedCIdsByUrl[lockUrl] = null;
      }
    }
  }

  private getLockUrl(url?: string): string {
    return url != null ? url.split('?')[0] : 'none';
  }
}
