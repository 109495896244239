import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import scrollIntoView from 'scroll-into-view-if-needed';

import { FAQArticle, FAQClarification } from '../../side-panel-list.model';
import { SidePanelListService } from '../../side-panel-list.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-faq-article',
  templateUrl: './faq-article.component.html',
  styleUrls: [ './faq-article.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqArticleComponent implements OnInit, OnDestroy {
  @HostBinding('class.faq-article') isArticle = true;
  @Input()
  data: FAQArticle;
  @Input()
  index = 0;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChildren(MatExpansionPanel, { read: ElementRef })
  panels!: QueryList<ElementRef>;

  subscription: Subscription;

  constructor(
    private _sidePanelListService: SidePanelListService,
    private _amplitudeService: AmplitudeService,
  ) {
  }

  trackClarifications(index: number, item: FAQClarification): string {
    return item.id;
  }

  scrollTo(openedIndex: number): void {
    const element = this.panels.get(openedIndex);
    this._amplitudeService.amplitudeEvent('Help - Answer View', {
      question: this.data.clarifications[openedIndex].question,
    });

    if (element != null) {
      scrollIntoView(element.nativeElement, {
        block: 'nearest',
        inline: 'nearest',
        scrollMode: 'if-needed',
      });
    }
  }

  ngOnInit(): void {
    this.subscription = this._sidePanelListService
      .getOpenedPanel()
      .pipe(
        tap((panel: string) => {
          if (panel == null) {
            this.accordion?.closeAll();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
