import { Injectable } from '@angular/core';
import { UUID } from '@app/core/models/common.model';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface LearnMoreTreeApi {
  children: LearnMoreTreeNodeApi[];
}

interface LearnMoreTreeNodeApi {
  uid: UUID;
  caption: string;
  articleCode: string;
  children: LearnMoreTreeNodeApi[];
}

interface UpdateLearnMoreArticleApi {
  content: string;
  visible: boolean;
}

interface LearnMoreArticleUpdatedApi {
  uid: UUID;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
}

interface LearnMoreArticleApi {
  uid: UUID;
  code: string;
  clientUid: UUID;
  content: string;
  visible: boolean;
  lastUpdatedBy: UUID;
  lastUpdatedDate: string;
}

interface LearnMoreArticleVisibilityListingApi {
  items: LearnMoreArticleVisibilityApi[];
  totalCount: number;
}

interface LearnMoreArticleVisibilityApi {
  uid: UUID;
  code: string;
  clientUid: UUID;
  visible: boolean;
}

export interface LearnMoreNodeApi {
  uid: UUID;
  parentUid: UUID;
  caption: string;
  articleCode: string;
  sortOrder: number;
  articles: LearnMoreNodeClientArticleApi[]
}

export interface LearnMoreNodeClientArticleApi {
  clientUid: string;
  visible: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LearnMoreApiService {

  private readonly blockCid = 'LearnMoreApiService';

  constructor(private apiService: ApiService) {
  }

  getTree(blockCid?: string): Observable<LearnMoreTreeApi> {
    return this.apiService.get<LearnMoreTreeApi>(
      'common/learn-more/tree',
      blockCid || this.blockCid,
      -1
    );
  }

  listTopicVisibilities(
    clientUid: UUID,
  ): Observable<LearnMoreArticleVisibilityApi[]> {
    return this.apiService.get<LearnMoreArticleVisibilityListingApi>(
      `common/clients/${ clientUid }/learn-more/visibility`,
      this.blockCid,
      -1
    ).pipe(
      map(result => result.items)
    );
  }

  getLearnMoreArticle(
    clientUid: UUID,
    code: string,
    blockCid?: string
  ): Observable<LearnMoreArticleApi> {
    return this.apiService.get<LearnMoreArticleApi>(
      `common/clients/${ clientUid }/learn-more/code/${ code }`,
      blockCid || this.blockCid,
      -1
    );
  }

  updateLearnMoreArticle(
    clientUid: UUID,
    code: string,
    data: UpdateLearnMoreArticleApi,
    blockCid?: string
  ): Observable<LearnMoreArticleUpdatedApi> {
    return this.apiService.put<LearnMoreArticleUpdatedApi>(
      `common/clients/${ clientUid }/learn-more/code/${ code }`,
      data,
      blockCid || this.blockCid,
      -1
    );
  }

  deleteLearnMoreArticle(
    clientUid: UUID,
    code: string,
    blockCid?: string
  ): Observable<void> {
    return this.apiService.delete<void>(
      `common/clients/${ clientUid }/learn-more/code/${ code }`,
      blockCid || this.blockCid,
      -1
    );
  }

  getLearnMoreNodeByCode(
    code: string,
    blockCid?: string
  ): Observable<LearnMoreNodeApi> {
    return this.apiService.get<LearnMoreNodeApi>(
      `common/learn-more/nodes/code/${ code }`,
      blockCid || this.blockCid,
      -1
    );
  }
}
