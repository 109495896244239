import {parseISO, sub} from 'date-fns';

// set UTC timezone if not provided
export function timezoneCorrection(date?: string): Date {

  if (date === undefined) {
    return undefined;
  }
  if (date === null || date.trim().length === 0) {
    return null;
  }

  const offset = new Date().getTimezoneOffset();
  const isoDatePattern =
    /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;

  return !!date.match(isoDatePattern)
    ? parseISO(date)
    : sub(parseISO(date), {
      minutes: offset,
    });
}