import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { PopoverRef } from '../../popover';

@Component({
  selector: 'fnl-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: [ './info-popover.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FnlInfoPopoverComponent {
  template: TemplateRef<any>;
  isLeftAligned: boolean;
  width: number;
  height: number;

  constructor(private popoverRef: PopoverRef<FnlInfoPopoverComponent>) {
    this.template = this.popoverRef.data.template;
    this.isLeftAligned = this.popoverRef.data.isLeftAligned;
    this.width = this.popoverRef.data.width;
    this.height = this.popoverRef.data.height;
  }

  closePopover() {
    this.popoverRef.close();
  }
}
