import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserRole } from '@app/core/models/user.model';
import { NotificationsService } from '@app/core/services/notifications.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { catchError, first, map, shareReplay, startWith, switchMap, takeUntil, tap, } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AmplitudeService } from '../../api/amplitude/amplitude.service';
import { ApiService } from './api.service';
import { HotjarService } from './hotjar.service';
import {
  ClientAdministrator
} from '@app/repositories/identity/client-user-management/models/client-administrators.model';
import {
  ClientUserManagementRepository
} from '@app/repositories/identity/client-user-management/client-user-management.repository';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser$: Observable<User>;
  currentUserClientAdmins$: Observable<ClientAdministrator[]>;

  private _favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  private _titleApp: HTMLLinkElement = document.querySelector('#appTitle');

  private _refetch = true;
  private _isNeedToReload = true;

  private _user$: Observable<User>;

  private _isActive = new BehaviorSubject<boolean>(true);
  private _usersRefresher = new Subject<number>();
  private _clearer = new Subject<void>();

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _notificationsService: NotificationsService,
    private _gTagService: GoogleTagManagerService,
    private _amplitudeService: AmplitudeService,
    private _hotjarService: HotjarService,
    private _clientUserManagementRepository: ClientUserManagementRepository,
  ) {
    this.currentUser$ = this._isActive
      .pipe(
          switchMap((isActive) => this.getCurrentUser()
        .pipe(
          first(),
      )),
    );

    this.currentUserClientAdmins$ = this.getCurrentUser()
      .pipe(
        switchMap((user: User) =>
          this._clientUserManagementRepository.getClientAdministrators(user.clientUid)
        ),
        map((response) => response.items),
        shareReplay(1),
      );
  }

  getUserRefresher(): Observable<number> {
    return this._usersRefresher.asObservable();
  }

  getCurrentUser(): Observable<User> {
    if (this._user$ == null || this._refetch) {
      this._refetch = false;

      this._clear();

      this._user$ = this._usersRefresher.pipe(
        startWith(0),
        switchMap(() =>
          this._apiService.get<User>('identity/user').pipe(
            map((value: User) => ({
              ...value,
              name: value.name || 'Test User',
              email: value.email || 'test@test.com',
            })),
            tap((currentUser: User) => {
              if (this._isNeedToReload) {
                // Run GTag user sign in
                this._gTagService.pushTag({
                  clientID: currentUser.legacySiteId,
                  userId: currentUser.legacyId,
                });
                this._amplitudeService.amplitudeUserLogin(currentUser.email);
                this._hotjarService.login(
                  currentUser.legacyId,
                  currentUser.email
                );
              } else {
                this._isNeedToReload = true;
              }
              this._favIcon.href =
                currentUser.clientCode === 'IBM'
                  ? '/assets/images/logo-product-CBV.svg'
                  : '/favicon.ico';
              this._titleApp.textContent =
                currentUser.clientCode === 'IBM'
                  ? 'FinListics CBV'
                  : 'FinListics ClientIQ';
            }),
            catchError(() => {
              this._isNeedToReload = true;
              return of(null);
            })
          )
        ),
        takeUntil(this._clearer),
        shareReplay(1),
      );
    }

    return this._user$;
  }

  isUserAdmin(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user: User) => user.roleNames.includes(UserRole.FinlisticsAdmin))
    );
  }

  hasAnyRole(roles: UserRole[]): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user: User) => user.roleNames.filter(it => roles.includes(it)).length > 0)
    );
  }

  isClientAdmin(): Observable<boolean> {
    return this.hasAnyRole([UserRole.ClientAdmin, UserRole.FinlisticsAdmin]);
  }

  hasProAccess(): Observable<boolean> {
    return this.hasAnyRole([UserRole.ProUser, UserRole.ClientAdmin, UserRole.FinlisticsAdmin]);
  }

  refetchUser(): void {
    this._isNeedToReload = false;
    this._usersRefresher.next(Date.now());
  }

  isUserActive(): Observable<boolean> {
    return this._isActive.pipe(
      switchMap((isActive: boolean) =>
        isActive
          ? this.getCurrentUser().pipe(map((data) => !!data))
          : of(isActive)
      )
    );
  }

  async logOutUser(): Promise<void> {
    const result = await firstValueFrom(
      this._apiService.post<boolean>('identity/account/logout', {}).pipe(
        tap(() => {
          this._notificationsService.removeNotification('log-out');
        }),
        map(() => true),
        catchError(() => of(false))
      )
    );

    if (result) {
      this._gTagService.pushTag({
        userId: null,
      });
      this._hotjarService.logout();
      this.changeActive(false);

      if (environment.logoutRedirect) {
        window.location.replace(
          `${environment.logoutRedirect}?backURL=${window.encodeURIComponent(
            `${window.location.origin}`
          )}`
        );
      } else {
        this._router.navigate(['account', 'sign-in']);
      }
    } else {
      this._notificationsService.removeNotification(
        NotificationsService.generalError
      );
      this._notificationsService.addError({
        id: 'log-out',
        message:
          'Failed to log out. Please reload the page or try again later.',
        autoRemovable: true,
      });
    }
  }

  changeActive(isActive = false): void {
    this._refetch = isActive;

    this._isActive.next(isActive);
  }

  getActiveStatus(): Observable<boolean> {
    return this._isActive.asObservable();
  }

  getClearer(): Observable<void> {
    return this._clearer.asObservable();
  }

  private _clear() {
    this._clearer.next();

    this._user$ = null;
  }
}
