import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';

@Component({
  selector: 'app-base-header-public',
  templateUrl: './header-public.component.html',
  styleUrls: ['./header-public.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderPublicComponent {

  constructor(
    private _amplitudeService: AmplitudeService,
  ) {}

  amplitudeEventTracking(nameEvent: string): void {
    this._amplitudeService.amplitudeEvent(nameEvent);
  }

}
