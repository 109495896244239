import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-version-manager',
  templateUrl: './version-manager.component.html',
  styleUrls: [ './version-manager.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionManagerComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;

  constructor(
    private logger: NGXLogger,
    private _apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    this._subscription = this._apiService
      .get<{ version: string }>('common/version', true)
      .pipe(catchError(() => of(null)))
      .subscribe((result: { version: string }) =>
        this.logger.info(
          `API version ${
            result?.version || 0
          } - Copyright © ${ new Date().getFullYear() } FinListics Solutions. All rights reserved`
        )
      );
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }
}
