<div class="header global-wrapper">
  <div class="header__logo">
    <a
      class="header__logo-image"
      routerLink="/"
      (click)="amplitudeEventTracking('Logo Click')"
    >
      <img src="/assets/images/logo.svg"
           alt="logo"/>
    </a>
  </div>
</div>
