<div
  class="history-view"
  [ngClass]="{
    'history-view_theme_default': isDefaultTheme,
    'history-view_theme_header': isHeaderTheme
  }"
>
  <div
    *ngFor="let item of items; trackBy: trackByUid"
    class="history-view__item"
  >
    <app-icon-button
      *ngIf="isFavorites"
      class="history-view__item-action"
      (click)="removeItem(item)"
      matTooltip="Remove from Favorites"
      i18n-matTooltip
    >
      <mat-icon class="history-view__item-action-icon">favorites</mat-icon>
    </app-icon-button>
    <div *ngIf="isHeaderTheme" class="history-view__item-class">
      <app-chip *ngIf="isCompany(item)" color="secondary" i18n>
        Company
      </app-chip>
      <app-chip *ngIf="isIndustry(item)" i18n>Industry</app-chip>
    </div>
    <a
      class="history-view__item-name"
      [routerLink]="getRoute(item)"
      (click)="navigatedTo(item, isFavorites)"
    >
      <ng-container *ngIf="isIndustry(item)">
        {{ itemName(item) }} - {{ itemRegion(item) }}
      </ng-container>
      <ng-container *ngIf="isCompany(item)">
        {{ itemName(item) }}
        <span *ngIf="item.isPrivateCompany" class="history-view__item-private"
          >(PRIVATE)</span
        >
        <span *ngIf="item.isCustomCompany" class="history-view__item-custom"
          >(CUSTOM)</span
        >
      </ng-container>
    </a>
  </div>
  <ng-container *ngIf="items?.length === 0">
    <app-error-no-data
      *ngIf="isFavorites"
      class="history-view__caption"
      errorText="You have no favorite items for now. Once you mark your search result as a favorite one it will be displayed here."
      i18n-errorText
      [compact]="isDefaultTheme"
    ></app-error-no-data>
    <app-error-no-data
      *ngIf="!isFavorites"
      class="history-view__caption"
      errorText="You have no recently viewed items for now. Once you start using the search bar, all recently viewed items will be displayed here."
      i18n-errorText
      [compact]="isDefaultTheme"
    ></app-error-no-data>
  </ng-container>
</div>
