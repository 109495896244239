<div class="help__header">
  <h3 i18n class="help__title">Ask a Question or Provide Feedback</h3>
  <p *ngIf="!isResponseSuccess" class="help__description">
    If you would like assistance with customization options, or have questions, please send us a message here,
    or contact us at <a href="mailto:help@finlistics.com">help@FinListics.com</a>.
  </p>
</div>

<app-help-content-success
  *ngIf="isResponseSuccess"
  (reply)="handleResponseSuccess(false)"
></app-help-content-success>

<app-ui-blocker *ngIf="!isResponseSuccess" [cId]="'HELP_request'">
  <app-help-content-form
    (successChange)="handleResponseSuccess($event)"
  ></app-help-content-form>
</app-ui-blocker>
