import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { FAQArticle } from '../side-panel-list.model';
import { SidePanelListService } from '../side-panel-list.service';

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html',
  styleUrls: ['./faq-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqContentComponent implements OnInit {
  articles$: Observable<FAQArticle[]>;

  constructor(private sidePanelListService: SidePanelListService) {}

  trackArticle(index: number, item: FAQArticle): string {
    return item.id;
  }

  ngOnInit(): void {
    this.articles$ = this.sidePanelListService.getFAQContent();
  }
}
