import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserRole} from '@app/core/models/user.model';
import {UserService} from '@app/core/services/user.service';

import {AuthGuard} from './auth.guard';
import {AnyRoleGuard} from './any-role.guard';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard extends AnyRoleGuard {
  constructor(
    _router: Router,
    _authGuard: AuthGuard,
    _userService: UserService
  ) {
    super(_router, _authGuard, _userService, [UserRole.ClientAdmin, UserRole.FinlisticsAdmin])
  }
}
