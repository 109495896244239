import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'fnl-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FnlIconButtonComponent implements OnInit {
  @Input() disabled = false;

  @Input() isRippleSmall = false;
  @Input() rippleWidth = 34;
  @Input() rippleHeight = 34;

  ngOnInit() {
    if (this.isRippleSmall) {
      this.rippleWidth = 28;
      this.rippleHeight = 28;
    }
  }
}
