import {
  Component,
  HostListener,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
  animations: [
    trigger('appearInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('400ms', style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackToTopComponent {
  isBackToTopShown = false;

  private readonly scrollDistance = 80;
  private readonly animate = false;

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isBackToTopShown =
      this.getCurrentScrollTop() > this.scrollDistance / 2 ? true : false;
  }

  getCurrentScrollTop() {
    return document.documentElement.scrollTop;
  }

  scrollTop() {
    if (this.animate) {
      this.animateScrollTop();
    } else {
      window.scrollTo(0, 0);
    }
  }

  animateScrollTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

}
