import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';

@Component({
  selector: 'app-learn-basics-button',
  templateUrl: './learn-basics-button.component.html',
  styleUrls: ['./learn-basics-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnBasicsButtonComponent {
  @Input() isOpened = false;
  @Input() isActive = false;

  constructor(
    public elRef: ElementRef,
    private _amplitudeService: AmplitudeService
  ) {}

  triggerAmplitude(): void {
    this._amplitudeService.amplitudeEvent('Learn the Basics Click');
  }
}
