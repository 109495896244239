import {
  ContentChild,
  Directive,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';

type FieldType = 'small' | 'medium' | 'large' | '' | undefined;

export type FnlFormFieldThemes = 'light' | 'dark';

const DEFAULT_FIELD_TYPE: FieldType = 'small';

@Directive({
  selector: 'mat-form-field[fnlFormField]',
  standalone: true,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'fnl-overlay-pane' }
    },
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'fnl-overlay-pane' }
    },
  ],
})
export class FnlFormFieldDirective implements OnInit, OnChanges {

  /**
   * Field type.
   * Default: small.
   */
  @Input() fnlFormField: FieldType = DEFAULT_FIELD_TYPE;
  /**
   * Always render subscript wrapper element where mat-error or mat-hint can be displayed.
   * This is a one line element that will be rendered below the input element.
   * It is useful when you want to display error or hint message below the input element without changing the height of the content.
   */
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() alwaysRenderSubscriptWrapper: boolean = false;

  @Input() theme: FnlFormFieldThemes = 'light';

  @ContentChild(MatSelect, { static: true }) matSelect: MatSelect;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private matFormField: MatFormField,
    private renderer2: Renderer2,
  ) {
    this.matFormField.appearance = 'outline';
  }

  ngOnInit(): void {
    const el = this.viewContainerRef.element.nativeElement;
    this.renderer2.addClass(el, 'fnl-form-field');

    // Hide single selection indicator for mat-select
    if (this.matSelect) {
      this.matSelect.hideSingleSelectionIndicator = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('fnlFormField' in changes) {
      if (changes.fnlFormField.currentValue === changes.fnlFormField.previousValue) {
        return;
      }

      const clsToRemove = this.getElementCssClass(changes.fnlFormField.previousValue);
      const clsToAdd = this.getElementCssClass(changes.fnlFormField.currentValue);
      const el = this.viewContainerRef.element.nativeElement;

      if (clsToRemove) {
        this.renderer2.removeClass(el, clsToRemove);
      }
      if (clsToAdd) {
        this.renderer2.addClass(el, clsToAdd);
      }
    }

    if ('alwaysRenderSubscriptWrapper' in changes) {
      if (changes.alwaysRenderSubscriptWrapper.currentValue === changes.alwaysRenderSubscriptWrapper.previousValue) {
        return;
      }

      const el = this.viewContainerRef.element.nativeElement;
      if (this.alwaysRenderSubscriptWrapper) {
        this.renderer2.addClass(el, 'fnl-form-field--render-subscript-wrapper');
      } else {
        this.renderer2.removeClass(el, 'fnl-form-field--render-subscript-wrapper');
      }
    }

    if ('theme' in changes) {
      if (changes.theme.currentValue === changes.theme.previousValue) {
        return;
      }

      const el = this.viewContainerRef.element.nativeElement;
      if (this.theme === 'dark') {
        this.renderer2.addClass(el, 'fnl-form-field--dark-theme');
        if (this.matSelect) {
          this.matSelect.panelClass = 'fnl-form-field--dark-theme';
        }
      } else {
        this.renderer2.removeClass(el, 'fnl-form-field--dark-theme');
        if (this.matSelect) {
          this.matSelect.panelClass = '';
        }
      }
    }
  }

  private getElementCssClass(fieldType: FieldType): string {
    if (!fieldType) {
      fieldType = DEFAULT_FIELD_TYPE;
    }

    return `fnl-form-field--${ fieldType }`;
  }
}
