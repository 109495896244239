import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BaseGroupItem, BaseItem } from '@app/core/models/common.model';
import { trackByUid } from '@app/core/utils/base';
import { PopoverRef } from '@app/shared/popover';
import { debounce, flatMap } from 'lodash';
import scrollIntoView from 'scroll-into-view-if-needed';
import { BaseSelectorButtonComponent } from '../base-selector-button/base-selector-button.component';
import { BaseSelectorInputComponent } from '../base-selector-input/base-selector-input.component';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import {
  MatLegacySelectionList as MatSelectionList,
  MatLegacySelectionListChange as MatSelectionListChange,
} from '@angular/material/legacy-list';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
  selector: 'app-base-selector-popover',
  templateUrl: './base-selector-popover.component.html',
  styleUrls: ['./base-selector-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    BaseSelectorButtonComponent,
    BaseSelectorInputComponent,
    CommonModule,
    MatListModule,
    A11yModule 
  ]
})
export class BaseSelectorPopoverComponent implements AfterViewInit {
  @ViewChild('handle', { read: ElementRef }) handleRef: ElementRef;
  @ViewChild('content') contentRef: ElementRef<HTMLElement>;
  @ViewChild('list') listRef: MatSelectionList;
  items: BaseItem[];
  groups: BaseGroupItem[];
  withGroups = false;
  selected?: BaseItem;
  width: number;
  inputLike: 'none' | 'base' | 'form';
  inverted: boolean;
  placeholder?: string;
  isFullWidth = false;
  containerWidth = 0;
  classNames?: { button?: string; popover?: string };

  trackByUid = trackByUid;

  private _searchText = '';

  private _debouncedClearSearch = debounce(this._clearSearch, 1500);

  constructor(
    private _popoverRef: PopoverRef<BaseSelectorPopoverComponent>,
    private _cdr: ChangeDetectorRef
  ) {
    this.withGroups = this._popoverRef.data.withGroups;
    this.groups = this.withGroups
      ? (this._popoverRef.data.items as BaseGroupItem[])
      : [];
    this.items = !this.withGroups
      ? (this._popoverRef.data.items as BaseItem[])
      : flatMap(this.groups, 'items');
    this.selected = this._popoverRef.data.selected;
    this.width = this._popoverRef.data.width;
    this.inputLike = this._popoverRef.data.inputLike;
    this.inverted = this._popoverRef.data.inverted;
    this.placeholder = this._popoverRef.data.placeholder;
    this.classNames = this._popoverRef.data.classNames;
  }

  get fullInput(): boolean {
    return this.inputLike !== 'none';
  }

  ngAfterViewInit(): void {
    this._initFullWidthFlag();
  }

  isItemSelected(item: BaseItem): boolean {
    return this.selected?.uid === item.uid;
  }

  closePopover(): void {
    this._popoverRef.close();
  }

  updateSelected(selected: BaseItem): void {
    this._popoverRef.close(selected.uid);
  }
  updateSelection(data: MatSelectionListChange): void {
    this._popoverRef.close(data.options[0]?.value);
  }
  runPreSearch(event: KeyboardEvent): void {
    this._searchText += event.key.toLowerCase();

    const firstToFind = this.items.find((item) =>
      item.name.toLowerCase().includes(this._searchText)
    );
    if (firstToFind != null) {
      this.listRef.options
        .find((item) => item.value === firstToFind.uid)
        ?.focus();
    }
    this._debouncedClearSearch();
  }

  private _clearSearch() {
    this._searchText = '';
  }

  private _initFullWidthFlag(): void {
    // setTimeout to wait when all data will be rendered
    setTimeout(() => {
      const handleElement = this.handleRef.nativeElement;
      const listElement = this.contentRef.nativeElement;
      const handleWidth = handleElement.getBoundingClientRect().width;
      const listWidth = listElement.getBoundingClientRect().width;
      this.isFullWidth = handleWidth >= listWidth;
      this.containerWidth = this.isFullWidth
        ? handleWidth
        : handleWidth >= listWidth - 12
        ? handleWidth + 12
        : 0;
      this._cdr.markForCheck();

      const selectedElement = this.contentRef.nativeElement.querySelector(
        '.base-selector-list__item_selected'
      );

      if (selectedElement != null) {
        scrollIntoView(selectedElement, {
          block: 'start',
          inline: 'start',
          scrollMode: 'if-needed',
          boundary: this.contentRef.nativeElement,
        });
      }
    });
  }
}
