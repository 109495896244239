import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { tuiDefaultProp } from '@taiga-ui/cdk';

/**
 * @deprecated Use {@link FnlErrorNoDataComponent} instead.
 */
@Component({
  selector: 'app-error-no-data',
  templateUrl: './error-no-data.component.html',
  styleUrls: ['./error-no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorNoDataComponent {
  @Input() errorText: string;
  @Input()
  @tuiDefaultProp()
  @HostBinding('class.compact')
  compact = false;
}
