import { Injectable } from '@angular/core';
import { FnlLearnMoreItem } from './models/fnl-learn-more.model';
import { BehaviorSubject, Observable } from 'rxjs';


/**
 * Learn More component manager
 */
@Injectable({
  providedIn: 'root',
})
export class FnlLearnMoreService {
  isOpen$: Observable<boolean>;
  learnMoreObject$: Observable<FnlLearnMoreItem>;

  afterClosed$: Observable<void>;

  private isOpenSubject = new BehaviorSubject<boolean>(false);
  private learnMoreObjectSubject = new BehaviorSubject<FnlLearnMoreItem>(null);

  constructor() {
    this.isOpen$ = this.isOpenSubject.asObservable();
    this.learnMoreObject$ = this.learnMoreObjectSubject.asObservable();
  }

  open(learnMoreObj: FnlLearnMoreItem): void {
    this.isOpenSubject.next(true);
    this.learnMoreObjectSubject.next(learnMoreObj);
  }

  close(): void {
    this.isOpenSubject.next(false);
  }
}
