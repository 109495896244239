<div class="field-search" [ngClass]="{ 'field-search_dark': isDark }">
  <div class="field-search__input">
    <mat-icon class="field-search__input-icon" svgIcon="search"></mat-icon>
    <input
      class="field-search__input-field"
      type="text"
      [placeholder]="placeholder"
      i18n-placeholder
      [(ngModel)]="value"
      (input)="submit()"
      (blur)="trimOnBlur()"
    />
    <app-icon-button
      class="field-search__input-clear"
      matTooltip="Clear"
      i18n-matTooltip
      [matTooltipDisabled]="isEmpty"
      [disabled]="isEmpty"
      (click)="clearValue()"
    >
      <mat-icon
        class="field-search__input-clear-icon"
        [ngClass]="{ 'field-search__input-clear-icon_disabled': isEmpty }"
        svgIcon="close"
      ></mat-icon>
    </app-icon-button>
  </div>
  <button class="field-search__button" (click)="submit()">Search</button>
</div>
