import { Injectable } from '@angular/core';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { Dictionary } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { PrivateCompanyDataService } from './private-company-data.service';

@Injectable()
export class PrivateCompanyAmplitudeService {
  constructor(
    private _amplitudeService: AmplitudeService,
    private _privateCompanyDataService: PrivateCompanyDataService
  ) {}

  async triggerAmplitude(
    nameEvent: string,
    action?: Dictionary<unknown>
  ): Promise<void> {
    const name = await this._getPrivateCompanyName();

    const properties: Dictionary<unknown> = {
      target: 'Private Company',
      name,
    };

    this._amplitudeService.amplitudeEvent(
      nameEvent,
      !action ? properties : { ...properties, ...action }
    );
  }

  private async _getPrivateCompanyName(): Promise<string> {
    const company = await firstValueFrom(
      this._privateCompanyDataService.getNotEmptyCompany()
    );

    return company.name;
  }
}
