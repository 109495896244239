<button
  #button
  type="button"
  class="base-selector-button"
  [class.base-selector-button_opened]="isOpened"
  [class.base-selector-button_disabled]="disabled"
  [disabled]="disabled"
  [attr.tabIndex]="focusable ? 0 : -1"
>
  <span *ngIf="placeholder" class="base-selector-button__label"
    >{{ placeholder }}{{ selected != null ? ':' : '' }}</span
  >
  <span class="base-selector-button__value">
    {{ selected?.name || '' }}
  </span>
  <mat-icon
    class="base-selector-button__arrow"
    svgIcon="navigate_before"
  ></mat-icon>
</button>
