<form
  novalidate
  autocomplete="off"
  class="help-content__form"
  [formGroup]="helpPanelForm"
  (ngSubmit)="onSubmit(helpPanelForm.value)"
>
  <div class="help-content__form-fields">
    <div class="help-content__form-field">
      <textarea
        name="message"
        formControlName="message"
        i18n-placeholder
        placeholder="Message"
        maxlength="1000"
        [class.submitted]="isFormSubmitted && !isFormCleared"
      >
      </textarea>
      <app-error *ngIf="hasError('message', 'required')">
        Required field.
      </app-error>
    </div>

    <div class="help-content__form-validation" *ngIf="isErrorOpened">
      <app-validation-error i18n (hideError)="handleHideError()">
        Failed to send a message. Try again please.
      </app-validation-error>
    </div>
  </div>

  <div class="help-content__form-actions">
    <button
      type="submit"
      i18n
      mat-raised-button
      color="primary"
      class="help-content__form-actions-button"
    >
      Send message
    </button>
  </div>
</form>
