import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

type VoidFunc = (...args: any[]) => void;

export interface HotjarRunner extends VoidFunc {
  q: any[];
}

export interface HotjarSettings {
  hjid: number;
  hjsv: number;
}

export interface WindowWithHotjar extends Window {
  hj?: HotjarRunner;
  _hjSettings?: HotjarSettings;
}

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  private _isEnabled = new BehaviorSubject<[boolean, boolean]>([false, false]);
  private _window: WindowWithHotjar;

  constructor(@Inject(DOCUMENT) private _document: Document) {
    this._window = this._document.defaultView;
  }

  injectHotjar(): HotjarSettings {
    if (this._window == null) {
      // eslint-disable-next-line @typescript-eslint/quotes,no-console
      console.warn("Hotjar can't be injected without window object");
      return null;
    }

    const hj = (this._window.hj =
      this._window.hj ||
      (function (...args: any[]) {
        (hj.q = hj.q || []).push(args);
      } as HotjarRunner));
    this._window._hjSettings = environment.hotjarSiteId
      ? { hjid: environment.hotjarSiteId, hjsv: 6 }
      : null;

    const value = this._isEnabled.getValue();
    this._isEnabled.next([true, value[1]]);

    return this._window._hjSettings;
  }

  enable(): void {
    const value = this._isEnabled.getValue();
    this._isEnabled.next([value[0], true]);
  }
  disable(): void {
    const value = this._isEnabled.getValue();
    this._isEnabled.next([value[0], false]);
  }

  login(userId: string, email?: string): void {
    if (this._isEnabled.getValue()[0]) {
      this._window?.hj('identify', userId, {
        email,
      });
    }
  }
  logout(): void {
    if (this._isEnabled.getValue()[0]) {
      this._window?.hj('identify', null);
    }
  }
}
