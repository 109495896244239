import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Region, RegionSelection } from '@app/core/models/region.model';
import { cache } from '@app/core/operators';
import { ApiService } from '@app/core/services/api.service';
import { UserService } from '@app/core/services/user.service';
import { sortAscDefault } from '@app/core/utils/base';
import { isNil, omitBy } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private readonly defaultRegionName = 'Global';
  private regions$: Observable<Region[]>;
  private defaultRegion$: Observable<Region>;

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    // eslint-disable-next-line import/no-deprecated
    this.userService.getActiveStatus().subscribe((isActive: boolean) => {
      if (!isActive) {
        this.regions$ = null;
        this.defaultRegion$ = null;
      }
    });
  }

  getRegions(blockCid?: string): Observable<Region[]> {
    if (!this.regions$) {
      this.regions$ = this.apiService
        .get<Region[]>('common/region', blockCid)
        .pipe(
          map((regions) => this.orderRegions(regions)),
          catchError(() => {
            this.regions$ = null;
            return of([]) as Observable<Region[]>;
          }),
          shareReplay(1)
        );
    }

    return this.regions$;
  }

  getRegionsWithCategory(categoryUid?: string, excludeEmpty = true,  blockCid?: string): Observable<Region[]> {
      return this.apiService
        .get<Region[]>('common/region' + 
          (categoryUid ? `?categoryUid=${categoryUid}` : '') + 
          `${categoryUid ? '&' : '?'}excludeEmpty=${excludeEmpty}`,
           blockCid
          )
        .pipe(
          map((regions) => this.orderRegions(regions)),
          catchError(() => {
            this.regions$ = null;
            return of([]) as Observable<Region[]>;
          }),
          shareReplay(1)
        );
  }

  getDefaultRegion(): Observable<Region> {
    if (!this.defaultRegion$) {
      this.defaultRegion$ = this.getRegions().pipe(
        map((regions) =>
          regions.find((r) => r.name === this.defaultRegionName)
        ),
        cache()
      );
    }

    return this.defaultRegion$;
  }

  getSelectedRegionsUIds(selection: RegionSelection): Observable<string[]> {
    const httpParams = new HttpParams({
      fromObject: omitBy(selection, isNil),
    });

    return this.apiService
      .get<string[]>('industryResearch/industry/regions', {
        params: httpParams,
      })
      .pipe(
        catchError(() => of([]) as Observable<string[]>),
        cache()
      );
  }

  getRegionsBySelection(selection: RegionSelection): Observable<Region[]> {
    return combineLatest([
      this.getSelectedRegionsUIds(selection),
      this.getRegions(),
    ]).pipe(
      map(([selectedRegionsUIds, allRegions]) => {
        const selectedRegionsMap = selectedRegionsUIds.reduce(
          (mapped, value) => {
            mapped[value] = true;
            return mapped;
          },
          {}
        );

        return allRegions.filter((value) => selectedRegionsMap[value.uid]);
      })
    );
  }

  findSelectedRegion(
    regions: Region[],
    defaultRegion: Region,
    regionId?: string
  ): Region {
    if (!regionId) {
      return defaultRegion;
    }

    return regions.find((region) => region.uid === regionId);
  }

  private orderRegions(regions): Region[] {
    return sortAscDefault(regions, 'name');
  }
}
