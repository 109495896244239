import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FinAbstractControlComponent } from '@app/core/utils/abstract-control.component';
import { fixNumber } from '@app/core/utils/base';
import { tuiDefaultProp } from '@taiga-ui/cdk';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { CommonModule } from '@angular/common';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-limited-input',
  templateUrl: './limited-input.component.html',
  styleUrls: [ './limited-input.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LimitedInputComponent),
    },
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    DigitOnlyModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    MatIconModule,
  ]
})
export class LimitedInputComponent extends FinAbstractControlComponent<number> {
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input()
  @tuiDefaultProp()
  value = 0;
  @Input()
  defaultValue?: number;
  @Output() valueChange = new EventEmitter<number>();
  @Input()
  @tuiDefaultProp()
  max = 100;
  @Input()
  @tuiDefaultProp()
  min = 1;
  @Input()
  @tuiDefaultProp()
  placeholder = '0.0';
  @Input()
  @tuiDefaultProp()
  disabled = false;
  @Input()
  @tuiDefaultProp()
  decimal = 1;
  @Input()
  @tuiDefaultProp()
  invalid = false;
  @Input() showClear = false;
  @Input() clearTooltip = 'clear';
  @Output() valueClear = new EventEmitter<void>();

  private _valueInput: string;
  private _clearClicked = false;

  constructor(
    public platform: Platform,
    injector: Injector,
    cdr: ChangeDetectorRef
  ) {
    super(injector, cdr);
  }

  get pattern(): RegExp {
    return this.decimal !== 0
      ? new RegExp(`^\\d*\\.?(\\d{1,${this.decimal}})$`)
      : /^\d+$/;
  }

  get step(): string {
    return (this.decimal === 0 ? 1 : Math.pow(0.1, this.decimal)).toFixed(
      this.decimal
    );
  }

  get valueInput(): string {
    return this._valueInput != null ? this._valueInput : this.value?.toString();
  }

  set valueInput(input: string) {
    this._valueInput = input != null ? input : '';
  }

  writeValue(value: number): void {
    this.value = value;
    this._cdr.markForCheck();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onBlur(): void {
    if (this.disabled) {
      return;
    }

    this._updateValue(this._getValue());
  }

  onTouch(): void {
    this._clearClicked = false;
    if (!this._touched || !this._formControl.touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  onClear(event: MouseEvent): void {
    event.stopPropagation();
    this._clearClicked = true;
    this.valueClear.emit();
  }

  updateToFixed(fixed: number): void {
    if (fixed % 1 > 0 && !this.decimal) {
      return;
    }

    const value = this._getValue() + fixed;

    if (value >= this.min && value <= this.max) {
      this.valueInput =
        value % 1 > 0 ? value.toFixed(this.decimal) : value.toString();
    }
  }

  private _getValue(): number {
    let value: number;
    if (this.valueInput !== '') {
      value = Number(this.valueInput);
      value = !Number.isNaN(value) ? value : this.min;
      value = value >= this.min ? value : this.min;
      value = value <= this.max ? value : this.max;
      value = fixNumber(value, this.decimal);
    } else {
      if (this.defaultValue) {
        value = this.defaultValue;
      } else {
        value = this.min > 0 ? this.min : this.max > 0 ? 0 : this.max;
      }
    }

    return value;
  }

  private _updateValue(value: number): void {
    if (value !== this.value) {
      if (!this._clearClicked) {
        this.value = value;
        this.valueChange.emit(value);
        this._onChange(value);
      }
    }
    this._valueInput = null;
  }
}
