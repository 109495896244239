<div
  *ngFor="let notification of orderedNotifications$ | async; trackBy: trackById"
  class="notifications-queue__item"
  [class.notifications-queue__item_error]="isError(notification.type)"
  [class.notifications-queue__item_success]="isSuccess(notification.type)"
  [class.notifications-queue__item_warning]="isWarning(notification.type)"
  @appearNotification
>
  <mat-icon
    class="notifications-queue__icon"
    [svgIcon]="getIcon(notification.type)"
  ></mat-icon>
  <div class="notifications-queue__message">
    {{ notification.message }}
  </div>
  <button
    *ngIf="notification.removable"
    class="notifications-queue__remove"
    type="button"
    mat-icon-button
    aria-label="Remove"
    (click)="removeOne(notification)"
  >
    <mat-icon svgIcon="close-circle-o"></mat-icon>
  </button>
</div>
