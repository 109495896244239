import { NgModule } from '@angular/core';
import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@app/shared/shared.module';

import { HeaderComponent } from './header.component';
import { ListButtonComponent } from './list-button/list-button.component';
import { ListFiltersComponent } from './list-filters/list-filters.component';
import { ListPopoverComponent } from './list-popover/list-popover.component';
import {
  ProfileButtonComponent,
  ProfileMenuComponent,
  ProfilePopoverComponent,
} from './profile-menu';
import { LearnBasicsComponent } from './learn-basics/learn-basics.component';
import { LearnBasicsButtonComponent } from './learn-basics/learn-basics-button/learn-basics-button.component';
import { LearnBasicsPopoverComponent } from './learn-basics/learn-basics-popover/learn-basics-popover.component';
import { HeaderPublicComponent } from './header-public.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ProfilePopoverComponent,
    ProfileButtonComponent,
    ListButtonComponent,
    ListPopoverComponent,
    ListFiltersComponent,
    ProfileMenuComponent,
    LearnBasicsComponent,
    LearnBasicsButtonComponent,
    LearnBasicsPopoverComponent,
    HeaderPublicComponent,
  ],
  imports: [SharedModule, AppRoutingModule],
  exports: [HeaderComponent, HeaderPublicComponent],
})
export class HeaderModule {}
