import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnonymGuard  {
  private _isRunning = false;

  constructor(private _router: Router, private _userService: UserService) {}

  get isRunning(): boolean {
    return this._isRunning;
  }

  canActivate(): Observable<boolean | UrlTree> {
    this._isRunning = true;

    return this._userService.isUserActive().pipe(
      map((isActive) => {
        this._isRunning = false;

        return (
          !isActive ||
          this._router.createUrlTree(
            ['account-discovery'],
            /* Removed unsupported properties by Angular migration: replaceUrl. */ {}
          )
        );
      })
    );
  }
}
