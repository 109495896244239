import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FactorsRisksTrends,
  Industry, IndustryCategory,
  IndustryDetails,
  IndustryFactor,
  IndustryFactorFilter,
  IndustryFactorTrends,
  IndustryReportsGroup,
  IndustryRisk,
  Sic,
} from '@app/core/models/industry.model';
import {
  ApiService,
  BaseCreateResponse,
  BaseErrorResponse,
} from '@app/core/services/api.service';
import { UserService } from '@app/core/services/user.service';

import { flatMap, isNil, omitBy, sortBy } from 'lodash';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  takeUntil,
} from 'rxjs';

import { BaseGroupItem, BaseItem, BaseList } from '../models/common.model';
import { ITSpendings } from '../models/company.model';
import { MetricChartView } from '../models/metric-chart.model';
import { ListPage } from './client.service';
import { IndustryGroupItem } from '@app/modules/company-management/modules/company-solutions/modules/solution-editor/solution-editor.model';

export type FactorSorter = {
  property: string;
  direction: SortFactorDirection;
};
export type SortFactorDirection = 'Ascending' | 'Descending' | '';

export interface IndustryHierarchyPosition {
  categoryUid?: string;
  industryUid?: string;
  subIndustryUid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class IndustryService {
  private _industries$: Observable<Industry[]>;
  private _industryByCategory$: Observable<BaseGroupItem<IndustryGroupItem>[]>;
  private _industriesForGoals$: Observable<BaseGroupItem[]>;

  private _destroyer = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    this.userService.getActiveStatus().subscribe((isActive: boolean) => {
      if (!isActive) {
        this._destroyer.next();

        this._industries$ = null;
        this._industryByCategory$ = null;
      }
    });
  }

  getIndustries(blockId?: string): Observable<Industry[]> {
    if (!this._industries$) {
      this._industries$ = this.apiService
        .get<Industry[]>('industryResearch/industry', blockId)
        .pipe(
          map((industries) =>
            industries.map(
              (industry) =>
                ({
                  ...industry,
                  subIndustries: sortBy(industry.subIndustries, [ 'name' ]),
                } as Industry)
            )
          ),
          map((industries) => sortBy(industries, [ 'name' ])),
          catchError(() => {
            this._industries$ = null;

            return of([]) as Observable<Industry[]>;
          }),
          takeUntil(this._destroyer),
          shareReplay(1)
        );
    }

    return this._industries$;
  }

  getIndustriesForGoals(
    firstCallBlockCid?: string
  ): Observable<BaseGroupItem[]> {
    if (this._industriesForGoals$ == null) {
      this._industriesForGoals$ = this.apiService
        .get<{
          items: (BaseItem & { subIndustries: BaseItem[] })[];
        }>('fishbone/goals/industries-with-subIndustries', firstCallBlockCid)
        .pipe(
          map((data) =>
            data?.items.map(
              (item) =>
                ({
                  uid: item.uid,
                  name: item.name,
                  items: item.subIndustries,
                } as BaseGroupItem)
            )
          ),
          catchError(() => of([])),
          takeUntil(this._destroyer),
          shareReplay(1)
        );
    }

    return this._industriesForGoals$;
  }

  getIndustriesByCategory(blockCid?: string): Observable<BaseGroupItem<IndustryGroupItem>[]> {
    if (this._industryByCategory$ == null) {
      this._industryByCategory$ = this.apiService
        .get<{
          items: (BaseItem & {
            industries: (BaseItem & { subIndustries: Array<BaseItem> })[];
          })[];
        }>('industryResearch/industry/industry-options-by-category', blockCid)
        .pipe(
          map((data) =>
            data.items.map(
              (item) =>
                ({
                  uid: item.uid,
                  name: item.name,
                  items: item.industries?.map((ind) => ({
                    uid: ind.uid,
                    name: ind.name,
                    items: ind.subIndustries,
                  })),
                } as BaseGroupItem)
            )
          ),
          catchError(() => of([])),
          takeUntil(this._destroyer),
          shareReplay(1)
        );
    }

    return this._industryByCategory$;
  }

  getIndustryDetails(
    params: {
      industryUid?: string;
      subIndustryUid?: string;
      sicUid?: string;
    },
    blockCid = 'ISD'
  ): Observable<IndustryDetails> {
    return this.apiService
      .get<IndustryDetails>(
        'industryResearch/Industry/industry-details',
        {
          params: new HttpParams({
            fromObject: omitBy(params, isNil),
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  getIndustryFactor(
    type: number,
    industryUid?: string,
    subIndustryUid?: string,
    cId?: string
  ): Observable<IndustryFactor[]> {
    const httpParams = new HttpParams({
      fromObject: omitBy(
        {
          type,
          industryUid,
          subIndustryUid,
        },
        isNil
      ),
    });

    return this.apiService
      .get<IndustryFactor[]>(
        'industryResearch/Industry/industry-factors',
        {
          params: httpParams,
        },
        cId
      )
      .pipe(catchError(() => of([])));
  }

  getIndustryFactorAndRisk(
    industryUid?: string,
    subIndustryUid?: string,
    cId?: string
  ): Observable<FactorsRisksTrends> {
    const httpParams = new HttpParams({
      fromObject: omitBy(
        {
          industryUid,
          subIndustryUid,
        },
        isNil
      ),
    });

    return this.apiService
      .get<FactorsRisksTrends>(
        'industryResearch/Industry/trends-drivers-inhibitors',
        {
          params: httpParams,
        },
        cId
      )
      .pipe(catchError(() => of(null)));
  }

  getReportLinks(
    fullParams: {
      industryUid: string;
      subIndustryUid?: string;
      industryRevenueGroupUid: string;
      currencyUid?: string;
      financialAnalysisType?: MetricChartView;
      revenue?: number;
    },
    cId?: string
  ): Observable<IndustryReportsGroup[]> {
    const params = new HttpParams({
      fromObject: omitBy(fullParams, isNil),
    });

    return this.apiService
      .get<{ groups: IndustryReportsGroup[] }>(
        'industryResearch/industry/reports',
        {
          params,
        },
        cId
      )
      .pipe(
        map(({ groups }: { groups: IndustryReportsGroup[] }) => groups),
        catchError(() => of([]))
      );
  }

  getRisks(
    industryUid: string,
    subIndustryUid?: string,
    blockCid?: string
  ): Observable<IndustryRisk[]> {
    return this.apiService
      .get<IndustryRisk[]>(
        'industryResearch/Industry/risks',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                industryUid,
                subIndustryUid,
              },
              isNil
            ),
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of([])));
  }

  getIndustryUrl(industryOrSicUid: string, regionUid: string): string {
    return `/industry-research/${ industryOrSicUid }/${ regionUid }`;
  }

  getIndustryCategoriesList(blockCid?: string): Observable<BaseItem[]> {
    return this.apiService
      .get<{ items: BaseItem[] }>(
        'industryResearch/category/category-options',
        blockCid
      )
      .pipe(
        map((data) => data.items),
        catchError(() => of([]))
      );
  }

  getExecutiveCompensation(
    subIndustryUid: string,
    blockCid?: string
  ): Observable<string[]> {
    return this.apiService
      .get<{ text: string }[]>(
        'industryResearch/industry/executive-compensation-factors',
        {
          params: new HttpParams({
            fromObject: {
              subIndustryUid,
            },
          }),
        },
        blockCid
      )
      .pipe(
        map((items) => items.map((item) => item.text)),
        catchError(() => of(null))
      );
  }

  createPursuit(
    industryRevenueGroupUid: string,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    return this.apiService
      .post<BaseCreateResponse>(
        'fishbone/myPursuit/industry-pursuit',
        {
          industryRevenueGroupUid,
          pursuit: {},
        },
        blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  findParentIndustry(
    industries: Industry[],
    industryOrSicId?: string
  ): Industry {
    return industries.find((industry) =>
      this._isIndustryParent(industry, industryOrSicId)
    );
  }

  findIndustry(industries: Industry[], industryOrSicId?: string): Industry {
    return industries.find((industry) => industry.uid === industryOrSicId);
  }

  findParentSubIndustry(
    industries: Industry[],
    industryOrSicId?: string
  ): Industry {
    const subIndustries = flatMap(
      industries,
      (industry) => industry.subIndustries || []
    );

    return subIndustries.find((subIndustry: Industry) => {
      let successfully = subIndustry.uid === industryOrSicId;

      if (!successfully) {
        successfully =
          (subIndustry.classifications || []).find(
            (sic: Sic) => sic.uid === industryOrSicId
          ) != null;
      }

      return successfully;
    });
  }

  findSubIndustry(industries: Industry[], industryOrSicId?: string): Industry {
    const subIndustries = flatMap(
      industries,
      (industry) => industry.subIndustries || []
    );

    return subIndustries.find(
      (subIndustry) => subIndustry.uid === industryOrSicId
    );
  }

  findSic(industries: Industry[], industryOrSicId?: string): Sic {
    const sics = flatMap(industries, (industry) => {
      const subIndustries = industry.subIndustries || [];
      return flatMap(
        subIndustries,
        (subIndustry) => subIndustry.classifications || []
      );
    });

    return sics.find((sic) => sic.uid === industryOrSicId);
  }

  listChildSics(
    childrenOf: IndustryHierarchyPosition,
    blockId?: string
  ): Observable<Sic[]> {
    return this.apiService
      .get<BaseList<Sic>>(
        'industryResearch/standard-industrial-classifications',
        { params: { ...childrenOf } },
        blockId
      )
      .pipe(
        map((sicList) => sicList.items),
        map((sics) => sortBy(sics, [ 'code', 'name' ])),
        catchError(() => of([]) as Observable<Sic[]>),
        takeUntil(this._destroyer),
        shareReplay(1)
      );
  }

  getAllFactors(
    page: ListPage,
    sort: FactorSorter[],
    filter: IndustryFactorFilter,
    blockCid?: string
  ): Observable<BaseList<IndustryFactorTrends>> {
    const { industryUid, ...formToSent } = filter;
    const data =
      formToSent.subIndustryUid != null
        ? formToSent
        : { ...formToSent, industryUid };

    return this.apiService
      .post<BaseList<IndustryFactorTrends>>(
        'industryresearch/industry-factors/query',
        {
          page,
          sort,
          filter: data,
        },
        blockCid,
        -1
      )
      .pipe(
        catchError(() =>
          of({
            items: [] as IndustryFactorTrends[],
            totalCount: 0,
          } as BaseList<IndustryFactorTrends>)
        )
      );
  }

  getIndustryFactorData(
    uid: string,
    blockCid?: string
  ): Observable<IndustryFactorTrends> {
    return combineLatest([
      this.getIndustries(),
      this.apiService
        .get<IndustryFactorTrends>(
          `industryresearch/industry-factors/${ uid }`,
          blockCid
        )
        .pipe(catchError(() => of(null))),
    ]).pipe(
      map(([ industries, factor ]) =>
        factor == null || factor?.industryUid != null
          ? factor
          : {
            ...factor,
            industryUid: industries.find(
              (industry) =>
                industry.subIndustries.find(
                  (subindustry) => subindustry.uid === factor.subIndustryUid
                ) != null
            )?.uid,
          }
      )
    );
  }

  createFactor(
    form: IndustryFactorTrends,
    uid: string,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    const { industryUid, ...formToSent } = form;

    return this.apiService
      .post<BaseCreateResponse>(
        `industryresearch/industry-factors/`,
        formToSent.subIndustryUid != null
          ? formToSent
          : { ...formToSent, industryUid },
        blockCid
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  updateFactor(
    form: IndustryFactorTrends,
    uid: string,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    return this.apiService
      .put<BaseCreateResponse>(
        `industryresearch/industry-factors/${ uid }`,
        {
          title: form.title,
          description: form.description,
        },
        blockCid
      )
      .pipe(
        map(() => form),
        catchError(() => of(null))
      );
  }

  removeFactorTrend(uid: string): Observable<boolean> {
    return this.apiService
      .delete(`industryresearch/industry-factors/${ uid }`)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  private _isIndustryParent(
    industry: Industry,
    industryOrSicId: string
  ): boolean {
    const isParentIndustrySelected = industry.uid === industryOrSicId;
    const isSubIndustryOrSicSelected = industry.subIndustries.some(
      (subIndustry) => {
        const isSubIndustrySelected = subIndustry.uid === industryOrSicId;
        const isSicSelected = subIndustry.classifications.some(
          (sic) => sic.uid === industryOrSicId
        );
        return isSubIndustrySelected || isSicSelected;
      }
    );
    return isParentIndustrySelected || isSubIndustryOrSicSelected;
  }

}
