import { FormControl } from '@angular/forms';

class AppValidators {

  notBlank(control: FormControl<string | null>) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    if (!isValid) {
      return {
        required: { value: 'Field is required' },
      };
    }

    return null;
  }

  requiredObject(control: FormControl<any>) {
    const isValid = !!control.value && typeof control.value === 'object';

    if (!isValid) {
      return {
        required: { value: 'Field is required' },
      };
    }

    return null;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CustomValidators = new AppValidators();
