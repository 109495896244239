import { PageEvent } from '@app/shared/pagination/pagination.model';
import { Injectable } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { ApiService } from '@app/core/services/api.service';
import { first, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@app/core/models/user.model';
import { BaseItem, UUID } from '@app/core/models/common.model';

export enum JourneyType {
  PreparationForMeeting = 'PreparationForMeeting',
  GetTheMeeting = 'GetTheMeeting',

  ResearchProspects = 'ResearchProspects',
  Pursuit = 'Pursuit',
  ValueModeler = 'ValueModeler'
}

export interface JourneyResponseApi {
  uid: string,
  ownerUid: string,
  companyUid: string,
  privateCompanyUid: string;
  name: string,
  type: JourneyType,
  data: string,
  creatorUid: string,
  dateCreated: string,
  lastUpdaterUid: string,
  dateLastUpdated: string,
  deleterUid: string,
  dateDeleted: string,
  createdByName: string;
  lastUpdatedByName: string;
}

export interface Journey<T> {
  uid?: string,
  ownerUid: string,
  companyUid?: string,
  privateCompanyUid?: string;
  name: string,
  type: JourneyType,
  creatorUid: string,
  dateCreated: string,
  lastUpdaterUid?: string,
  dateLastUpdated?: string,
  deleterUid?: string,
  dateDeleted?: string,
  createdByName: string;
  lastUpdatedByName?: string;
  data: T;
}

interface CreateJourneyResponseApi {
  createdBy: string;
  createdByName: string;
  createdDate: string;
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  lastUpdatedDate: string;
  name: string;
  owner: string;
  ownerName: string;
  uid: string;
}

export interface CreateJourneyResponse {
  createdBy: string;
  createdByName: string;
  createdDate: string;
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  lastUpdatedDate: string;
  name: string;
  owner: string;
  ownerName: string;
  uid: string;
}

// TODO: add type (data depends on journey type)
export type JourneyData = unknown;

export interface JourneyCreate {
  companyUid?: string;
  privateCompanyUid?: string;
  type: JourneyType;
  name: string;
  data: JourneyData;
}

export interface JourneyCreateApi {
  companyUid?: string;
  privateCompanyUid?: string;
  type: JourneyType;
  name: string;
  data: string;
}

interface JourneyUpdateApi {
  uid: string;
  companyUid?: string;
  privateCompanyUid?: string;
  type: JourneyType;
  name: string;
  data: string;
}

export interface JourneyUpdate {
  uid: string;
  companyUid?: string;
  privateCompanyUid?: string;
  type: JourneyType;
  name: string;
  data: JourneyData;
}

interface UpdateJourneyResponseApi {
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  lastUpdatedDate: string;
  uid: string;
}

export interface UpdateJourneyResponse {
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  lastUpdatedDate: string;
  uid: string;
}

/**
 * @deprecated
 * @see LearnMoreItem
 * @see LearnMoreItemApi
 */
export interface JourneyLearnMore {
  uid: string,
  code: string,
  clientUid: string,
  content: string,
  lastUpdatedBy: string,
  lastUpdatedDate: string,
}

export interface JourneyItem extends BaseItem {
  type: JourneyType;
  companyUid: string;
  owner: string;
  ownerName: string;
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  lastUpdatedDate: Date;
  isOwner?: boolean;
}

export interface JourneysListFilter {
  nameLike?: string;
  type?: JourneyType;
}

export interface JourneysList {
  items: JourneyItem[];
  totalCount: number;
}

export interface JourneyCopy {
  uid: UUID;
  name: string;
  owner: UUID;
  ownerName: string;
  createdBy: UUID;
  createdByName: string;
  createdDate: string;
  lastUpdatedBy: UUID;
  lastUpdatedByName: string;
  lastUpdatedDate: string;
}

export enum PrepForMeetingLearnMoreCodes {
  PrepForMeeting = 'USER_JOURNEY__PREPARATION_FOR_MEETING',
  Account = 'USER_JOURNEY__PREPARATION_FOR_MEETING__SELECT_ACCOUNT',
  Peers = 'USER_JOURNEY__PREPARATION_FOR_MEETING__PEERS',
  Segments = 'USER_JOURNEY__PREPARATION_FOR_MEETING__SEGMENTS',
  BusinessFunction = 'USER_JOURNEY__PREPARATION_FOR_MEETING__BUSINESS_FUNCTION',
  FinancialMetrics = 'USER_JOURNEY__PREPARATION_FOR_MEETING__FINANCIAL_METRICS',
  Solution = 'USER_JOURNEY__PREPARATION_FOR_MEETING__SOLUTION',
  IndustryGoals = 'USER_JOURNEY__PREPARATION_FOR_MEETING__GOALS',
  Currency = 'USER_JOURNEY__PREPARATION_FOR_MEETING__CURRENCY',
  SalesPlaybook = 'USER_JOURNEY__PREPARATION_FOR_MEETING__SALES_PLAYBOOK',
  BusinessGoalsAndStrategies = 'USER_JOURNEY__PREPARATION_FOR_MEETING__BUSINESS_GOALS_STRATEGIES',
  Questions = 'USER_JOURNEY__PREPARATION_FOR_MEETING__QUESTIONS',
}

export enum DashboardCodes {
  LearnMore = 'DASHBOARD__LEARN_MORE'
}

@Injectable({
  providedIn: 'root'
})
export class JourneyApiService {
  static blockCid = 'JourneyApiService';

  constructor(
    private userService: UserService,
    private apiService: ApiService
  ) {
  }

  create(journey: JourneyCreate, blockCid?: string): Observable<CreateJourneyResponse> {
    return this.userService.getCurrentUser()
      .pipe(
        first(),
        switchMap(user => {

          const body: JourneyCreateApi = convertJourneyCreateToApi(journey);
          return this.apiService.post<CreateJourneyResponseApi>(
            `fishbone/users/${ user.uid }/journeys`,
            body,
            blockCid || JourneyApiService.blockCid,
            -1
          );
        }),
        map((response) => convertCreateJourneyResponseApiToClient(response)),
      );
  }

  update(journey: JourneyUpdate, blockCid?: string): Observable<UpdateJourneyResponse> {
    return this.userService.getCurrentUser()
      .pipe(
        first(),
        switchMap(user => {

          const uid = journey.uid;
          const body: JourneyUpdateApi = convertJourneyUpdateToApi(journey);
          return this.apiService.put<UpdateJourneyResponseApi>(
            `fishbone/users/${ user.uid }/journeys/${ uid }`,
            body,
            blockCid || JourneyApiService.blockCid,
            -1
          );
        }),
        map((response) => convertUpdateJourneyResponseApiToClient(response)),
      );
  }

  getJourney(journeyUid: string, blockCid?: string): Observable<JourneyResponseApi> {
    return this.userService.getCurrentUser()
      .pipe(
        first(),
        switchMap(user => this.apiService.get<JourneyResponseApi>(
          `fishbone/users/${ user.uid }/journeys/${ journeyUid }`,
          blockCid || JourneyApiService.blockCid,
          -1
        )),
      );
  }

  getList(filter?: JourneysListFilter, page?: PageEvent, blockCid?: string): Observable<JourneysList> {
    return this.userService.getCurrentUser()
      .pipe(
        first(),
        switchMap((user: User) => this.apiService.post<JourneysList>(
              `fishbone/users/${ user.uid }/journeys/query`,
              {
                filter,
                sort: [ {
                  property: 'dateLastUpdated',
                  direction: 'Descending'
                } ],
                page: page
                  ? {
                    size: page.pageSize,
                    index: page.pageIndex
                  }
                  : undefined
              },
              blockCid || JourneyApiService.blockCid
            )
            .pipe(
              map(
                (data) =>
                  ({
                    ...data,
                    items: data.items.map((journeyItem) => ({
                      ...journeyItem,
                      isOwner: journeyItem.owner === user.uid,
                    })),
                  } as JourneysList)
              )
            )
        ),
      );
  }

  deleteJourney(uid: string, blockCid?: string): Observable<string> {
    return this.userService.getCurrentUser()
      .pipe(
        first(),
        switchMap((user: User) => this.apiService.delete<string>(
            `fishbone/users/${ user.uid }/journeys/${ uid }`,
            blockCid || JourneyApiService.blockCid,
            -1
          )
        ),
      );
  }

/**
 * Generate name for copied journey
 */
  generateNameForCopiedJourney(
    name : string,
    blockCid?: string
  ): Observable<string> {
    return this.userService.getCurrentUser()
      .pipe(
        first(),
        switchMap((user: User) => this.apiService.post<{name: string}>(
            `fishbone/users/${ user.uid }/journeys/names/next`,
            { name },
            blockCid || JourneyApiService.blockCid,
            -1
          )
        ),
        map(data => data.name)
      );
  }

  
  /**
   * Copy Journey with new name
   */
  copyValueModeler(
    name : string,
    sourceUid: UUID,
    blockCid?: string,
  ): Observable<JourneyCopy> {

    return this.userService.getCurrentUser()
    .pipe(
      first(),
      switchMap((user: User) => this.apiService.post<JourneyCopy>(
          `fishbone/users/${ user.uid }/journeys/copy`,
          {
            name,
            sourceUid
          },
          blockCid || JourneyApiService.blockCid,
          -1
        )
      ),
    );
  }


  /**
   * @deprecated
   * @use LearnMoreRepository.getLearnMoreTopic
   * @see LearnMoreRepository.getLearnMoreTopic
   */
  getJourneysLearnMore(code: string, blockCid?: string): Observable<JourneyLearnMore> {
    return this.userService.getCurrentUser()
      .pipe(
        switchMap((user: User) => this.apiService.get<JourneyLearnMore>(
          `common/clients/${ user.clientUid }/learn-more?code=${ code }`,
          blockCid || JourneyApiService.blockCid,
          -1
        )),
      )
  }
}

function convertJourneyCreateToApi(journey: JourneyCreate): JourneyCreateApi {

  const result = {
    companyUid: journey.companyUid,
    privateCompanyUid: journey.privateCompanyUid,
    type: journey.type,
    name: journey.name,
    data: JSON.stringify(journey.data)
  };

  if (!journey.companyUid) {
    delete journey.companyUid;
  }

  if (!journey.privateCompanyUid) {
    delete journey.privateCompanyUid;
  }

  return result;
}

function convertCreateJourneyResponseApiToClient(response: CreateJourneyResponseApi): CreateJourneyResponse {
  return {
    createdBy: response.createdBy,
    createdByName: response.createdByName,
    createdDate: response.createdDate,
    lastUpdatedBy: response.lastUpdatedBy,
    lastUpdatedByName: response.lastUpdatedByName,
    lastUpdatedDate: response.lastUpdatedDate,
    name: response.name,
    owner: response.owner,
    ownerName: response.ownerName,
    uid: response.uid
  };
}

function convertJourneyUpdateToApi(journey: JourneyUpdate): JourneyUpdateApi {

  const result = {
    uid: journey.uid,
    companyUid: journey.companyUid,
    privateCompanyUid: journey.privateCompanyUid,
    type: journey.type,
    name: journey.name,
    data: JSON.stringify(journey.data)
  };

  if (!journey.companyUid) {
    delete journey.companyUid;
  }

  if (!journey.privateCompanyUid) {
    delete journey.privateCompanyUid;
  }

  return result;
}

function convertUpdateJourneyResponseApiToClient(response: UpdateJourneyResponseApi): UpdateJourneyResponse {
  return {
    lastUpdatedBy: response.lastUpdatedBy,
    lastUpdatedByName: response.lastUpdatedByName,
    lastUpdatedDate: response.lastUpdatedDate,
    uid: response.uid
  };
}
