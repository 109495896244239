<label *ngIf="preLabel" class="datepicker__label">{{ preLabel }}:</label>
<input
  #input
  type="text"
  placeholder="../../...."
  class="datepicker__input"
  [class.datepicker__input-clearable]="clearable"
  [class.datepicker__input-disabled]="disabled"
  matInput
  [imask]="maskOptions"
  [unmask]="true"
  [matDatepicker]="picker"
  autoComplete="off"
  [value]="value"
  [min]="min || minDate"
  [max]="max || maxDate"
  [disabled]="disabled"
  (accept)="setActiveOnInput($event)"
  (dateInput)="emitInput($event)"
  (dateChange)="emitChange($event)"
  (focus)="onFocus()"
  (blur)="onBlur()"
/>
<button
  *ngIf="clearable && isActive"
  type="button"
  class="datepicker__clearer"
  matTooltip="Clear"
  i18n-matTooltip
  (click)="clearAll($event)"
>
  <mat-icon
    class="datepicker__icon datepicker__icon-sm"
    matDatepickerToggleIcon
    svgIcon="close"
  ></mat-icon>
</button>
<mat-datepicker-toggle
  class="datepicker__toggler"
  matSuffix
  [for]="picker"
  (click)="$event.stopPropagation()"
  color="primary"
  matTooltip="Calendar"
  i18n-matTooltip
>
  <mat-icon
    class="datepicker__icon"
    matDatepickerToggleIcon
    svgIcon="datepicker"
  ></mat-icon>
</mat-datepicker-toggle>
<mat-datepicker #picker class="datepicker__picker"></mat-datepicker>
