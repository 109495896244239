import { WordForms } from '@app/core/models/common.model';

export enum SolutionTerminology {
    Solution = 'Solution',
    Solutions = 'Solutions',
    RockwellUseCase = 'Rockwell Use Case',
    RockwellUseCases = 'Rockwell Use Cases',
}

export const DEFAULT_SOLUTION_NAME: WordForms = {
  singular: SolutionTerminology.Solution,
  plural: SolutionTerminology.Solutions
}

export const ROCKWELL_USE_CASE_NAME: WordForms = {
  singular: SolutionTerminology.RockwellUseCase,
  plural: SolutionTerminology.RockwellUseCases
}
