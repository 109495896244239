import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { UserNotification } from '@app/core/models/notification.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-notice-bar',
  templateUrl: './header-notice-bar.component.html',
  styleUrls: ['./header-notice-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNoticeBarComponent implements OnChanges {
  @Input() notificationData: UserNotification[];
  @Output() notificationViewed = new EventEmitter<UserNotification>();

  private _queue: UserNotification[] = [];

  get notification(): UserNotification | undefined {
    return this._queue[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notificationData) {
      this._queue.push(...(this.notificationData || []));
    }
  }

  getTypeClass(type: string): string {
    return `notice-bar-wrapper--${type.toLowerCase()}`;
  }

  closeNoticeBar(): void {
    setTimeout(() => {
      const closedNotification = this._queue.shift();
      this.notificationViewed.emit(closedNotification);
    }, 0);
  }
}
