import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SidePanelListService } from './side-panel-list.service';

@Component({
  selector: 'app-side-panel-list',
  templateUrl: './side-panel-list.component.html',
  styleUrls: ['./side-panel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelListComponent implements OnInit, OnDestroy {
  @HostBinding('class.side-panel-list') isPanelList = true;

  isSidePanelOpened$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public element: ElementRef<HTMLElement>,
    private sidePanelListService: SidePanelListService
  ) {}

  closeSidePanel() {
    this.sidePanelListService.setOpenedPanel(null);
  }

  ngOnInit(): void {
    this.isSidePanelOpened$ = this.sidePanelListService.getOpenedPanel().pipe(
      map((panel) => panel != null),
      tap((opened: boolean) => {
        if (opened) {
          this.document.body.style.overflow = 'hidden';
        } else {
          this.document.body.style.overflow = null;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.closeSidePanel();
  }
}
