import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageFolder } from './image-options.model';
import { FNL_HEADER_OPPORTUNITIES } from './images/header-opportunities';
import { FNL_ICONS } from './images/icons';

@Injectable()
export class FnlIconsRegistry {
  private readonly imageFolders = [ FNL_ICONS, FNL_HEADER_OPPORTUNITIES ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  /**
   * Get list of available icons
   */
  getIcons(): string[] {
    return FNL_ICONS.imagesList
      .map((icon) => icon.name)
      .sort();
  }

  /**
   * Get list of available Header Opportunities Images
   */
  getHeaderOpportunitiesImages(): string[] {
    return FNL_HEADER_OPPORTUNITIES.imagesList
      .map((icon) => icon.name)
      .sort();
  }

  initCustomIcons() {
    this.loadIcons(this.imageFolders, '/assets/fnl-images');
  }

  private loadIcons(imageFolders: ImageFolder[], baseUrl: string): void {
    for (const imageFolder of imageFolders) {
      const { imagesList, subFolder } = imageFolder;

      imagesList.forEach((image) => {
        const { name, size = '0 0 24 24' } = image;
        const imageUrl = `${ baseUrl }${ subFolder }/${ name }.svg`;

        const url = this.domSanitizer.bypassSecurityTrustResourceUrl(imageUrl);

        this.matIconRegistry.addSvgIcon(name, url, { viewBox: size });
      });
    }
  }
}
