<div class="currency-list">
  <div
    *ngFor="let currency of list; trackBy: trackByUid"
    class="currency-list__item"
    (click)="onClick(currency)"
  >
    <div
      class="currency-list__item-icon"
      [style.backgroundImage]="flagSrc(currency)"
    ></div>
    <div class="currency-list__item-name">
      {{ currency.code }} - {{ currency.name }}
    </div>
  </div>
  <div *ngIf="!list.length" class="currency-list__empty" i18n>
    Sorry, no currency matches your criteria.
  </div>
</div>
