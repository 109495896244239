import { IndustryCode } from './models/industry.model';

export const USD_CURRENCY_UID = '8195a81b-83fa-4d5e-a81f-b6bb9699d64f';

export const EMPTY_UID = '00000000-0000-0000-0000-000000000000';
export const NOT_AVAILABLE = 'n/a';
export const FINLISTICS_CLIENT_UID = '69e6eb97-435c-9dce-9d75-41f8790926d1';
export const DEFAULT_CURRENCY_UID = USD_CURRENCY_UID;

export const VIEW_THRESHOLD = 0.1;
export const PERCENT_CONVERSION_DECIMAL = 2;

export const INSURANCE_INDUSTRY_UID = '69d9acd9-337c-4fda-9919-ad80d28da124';

export const LOCALE = 'en-US';

export const NAME_REGEXP = /^[a-zA-Z -]*$/;
export const PASSWORD_REGEXP = /^[a-zA-Z0-9!@#$%^&*()_+\[\]{}']{8,}$/;

export const URL_REGEXP =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[-a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const HUMAN_CAPITAL_GROUP_IDS = [
  'fb5f1695-0630-4ecd-a864-81f052c5467e', // INDL
  '8c499d8b-2f7c-4009-89f2-9dbf4b160535', // BANK
  'b68444e6-e837-49aa-b312-b846635cfd21', // INS
  'a1fe49c8-d5de-401b-a866-6515cb3a60ae', // HLTHCR
];

export const CACHE_EXPIRE_DISTANCE = 10000;

export const peersColors = ['#aac638', '#56CCF2', '#F2C94C', '#FF7B59', '#BB6BD9'];

export const revenueUidsByIndustryCode = {
  [IndustryCode.Healthcare]: '96354214-ba0b-4180-84be-e0e11b8548e8',
  [IndustryCode.Industrial]: 'e9d4d0f2-767a-40ee-baba-cdf9bea780c2',
  [IndustryCode.Bank]: '0e907e0d-7cda-40cc-86ae-70eb78e64cff',
  [IndustryCode.Insurance]: '318d7180-6963-4b53-aaf5-36b7c0ba78f0',
}

export const incomeUidsByIndustryCode = {
  [IndustryCode.Healthcare]: 'e77e1316-7dc8-46be-8652-47cfb934fc39',
  [IndustryCode.Industrial]: '39d0bc91-721b-4758-9db8-879f64f69174',
  [IndustryCode.Bank]: '229c18f0-0c0a-4edd-920d-72da48433659',
  [IndustryCode.Insurance]: 'db95d05b-2154-4187-8dfb-57d4d91e8e5e',
}

export const REVENUE_UIDS = [
  // Industrial - Revenue
  'e9d4d0f2-767a-40ee-baba-cdf9bea780c2',
  // Banking - TotalIncome
  '0e907e0d-7cda-40cc-86ae-70eb78e64cff',
  // Insurance - TotalRevenue
  '318d7180-6963-4b53-aaf5-36b7c0ba78f0',
  // Healthcare - Net Patient Revenue
  '96354214-ba0b-4180-84be-e0e11b8548e8',
];

export const INCOME_UIDS = [
  // Industrial - Operating Income Margin
  '39d0bc91-721b-4758-9db8-879f64f69174',
  // Banking - PreTaxProfitMargin
  '229c18f0-0c0a-4edd-920d-72da48433659',
  // Insurance - PreTaxProfitMargin
  'db95d05b-2154-4187-8dfb-57d4d91e8e5e',
  // Healthcare - Operating Income Margin
  'e77e1316-7dc8-46be-8652-47cfb934fc39',
];

export const TOTAL_COSTS_UIDS = [
  // Industrial
  // CostOfGoodsSold
  'e326728d-782f-409f-813e-f959333b6b74',
  // Research & Development
  '72062b62-d3cf-452f-83c2-0dd12787de88',
  // Selling, General & Admin. Exp.
  'e0e01a58-adb9-4125-bd31-b9a26bd73946',

  // Banking - CostToIncome
  'a59054dd-6616-47c5-b2cd-bbcc5cc212ca',

  // Insurance
  // BenefitsAndClaims
  'daa91faa-e722-43f8-b39a-7797c0041575',
  // Other Operating Expenses
  '0afedaf0-72ae-4ca5-9517-145972c6ea79',
  // Selling, General & Admin. Exp.
  '938e15c6-a84b-4f2c-a4dc-4946c93c8432',

  // Healthcare
  // Other Operating Expenses
  '1ae98cec-c09c-46f6-8e17-03c6eb9bd4a0',
  // Salaries & Wages
  'd69f8a42-a14c-4577-bc4c-aba7ff4efe42',
];

export const INCOME_GROWTH_UIDS = [
  // Banking
  // NetInterestIncomeGrowth
  'ca5773e4-1f8a-4006-8e69-fa4e36619462',
  // NonInterestIncomeGrowth
  '9452d7ee-9626-41c0-8c7b-ee0786338d0f',

  // Insurance
  // PremiumEarnedGrowth
  '416c6bcf-978d-49a9-9603-89ad62aebdd4',
  // NonPremiumEarnedGrowth
  '860709e6-e56c-47c0-b6c7-ca9bcacd2398',
];

export const REVENUE_CUSTOM_COMPANIES_UIDS = [
  //INDL
  'e9d4d0f2-767a-40ee-baba-cdf9bea780c2',
  //Banks
  'a05cdacc-3e4f-41eb-bb25-b9ca58d25bf7',
  '9452d7ee-9626-41c0-8c7b-ee0786338d0f',
  'a4c0aa9b-d121-4fe7-8729-1c04e4a6c983',
  //INS
  '318d7180-6963-4b53-aaf5-36b7c0ba78f0',
  '416c6bcf-978d-49a9-9603-89ad62aebdd4',
  //HLTCHR
  '96354214-ba0b-4180-84be-e0e11b8548e8',
];

//Metrics for calculation on custom companies financial table-------------
export const BANKING_METRICS_FOR_CALCULATE_NIE = [
  '35208948-89a8-4229-a9de-fcd1c5c404e3', //Salaries & Related Expenses
  '058ac1c0-6fc1-42d7-92ee-40e2a82a151a', //Premises & Equipment
  '92cd62ea-f2d3-4be3-bd85-8fd4cdad8edf', //Other Non-Interest Expense
];

export const BANKING_METRICS_FOR_CALCULATE_PTI = [
  'a05cdacc-3e4f-41eb-bb25-b9ca58d25bf7', //Total Interest Income
  'a4c0aa9b-d121-4fe7-8729-1c04e4a6c983', //Total Interest Expense
  '9452d7ee-9626-41c0-8c7b-ee0786338d0f', //Non-Interest Income
  'ebbaf3ad-147d-40f3-b3ba-3da86730f42d', //Provision for Credit Loss
  '40f127c4-5f77-4e25-89a1-dea6b210bb3e', //Total Non Interest Expense
];
export const HEALTHCARE_METRICS_FOR_CALCULATE_OI = [
  '96354214-ba0b-4180-84be-e0e11b8548e8', //Net Patient Revenue
  'd69f8a42-a14c-4577-bc4c-aba7ff4efe42', //Salaries & Related Expense
  '1ae98cec-c09c-46f6-8e17-03c6eb9bd4a0', //Other Operating Expense
];
export const INDUSTRIAL_METRICS_FOR_CALCULATE_OI = [
  'e9d4d0f2-767a-40ee-baba-cdf9bea780c2', //Revenue
  'e326728d-782f-409f-813e-f959333b6b74', //Cost of Goods Sold
  'e0e01a58-adb9-4125-bd31-b9a26bd73946', //Selling General & Admin. Expense
  '72062b62-d3cf-452f-83c2-0dd12787de88', //Research & Development Expense
  '4422675c-dfc0-4179-92c0-efedf996f833', //Depreciation & Amortization
];
export const INSURANCE_METRICS_FOR_CALCULATE_TOE = [
  'daa91faa-e722-43f8-b39a-7797c0041575', //Benefits & Claims Expense
  '938e15c6-a84b-4f2c-a4dc-4946c93c8432', //Selling, General & Admin. Expense
  '0afedaf0-72ae-4ca5-9517-145972c6ea79', //Other Operating Expense
];
export const INSURANCE_METRICS_FOR_CALCULATE_PTI = [
  '318d7180-6963-4b53-aaf5-36b7c0ba78f0', //Total Revenue
  '4733a498-785e-4b09-855e-2597ed9a811b', //Total Operating Expenses
];

export const nieMetricUid = '40f127c4-5f77-4e25-89a1-dea6b210bb3e';
export const ptiMetricUid = '95e35be8-fdf1-4b6d-8411-1b85c2db072a';
export const oiHealthcareMetricUid = 'f8f212dc-c7dc-4348-96b3-99aa549f2303';
export const oiIndustrialMetricUid = '17ea58ae-cd55-448b-960e-414845c06438';
export const toeInsuranceMetricUid = '4733a498-785e-4b09-855e-2597ed9a811b';
export const piInsuranceMetricUid = '04e6d4d8-6d39-4728-9178-b57f5259fa60';
//---------------------------------------------------------------------------
