import { Directive, OnInit, Renderer2, ViewContainerRef } from '@angular/core';

@Directive({
  selector: 'button[fnlButtonAsLink]',
  standalone: true,
})
export class FnlButtonAsLinkDirective implements OnInit {

  private buttonElement: HTMLElement;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private renderer2: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.buttonElement = this.viewContainerRef.element.nativeElement;
    this.renderer2.addClass(this.buttonElement, 'fnl-button-as-link');
  }
}
