import { CommonModule, getCurrencySymbol } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EMPTY_UID, LOCALE, } from '@app/core/constants';
import { Industry, IndustryCode } from '@app/core/models/industry.model';
import { Insights } from '@app/core/models/insights.model';
import { MetricChartView, } from '@app/core/models/metric-chart.model';
import { MetricsTrend, MetricsUnit } from '@app/core/models/metric.model';
import { tuiPure } from '@taiga-ui/cdk';
import {
  DashboardChartComponent,
  DashboardMeasures,
  DashboardMetric
} from '@app/common/components/dashboard-chart/dashboard-chart.component';
import {
  TrendChartComponent,
  TrendMeasures,
  TrendMetric
} from '@app/common/components/trend-chart/trend-chart.component';
import {
  generateIncomeDescription,
  generateIncomeName,
  generateIncomeTrendName,
  generateRevenueDescription,
  generateRevenueName,
  generateRevenueTrendName
} from '@app/common/components/dashboard-chart/helpers/dashboard-common-metrics.helper';
import { EmptyPipe } from '@app/common/pipes/empty.pipe';
import { LearnMoreLinkComponent } from '@app/common/components/learn-more-link/learn-more-link.component';

export type DashboardAbsolute = {
  name: string;
  value: number;
  currencyCode: string;
  date: Date;
};

export type WidgetMeasuresData = {
  dashboard: DashboardMeasures,
  trend: TrendMeasures,
}

export type WidgetData = {
  dashboard: DashboardMetric,
  trend: TrendMetric,
}

@Component({
  selector: 'app-metrics-dashboard',
  templateUrl: './metrics-dashboard.component.html',
  styleUrls: [ './metrics-dashboard.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DashboardChartComponent,
    TrendChartComponent,
    EmptyPipe,
    LearnMoreLinkComponent,
  ]
})
export class MetricsDashboardComponent {
  /**
   * New Input() params
   */
  @Input() revenueWidgetMeasuresData: WidgetMeasuresData;
  @Input() incomeWidgetMeasuresData: WidgetMeasuresData;
  @Input() view: MetricChartView = MetricChartView.Years;
  @Input() industry: Industry;
  @Input() absoluteInsights: Insights;

  //Optional params
  @Input() companyName?: string;
  @Input() width?: number;
  @Input() learnMoreTopic?: string;

  @Output() learnMoreOpened = new EventEmitter<string>();


  /**
   * New getter revenue and income data
   */

  get revenueWidgetData(): WidgetData {
    let measuresData = this.revenueWidgetMeasuresData;

    if (this.revenueWidgetMeasuresData === null) {
      measuresData = this._getPlaceholderData()
    }

    return this._prepareRevenueWidgetData(measuresData)
  }

  get incomeWidgetData(): WidgetData {
    let measuresData = this.incomeWidgetMeasuresData;

    if (this.incomeWidgetMeasuresData === null) {
      measuresData = this._getPlaceholderData()
    }

    return this._prepareIncomeWidgetData(measuresData)

  }

  get periodText(): string {
    return this.absoluteData?.date != null
      ? `(for the ${
        this.view === MetricChartView.Years ? 'year' : '12 months'
      } ended ${ this.absoluteData?.date.toLocaleString('en-US', { timeZone: 'UTC', month: 'long', year: 'numeric' }) })`
      : '';
  }


  get absoluteData(): DashboardAbsolute {
    return this.absoluteInsights != null
      ? this._prepareAbsolute(this.absoluteInsights)
      : null;
  }

  get currencySymbol(): string {
    return getCurrencySymbol(this.absoluteData?.currencyCode, 'narrow', LOCALE);
  }


  @tuiPure
  private _prepareRevenueWidgetData(widgetMeasuresData: WidgetMeasuresData): WidgetData {
    return ({
      dashboard: {
        ...widgetMeasuresData?.dashboard,
        trend: MetricsTrend.Positive,
        unit: MetricsUnit.Percent,
        name: this._generateRevenueName(this.industry?.category.code),
        description: this._generateRevenueDescription(this.industry?.category.code)
      },
      trend: {
        ...widgetMeasuresData?.trend,
        name: this._generateRevenueTrendName(this.industry?.category.code, this.view),
        unit: MetricsUnit.Percent,

      }
    })
  }

  @tuiPure
  private _prepareIncomeWidgetData(widgetMeasuresData: WidgetMeasuresData): WidgetData {
    return ({
      dashboard: {
        ...widgetMeasuresData?.dashboard,
        trend: MetricsTrend.Positive,
        unit: MetricsUnit.Percent,
        name: this._generateIncomeName(),
        description: this._generateIncomeDescription(this.industry?.category.code)
      },
      trend: {
        ...widgetMeasuresData?.trend,
        name: this._generateIncomeTrendName(this.view),
        unit: MetricsUnit.Percent,

      }
    })
  }

  /**
   * @deprecated use getEmptyDashboard function instead
   * @private
   */
  private _getPlaceholderData(): WidgetMeasuresData {
    return {
      dashboard: {
        uid: EMPTY_UID,
        median: null,
        company: {
          uid: EMPTY_UID,
          name: '',
          color: '',
        },
        peers: [],
        placeholder: true,
      } as DashboardMetric,
      trend: {
        uid: EMPTY_UID,
        values: new Array(5).fill(0).map((_, index) => ({
          uid: `${ EMPTY_UID }_${ index }`,
          name: '',
          xCord: index,
        })),
        placeholder: true,
      } as TrendMetric,
    };
  }

  private _generateRevenueName(industryCode: IndustryCode): string {
    return generateRevenueName(industryCode);
  }

  private _generateRevenueDescription(industryCode: IndustryCode): string {
    return generateRevenueDescription(industryCode);
  }

  private _generateRevenueTrendName(industryCode: IndustryCode, view: MetricChartView): string {
    return generateRevenueTrendName(industryCode, view);
  }

  private _generateIncomeName(): string {
    return generateIncomeName();
  }

  private _generateIncomeDescription(industryCode: IndustryCode): string {
    return generateIncomeDescription(industryCode);
  }

  private _generateIncomeTrendName(view: MetricChartView): string {
    return generateIncomeTrendName(view);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @tuiPure
  private _prepareAbsolute(insights: Insights): DashboardAbsolute {
    return insights.category.code !== IndustryCode.Bank
      ? {
        name: 'Revenue',
        currencyCode: insights.currencyCode,
        value: insights.revenue,
        date:
          new Date(insights.fiscalPeriodEnd),
      }
      : {
        name: 'Total Income',
        currencyCode: insights.currencyCode,
        value: insights.metrics[0]?.value,
        date:
          new Date(insights.fiscalPeriodEnd),
      };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  prepareLegendData() {
    return ([ this.revenueWidgetData.dashboard.company ].concat(this.revenueWidgetData.dashboard.peers))?.length > 1
      ? [ this.revenueWidgetData.dashboard.company ].concat(this.revenueWidgetData.dashboard.peers)
      : [ this.incomeWidgetData.dashboard.company ].concat(this.incomeWidgetData.dashboard.peers)
  }
}
