import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseItem } from '@app/core/models/common.model';
import { PopoverRef, PopoverService } from '@app/shared/popover';
import { tuiDefaultProp } from '@taiga-ui/cdk';
import { MoreActionsButtonComponent } from './more-actions-button/more-actions-button.component';

import { MoreActionsPopoverComponent } from './more-actions-popover/more-actions-popover.component';

@Component({
  selector: 'app-more-actions',
  templateUrl: './more-actions.component.html',
  styleUrls: ['./more-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MoreActionsButtonComponent
  ]
})
export class MoreActionsComponent implements OnDestroy {
  @ViewChild('button') buttonRef: MoreActionsButtonComponent;
  @Input()
  @tuiDefaultProp()
  actions: BaseItem[] = [];
  @Output() actionResult = new EventEmitter<string>();

  private _popoverRef: PopoverRef<MoreActionsPopoverComponent>;

  constructor(
    private _elRef: ElementRef,
    private _popoverService: PopoverService
  ) {}

  get isDisabled(): boolean {
    return !this.actions || (this.actions && this.actions.length === 0);
  }

  private get _popoverPositions(): ConnectedPosition[] {
    const height =
      this.buttonRef.element.nativeElement.getBoundingClientRect().height;
    return [
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: height,
        panelClass: 'more-actions-popover__top-end',
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetY: -height,
        panelClass: 'more-actions-popover__bottom-end',
      },
    ];
  }

  ngOnDestroy(): void {
    if (this._popoverRef != null) {
      this._popoverRef.close();
      this._popoverRef = null;
    }
  }

  openPopover(): void {
    if (this._popoverRef != null || this.isDisabled) {
      return;
    }

    this._popoverRef = this._popoverService.open(
      MoreActionsPopoverComponent,
      this._elRef.nativeElement,
      {
        positions: this._popoverPositions,
        data: {
          actions: this.actions,
        },
      }
    );
    this._popoverRef.afterClosed$.subscribe((item: BaseItem) => {
      this._popoverRef = null;
      if (item != null) {
        this.actionResult.emit(item.uid);
      }
    });
  }
}
