import { RetryInterceptor } from './../interceptors/retry.interceptor';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiOptions } from '@app/core/models/api.model';
import { BlockerService } from '@app/core/services/blocker.service';
import { NotificationsService } from '@app/core/services/notifications.service';
import { defaultsDeep, Dictionary } from 'lodash';
import { omit } from 'lodash/fp';
import { first, Observable, throwError } from 'rxjs';
import { catchError, delay, filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DebounceInterceptorService } from '@app/core/interceptors/debounce.interceptor';

export interface BaseCreateResponse {
  entityUid?: string;
  uid?: string;
  uids?: string[];
  statusText?: string;
}

export interface BaseErrorResponse {
  type: string;
  title: string;
  status: number;
  detail: string;
  traceId: string;
  instance?: string;
  errors?: Dictionary<string | string[]>;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  aPIUrl = environment.apiGateway;

  private options: ApiOptions = {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    preFillUrl: true,
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private blockerService: BlockerService,
    private notificationsService: NotificationsService,
    private debounceInterceptorService: DebounceInterceptorService,
  ) {
  }

  get<T>(
    path: string,
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number
  ): Observable<T>;
  get<T>(
    path: string,
    optionsOrCIdOrSkipCatch?: ApiOptions | string | boolean,
    cIdOrSkipCatchOrRetryNum?: string | boolean | number
  ): Observable<T>;
  get<T>(
    path: string,
    optionsOrCId?: ApiOptions | string,
    cIdOrSkipCatch?: string | boolean,
    skipCatchOrRetryNum?: boolean | number
  ): Observable<T>;
  get<T>(
    path: string,
    optionsOrCIdOrSkipCatchOrRetryNum?: any,
    cIdOrSkipCatchOrRetryNum?: any,
    skipCatchOrRetryNum?: any,
    retryNum = -1
  ): Observable<T> {
    let options: ApiOptions = this._getOptions(
      optionsOrCIdOrSkipCatchOrRetryNum
    );
    options = options ? defaultsDeep(options, this.options) : this.options;
    const cId = this._getCId(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum
    );
    const skipCatch = this._getSkipCatch(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum
    );
    retryNum = this._getRetry(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum,
      retryNum
    );
    options = this._insertMaxRetry(options, retryNum);

    const fullUrl = options.preFillUrl ? `${ this.aPIUrl }/${ path }` : path;
    options = omit([ 'preFillUrl' ], options);

    if (cId != null) {
      this.blockerService.prepareUrlLock(cId, fullUrl);
    }

    return this.http.get(fullUrl, options).pipe(
      tap(() => {
        if (cId != null) {
          this.blockerService.removeLock(cId, fullUrl);
        }
      }),
      map((response: any) => response as T),
      catchError((error) =>
        !skipCatch ? this._handleError(error, fullUrl) : throwError(error)
      )
    );
  }

  post<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number
  ): Observable<T>;
  post<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatch?: ApiOptions | string | boolean,
    cIdOrSkipCatchOrRetryNum?: string | boolean | number
  ): Observable<T>;
  post<T>(
    path: string,
    body: any,
    optionsOrCId?: ApiOptions | string,
    cIdOrSkipCatch?: string | boolean,
    skipCatchOrRetryNum?: boolean | number
  ): Observable<T>;
  post<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatchOrRetryNum?: any,
    cIdOrSkipCatchOrRetryNum?: any,
    skipCatchOrRetryNum?: any,
    retryNum = 0
  ): Observable<T> {
    let options: ApiOptions = this._getOptions(
      optionsOrCIdOrSkipCatchOrRetryNum
    );
    options = options ? defaultsDeep(options, this.options) : this.options;
    const cId = this._getCId(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum
    );
    const skipCatch = this._getSkipCatch(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum
    );
    retryNum = this._getRetry(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum,
      retryNum
    );
    options = this._insertMaxRetry(options, retryNum);

    const fullUrl = options.preFillUrl ? `${ this.aPIUrl }/${ path }` : path;
    options = omit([ 'preFillUrl' ], options);

    if (cId != null) {
      this.blockerService.prepareUrlLock(cId, fullUrl);
    }

    return this.http.post(fullUrl, body, options).pipe(
      tap(() => {
        if (cId != null) {
          this.blockerService.removeLock(cId, fullUrl);
        }
      }),
      map((response: any) => response as T),
      catchError((error) =>
        !skipCatch ? this._handleError(error, fullUrl) : throwError(error)
      )
    );
  }

  delete<T>(
    path: string,
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number
  ): Observable<T>;
  delete<T>(
    path: string,
    optionsOrCIdOrSkipCatch?: ApiOptions | string | boolean,
    cIdOrSkipCatchOrRetryNum?: string | boolean | number
  ): Observable<T>;
  delete<T>(
    path: string,
    optionsOrCId?: ApiOptions | string,
    cIdOrSkipCatch?: string | boolean,
    skipCatchOrRetryNum?: boolean | number
  ): Observable<T>;
  delete<T>(
    path: string,
    optionsOrCIdOrSkipCatchOrRetryNum?: any,
    cIdOrSkipCatchOrRetryNum?: any,
    skipCatchOrRetryNum?: any,
    retryNum = 0
  ): Observable<T> {
    let options: ApiOptions = this._getOptions(
      optionsOrCIdOrSkipCatchOrRetryNum
    );
    options = options ? defaultsDeep(options, this.options) : this.options;
    const cId = this._getCId(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum
    );
    const skipCatch = this._getSkipCatch(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum
    );
    retryNum = this._getRetry(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum,
      retryNum
    );
    options = this._insertMaxRetry(options, retryNum);

    const fullUrl = options.preFillUrl ? `${ this.aPIUrl }/${ path }` : path;
    options = omit([ 'preFillUrl' ], options);

    if (cId != null) {
      this.blockerService.prepareUrlLock(cId, fullUrl);
    }

    return this.http.delete(fullUrl, options).pipe(
      tap(() => {
        if (cId != null) {
          this.blockerService.removeLock(cId, fullUrl);
        }
      }),
      map((response: any) => response as T),
      catchError((error) =>
        !skipCatch ? this._handleError(error, fullUrl) : throwError(error)
      )
    );
  }

  put<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number
  ): Observable<T>;
  put<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatch?: ApiOptions | string | boolean,
    cIdOrSkipCatchOrRetryNum?: string | boolean | number
  ): Observable<T>;
  put<T>(
    path: string,
    body: any,
    optionsOrCId?: ApiOptions | string,
    cIdOrSkipCatch?: string | boolean,
    skipCatchOrRetryNum?: boolean | number
  ): Observable<T>;
  put<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatchOrRetryNum?: any,
    cIdOrSkipCatchOrRetryNum?: any,
    skipCatchOrRetryNum?: any,
    retryNum = 0
  ): Observable<T> {
    let options: ApiOptions = this._getOptions(
      optionsOrCIdOrSkipCatchOrRetryNum
    );
    options = options ? defaultsDeep(options, this.options) : this.options;
    const cId = this._getCId(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum
    );
    const skipCatch = this._getSkipCatch(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum
    );
    retryNum = this._getRetry(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum,
      retryNum
    );
    options = this._insertMaxRetry(options, retryNum);

    const fullUrl = options.preFillUrl ? `${ this.aPIUrl }/${ path }` : path;
    options = omit([ 'preFillUrl' ], options);

    if (cId != null) {
      this.blockerService.prepareUrlLock(cId, fullUrl);
    }

    return this.http.put(fullUrl, body, options).pipe(
      tap(() => {
        if (cId != null) {
          this.blockerService.removeLock(cId, fullUrl);
        }
      }),
      map((response: any) => response as T),
      catchError((error) =>
        !skipCatch ? this._handleError(error, fullUrl) : throwError(error)
      )
    );
  }

  patch<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number
  ): Observable<T>;
  patch<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatch?: ApiOptions | string | boolean,
    cIdOrSkipCatchOrRetryNum?: string | boolean | number
  ): Observable<T>;
  patch<T>(
    path: string,
    body: any,
    optionsOrCId?: ApiOptions | string,
    cIdOrSkipCatch?: string | boolean,
    skipCatchOrRetryNum?: boolean | number
  ): Observable<T>;
  patch<T>(
    path: string,
    body: any,
    optionsOrCIdOrSkipCatchOrRetryNum?: any,
    cIdOrSkipCatchOrRetryNum?: any,
    skipCatchOrRetryNum?: any,
    retryNum = 0
  ): Observable<T> {
    let options: ApiOptions = this._getOptions(
      optionsOrCIdOrSkipCatchOrRetryNum
    );
    options = options ? defaultsDeep(options, this.options) : this.options;
    const cId = this._getCId(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum
    );
    const skipCatch = this._getSkipCatch(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum
    );
    retryNum = this._getRetry(
      optionsOrCIdOrSkipCatchOrRetryNum,
      cIdOrSkipCatchOrRetryNum,
      skipCatchOrRetryNum,
      retryNum
    );
    options = this._insertMaxRetry(options, retryNum);

    const fullUrl = options.preFillUrl ? `${ this.aPIUrl }/${ path }` : path;
    options = omit([ 'preFillUrl' ], options);

    if (cId != null) {
      this.blockerService.prepareUrlLock(cId, fullUrl);
    }

    return this.http.patch(fullUrl, body, options).pipe(
      tap(() => {
        if (cId != null) {
          this.blockerService.removeLock(cId, fullUrl);
        }
      }),
      map((response: any) => response as T),
      catchError((error) =>
        !skipCatch ? this._handleError(error, fullUrl) : throwError(error)
      )
    );
  }

  downloadByLink(downloadUrl: string, fileName: string = 'download', newTab = false): void {
    const aElem = document.createElement('a');
    if (
      typeof aElem.download === 'undefined'
    ) {
      if (newTab) {
        window.open(downloadUrl, '_blank');
      } else {
        window.location.assign(downloadUrl);
      }
    } else {
      aElem.style.display = 'none';
      aElem.href = downloadUrl;
      aElem.download = fileName;
      aElem.rel = 'noopener'
      if (newTab) {
        aElem.target = '_blank';
      }

      // Firefox requires to append this link.
      document.body.appendChild(aElem);

      this.debounceInterceptorService.requestsIdle$
        .pipe(
          filter((isIdle) => isIdle),
          first(),
          tap(() => aElem.click()),
          delay(100),
          tap(() => {
            URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(aElem);
          }),
        )
        .subscribe();
    }
  }

  /**
   * Download file from blob
   *
   * @param blob
   * @param filename
   */
  downloadFile(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private _getOptions(
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number
  ): ApiOptions {
    return typeof optionsOrCIdOrSkipCatchOrRetryNum !== 'string' &&
    typeof optionsOrCIdOrSkipCatchOrRetryNum !== 'boolean' &&
    typeof optionsOrCIdOrSkipCatchOrRetryNum !== 'number'
      ? optionsOrCIdOrSkipCatchOrRetryNum
      : null;
  }

  private _getCId(
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number,
    cIdOrSkipCatchOrRetryNum?: string | boolean | number
  ): string {
    return cIdOrSkipCatchOrRetryNum != null &&
    typeof cIdOrSkipCatchOrRetryNum !== 'boolean' &&
    typeof cIdOrSkipCatchOrRetryNum !== 'number'
      ? cIdOrSkipCatchOrRetryNum
      : typeof optionsOrCIdOrSkipCatchOrRetryNum === 'string'
        ? optionsOrCIdOrSkipCatchOrRetryNum
        : null;
  }

  private _getSkipCatch(
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number,
    cIdOrSkipCatchOrRetryNum?: string | boolean | number,
    skipCatchOrRetryNum?: boolean | number
  ): boolean {
    return typeof skipCatchOrRetryNum !== 'number' && typeof skipCatchOrRetryNum !== 'undefined'
      ? skipCatchOrRetryNum || false
      : typeof cIdOrSkipCatchOrRetryNum === 'boolean'
        ? cIdOrSkipCatchOrRetryNum
        : typeof optionsOrCIdOrSkipCatchOrRetryNum === 'boolean'
          ? optionsOrCIdOrSkipCatchOrRetryNum
          : false;
  }

  private _getRetry(
    optionsOrCIdOrSkipCatchOrRetryNum?: ApiOptions | string | boolean | number,
    cIdOrSkipCatchOrRetryNum?: string | boolean | number,
    skipCatchOrRetryNum?: boolean | number,
    retryNum?: number
  ): number {
    return typeof skipCatchOrRetryNum === 'number'
      ? skipCatchOrRetryNum
      : typeof cIdOrSkipCatchOrRetryNum === 'number'
        ? cIdOrSkipCatchOrRetryNum
        : typeof optionsOrCIdOrSkipCatchOrRetryNum === 'number'
          ? optionsOrCIdOrSkipCatchOrRetryNum
          : retryNum;
  }

  private _handleError(error: HttpErrorResponse, url: string) {
    switch (error.status) {
      case 401:
        break;
      // case 404:
      //   this.router.navigateByUrl('/not-found');
      //   break;
      case 405:
        this.notificationsService.addError({
          id: '405-error',
          message:
            'The data migration is in progress. You can only view the data in the system.',
          removable: true,
        });
        break;
      case 403:
        this.notificationsService.addError({
          id: '403-error',
          message:
            'Your account is not allowed to perform requested operation. You may have to sign in with an account that has sufficient permissions.\nSign In with a different account.',
          removable: true,
        });
        break;
      default:
        this.notificationsService.addError({
          id: NotificationsService.generalError,
          message:
            'Sorry, something went wrong. Please reload the page or try again later.',
          removable: true,
        });
    }

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // eslint-disable-next-line no-console
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // eslint-disable-next-line no-console
      console.error(
        `An error occurred in request '${ url }', backend returned code ${
          error.status
        }, body was: ${ error.error ? JSON.stringify(error.error) : error.error }`
      );
    }

    // return an observable with a user-facing error message
    return throwError(error);
  }

  private _insertMaxRetry(options: ApiOptions, maxRetry: number): ApiOptions {
    return {
      ...options,
      headers: {
        ...(options.headers || {}),
        [RetryInterceptor.retryHeaderName]: maxRetry.toString(),
      },
    };
  }
}
