import { Injectable } from '@angular/core';
import { Currency } from '@app/core/models/currency.model';
import { ApiService } from '@app/core/services/api.service';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private currencies$: Observable<Currency[]>;
  private defaultCurrency$: Observable<Currency>;

  constructor(private apiService: ApiService) {}

  getAllCurrencies(): Observable<Currency[]> {
    if (!this.currencies$) {
      this.currencies$ = this.apiService
        .get<Currency[]>('common/Currency')
        .pipe(
          catchError(() => of([]) as Observable<Currency[]>),
          shareReplay(1)
        );
    }

    return this.currencies$;
  }

  getCurrencyByCode(code: string): Observable<Currency> {
    return this.getAllCurrencies().pipe(
      map((currencies) => currencies.find((currency) => currency.code === code))
    );
  }

  getCurrencyByUid(uid: string): Observable<Currency> {
    return this.getAllCurrencies().pipe(
      map((currencies) => currencies.find((currency) => currency.uid === uid))
    );
  }

  getDefaultCurrency(): Observable<Currency> {
    if (!this.defaultCurrency$) {
      this.defaultCurrency$ = this.getCurrencyByCode('USD');
    }

    return this.defaultCurrency$;
  }
}
