<app-hotjar></app-hotjar>
<app-version-manager></app-version-manager>

<div
  class="layout"
  [class.layout_anonym]="isAnonymLayout"
  [class.layout_special]="isSpecialLayout"
  [class.layout_public]="isPublicLayout"
>
  <ngx-loading-bar></ngx-loading-bar>

  <app-header-notice-bar *ngIf="isBaseLayout" [notificationData]="notificationsData$ | async"
                         (notificationViewed)="sentViewedNotice($event)"></app-header-notice-bar>

  <app-notifications-queue
    *ngIf="isBaseLayout"
    class="layout__notifications"
  ></app-notifications-queue>

  <img
    *ngIf="!isBaseLayout && !isPublicLayout"
    class="layout__logo"
    src="/assets/images/logo.svg"
    alt="Finlistics"
  />
  <div class="layout__content">
    <app-base-header *ngIf="isBaseLayout"></app-base-header>
    <app-base-header-public *ngIf="isPublicLayout"></app-base-header-public>
    <router-outlet></router-outlet>
  </div>

  <app-footer
    *ngIf="isBaseLayout || isSpecialLayout || isPublicLayout"
    class="layout__footer"
  ></app-footer>
</div>

<app-side-panel-list *ngIf="isBaseLayout"></app-side-panel-list>
<app-cookies-message *ngIf="isBaseLayout"></app-cookies-message>
<fnl-learn-more
  *ngIf="isBaseLayout"
  [canEdit]="isFinlisticsAdmin | async"
  (editTopic)="onEditTopic($event)"
  [contentWrapperClass]="['ck-content']"
></fnl-learn-more>
<app-back-to-top *ngIf="isBaseLayout || isSpecialLayout || isPublicLayout"></app-back-to-top>
