import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { FnlIconsRegistry } from './fnl-icons.registry';

@NgModule({
  providers: [FnlIconsRegistry],
  imports: [CommonModule, HttpClientModule, MatIconModule],
  exports: [MatIconModule],
})
export class FnlIconModule {}
