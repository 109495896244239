import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomAndVisible } from '@app/core/models/common.model';
import { IndustryService } from '@app/core/services/industry.service';
import { Branch, Trunk } from '@app/shared/data-tree/data-tree.component';
import { Dictionary, isNil, omitBy } from 'lodash';
import { catchError, combineLatest, map, Observable, of, tap } from 'rxjs';

import { CACHE_EXPIRE_DISTANCE } from '../constants';
import { BaseItem } from '../models/common.model';
import { GoalItem } from '../models/goal.model';
import { Industry } from '../models/industry.model';
import {
  BusinessStrategies,
  StrategyAndInitiatives,
  StrategyForm,
  StrategyWithBFItem,
} from '../models/strategy.model';
import {
  ApiService,
  BaseCreateResponse,
  BaseErrorResponse,
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class StrategiesService {
  static readonly blockCid = 'StrategyService';

  private _cache: Dictionary<{ data: StrategyForm; time: Date }> = {};

  constructor(
    private _apiService: ApiService,
    private _industryService: IndustryService
  ) {}

  getStrategies(
    industryUid: string,
    subIndustryUid?: string,
    financialGoalUid?: string[],
    businessFunctions?: BaseItem[],
    blockCid = StrategiesService.blockCid
  ): Observable<Trunk[]> {
    return this._apiService
      .get<BusinessStrategies[]>(
        'fishbone/financialsAndKpis/strategy-initiatives',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                businessFunctionUid: businessFunctions.map(
                  (item: BaseItem) => item.uid
                ),
                industryUid,
                subIndustryUid,
                financialGoalUid,
              },
              isNil
            ),
          }),
        },
        blockCid
      )
      .pipe(
        map((list: BusinessStrategies[]) => {
          const namesMap = businessFunctions.reduce(
            (result: Dictionary<string>, item: BaseItem) => {
              result[item.uid] = item.name;

              return result;
            },
            {}
          );

          return list.map(
            (item: BusinessStrategies) =>
              ({
                uid: item.businessFunctionUids.join('_'),
                name: item.businessFunctionUids
                  .map((bfUid: string) => namesMap[bfUid])
                  .join(', '),
                branches: item.strategyInitiatives.map(
                  (branch: StrategyAndInitiatives) =>
                    ({
                      ...branch.strategy,
                      leaves: branch.initiatives,
                    } as Branch)
                ),
              } as Trunk)
          );
        }),
        catchError(() => of([]))
      );
  }

  getStrategiesByGoal(
    industryUid: string,
    subIndustryUid?: string,
    businessFunctionUid?: string,
    blockCid = StrategiesService.blockCid
  ): Observable<GoalItem[]> {
    return this._apiService
      .get<GoalItem[]>(
        'fishbone/strategies-by-goals',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                industryUid,
                subIndustryUid,
                businessFunctionUid,
              },
              isNil
            ),
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of([])));
  }

  getStrategyByUid(
    uid: string,
    clientUid?: string,
    force = false,
    useCache = false,
    blockCid = StrategiesService.blockCid
  ): Observable<StrategyForm> {
    const cacheUid = this._getCacheUid(uid, clientUid);

    if (
      this._cache[cacheUid] != null &&
      (Date.now() - this._cache[cacheUid].time.getTime() <
        CACHE_EXPIRE_DISTANCE ||
        useCache) &&
      !force
    ) {
      return of(this._cache[cacheUid].data);
    }

    return combineLatest([
      this._industryService.getIndustries(),
      this._apiService
        .get<StrategyForm>(
          clientUid == null
            ? `fishbone/strategies/${uid}`
            : `fishbone/clients/${clientUid}/strategies/${uid}`,
          blockCid
        )
        .pipe(catchError(() => of(null))),
    ]).pipe(
      map(([industries, strategy]) => {
        if (strategy == null || strategy?.industryUid != null) {
          return strategy;
        } else {
          const industryBySubIndustry: Industry = industries.find(
            (industry) =>
              industry.subIndustries.find(
                (subindustry) => subindustry.uid === strategy.subIndustryUid
              ) != null
          );
          return {
            ...strategy,
            industryUid: industryBySubIndustry?.uid,
            industryName: industryBySubIndustry?.name,
          };
        }
      }),
      tap((strategy: StrategyForm) => {
        this._cache[cacheUid] = {
          data: strategy,
          time: new Date(),
        };
      }),
      catchError(() => of(null))
    );
  }

  createStrategy(
    form: StrategyForm,
    clientUid?: string,
    blockCid = StrategiesService.blockCid
  ): Observable<BaseCreateResponse> {
    return this._apiService
      .post<BaseCreateResponse>(
        clientUid == null
          ? 'fishbone/strategies'
          : `fishbone/clients/${clientUid}/strategies`,
        {
          name: form.name,
          goalUid: form.goalUid,
          businessFunctionUids: form.businessFunctionUids,
        },
        blockCid
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  updateStrategy(
    form: StrategyForm,
    clientUid?: string,
    blockCid = StrategiesService.blockCid
  ): Observable<BaseCreateResponse> {
    this._cache[this._getCacheUid(form.uid, clientUid)] = null;

    return this._apiService
      .put<BaseCreateResponse>(
        clientUid == null
          ? `fishbone/strategies/${form.uid}`
          : `fishbone/clients/${clientUid}/strategies/${form.uid}`,
        {
          name: form.name,
          goalUid: form.goalUid,
          businessFunctionUids: form.businessFunctionUids,
        },
        blockCid
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  removeStrategy(
    uid: string,
    clientUid?: string,
    blockCid = StrategiesService.blockCid
  ): Observable<boolean> {
    return this._apiService
      .delete(
        clientUid == null
          ? `fishbone/strategies/${uid}`
          : `fishbone/clients/${clientUid}/strategies/${uid}`,
        blockCid
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  getStrategiesByGoalBusinessFunctions(
    clientUid: string,
    goalUid: string,
    businessFunctionUid?: Array<string>,
    blockCid = StrategiesService.blockCid
  ): Observable<StrategyWithBFItem[]> {
    return this._apiService
      .get<{
        items: (BaseItem &
          CustomAndVisible & { businessFunctions: BaseItem[] })[];
      }>(
        `fishbone/clients/${clientUid}/strategies`,
        {
          params: new HttpParams({
            fromObject: {
              goalUid,
              ...businessFunctionUid.reduce(
                (result: Dictionary<string>, item: string, index: number) => {
                  result[`businessFunctionUid[${index}]`] = item;
                  return result;
                },
                {}
              ),
            },
          }),
        },
        blockCid
      )
      .pipe(
        map((data) =>
          data.items.map((item) => ({
            uid: item.uid,
            name: item.name,
            custom: item.custom,
            items: item.businessFunctions,
          }))
        ),
        catchError(() => of([]))
      );
  }

  toggleStrategyVisibility(
    clientUid: string,
    uid: string,
    visible: boolean,
    blockCid = StrategiesService.blockCid
  ): Observable<BaseCreateResponse> {
    return this._apiService
      .put<BaseCreateResponse>(
        `fishbone/clients/${clientUid}/strategies/${uid}/visible`,
        { visible },
        blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  private _getCacheUid(uid: string, clientUid?: string): string {
    return clientUid == null ? uid : `${clientUid}__${uid}`;
  }
}
