import { ModuleWithProviders, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { CookieStorage } from './interfaces/cookie-storage.interface';
import { LocalStorage } from './interfaces/local-storage.interface';
import { MemoryStorage } from './interfaces/memory-storage.interface';
import { SessionStorage } from './interfaces/session-storage.interface';
import { StorageOptions } from './interfaces/storage-options.interface';
import { COOKIE_IN_INCOGNITO } from './storage.tokens';
import { BaseCookieStorage } from './storages/base-cookie.storage';
import { BaseLocalStorage } from './storages/base-local.storage';
import { BaseMemoryStorage } from './storages/base-memory.storage';
import { BaseSessionStorage } from './storages/base-session.storage';

@NgModule()
export class BrowserStorageModule {
  static forRoot(
    options: Partial<StorageOptions> = {}
  ): ModuleWithProviders<BrowserStorageModule> {
    return {
      ngModule: BrowserStorageModule,
      providers: [
        {
          provide: COOKIE_IN_INCOGNITO,
          useValue: !!options.cookieInIncognito,
        },
        {
          provide: CookieService,
          useClass: options.cookieService || CookieService,
        },
        {
          provide: MemoryStorage,
          useClass: options.memoryStorage || BaseMemoryStorage,
        },
        {
          provide: CookieStorage,
          useClass: options.cookieStorage || BaseCookieStorage,
        },
        {
          provide: LocalStorage,
          useClass: options.localStorage || BaseLocalStorage,
        },
        {
          provide: SessionStorage,
          useClass: options.sessionStorage || BaseSessionStorage,
        },
      ],
    };
  }
}
