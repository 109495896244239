import {BaseItem} from './common.model';

export enum UserRole {
  BaseUser = 'BASE_USER',
  ProUser = 'CLIENT_USER',
  /**
   * @deprecated Replaces with UserRole.ProUser
   */
  ClientUser = 'CLIENT_USER',
  ClientAdmin = 'CLIENT_ADMIN',
  FinlisticsAdmin = 'FINLISTICS_ADMIN',
}

export interface User extends BaseItem {
  clientUid: string;
  clientName: string;
  clientCode: string;
  legacyId?: string;
  legacySiteId?: string;
  email: string;
  avatarUrl: string;
  roleNames: UserRole[];
  clientCustomLogo?: boolean;
}

export interface UserSettings {
  code: UserSettingCode;
  value: string;
}

export enum UserSettingCode {
  ImprovementDecimalPlaces = 'IMPROVEMENT_DECIMAL_PLACES',
  IncludePrivateCompanySearch = 'INCLUDE_PRIVATE_COMPANY_SEARCH_RESULTS',
  SalesForceIntegration = 'SALESFORCE_INTEGRATION_ENABLED',
  DisplayCustomLogo = 'DISPLAY_CUSTOM_LOGO',
  AccountPlanTerm = 'ACCOUNT_PLAN_TERM',
  AccountPlanCreateDescription = 'ACCOUNT_PLAN_CREATE_DESCRIPTION',
}
