import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  BaseGroupItem,
  BaseItem,
  MainEntityTokenKeys,
} from '@app/core/models/common.model';
import { Peer } from '@app/core/models/peer.model';
import { AllSegmentsItems } from '@app/core/models/segment.model';
import { Observable, map } from 'rxjs';

import {
  BasePresentationData,
  CompanyPresentationData,
  PrivateCompanyPresentationData,
} from './presentation.model';
import { CompanyPresentationService } from '@app/modules/company/services/company-presentation.service';
import { IndustryMetric } from '@app/core/models/metric.model';
import { EMPTY_UID } from '@app/core/constants';
import { KpiGroup } from '@app/core/models/kpi.model';
import { PrivateCompanyPresentationService } from '@app/modules/private-company/services/private-company-presentation.service';
import { IndustryCategory } from '@app/core/models/industry.model';

@Injectable()
export class PresentationService {
  static readonly blockCid = 'PresentationService';

  constructor(
    @Inject(MAT_DIALOG_DATA) private _type: MainEntityTokenKeys,
    @Optional() private _companyPresentationService: CompanyPresentationService,
    @Optional() private _privateCompanyPresentationService: PrivateCompanyPresentationService
  ) {
  }

  get isCompany(): boolean {
    return this._type === 'company';
  }

  getCompanyCategory(): Observable<IndustryCategory> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getCompanyCategory();
      case 'private-company':
        return this._privateCompanyPresentationService.getCompanyCategory();
    }
  }

  setType(value: MainEntityTokenKeys): void {
    this._type = value;
  }

  setBusinessFunction(value?: string): void {
    switch (this._type) {
      case 'company':
        this._companyPresentationService.setBusinessFunction(value);
        break;
      case 'private-company':
        this._privateCompanyPresentationService.setBusinessFunction(value);
        break;
    }
  }

  setSolution(value?: string): void {
    switch (this._type) {
      case 'company':
        this._companyPresentationService.setSolution(value);
        break;
      case 'private-company':
        this._privateCompanyPresentationService.setSolution(value);
        break;
    }
  }

  getIndustryGoals(): Observable<BaseItem[]> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getIndustryGoals();
      case 'private-company':
        return this._privateCompanyPresentationService.getIndustryGoals();
    }
  }

  getBusinessFunctions(): Observable<BaseItem[]> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getBusinessFunctions().pipe(
          map((businessFunctions) => (businessFunctions.reduce((allBusinessFunctions: BaseItem[], currentBusinessFunction: BaseItem) => {
                if (currentBusinessFunction.uid === EMPTY_UID) {
                  currentBusinessFunction = ({...currentBusinessFunction, name: 'Select a Business Function'})
                }

                allBusinessFunctions.push(currentBusinessFunction);
                return allBusinessFunctions
              }, []
            ))
          ));
      case 'private-company':
        return this._privateCompanyPresentationService.getBusinessFunctions().pipe(
          map((businessFunctions) => (businessFunctions.reduce((allBusinessFunctions: BaseItem[], currentBusinessFunction: BaseItem) => {
                if (currentBusinessFunction.uid === EMPTY_UID) {
                  currentBusinessFunction = ({...currentBusinessFunction, name: 'Select a Business Function'})
                }

                allBusinessFunctions.push(currentBusinessFunction);
                return allBusinessFunctions
              }, []
            ))
          ));
    }
  }

  getSolutions(): Observable<BaseGroupItem[]> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getSolutions();
      case 'private-company':
        return this._privateCompanyPresentationService.getSolutions();
    }
  }

  getSegments(): Observable<AllSegmentsItems> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getSegments();
    }
  }

  getRecommendedPeers(): Observable<Peer[]> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getRecommendedPeers();
      case 'private-company':
        return this._privateCompanyPresentationService.getRecommendedPeers();
    }
  }

  getParentPeer(): Observable<Peer> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getParentPeer();
      case 'private-company':
        return this._privateCompanyPresentationService.getParentPeer();
    }
  }

  getEntityName(): Observable<string> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getEntityName();
      case 'private-company':
        return this._privateCompanyPresentationService.getEntityName();
    }
  }

  getPresentationData(): Observable<BasePresentationData> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getCompanyPresentationData();
      case 'private-company':
        return this._privateCompanyPresentationService.getCompanyPresentationData();
    }
  }

  sendPresentationFilterForm(
    formData: CompanyPresentationData | PrivateCompanyPresentationData
  ): Observable<boolean> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.sendPresentationData(formData as CompanyPresentationData);
      case 'private-company':
        return this._privateCompanyPresentationService.sendPresentationData(formData as PrivateCompanyPresentationData);
    }
  }

  getSelectedOtherMetrics(): Observable<IndustryMetric[]> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getSelectedOtherMetrics();
      case 'private-company':
        return this._privateCompanyPresentationService.getSelectedOtherMetrics();
    }
  }

  getSelectedRecommendedMetrics(): Observable<IndustryMetric[]> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getSelectedRecommendedMetrics();
      case 'private-company':
        return this._privateCompanyPresentationService.getSelectedRecommendedMetrics();
    }
  }

  getKpiGroups(): Observable<KpiGroup[]> {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.getKpiGroups();
      case 'private-company':
        return this._privateCompanyPresentationService.getKpiGroups();
    }
  }

  sendAmplitudeCancelEvent(): void {
    switch (this._type) {
      case 'company':
        this._companyPresentationService.trackingExport(false);
        break;
    }
  }

  clear(): void {
    switch (this._type) {
      case 'company':
        return this._companyPresentationService.clearData();
      case 'private-company':
        return this._privateCompanyPresentationService.clearData();
    }
  }
}
