import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { CancelLockInterceptor } from './cancel-lock.interceptor';
import { RetryInterceptor } from './retry.interceptor';
import { DebounceInterceptor } from '@app/core/interceptors/debounce.interceptor';

/** Http interceptor providers in outside-in order */
export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CancelLockInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DebounceInterceptor, multi: true },
];
