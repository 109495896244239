import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Company } from '@app/core/models/company.model';
import { FullIndustryDataSet, Industry, IndustryCategory, IndustryCode, IndustryDataSet, } from '@app/core/models/industry.model';
import { Region } from '@app/core/models/region.model';
import { RevenueRange } from '@app/core/models/revenue.model';
import { cache } from '@app/core/operators';
import { CompanyService } from '@app/core/services/company.service';
import { IndustryService } from '@app/core/services/industry.service';
import { RegionService } from '@app/core/services/region.service';
import { RoutingService } from '@app/core/services/routing.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, combineLatest, firstValueFrom, Observable, of, } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, } from 'rxjs/operators';
import { getRevenueText } from '@app/core/utils/base';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';

@Injectable()
export class CompanyComponentDataService {
  static readonly blockCid = 'CompanyComponentDataService';
  static readonly filtersCid = `${ CompanyComponentDataService.blockCid }_Filters`;

  private _selectedCompany$: Observable<Company>;
  private _companyRevenue$: Observable<RevenueRange>;

  private _industryDataSelection = new BehaviorSubject<IndustryDataSet>(null);

  constructor(
    private _routingService: RoutingService,
    private _regionService: RegionService,
    private _companyService: CompanyService,
    private _industryService: IndustryService,
    private _amplitudeService: AmplitudeService,
  ) {
  }

  getSelectedCompany(): Observable<Company> {
    if (!this._selectedCompany$) {
      this._selectedCompany$ = this._routingService.getActualParams().pipe(
        distinctUntilChanged(
          (a: Params, b: Params) =>
            a.companyId === b.companyId && a.query === b.query
        ),
        switchMap((params: Params) => {
          const companyId = params.companyId;
          const query = (params.query && decodeURI(params.query)) || '';
          return !companyId
            ? of(null)
            : this._companyService
              .getCompanies({ term: query })
              .pipe(
                map((companies) =>
                  companies.find((company) => company.uid === companyId)
                )
              );
        }),
        // HACK: this additional checking added because company reinitialized sometimes without any reason
        distinctUntilChanged((a: Company, b: Company) => a?.uid === b?.uid),
        untilDestroyed(this, 'clearCompanyData'),
        cache()
      );
    }

    return this._selectedCompany$;
  }

  getSelectedNotEmptyCompany(): Observable<Company> {
    return this.getSelectedCompany().pipe(filter((company) => company != null));
  }

  getCompanyCategory(): Observable<IndustryCategory> {
    return this.getCompanyIndustry().pipe(
      map(industry => industry.category),
    )
  }

  getCompanyIndustry(): Observable<Industry> {
    return combineLatest([
      this.getSelectedNotEmptyCompany(),
      this._industryDataSelection,
    ]).pipe(
      map(
        ([ company, industryDataSelection ]: [ Company, IndustryDataSet ]) =>
          industryDataSelection?.industry || company.industry
      )
    );
  }

  getCompanySubIndustry(): Observable<Industry> {
    return combineLatest([
      this.getSelectedNotEmptyCompany(),
      this._industryDataSelection,
    ]).pipe(
      map(([ company, industryDataSelection ]: [ Company, IndustryDataSet ]) =>
        industryDataSelection
          ? industryDataSelection.subIndustry
          : company.subIndustry
      )
    );
  }

  getCompanyRegion(): Observable<Region> {
    return combineLatest([
      this.getSelectedNotEmptyCompany(),
      this._industryDataSelection,
      this._regionService.getRegions(),
    ]).pipe(
      map(
        ([ company, industryDataSelection, regions ]: [
          Company,
          IndustryDataSet,
          Region[]
        ]) =>
          industryDataSelection?.region ||
          regions?.find((item: Region) => item.uid === company.regionUid)
      )
    );
  }

  getCompanyRevenue(): Observable<RevenueRange> {
    if (this._companyRevenue$ == null) {
      this._companyRevenue$ = this.getSelectedNotEmptyCompany().pipe(
        switchMap((company: Company) =>
          this._companyService.getCompanyRevenueRange(company.uid)
        ),
        untilDestroyed(this, 'clearCompanyData'),
        shareReplay(1)
      );
    }

    return this._companyRevenue$;
  }

  getCustomCompanyRevenue(): Observable<RevenueRange> {
    return this._industryDataSelection.pipe(
      map(
        (industryDataSelection: IndustryDataSet) =>
          industryDataSelection?.revenue
      )
    );
  }

  getCompanyIndustryData(): Observable<FullIndustryDataSet> {
    return this.getSelectedNotEmptyCompany().pipe(
      filter((company: Company) => company != null),
      switchMap((company: Company) =>
        combineLatest([
          this._regionService.getRegions(),
          this.getCompanyRevenue(),
        ]).pipe(
          filter(
            ([ regions, range ]: [ Region[], RevenueRange ]) =>
              regions.length !== 0 && range != null
          ),
          map(([ regions, revenue ]: [ Region[], RevenueRange ]) => {
            const regionUid = revenue?.regionUid || company.regionUid;
            const region = regions?.find(
              (item: Region) => item.uid === regionUid
            );

            return {
              industry: company.industry,
              subIndustry: company.subIndustry,
              revenueLabel:
                company.industry.category.code !== IndustryCode.Bank
                  ? 'Revenue'
                  : 'Assets',
              revenue,
              region,
              industryUrl: this._industryService.getIndustryUrl(
                company.subIndustry.uid,
                regionUid
              ),
              industryName: company.subIndustry.name,
            } as FullIndustryDataSet;
          })
        )
      ),
      cache()
    );
  }

  getIndustryData(): Observable<FullIndustryDataSet> {
    return combineLatest([
      this.getCompanyIndustryData(),
      this._industryDataSelection,
    ]).pipe(
      map(
        ([ companyIndustryData, industryDataSelection ]: [
          FullIndustryDataSet,
          IndustryDataSet
        ]) =>
          industryDataSelection == null
            ? companyIndustryData
            : {
              ...companyIndustryData,
              ...industryDataSelection,
              industryUrl: this._industryService.getIndustryUrl(
                industryDataSelection.sic?.uid ||
                industryDataSelection.subIndustry?.uid ||
                industryDataSelection.industry.uid,
                industryDataSelection.region.uid
              ),
              industryName: industryDataSelection.sic
                ? `${ industryDataSelection.sic.code } - ${ industryDataSelection.sic.name }`
                : industryDataSelection.subIndustry?.name ||
                industryDataSelection.industry.name,
            }
      ),
      cache()
    );
  }

  getCompanyBrief(companyUid: string): Observable<string> {
    return this._companyService.getCompanyBrief(companyUid)
      .pipe(
        catchError(() => of(null)),
        cache(),
      );
  }

  isIndustryCustomized(): Observable<boolean> {
    return combineLatest([
      this.getCompanyIndustryData(),
      this._industryDataSelection,
    ]).pipe(
      map(([ original, selected ]) => {
        return selected != null
          && selected.revenue?.uid !== original.revenue?.uid
      })
    );
  }

  async setIndustryData(dataSet?: IndustryDataSet): Promise<void> {
    const fullIndustryData = await firstValueFrom(
      this.getCompanyIndustryData()
    );
    const company = await firstValueFrom(this.getSelectedNotEmptyCompany());

    if (
      dataSet?.industry.uid === fullIndustryData.industry.uid &&
      dataSet?.subIndustry?.uid === fullIndustryData.subIndustry.uid &&
      dataSet?.sic == null &&
      dataSet?.region.uid === fullIndustryData.region.uid &&
      dataSet?.revenue.uid === fullIndustryData.revenue.uid
    ) {
      dataSet = null;
    }

    if (dataSet) {
      this._amplitudeService.amplitudeEvent('Source Industry Data change', {
        target: 'Public Company',
        name: company.name,
        value: dataSet?.sic !== null ? 'Industry' : 'SIC',
        sourceValue: dataSet?.subIndustry?.name || dataSet.industry.name,
        region: dataSet.region.name,
        revenue: getRevenueText(dataSet.revenue),
      });
    }

    this._industryDataSelection.next(dataSet);
  }

  getIndustryDataRaw(): Observable<IndustryDataSet> {
    return this._industryDataSelection.asObservable();
  }

  clearCompanyData(): void {
    this._industryDataSelection = new BehaviorSubject<IndustryDataSet>(null);

    this._selectedCompany$ = null;
    this._companyRevenue$ = null;
  }
}
