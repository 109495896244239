import { Injectable } from '@angular/core';
import { NavigationEnd, Router, Event as RouterEvent } from '@angular/router';
import { isFuture } from 'date-fns';
import { omit } from 'lodash/fp';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface NotificationsMap {
  [notificationId: string]: NotificationContainerExtended;
}

export enum NotificationType {
  Error,
  Success,
  Warning,
}

export interface NotificationContainer {
  id: string;
  type?: NotificationType;
  message: string;
  data?: any | null;
  removable?: boolean;
  autoRemovable?: boolean;
  autoRemoveTime?: number;
  top?: boolean;
}

export interface NotificationContainerExtended extends NotificationContainer {
  createDate: Date;
  removeDate?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  static readonly generalError = 'general-error';

  private readonly _autoRemovableTime = 3000;
  private _notificationsMap = new BehaviorSubject<NotificationsMap>({});
  private _requestID: number;

  constructor(private _router: Router) {
    this._frameChecker = this._frameChecker.bind(this);

    // eslint-disable-next-line import/no-deprecated
    this._router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.clearNotifications();
      }
    });
  }

  getNotificationsMap(): Observable<NotificationsMap> {
    return this._notificationsMap.asObservable();
  }
  getNotificationsList(): Observable<NotificationContainerExtended[]> {
    return this._notificationsMap.pipe(
      map((notificationsMap: NotificationsMap) =>
        Object.values(notificationsMap)
      )
    );
  }

  addNotification(notification: NotificationContainer): void {
    const createDate = new Date();
    this._notificationsMap.next({
      ...this._notificationsMap.getValue(),
      [notification.id]: {
        ...notification,
        createDate,
        removeDate: notification.autoRemovable
          ? new Date(
              createDate.getTime() +
                (notification.autoRemoveTime || this._autoRemovableTime)
            )
          : null,
      },
    });

    if (notification.autoRemovable && this._requestID == null) {
      this._requestID = window.requestAnimationFrame(this._frameChecker);
    }
  }
  removeNotification(id: string): void {
    const value = { ...this._notificationsMap.getValue() };
    if (value[id] != null) {
      delete value[id];

      this._notificationsMap.next(value);
    }
  }

  addError(notification: NotificationContainer): void {
    this.addNotification({ type: NotificationType.Error, ...notification });
  }
  addSuccess(notification: NotificationContainer): void {
    this.addNotification({ type: NotificationType.Success, ...notification });
  }
  addWarning(notification: NotificationContainer): void {
    this.addNotification({ type: NotificationType.Warning, ...notification });
  }

  clearNotifications(): void {
    this._notificationsMap.next({});

    if (this._requestID != null) {
      window.cancelAnimationFrame(this._requestID);
      this._requestID = null;
    }
  }

  private _frameChecker() {
    const notificationsMapValue = this._notificationsMap.getValue();
    const notificationValues = Object.values(notificationsMapValue);
    const keysToOmit = [];

    for (const notification of notificationValues) {
      if (
        notification.removeDate != null &&
        !isFuture(notification.removeDate)
      ) {
        keysToOmit.push(notification.id);
      }
    }

    if (keysToOmit.length !== 0) {
      this._notificationsMap.next(omit(keysToOmit, notificationsMapValue));
    }

    this._requestID =
      Object.keys(this._notificationsMap.getValue()).length !== 0
        ? window.requestAnimationFrame(this._frameChecker)
        : null;
  }
}
