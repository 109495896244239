import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { HistoryFilterType } from '@app/core/models/history.model';

@Component({
  selector: 'app-list-filters',
  templateUrl: './list-filters.component.html',
  styleUrls: ['./list-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFiltersComponent {
  @Input() isFavorites: boolean;
  @Input() isRecently: boolean;
  @Input() filter: HistoryFilterType;
  @Output() update = new EventEmitter<HistoryFilterType>();
  historyFilterType = HistoryFilterType;

  changeFilter(filter: HistoryFilterType) {
    this.update.emit(filter);
  }
}
