<h4 class="faq-article__heading">
  <span class="faq-article__number">{{ index }}</span
  >{{ data.heading }}
</h4>
<p *ngIf="data.description" class="faq-article__description">
  {{ data.description }}
</p>
<mat-accordion
  *ngIf="data.clarifications && data.clarifications.length !== 0"
  class="faq-article__clarifications"
  displayMode="flat"
  hideToggle="true"
>
  <mat-expansion-panel
    *ngFor="
      let clarification of data.clarifications;
      index as i;
      trackBy: trackClarifications
    "
    class="faq-clarification"
    (afterExpand)="scrollTo(i)"
  >
    <mat-expansion-panel-header class="faq-clarification__question">
      <mat-panel-title class="faq-clarification__title">
        {{ clarification.question }}
        <mat-icon
          class="faq-clarification__toggler"
          svgIcon="navigate_before"
        ></mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="faq-clarification__answer" [innerHTML]="clarification.answer"></div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
