import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { User } from '@app/core/models/user.model';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { UserService } from '@app/core/services/user.service';
import { PopoverRef, PopoverService } from '@app/shared/popover';
import { Observable } from 'rxjs';
import { ProfilePopoverComponent } from './profile-popover/profile-popover.component';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent implements OnInit, OnDestroy {
  user$: Observable<User>;

  private _popoverRef: PopoverRef<ProfilePopoverComponent>;

  constructor(
    private elRef: ElementRef,
    private _popoverService: PopoverService,
    private _userService: UserService,
    private _amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.user$ = this._userService.getCurrentUser();
  }
  ngOnDestroy(): void {
    if (this._popoverRef != null) {
      this._popoverRef.close();
    }
  }

  openPopover(user: User): void {
    if (this._popoverRef != null) {
      return;
    }

    this._amplitudeService.amplitudeEvent('Extended Menu Click');

    this._popoverRef = this._popoverService.open(
      ProfilePopoverComponent,
      this.elRef.nativeElement,
      {
        positions: [
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: this.elRef.nativeElement.getBoundingClientRect().height,
          },
        ],
        data: {
          user,
        },
      }
    );

    this._popoverRef.afterClosed$.subscribe(() => {
      this._popoverRef = null;
    });
  }
}
