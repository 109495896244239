import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlUiBlockerComponent } from './fnl-ui-blocker.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  declarations: [FnlUiBlockerComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [FnlUiBlockerComponent],
})
export class FnlUiBlockerModule {}
