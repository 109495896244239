import { Directive, Input } from '@angular/core';
import { MatOption } from '@angular/material/core';

@Directive({
  selector: 'mat-option[fnlSelectOption]'
})
export class FnlSelectOptionDirective {

  @Input() groupValue: unknown;

  constructor(
    public host: MatOption,
  ) {}
}
