<div class="pagination-entries">
  <ng-container *ngIf="!isOnePage(pageSize, length)">
    <span i18n>Showing </span>
    <span>{{ getStartIndexRange() + 1 }} </span>
    <span i18n>to </span>
    <span>{{ getEndIndexRange() }} </span>
    <span i18n>of </span>
    <span>{{ length }} </span>
    <span i18n>entries</span>
  </ng-container>
</div>
<div #pageSizeContainer class="pagination-page-size" *ngIf="!hidePageSize">
  <app-size-options-button
    [value]="pageSize"
    (toggle)="openSizeOptionsPopover(pageSizeContainer)"
  >
  </app-size-options-button>
</div>

<div class="pagination__navigation">
  <fnl-icon-button
    *ngIf="showFirstLastButtons"
    class="pagination__navigation-button pagination__navigation-first"
    [class.disabled]="_previousButtonsDisabled()"
    i18n-matTooltip
    matTooltip="first page"
    [matTooltipDisabled]="_previousButtonsDisabled()"
    [disabled]="_previousButtonsDisabled()"
    (click)="firstPage()"
  >
    <mat-icon
      class="pagination__navigation-button pagination__navigation-first-icon"
      svgIcon="paginate_first"
    ></mat-icon>
  </fnl-icon-button>
  <fnl-icon-button
    class="pagination__navigation-button pagination__navigation-previous"
    [class.disabled]="_previousButtonsDisabled()"
    i18n-matTooltip
    matTooltip="previous page"
    [matTooltipDisabled]="_previousButtonsDisabled()"
    [disabled]="_previousButtonsDisabled()"
    (click)="previousPage()"
  >
    <mat-icon
      class="pagination__navigation-button pagination__navigation-previous-icon"
      svgIcon="paginate_before"
    ></mat-icon>
  </fnl-icon-button>
  <ul class="pagination__navigation-list">
    <li class="pagination__navigation-list__item">
      <button
        type="button"
        class="pagination__navigation-list__item-link"
        [ngClass]="{
          'pagination__navigation-list__item-link_active': isSelected(0)
        }"
        (click)="firstPage()"
      >
        1
      </button>
    </li>
    <li
      *ngIf="previousEllipsisActive"
      class="pagination__navigation-list__item"
    >
      ...
    </li>
    <li
      *ngFor="let pageIndex of getArrayOfPages()"
      class="pagination__navigation-list__item"
    >
      <button
        type="button"
        class="pagination__navigation-list__item-link"
        [ngClass]="{
          'pagination__navigation-list__item-link_active': isSelected(pageIndex)
        }"
        (click)="goTo(pageIndex)"
      >
        {{ pageIndex + 1 }}
      </button>
    </li>
    <li *ngIf="nextEllipsisActive" class="pagination__navigation-list__item">
      ...
    </li>
    <li *ngIf="isLastPageVisible" class="pagination__navigation-list__item">
      <button
        type="button"
        class="pagination__navigation-list__item-link"
        [ngClass]="{
          'pagination__navigation-list__item-link_active': isSelected(
            getNumberOfPages() - 1
          )
        }"
        (click)="lastPage()"
      >
        {{ getNumberOfPages() }}
      </button>
    </li>
  </ul>
  <fnl-icon-button
    class="pagination__navigation-button pagination__navigation-next"
    [class.disabled]="_nextButtonsDisabled()"
    i18n-matTooltip
    matTooltip="next page"
    [matTooltipDisabled]="_nextButtonsDisabled()"
    [disabled]="_nextButtonsDisabled()"
    (click)="nextPage()"
  >
    <mat-icon
      class="pagination__navigation-button pagination__navigation-next-icon"
      svgIcon="paginate_next"
    ></mat-icon>
  </fnl-icon-button>
  <fnl-icon-button
    *ngIf="showFirstLastButtons"
    class="pagination__navigation-button pagination__navigation-last"
    [class.disabled]="_nextButtonsDisabled()"
    i18n-matTooltip
    matTooltip="last page"
    [matTooltipDisabled]="_nextButtonsDisabled()"
    [disabled]="_nextButtonsDisabled()"
    (click)="lastPage()"
  >
    <mat-icon
      class="pagination__navigation-button pagination__navigation-last-icon"
      svgIcon="paginate_last"
    ></mat-icon>
  </fnl-icon-button>
</div>
