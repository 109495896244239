<button
  type="button"
  tabindex="-1"
  class="profile-button"
  [class.profile-button_opened]="isOpened"
  matRipple
>
  <img
    *ngIf="user?.avatarUrl != null"
    class="profile-button__avatar profile-button__avatar_image"
    [src]="user?.avatarUrl"
    title="User avatar"
  />
  <div
    *ngIf="user?.avatarUrl == null"
    class="profile-button__avatar profile-button__avatar_initials"
  >
    {{ initials }}
  </div>
  <div class="profile-button__username" [title]="user.name">
    {{ user.name }}
  </div>
  <mat-icon class="profile-button__arrow" svgIcon="navigate_before"></mat-icon>
</button>
