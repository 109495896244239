<button
  type="button"
  class="more-actions-button"
  [class.more-actions-button_opened]="isOpened"
  [disabled]="disabled"
  aria-label="More"
  mat-button
>
  <mat-icon class="more-actions-button__icon" svgIcon="more"></mat-icon>
</button>
