import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, distinctUntilChanged, Observable, of, switchMap } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

@Injectable()
export class DebounceInterceptor implements HttpInterceptor {

  constructor(
    private debounceInterceptorService: DebounceInterceptorService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.debounceInterceptorService.increaseRequestsInProgress();

    return next.handle(request)
      .pipe(
        finalize(() => this.debounceInterceptorService.decreaseRequestsInProgress()),
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class DebounceInterceptorService {
  /**
   * Indicates that there have been no requests for some time.
   */
  requestsIdle$: Observable<boolean>;

  private requestsInProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() {
    this.requestsIdle$ = this.requestsInProgress
      .pipe(
        switchMap((requestsInProgress: number) => requestsInProgress === 0
          ? of(true)
            .pipe(debounceTime(1000))
          : of(false)
        ),
        distinctUntilChanged(),
      );
  }

  /**
   * Increase the number of requests in progress.
   */
  increaseRequestsInProgress(): void {
    this.requestsInProgress.next(this.requestsInProgress.value + 1);
  }

  /**
   * Decrease the number of requests in progress.
   */
  decreaseRequestsInProgress(): void {
    this.requestsInProgress.next(this.requestsInProgress.value === 0 ? 0 : this.requestsInProgress.value - 1);
  }

  /**
   * Reset the number of requests in progress.
   */
  resetRequestsInProgress(): void {
    this.requestsInProgress.next(0);
  }
}
