<div
  *ngIf="isBlocking"
  tabindex="0"
  (keyup.tab)="tabbedUpTop($event)"
  (keydown.tab)="tabbedDownTop($event)"
  #topFocus
></div>

<ng-content></ng-content>

<div
  *ngIf="isBlocking && spinnerStyle === 'default'"
  class="blocked-ui__container"
  tabindex="0"
  (keyup.shift.tab)="tabbedUpBottom($event)"
  (keydown.shift.tab)="tabbedDownBottom($event)"
  #blocker
>
  <div class="blocked-ui__overlay"></div>
  <div class="blocked-ui__message">
    <mat-spinner
      class="blocked-ui__spinner"
      color="primary"
      [diameter]="!smallLock ? 80 : 20"
    ></mat-spinner>
  </div>
</div>

<div
  *ngIf="isBlocking && spinnerStyle === 'square'"
  class="blocked-ui__container blocked-ui__container--custom"
  tabindex="0"
  (keyup.shift.tab)="tabbedUpBottom($event)"
  (keydown.shift.tab)="tabbedDownBottom($event)"
  #blocker
>
  <div class="blocked-ui__overlay"></div>
  <div class="blocked-ui__message blocked-ui__message--custom">
    <img src="assets/gifs/square-spinner.gif" alt="Spinner" height="150" width="150">
    <p *ngIf="spinnerText" class="blocked-ui__message-text">{{spinnerText}}</p>
  </div>
</div>
<span #helper></span>
