import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { HotjarService } from '@app/core/services/hotjar.service';

@Component({
  selector: 'app-hotjar',
  templateUrl: './hotjar.component.html',
  styleUrls: ['./hotjar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotjarComponent implements OnInit, OnDestroy {
  @Input() skipLoad = false;

  private readonly _hotjarId = '_hotjar';

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2,
    private _hotjarService: HotjarService
  ) {}

  ngOnInit(): void {
    const scriptElement = this._document.getElementById(this._hotjarId);

    if (!scriptElement && !this.skipLoad) {
      const hotjarSettings = this._hotjarService.injectHotjar();

      if (hotjarSettings != null) {
        const script = this._renderer2.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.language = 'javascript';
        script.id = this._hotjarId;
        script.src = `https://static.hotjar.com/c/hotjar-${hotjarSettings.hjid}.js?sv=${hotjarSettings.hjsv}`;

        script.onload = () => {
          this._hotjarService.enable();
        };

        this._renderer2.appendChild(this._document.head, script);
      }
    } else {
      this._hotjarService.enable();
    }
  }
  ngOnDestroy(): void {
    this._hotjarService.disable();
  }
}
