<ng-container *ngIf="isAllDataReceived">
  <app-currency-button
    #button
    *ngIf="inputLike === 'none'; else elseInputLike"
    class="currency__button"
    [currency]="displayCurrency"
    [placeholder]="placeholder"
    (click)="openCurrencyPopover()"
  ></app-currency-button>
  <ng-template #elseInputLike>
    <app-currency-input
      #button
      class="currency__button"
      [currency]="displayCurrency"
      [formView]="inputLike === 'form'"
      [placeholder]="placeholder"
      (click)="openCurrencyPopover()"
    ></app-currency-input>
  </ng-template>
</ng-container>
