<div class="size-options" (click)="onClick()">
  <span class="size-options__label i18n">Rows per page:</span>
  <div class="size-options__popover">
    <div
      class="size-options-button"
      [class.active]="isOpened"
      [class.disabled]="disabled"
    >
      <div class="size-options-button__text">{{ value }}</div>
      <mat-icon
        *ngIf="!disabled"
        svgIcon="paginate_down"
        class="size-options-button__arrow"
      ></mat-icon>
    </div>
  </div>
</div>
