import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { HistoryItem, HistoryTheme } from '@app/core/models/history.model';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { trackByUid } from '@app/core/utils/base';

@Component({
  selector: 'app-history-view',
  templateUrl: './history-view.component.html',
  styleUrls: ['./history-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryViewComponent {
  @Input() isFavorites: boolean;
  @Input() items: HistoryItem[];
  @Input() theme: HistoryTheme;
  @Output() remove = new EventEmitter<HistoryItem>();
  @Output() navigate = new EventEmitter<HistoryItem>();
  trackByUid = trackByUid;

  constructor(private _amplitudeService: AmplitudeService) {}

  get isDefaultTheme(): boolean {
    return this.theme === HistoryTheme.Default;
  }

  get isHeaderTheme(): boolean {
    return this.theme === HistoryTheme.Header;
  }

  isCompany(item: HistoryItem): boolean {
    return !!item.company;
  }

  isIndustry(item: HistoryItem): boolean {
    return !item.company;
  }

  itemName(item: HistoryItem): string {
    const industry = item.subIndustry || item.industry;
    const company = item.company;
    const sic = item.standardIndustrialClassification;

    if (industry) {
      return industry.name;
    } else if (company) {
      return company.name;
    } else if (sic) {
      return `${sic.name} (SIC: ${sic.code})`;
    } else {
      return '';
    }
  }

  itemRegion(item: HistoryItem): string {
    return item.region && item.region.name;
  }

  navigatedTo(item: HistoryItem, isFavorites: boolean) {
    const industryName =
      item.industry?.name ||
      item.subIndustry?.name ||
      item.standardIndustrialClassification?.name;

    const props = industryName
      ? {
          target: 'Industry Research',
          name: industryName,
        }
      : item.isPrivateCompany
      ? {
          target: 'Private Company',
          name: item.company.name,
        }
      : {
          target: 'Public Company',
          name: item.company.name,
        };
    this._amplitudeService.amplitudeEvent(
      isFavorites ? 'Favorites - Pick' : 'Recently Viewed - Pick',
      props
    );

    return this.navigate.emit(item);
  }

  removeItem(item: HistoryItem) {
    this.remove.emit(item);
  }

  getRoute(item: HistoryItem): string[] {
    const industryOrSic =
      item.subIndustry ||
      item.industry ||
      item.standardIndustrialClassification;
    const company = item.company;

    if (industryOrSic != null) {
      return ['/industry-research', industryOrSic.uid, item.region.uid];
    } else {
      const isPrivate = item.isPrivateCompany
        ? '/private-account-discovery'
        : '/account-discovery';
      return [isPrivate, company.name, company.uid];
    }
  }
}
