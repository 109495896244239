import { NgModule } from '@angular/core';
import { FnlErrorNoDataComponent } from './error-no-data.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    FnlErrorNoDataComponent
  ],
  imports: [
    MatIconModule
  ],
  exports: [
    FnlErrorNoDataComponent
  ]
})
export class FnlErrorNoDataModule { }
