import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-help-content-success',
  templateUrl: './help-content-success.component.html',
  styleUrls: ['./help-content-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpContentSuccessComponent {
  @Output() reply = new EventEmitter<void>();

  toggleSidePanel(): void {
    this.reply.emit();
  }
}
