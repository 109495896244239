import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  HostBinding,
} from '@angular/core';
import { PopoverRef } from '@app/shared/popover';

@Component({
  selector: 'app-priority-info-popover',
  templateUrl: './priority-info-popover.component.html',
  styleUrls: ['./priority-info-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityInfoPopoverComponent {
  @ViewChild('content') contentRef: ElementRef<HTMLElement>;
  @HostBinding('class.priority-info-popover') popover = true;

  constructor(private _popoverRef: PopoverRef<PriorityInfoPopoverComponent>) {}

  closePopover() {
    this._popoverRef.close();
  }
}
