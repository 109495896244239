import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { LayoutModule } from '@app/core/layout/layout.module';
import { SharedModule } from '@app/shared/shared.module';
import { environment } from 'src/environments/environment';

import { BrowserStorageModule } from './core/utils/storage/storage.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { FnlIconModule, FnlLearnMoreModule, FnlUiBlockerService } from 'fnl-ui';
import { BlockerService } from '@app/core/services/blocker.service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    LayoutModule,
    FnlLearnMoreModule,
    BrowserStorageModule.forRoot(),
    LoadingBarRouterModule,
    FnlIconModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    })
  ],
  providers: [
    {
      provide: BlockerService,
      useExisting: FnlUiBlockerService,
    },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    { provide: Window, useValue: window },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
