import { BaseItem } from '@app/core/models/common.model';
import { FishboneSelection } from '@app/shared/fishbone/fishbone.model';

export enum AnalysisView {
  CompanyTrends = '0',
  PeerComparison = '1',
}

export interface AddToFavoritesEvent {
  favoritesUid?: string;
  companyUid?: string;
  isPrivateCompany?: boolean;
}

export interface TabRouteLink {
  label: string;
  link: string;
}

export type PursuitMetric = BaseItem;

export interface MetricPursuitPreset {
  metric: PursuitMetric;
  fishboneSteps: FishboneSelection[];
}

export enum PursuitPresetType {
  GeographicalSegment,
  BusinessSegment,
}

export interface AdditionalPursuitData {
  uid: string;
  label: string;
}

export interface AdditionalPursuitPreset {
  type: PursuitPresetType;
  dataList: AdditionalPursuitData[];
}

export interface PursuitSelectionSet {
  metricPresets: MetricPursuitPreset[];
  additionalPresets: AdditionalPursuitPreset[];
}
