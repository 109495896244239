import { Injectable } from '@angular/core';
import { LearnMoreApiService, LearnMoreNodeApi } from '@app/api/common/learn-more/learn-more-api.service';
import { UUID } from '@app/core/models/common.model';
import { BehaviorSubject, first, Observable } from 'rxjs';
import {
  LearnMoreArticle,
  LearnMoreArticleUpdated,
  LearnMoreArticleVisibility,
  LearnMoreNodeClientArticle,
  LearnMoreTree,
  UpdateLearnMoreArticle,
} from './models/learn-more.model';
import { map, mergeMap } from 'rxjs/operators';

export interface TopicVisibilityDictionary {
  [index: string]: LearnMoreArticleVisibility
}

interface ClientTopicVisibilityCache {
  expires: number,
  data: BehaviorSubject<TopicVisibilityDictionary>
}

interface ClientTopicVisibilityCacheDictionary {
  [index: UUID]: ClientTopicVisibilityCache
}

@Injectable({
  providedIn: 'root',
})
export class LearnMoreRepository {

  private readonly blockCid = 'LearnMoreRepository';

  private readonly topicVisibilitiesCacheDuration = 360_000;
  private readonly topicVisibilitiesCache: ClientTopicVisibilityCacheDictionary = {};

  constructor(private learnMoreApiService: LearnMoreApiService) {
  }

  getTree(blockCid?: string): Observable<LearnMoreTree> {
    return this.learnMoreApiService.getTree(blockCid || this.blockCid);
  }

  listTopicsVisibility(clientUid: UUID): Observable<TopicVisibilityDictionary> {
    const now = Date.now();

    const cacheDictionary = this.topicVisibilitiesCache;
    if (!cacheDictionary[clientUid]) {
      cacheDictionary[clientUid] = { expires: now, data: new BehaviorSubject<TopicVisibilityDictionary>({}) }
    }

    const cache = cacheDictionary[clientUid];
    if (cache.expires <= now) {
      // This is needed to have only one refresh request
      cache.expires = now + this.topicVisibilitiesCacheDuration;

      this.learnMoreApiService.listTopicVisibilities(clientUid)
        .pipe(
          first(),
          map(result => result.reduce(
            (aggregator, item) => {
              aggregator[item.code] = item;
              return aggregator
            },
            {} as TopicVisibilityDictionary
          ))
        )
        .subscribe(visibilities => cache.data.next(visibilities))
    }

    return cache.data.asObservable();
  }

  isTopicVisible(clientUid: UUID, code: string): Observable<boolean | null> {
    return this.listTopicsVisibility(clientUid).pipe(
      map(topicMap => topicMap[code]),
      map(topicVisibility => topicVisibility?.visible)
    )
  }

  getLearnMoreTopic(
    clientUid: UUID,
    topicCode: string,
    blockCid?: string
  ): Observable<LearnMoreArticle> {
    return this.learnMoreApiService.getLearnMoreArticle(
      clientUid,
      topicCode,
      blockCid || this.blockCid
    );
  }

  updateLearnMoreTopic(
    clientUid: UUID,
    elementCode: string,
    data: UpdateLearnMoreArticle,
    blockCid?: string
  ): Observable<LearnMoreArticleUpdated> {
    return this.learnMoreApiService.updateLearnMoreArticle(
      clientUid,
      elementCode,
      data,
      blockCid || this.blockCid
    );
  }

  deleteLearnMoreTopic(
    clientUid: UUID,
    elementCode: string,
    blockCid?: string
  ): Observable<void> {
    return this.learnMoreApiService.deleteLearnMoreArticle(
      clientUid,
      elementCode,
      blockCid || this.blockCid
    );
  }

  listNodeClientArticles(
    topicCode: string,
    blockCid?: string
  ): Observable<LearnMoreNodeClientArticle> {
    return this.learnMoreApiService.getLearnMoreNodeByCode(topicCode, blockCid || this.blockCid)
      .pipe(
        mergeMap((node: LearnMoreNodeApi) => node.articles),
      );
  }
}
