<div class="help-content__success">
  <div class="help-content__success-content">
    <mat-icon
      class="help-content__success-icon"
      svgIcon="check-status"
    ></mat-icon>

    <p class="help-content__success-text">
      <span i18n>Your message has been successfully sent.</span>
      <span i18n>Check your e-mail, we will get back to you shortly.</span>
    </p>

    <button
      i18n
      mat-stroked-button
      color="primary"
      class="help-content__success-button button-primary_outlined"
      (click)="toggleSidePanel()"
    >
      OK
    </button>
  </div>
</div>
