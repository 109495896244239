import { Company as CompanyModel } from './company.model';
import { Industry as IndustryModel, Sic } from './industry.model';
import { Region } from './region.model';

export class HistoryItem {
  uid: string;
  lastViewDate: string;
  industry?: IndustryModel;
  subIndustry?: IndustryModel;
  standardIndustrialClassification?: Sic;
  region: Region;
  company?: CompanyModel;
  isPrivateCompany?: boolean;
  isCustomCompany?: boolean;
}

export enum HistoryFilterType {
  All,
  Company,
  Industry,
}

export enum HistoryTheme {
  Default,
  Header,
}
