<div class="currency-popover" [class.currency-popover_full-width]="isFullWidth">
  <app-currency-button
    #handle
    *ngIf="inputLike === 'none'; else elseInputLike"
    class="currency-popover__handle"
    [isOpened]="true"
    [currency]="currency"
    [style.width]="width + 'px'"
    [placeholder]="placeholder"
    (click)="closePopover()"
  ></app-currency-button>
  <ng-template #elseInputLike>
    <app-currency-input
      #handle
      class="currency-popover__handle currency-popover__handle_input"
      [isOpened]="true"
      [currency]="currency"
      [style.width]="width + 'px'"
      [placeholder]="placeholder"
      (click)="closePopover()"
    ></app-currency-input>
  </ng-template>
  <div
    #content
    class="currency-popover__content"
    [ngStyle]="{
      width:
        isFullWidth || containerWidth !== 0 ? containerWidth + 'px' : 'auto'
    }"
  >
    <div class="currency-popover__heading">
      <div class="currency-popover__heading-label" i18n>Popular Currencies</div>
    </div>
    <app-currency-list
      class="currency-popover__list currency-popover__list_popular"
      [list]="favoriteCurrencies"
      (update)="updateSelectedCurrency($event)"
    ></app-currency-list>
    <div class="currency-popover__heading">
      <div
        class="currency-popover__heading-label currency-popover__heading-label_all"
        i18n
      >
        All Currencies
      </div>
    </div>
    <app-field-search
      class="currency-popover__filter"
      [defaultValue]="filter"
      [isDark]="true"
      (search)="updateFilter($event)"
    ></app-field-search>
    <app-currency-list
      class="currency-popover__list currency-popover__list_all"
      [list]="nonFavoriteCurrencies | filterCurrencies: filter"
      (update)="updateSelectedCurrency($event)"
    ></app-currency-list>
  </div>
</div>
