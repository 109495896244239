import { Injectable } from '@angular/core';
import { ClientKpisApiService } from '@app/api/fishbone/client-kpis/client-kpis-api.service';
import { KpiGroup } from '@app/core/models/kpi.model';
import { Observable, map } from 'rxjs';
import { convertKpiGroupListFromApiToClient } from './helpers/client-kpis.helper';

@Injectable({
  providedIn: 'root',
})
export class ClientKpisRepository {
  private readonly blockCid = 'ClientKpisRepository';

  constructor(private clientKpisApiService: ClientKpisApiService) {}

  getKpiGroups(clientUid: string, queryParams, blockCid?: string): Observable<KpiGroup[]> {
    return this.clientKpisApiService.listPresentationKpis(clientUid, queryParams, blockCid || this.blockCid)
    .pipe(
      map((data) => convertKpiGroupListFromApiToClient(data.items))
    );
  }

}
