import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerActive = new BehaviorSubject<boolean>(false);

  getSpinnerActive(): Observable<boolean> {
    return this.spinnerActive.asObservable();
  }

  show() {
    this.spinnerActive.next(true);
  }

  hide() {
    this.spinnerActive.next(false);
  }
}
