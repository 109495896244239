import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  Injector,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EMPTY_UID } from '@app/core/constants';
import { BaseGroupItem, BaseItem } from '@app/core/models/common.model';
import { FinAbstractControlComponent } from '@app/core/utils/abstract-control.component';
import { PopoverRef, PopoverService } from '@app/shared/popover';
import { tuiDefaultProp } from '@taiga-ui/cdk';

import { BaseSelectorButtonComponent } from './base-selector-button/base-selector-button.component';
import { BaseSelectorInputComponent } from './base-selector-input/base-selector-input.component';
import { BaseSelectorPopoverComponent } from './base-selector-popover/base-selector-popover.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-base-selector',
  templateUrl: './base-selector.component.html',
  styleUrls: ['./base-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BaseSelectorComponent),
    },
  ],
  imports: [
    CommonModule,
    BaseSelectorButtonComponent,
    BaseSelectorInputComponent
  ]
})
export class BaseSelectorComponent
  extends FinAbstractControlComponent<string>
  implements OnDestroy
{
  @Input() selected: string;
  @Output() selectedChange = new EventEmitter<string>();
  @Input()
  @tuiDefaultProp()
  items: BaseItem[] | BaseGroupItem[] = [];
  @Input() placeholder?: string;
  @Input()
  @tuiDefaultProp()
  inputLike: 'none' | 'base' | 'form' = 'none';
  @Input()
  @tuiDefaultProp()
  inverted = false;
  @Input()
  @tuiDefaultProp()
  disabled = false;
  @Input() classNames?: { button?: string; popover?: string };
  @Input()
  @tuiDefaultProp()
  focusable = true;
  @Input() invalid = false;
  @Input() notChangeSelected = false;
  @Input() notChangeItem: BaseItem;
  @ViewChild('button') buttonRef:
    | BaseSelectorButtonComponent
    | BaseSelectorInputComponent;

  private _popoverRef: PopoverRef<BaseSelectorPopoverComponent>;

  constructor(
    private _elRef: ElementRef,
    private _popoverService: PopoverService,
    injector: Injector,
    cdr: ChangeDetectorRef
  ) {
    super(injector, cdr);
  }

  get withGroups(): boolean {
    return 'items' in ((this.items || [])[0] || {});
  }

  get visualSelected(): BaseItem {
    const itemsToSearch = !this.withGroups
      ? (this.items as BaseItem[])
      : (this.items as BaseGroupItem[])?.reduce(
          (result, item) => result.concat(item.items),
          []
        );
    return this.notChangeSelected
      ? this.notChangeItem
      : itemsToSearch?.find((item) => item.uid === this.selected);
  }

  private get _popoverPositions(): ConnectedPosition[] {
    const height =
      this.buttonRef.element.nativeElement.getBoundingClientRect().height;
    return [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: height,
        panelClass:
          this.classNames?.popover == null
            ? 'base-selector-popover__top-start'
            : ['base-selector-popover__top-start', this.classNames?.popover],
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -height,
        panelClass:
          this.classNames?.popover == null
            ? 'base-selector-popover__bottom-start'
            : ['base-selector-popover__bottom-start', this.classNames?.popover],
      },
    ];
  }

  ngOnDestroy(): void {
    if (this._popoverRef != null) {
      this._popoverRef.close();
      this._popoverRef = null;
    }
  }

  writeValue(selected: string): void {
    this.selected = selected;
    this._cdr.markForCheck();
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  openPopover(): void {
    if (this.disabled || this._popoverRef != null) {
      return;
    }
    this.buttonRef.blur();
    let skipFocus = !this.focusable;

    this._popoverRef = this._popoverService.open(
      BaseSelectorPopoverComponent,
      this._elRef.nativeElement,
      {
        positions: this._popoverPositions,
        data: {
          items: this.items,
          withGroups: this.withGroups,
          selected: this.visualSelected,
          width: this._elRef.nativeElement.getBoundingClientRect().width,
          inputLike: this.inputLike,
          inverted: this.inverted,
          placeholder: this.placeholder,
          classNames: this.classNames,
        },
      }
    );
    this._popoverRef.overlayRef.outsidePointerEvents().subscribe(() => {
      skipFocus = true;
    });
    this._popoverRef.afterClosed$.subscribe((value: string) => {
      if (value != null) {
        this.selected = value;
        this.selectedChange.emit(this.selected);
        this._onChange(this.selected);
      }
      this._cdr.markForCheck();

      this.onTouch();
      // Clear ref only after rerendering to prevent reopen popover on button focus and space click
      setTimeout(() => {
        this._popoverRef = null;
      }, 100);

      if (!skipFocus) {
        this.buttonRef.focus();
      }
    });
  }

  onTouch(): void {
    if (!this._touched || !this._formControl.touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
