<div
  class="base-selector-popover"
  [class.base-selector-popover_full-width]="isFullWidth"
  [class.base-selector-popover_inverted]="inverted"
  (keyup.esc)="closePopover()"
>
  <app-base-selector-button
    #handle
    *ngIf="!fullInput; else elseInputLike"
    class="base-selector-popover__handle"
    [ngClass]="classNames?.button"
    [isOpened]="true"
    [selected]="selected"
    [style.width]="width + 'px'"
    [placeholder]="placeholder"
    (click)="closePopover()"
  ></app-base-selector-button>
  <ng-template #elseInputLike>
    <app-base-selector-input
      #handle
      class="base-selector-popover__handle base-selector-popover__handle-input"
      [class.base-selector-popover__handle_inverted]="inverted"
      [ngClass]="classNames?.button"
      [isOpened]="true"
      [selected]="selected"
      [inverted]="inverted"
      [style.width]="width + 'px'"
      [placeholder]="placeholder"
      (click)="closePopover()"
    ></app-base-selector-input>
  </ng-template>
  <div
    #content
    class="base-selector-popover__content"
    [class.base-selector-popover__content_inverted]="inverted"
    [ngStyle]="{
      width:
        isFullWidth || containerWidth !== 0 ? containerWidth + 'px' : 'auto'
    }"
  >
    <mat-selection-list
      #list
      class="base-selector-popover__list base-selector-list"
      [class.base-selector-list_inverted]="inverted"
      cdkTrapFocus
      [cdkTrapFocusAutoCapture]="true"
      [multiple]="false"
      [disableRipple]="false"
      (selectionChange)="updateSelection($event)"
      (keypress)="runPreSearch($event)"
    >
      <ng-container *ngIf="!withGroups; else groupTemplate">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              list: items
            }
          "
        ></ng-container>
      </ng-container>

      <ng-template #itemTemplate let-list="list">
        <mat-list-option
          *ngFor="let item of list"
          [selected]="isItemSelected(item)"
          [value]="item.uid"
          class="base-selector-list__item"
          [class.base-selector-list__item_input-like]="fullInput"
          [class.base-selector-list__item_inverted]="inverted"
          [class.base-selector-list__item_selected]="isItemSelected(item)"
          (click)="updateSelected(item)"
        >
          {{ item.name }}
        </mat-list-option>
      </ng-template>
      <ng-template #groupTemplate>
        <ng-container *ngFor="let item of groups; trackBy: trackByUid">
          <div
            *ngIf="item.name"
            matSubheader
            class="base-list-group__name"
            [class.base-list-group__name_input-like]="fullInput"
            [class.base-list-group__name_inverted]="inverted"
          >
            {{ item.name }}
          </div>
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                list: item.items
              }
            "
          ></ng-container>
        </ng-container>
      </ng-template>
    </mat-selection-list>
  </div>
</div>
