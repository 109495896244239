import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ClientFeatureCode } from '../models/client.model';
import { UserSettingsService } from '../services/user-settings.service';

@Injectable()
export class PursuitsFeatureGuard  {
  constructor(
    private _router: Router,
    private _userSettingsService: UserSettingsService
  ) {}
  canActivate(): Observable<boolean | UrlTree> {
    return this._userSettingsService
      .isFeatureEnabled(ClientFeatureCode.Pursuit)
      .pipe(
        map((isVisible: boolean) =>
          !isVisible ? this._router.createUrlTree(['not-found']) : true
        )
      );
  }
}
