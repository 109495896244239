<ng-container
  *tuiLet="{
    portal: portal$ | async,
    showLegacyLink: showLegacyLink$ | async
  } as data"
>
  <div *ngIf="data.portal != null" class="footer__portal">
    <ng-container [cdkPortalOutlet]="data.portal"></ng-container>
  </div>
  <div
    class="footer__copyright"
    [class.footer__copyright_with-legacy]="data.showLegacyLink"
    [class.footer__copyright_with-portal]="data.portal != null"
    i18n
  >
    Copyright © {{ currentYear }} FinListics Solutions. All rights reserved.
    Financial data presented in the system is according to the provided
    companies' reporting.
    <div>
      <a
        [routerLink]="['/public', 'privacy-policy']"
        target="_blank"
        (click)="amplitudeTracking('Privacy Policy Click')"
        >Privacy Policy</a
      >
      <span>|</span>
      <a
        [routerLink]="['/public', 'terms-of-use']"
        target="_blank"
        (click)="amplitudeTracking('Terms of Use Click')"
        >Terms of Use</a
      >
    </div>
  </div>

  <div *ngIf="data.showLegacyLink" class="footer__go-back-legacy">
    <a
      mat-button
      [href]="goToBackLink"
      target="_blank"
      rel="noopener noreferrer"
      i18n
      (click)="amplitudeTracking('View Classic ClientIQ Click')"
    >
      {{ linkBackToLegacyTitle$ | async }}
    </a>
  </div>
</ng-container>
