import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Peer, PeerData } from '@app/core/models/peer.model';
import { isNil, omitBy } from 'lodash';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PeerService {
  constructor(private _apiService: ApiService) {
  }

  getCompanyPeers(
    companyUId: string,
    publicCompany = true,
    industryRevenueGroupUid?: string,
    blockCid?: string
  ): Observable<PeerData> {
    return this._apiService
      .get<PeerData>(
        publicCompany
          ? 'company/company/peers'
          : 'company/private-company/peers',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                [publicCompany ? 'companyUid' : 'privateCompanyUid']:
                companyUId,
                industryRevenueGroupUid,
              },
              isNil
            ),
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of({ recommendedPeers: [], savedPeers: [] })));
  }

  getOtherCompanyPeers(
    companyUId: string,
    searchQuery: string,
    quantity?: number,
    publicCompany = true,
    blockCid?: string
  ): Observable<Peer[]> {
    return this._apiService
      .get<Peer[]>(
        publicCompany
          ? 'company/Company/search-peers'
          : 'company/Company/search-private-company-peers',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                [publicCompany ? 'companyUid' : 'privateCompanyUid']: companyUId,
                searchQuery,
                quantity: quantity?.toString(),
              },
              isNil
            ),
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of([] as Peer[])));
  }

  async savePeersForCompany(
    companyUId: string,
    peerCompaniesUids: string[],
    publicCompany = true,
    blockCid?: string
  ): Promise<void> {
    const result = await firstValueFrom(
      this._apiService
        .post(
          publicCompany
            ? 'company/company/save-user-peers'
            : 'company/private-company/save-user-peers',
          {
            [publicCompany ? 'companyUid' : 'privateCompanyUid']: companyUId,
            peerCompaniesUids,
          },
          blockCid,
          true
        )
        .pipe(catchError(() => of(false)))
    );

    if (result) {
      // success result
    }
  }
}
