<app-base-selector-button
  #button
  *ngIf="inputLike === 'none'; else elseInputLike"
  [class]="classNames?.button"
  (click)="openPopover()"
  [selected]="visualSelected"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [focusable]="focusable"
></app-base-selector-button>
<ng-template #elseInputLike>
  <app-base-selector-input
    #button
    [class]="classNames?.button"
    [selected]="visualSelected"
    [placeholder]="placeholder"
    [inverted]="inverted"
    [disabled]="disabled"
    [formView]="inputLike === 'form'"
    (click)="openPopover()"
    [focusable]="focusable"
    [invalid]="invalid"
  ></app-base-selector-input>
</ng-template>
