import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { CustomValidators } from '@app/core/validators';
import { firstValueFrom } from 'rxjs';

import { MessageInfo } from '../../side-panel-list.model';
import { SidePanelListService } from '../../side-panel-list.service';

@Component({
  selector: 'app-help-content-form',
  templateUrl: './help-content-form.component.html',
  styleUrls: ['./help-content-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpContentFormComponent implements OnDestroy {
  @Output() successChange = new EventEmitter<boolean>();

  helpPanelForm: FormGroup<{
    message: FormControl<string | null>;
  }>;
  isFormSubmitted = false;
  isFormCleared = false;
  isErrorOpened = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private sidePanelListService: SidePanelListService,
    private spinnerService: SpinnerService,
    private _amplitudeService: AmplitudeService
  ) {
    this.helpPanelForm = new FormGroup<{
      message: FormControl<string | null>;
    }>({
      message: new FormControl('', [CustomValidators.notBlank]),
    });
  }

  hasError(controlName: string, errorName: string) {
    const control = this.helpPanelForm.controls[controlName];
    return (
      control.invalid &&
      this.isFormSubmitted &&
      !this.isFormCleared &&
      control.hasError(errorName)
    );
  }

  onSubmit(data?: Partial<MessageInfo>) {
    this.checkFormValidation(data);
  }

  checkFormValidation(helpPanelFormValue: Partial<MessageInfo>) {
    this.isFormSubmitted = true;
    this.isFormCleared = false;

    if (this.helpPanelForm.valid) {
      this.sendMessage(helpPanelFormValue);
    }
  }

  async sendMessage(messageInfo: Partial<MessageInfo>): Promise<void> {
    this.spinnerService.show();
    try {
      const result = await firstValueFrom(
        this.sidePanelListService.sendMessage({
          ...messageInfo,
          currentUrl: window.location.pathname,
        } as MessageInfo)
      );
      this.successChange.emit(result);
      this.isErrorOpened = !result;
      this.cdr.markForCheck();
      this._amplitudeService.amplitudeEvent('Feedback - Send Message Click', {
        message: messageInfo.message,
      });
    } catch (e) {
      this.successChange.emit(false);
      this.isErrorOpened = true;
      this.cdr.markForCheck();
    }
  }

  reset(): void {
    this.isFormSubmitted = false;
    this.isErrorOpened = false;
    this.isFormCleared = false;

    this.helpPanelForm.reset();
    this.cdr.markForCheck();
  }

  handleHideError(): void {
    this.isErrorOpened = false;
  }

  ngOnDestroy() {
    this.reset();
  }
}
