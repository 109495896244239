import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { format, isValid, parse, isBefore, isAfter } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class AppDateAdapter extends NativeDateAdapter {
  static readonly pattern = 'MM/dd/yyyy';
  private readonly _minDate = new Date(1900, 0, 1);
  private readonly _maxDate = new Date(2999, 11, 31);

  getMinDate(): Date {
    return this._minDate;
  }

  getMaxDate(): Date {
    return this._maxDate;
  }

  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      return format(date, AppDateAdapter.pattern);
    }

    return format(date, displayFormat);
  }

  parse(value: any): Date | null {
    if (value && typeof value === 'string') {
      value = value.replace('.', '').slice(0, AppDateAdapter.pattern.length);
    }

    let result: Date | null;

    result = parse(value, AppDateAdapter.pattern, new Date());
    if (
      !isValid(result) ||
      isBefore(result, this._minDate) ||
      isAfter(result, this._maxDate)
    ) {
      result = null;
    }

    return result;
  }
}
