import { JourneyType } from '@app/core/services/journey.api.service';

export const JOURNEY_TYPES_NAMES: Record<JourneyType, string> = {
  [JourneyType.ResearchProspects]: 'Research Prospects',
  [JourneyType.GetTheMeeting]: 'Get the Meeting',
  [JourneyType.PreparationForMeeting]: 'Prep for a Meeting',
  [JourneyType.ValueModeler]: 'Value Modeler',
  [JourneyType.Pursuit]: 'Pursuit',
};

export const JOURNEY_TYPES_LONG_NAMES: Record<JourneyType, string> = {
  [JourneyType.PreparationForMeeting]: 'Preparation for Meeting',
  [JourneyType.GetTheMeeting]: 'Get the Meeting',
  [JourneyType.ResearchProspects]: 'Research Prospects',
  [JourneyType.Pursuit]: 'Pursuit analysis',
  [JourneyType.ValueModeler]: 'Value Modeler analysis',
};

export const JOURNEY_TYPES_DEFAULT_SUB_NAMES: Record<JourneyType, string> = {
  [JourneyType.PreparationForMeeting]: 'Presentation',
  [JourneyType.GetTheMeeting]: 'Email',
  [JourneyType.ResearchProspects]: 'Research Prospects',
  [JourneyType.Pursuit]: 'Pursuit',
  [JourneyType.ValueModeler]: 'Value Modeler',
};
