import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnlPaperComponent } from './paper.component';

@NgModule({
  declarations: [FnlPaperComponent],
  imports: [CommonModule],
  exports: [FnlPaperComponent],
})
export class FnlPaperModule {}
