<button
  type="button"
  class="currency-button"
  [class.currency-button_opened]="isOpened"
  tabindex="0"
>
  <span *ngIf="placeholder" class="base-selector-button__label">
    {{ placeholder }}{{ currency != null ? ':' : '' }}
  </span>
  <div class="currency-button__value">
    {{ currency?.code }}
  </div>
  <mat-icon class="currency-button__arrow" svgIcon="navigate_before"></mat-icon>
</button>
