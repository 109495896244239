import { Pipe, PipeTransform } from '@angular/core';

import { HistoryFilterType, HistoryItem } from '@app/core/models/history.model';

@Pipe({
  name: 'historyFilter',
})
export class HistoryFilterPipe implements PipeTransform {
  transform(items: HistoryItem[], filter: HistoryFilterType): HistoryItem[] {
    if (!items || filter === HistoryFilterType.All) {
      return items;
    }

    const isIndustryFilter = filter === HistoryFilterType.Industry;
    return items.filter(item => {
      const isIndustryItem = !item.company;
      return isIndustryFilter === isIndustryItem;
    });
  }
}
