<div class="list-filters">
  <mat-button-toggle-group
    class="list-filters__group"
    name="listFilter"
    [value]="filter"
    (change)="changeFilter($event.value)"
  >
    <mat-button-toggle
      *ngIf="isFavorites"
      class="list-filters__group-item"
      i18n
      [value]="historyFilterType.All"
      >All Favorites</mat-button-toggle
    >
    <mat-button-toggle
      *ngIf="isRecently"
      class="list-filters__group-item"
      i18n
      [value]="historyFilterType.All"
      >All Recently Viewed</mat-button-toggle
    >
    <mat-button-toggle
      class="list-filters__group-item"
      i18n
      [value]="historyFilterType.Industry"
      >Industry</mat-button-toggle
    >
    <mat-button-toggle
      class="list-filters__group-item"
      i18n
      [value]="historyFilterType.Company"
      >Company</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
